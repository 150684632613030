import { ColDef, ColumnApi, GridApi, HeaderValueGetterParams } from "ag-grid-community";
import AngularRedirect from "components/common/tabNavigator/angularRedirect";
import useConfirmModal from "components/modals/confirmModal/useConfirmModalHook";
import PopoverRender from "components/popovers/popoverRender";
import _, { sortBy } from "lodash";
import { restApiService } from "providers/restApi";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "reducers";
import ExpenseReportApis from "services/admin/expenses/expenseReport/expenseReportApis";
import { ExpenseReportTypes } from "services/admin/expenses/expenseReport/expenseReportType";
import { ExpensesTypes } from "services/admin/expenses/expensesType";
import expenseService from "services/admin/expenses/expenseSvc";
import commonService from "services/common/commonSvc";
import { setAllowedFilters } from "services/common/gridService";
import paymentTypeService from "services/common/paymentMethod/paymentTypeSvc";
import { FieldFilterMapType } from "services/common/types/grid.type";
import { IUser } from "services/common/user/userTypes";
import { companyDateFormat } from "services/general/dateSvc";
import { formattedAmount } from "services/general/helpers";
import { CreateNotification, NotificationType } from "services/general/notifications";
import { translate } from "services/general/translation";
import gridService from "services/grid/gridSvc";
import PayExpense from "../components/payExpense";
import styles from "./payExpense.module.css";

type CellRendererParamsType = {
  value: any;
  data: ExpenseReportTypes.ListItem;
};

export const FIELD_NAME_MAP: FieldFilterMapType = {
  id: {
    equals: "id",
  },
  number: {
    contains: "number",
  },
  "purchase_order.number": {
    equals: "purchase_order_number",
    blank: "purchase_order_number_has_data",
    notBlank: "purchase_order_number_has_data",
  },
  vendor_name: {
    contains: "vendor_name",
    blank: "vendor_name_has_data",
    notBlank: "vendor_name_has_data",
  },
  status: {
    equals: "statuses",
    default: "statuses",
  },
  date: {
    inRange: "",
    lessThanOrEqual: "date_before",
    greaterThanOrEqual: "date_after",
  },
  due_date: {
    inRange: "",
    lessThanOrEqual: "date_before",
    greaterThanOrEqual: "date_after",
  },
  payment_submission_window: {
    inRange: "",
    lessThanOrEqual: "date_before",
    greaterThanOrEqual: "date_after",
  },
  total: {
    inRange: "",
    lessThanOrEqual: "total_less_than",
    greaterThanOrEqual: "total_greater_than",
  },
  currency_code: {
    equals: "currency_iso_code",
  },
  submit_date: {
    inRange: "",
    lessThanOrEqual: "submit_date_before",
    greaterThanOrEqual: "submit_date_after",
  },
  "subsidiary.name": {
    contains: "subsidiary_name",
  },
  "policy.name": {
    contains: "policy_name",
  },
  project_name: {
    contains: "project_name",
  },
  "submitter.name": {
    contains: "submitter_name",
  },
  "employee.name": {
    contains: "employee_name",
  },
  has_violations: {
    equals: "violation",
    default: "violation",
  },
  reimbursable: {
    equals: "reimbursable",
  },
  "expense_report.number": {
    contains: "expense_report_number",
    blank: "expense_report_has_data",
    notBlank: "expense_report_has_data",
  },
};

const Actions = ({ data, gridApi }: { data: ExpenseReportTypes.ListItem; gridApi?: GridApi }) => {
  const currentUser: IUser = useTypedSelector((state) => state.user);
  const { t } = useTranslation();
  const { createConfirmModal } = useConfirmModal();

  const onDeleteConfirm = async () => {
    try {
      if (data.id && gridApi) {
        const result = await ExpenseReportApis.delete(data.id);
        gridService.removeRowFromGrid(gridApi, String(data.id));
        CreateNotification(t("success"), `Invoice Number ${data.number} deleted.`, NotificationType.success);
      }
    } catch (error) {
      CreateNotification(`Error Details`, `Unable to connect.`, NotificationType.danger);
    }
  };

  const createDeleteConfirmation = () => {
    createConfirmModal({
      title: "Confirm",
      body: `Are you sure you want to delete Invoice #${data.number}`,
      callBackData: null,
      cancelCallBack: null,
      confirmButtonLabel: "OK",
      saveCallBack: onDeleteConfirm,
    });
  };
  const { pay_amount } = data;
  return (
    <>
      <a href={restApiService.makeAngularURLWithId("expense_report/", data.id)}>
        <button type="button" className="actionButtons view" data-toggle="tooltip" title="View"></button>
      </a>
      {expenseService.canEditOrDeleteExpenseItem(data as ExpensesTypes.ListItem, currentUser) && (
        <>
          <button
            type="button"
            className="actionButtons icon-delete"
            onClick={createDeleteConfirmation}
            data-toggle="tooltip"
            title="View"
          ></button>
          {pay_amount && pay_amount > 0 && (
            <PayExpense title="Submit for Payment Approval" selectedExpenseReport={data} />
          )}
        </>
      )}
    </>
  );
};

export const getPayExpenseItemHeader = ({
  gridApi,
  gridColumnApi,
  currentUser,
  checkBoxRef,
}: {
  gridApi?: GridApi;
  gridColumnApi?: ColumnApi;
  currentUser: IUser;
  checkBoxRef: any;
}): { columnDefs: ColDef[]; defaultOrder: string[] } => {
  let columnDefs: ColDef[] = [
    {
      headerName: "Select",
      field: "select",
      filter: false,
      headerCheckboxSelection: true,
      headerValueGetter: function (params: HeaderValueGetterParams) {
        return "Select";
      },
      resizable: false,
      pinned: true,
      lockPinned: true,
      checkboxSelection: true,
      minWidth: 35,
      width: 35,
      maxWidth: 35,
    },
    {
      field: "id",
      headerName: "Id",
      sortable: true,
      hide: true,
      filter: false,
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return translate("ID");
      },
      minWidth: 50,
    },
    {
      field: "external_id",
      headerName: "External ID",
      sortable: true,
      hide: true,
      filter: false,
      filterParams: {
        suppressAndOrCondition: true,
      },
      minWidth: 100,
    },
    {
      field: "number",
      headerName: "Number",
      sortable: true,
      filter: "agTextColumnFilter",
      cellRenderer: (params: CellRendererParamsType) => (
        <AngularRedirect to={"expense_report/" + params?.data?.id}>{params?.data?.number}</AngularRedirect>
      ),
      filterParams: {
        suppressAndOrCondition: true,
      },
      minWidth: 100,
    },
    {
      field: "employee.name",
      headerName: "Employee",
      sortable: true,
      filter: false,
      cellRendererParams: {
        redirectToAngular: true,
      },
      cellRenderer: (params: any) => {
        return _.isPlainObject(params.data.employee) ? (
          <a className="link" href={restApiService.makeAngularURLWithId("contacts/", params.data.employee.id)}>
            {params.data.employee.name}
          </a>
        ) : null;
      },
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return translate("employee");
      },
      minWidth: 100,
    },
    {
      field: "purchase_order",
      headerName: "Purchase Order",
      hide: true,
      filter: false,
      cellRenderer: "childCellRouting",
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return translate("poNumber");
      },
      minWidth: 100,
    },
    {
      field: "is_po_approved",
      headerName: "PO Approved",
      hide: true,
      filter: false,
      cellClass: "justify-content-end",
      minWidth: 115,
    },
    {
      field: "vendor.requestor.name",
      headerName: "Vendor Acct Mgr",
      hide: true,
      filter: false,
      filterParams: {
        suppressAndOrCondition: true,
      },
      minWidth: 200,
    },
    {
      field: "date",
      headerName: "Date",
      sortable: true,
      hide: true,
      sort: "desc",
      filter: "agDateColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
      },
      cellRenderer: (params: any) => {
        return companyDateFormat(params.value, currentUser);
      },
      headerValueGetter: function () {
        return translate("date");
      },
      minWidth: 100,
    },
    {
      field: "due_date",
      headerName: "Due Date",
      sortable: true,
      sort: "desc",
      filter: "agDateColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
      },
      cellRenderer: (params: any) => {
        return companyDateFormat(params.value, currentUser);
      },
      minWidth: 150,
    },
    {
      field: "due_status",
      headerName: "Due Status",
      headerValueGetter: () => translate("dueStatus"),
      sortable: false,
      filter: false,
      cellRenderer: (params: CellRendererParamsType) => {
        return (
          <span className={commonService.getDueDateClass(params?.data?.due_status)}>{params?.data?.due_status}</span>
        );
      },
      floatingFilterComponent: "agCustomSelectFilter",
      floatingFilterComponentParams: {
        suppressFilterButton: true,
        options: [
          { value: "DUE TODAY", label: "DUE TODAY" },
          { value: "DUE SOON", label: "DUE SOON" },
          { value: "PAST DUE", label: "PAST DUE" },
          { value: "NEW", label: "NEW" },
          { value: "PAID", label: "PAID" },
          { value: "PAID (PROCESSING)", label: "PAID (PROCESSING)" },
        ],
      },
      minWidth: 100,
    },
    {
      field: "submit_date",
      headerName: "Submit Date",
      hide: true,
      sortable: true,
      filter: false,
      filterParams: {
        suppressAndOrCondition: true,
      },
      cellRenderer: (params: any) => {
        return companyDateFormat(params.value, currentUser);
      },
      headerValueGetter: function () {
        return translate("submitDate");
      },
      minWidth: 115,
    },
    {
      field: "amount",
      headerName: "Amount",
      sortable: true,
      cellClass: "justify-content-end",
      filter: false,
      cellRenderer: (params: any) => formattedAmount(params.value, params.data?.currency_code, 2, true),
      filterParams: {
        suppressAndOrCondition: true,
      },
      minWidth: 110,
    },
    {
      field: "amount_in_functional",
      headerName: "Functional Amount",
      sortable: true,
      hide: true,
      cellClass: "justify-content-end",
      filter: false,
      cellRenderer: (params: any) => formattedAmount(params.value, params.data?.currency_code, 2, true),
      filterParams: {
        suppressAndOrCondition: true,
      },
      minWidth: 150,
    },
    {
      field: "orig_amount",
      headerName: "Original Amount",
      sortable: true,
      hide: true,
      cellClass: "justify-content-end",
      filter: false,
      cellRenderer: (params: any) => formattedAmount(params.value, params.data?.currency_code, 2, true),
      filterParams: {
        suppressAndOrCondition: true,
      },
      minWidth: 150,
    },
    {
      field: "balance_outstanding",
      headerName: "Outstanding Balance",
      sortable: true,
      hide: true,
      cellClass: "justify-content-end",
      filter: false,
      cellRenderer: (params: any) => formattedAmount(params.value, params.data?.currency_code, 2, true),
      filterParams: {
        suppressAndOrCondition: true,
      },
      minWidth: 150,
    },
    {
      field: "outstanding_balance_pending",
      headerName: "Open Balance",
      sortable: true,
      hide: true,
      cellClass: "justify-content-end",
      filter: false,
      cellRenderer: (params: any) => formattedAmount(params.value, params.data?.currency_code, 2, true),
      filterParams: {
        suppressAndOrCondition: true,
      },
      minWidth: 110,
    },
    {
      field: "po_budget_total",
      headerName: "PO Budget Total",
      sortable: true,
      hide: true,
      cellClass: "justify-content-end",
      filter: false,
      cellRenderer: (params: any) => formattedAmount(params.value, params.data?.currency_code, 2, true),
      filterParams: {
        suppressAndOrCondition: true,
      },

      minWidth: 150,
    },
    {
      field: "notes",
      headerName: "Note",
      hide: true,
      filter: false,
      minWidth: 100,
    },
    {
      field: "subsidiary.name",
      headerName: "Subsidiary",
      sortable: true,
      filter: "agTextColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
      },
      minWidth: 100,
      hide: true,
    },
    {
      field: "currency.iso_code",
      headerName: "Currency Code",
      sortable: true,
      filter: false,
      filterParams: {
        suppressAndOrCondition: true,
      },
      minWidth: 120,
    },
    {
      field: "amount_disc",
      headerName: "Available Discount",
      sortable: true,
      hide: true,
      cellClass: "justify-content-end",
      filter: false,
      cellRenderer: (params: any) => formattedAmount(params.value, params.data?.currency_code, 2, true),
      filterParams: {
        suppressAndOrCondition: true,
      },
      minWidth: 150,
    },
    {
      field: "amount_disc_date",
      headerName: "Discount Expired Date",
      hide: true,
      sortable: true,
      filter: false,
      filterParams: {
        suppressAndOrCondition: true,
      },
      cellRenderer: (params: any) => {
        return companyDateFormat(params.value, currentUser);
      },
      minWidth: 150,
    },
    {
      field: "pay_amount",
      headerName: "Payment Amount",
      sortable: true,
      cellClass: "justify-content-end",
      cellRenderer: (params: any) => {
        return (
          <form className={`${styles.paymentAmountCell}`} name="inputForm">
            <input
              onChange={(e) => {
                params.setValue(Number(e.target.value));
              }}
              type="number"
              min={0.01}
              max={params?.data?.outstanding_balance_pending}
              defaultValue={params?.value}
              className={`${styles.paymentAmountEditCell}`}
            />
          </form>
        );
      },
      filterParams: {
        suppressAndOrCondition: true,
      },
      filter: false,
      minWidth: 150,
      cellStyle: (params) => {
        return { paddingLeft: "0px", paddingRight: "0px" };
      },
    },
    {
      field: "payment_submission_window",
      headerName: "Payment Date",
      sortable: true,
      filter: false,
      filterParams: {
        suppressAndOrCondition: true,
      },
      cellRenderer: (params: any) => {
        return (
          <input
            type="text"
            name="dateInput"
            onChange={(e) => {
              params.setValue(e.target.value);
            }}
            defaultValue={companyDateFormat(params.value, currentUser)}
            className={`${styles.paymentDateCell}`}
          />
        );
      },
      minWidth: 115,
      cellStyle: (params) => {
        return { paddingLeft: "0px", paddingRight: "0px" };
      },
    },
    {
      field: "purchase_orders_gross_amount",
      headerName: "Gross PO Amount",
      sortable: true,
      hide: true,
      cellClass: "justify-content-end",
      filter: false,
      cellRenderer: (params: any) => formattedAmount(params.value, params.data?.currency_code, 2, true),
      filterParams: {
        suppressAndOrCondition: true,
      },
      minWidth: 150,
    },
    {
      field: "purchase_orders_open_balance",
      headerName: "Open PO Balance",
      sortable: true,
      hide: true,
      cellClass: "justify-content-end",
      filter: false,
      cellRenderer: (params: any) => formattedAmount(params.value, params.data?.currency_code, 2, true),
      filterParams: {
        suppressAndOrCondition: true,
      },
      minWidth: 150,
    },
    {
      field: "term.code",
      headerName: "Invoice Terms",
      headerValueGetter: () => "Invoice Terms",
      minWidth: 150,
      hide: true,
      sortable: false,
      filter: false,
    },
    {
      field: "status",
      headerName: "Status",
      sortable: true,
      hide: true,
      filter: false,
      filterParams: {
        suppressAndOrCondition: true,
      },
      floatingFilterComponent: "agCustomSelectFilter",
      floatingFilterComponentParams: {
        suppressFilterButton: true,
        options: [
          { label: "ALL", value: "" },
          { label: "PENDING", value: ["PENDING"] },
          { label: "REJECTED", value: ["REJECTED"] },
          { label: "APPROVED", value: ["APPROVED"] },
          { label: "NEW", value: ["NEW"] },
          { label: "PAID", value: ["PAID", "PAID (PROCESSING)"] },
          { label: "DRAFT", value: ["DRAFT"] },
        ],
      },
      headerClass: styles.agGridHeaderColor,
      headerComponent: (params: any) => (
        <div draggable className="d-flex align-items-center">
          {translate("status")}
          <PopoverRender
            popoverClassName={"status-tooltip"}
            placement="auto"
            title="Status Definitions"
            content={
              <Row>
                <Col sm="3" className={styles.statusTitle}>
                  New:
                </Col>
                <Col sm="9">Newly created, needs to be reviewed and added to expense report</Col>
                <Col sm="3" className={styles.statusTitle}>
                  Draft:
                </Col>
                <Col sm="9">
                  Expense has been edited and saved as a draft, needs to be reviewed and added to expense report
                </Col>
                <Col sm="3" className={styles.statusTitle}>
                  Pending:
                </Col>
                <Col sm="9"> Submitted for and awaiting approval</Col>
                <Col sm="3" className={styles.statusTitle}>
                  Approved:
                </Col>
                <Col sm="9"> All approvals completed</Col>
                <Col sm="3" className={styles.statusTitle}>
                  Paid:
                </Col>
                <Col sm="9"> Fully processed and expense reimbursement paid to requestor</Col>
                <Col sm="3" className={styles.statusTitle}>
                  Rejected:
                </Col>
                <Col sm="9"> Rejected during approval, no action needed</Col>{" "}
              </Row>
            }
            className="icon icon-info-blue mt-3 mx-1"
          />
        </div>
      ),
      headerValueGetter: function () {
        return translate("status");
      },
      minWidth: 80,
    },
    {
      field: "description",
      headerName: translate("description"),
      headerValueGetter: () => translate("description"),
      filter: false,
      minWidth: 100,
    },
    {
      field: "payment_method.name",
      headerName: "Payment Method",
      hide: true,
      filter: false,
      minWidth: 200,
    },
    {
      field: "payment_method.payment_type",
      headerName: "Payment Method Type",
      cellRenderer: (params: any) =>
        params?.value ? paymentTypeService.getPaymentMethodLabel(params?.value, currentUser) : "",
      filter: false,
      hide: true,
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return translate("payment_method_type");
      },
      minWidth: 180,
    },
    {
      field: "payment_method.program_id",
      headerName: "Payment Method Program ID",
      hide: true,
      filter: false,
      minWidth: 200,
    },
    {
      field: "payment_method_from.payment_type",
      headerName: "Payment Method From",
      sortable: false,
      filter: false,
      headerValueGetter: function () {
        return translate("payment_method_name");
      },
      cellRenderer: (params: any) => {
        const payment_method_from = params.data?.payment_method_from;
        return `${payment_method_from?.payment_type ?? ""} - ${payment_method_from?.name ?? ""}`;
      },
      minWidth: 200,
    },
    {
      field: "payment_method_from.currency_code",
      headerName: "Payment Method From Currency",
      sortable: true,
      filter: false,
      filterParams: {
        suppressAndOrCondition: true,
      },
      minWidth: 200,
      hide: true,
    },
    {
      field: "service_start_date",
      headerName: "Service Start Date",
      hide: true,
      sortable: true,
      filter: false,
      filterParams: {
        suppressAndOrCondition: true,
      },
      cellRenderer: (params: any) => {
        return companyDateFormat(params.value, currentUser);
      },
      minWidth: 200,
    },
    {
      field: "service_end_date",
      headerName: "Service End Date",
      hide: true,
      sortable: true,
      filter: false,
      filterParams: {
        suppressAndOrCondition: true,
      },
      cellRenderer: (params: any) => {
        return companyDateFormat(params.value, currentUser);
      },
      minWidth: 200,
    },

    {
      field: "submitter.name",
      headerName: "Submitter",
      sortable: true,
      filter: false,
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return translate("submitter");
      },
      minWidth: 100,
      hide: true,
    },
    {
      field: "submitter.submitter_type",
      headerName: translate("submitterType"),
      headerValueGetter: () => translate("submitterType"),
      sortable: false,
      filter: false,
      minWidth: 150,
      hide: true,
    },
    {
      field: "payment_run_number",
      headerName: "Payment Run Batch Number",
      sortable: true,
      filter: false,
      filterParams: {
        suppressAndOrCondition: true,
      },
      minWidth: 100,
      hide: true,
    },
    {
      field: "amount_overDue",
      headerName: "Amt Overdue",
      sortable: true,
      filter: false,
      filterParams: {
        suppressAndOrCondition: true,
      },
      minWidth: 100,
      hide: true,
    },
    {
      field: "amt_overdue_0_to_30",
      headerName: "Amt Due < 30 Days",
      sortable: true,
      filter: false,
      filterParams: {
        suppressAndOrCondition: true,
      },
      minWidth: 150,
      hide: true,
    },
    {
      field: "amt_overdue_31_to_60",
      headerName: "Amt Due 30-60 Days",
      sortable: true,
      filter: false,
      filterParams: {
        suppressAndOrCondition: true,
      },
      minWidth: 150,
    },
    {
      field: "amt_overdue_61_to_over",
      headerName: "Amt Due Over 61 days",
      sortable: true,
      filter: false,
      filterParams: {
        suppressAndOrCondition: true,
      },
      minWidth: 150,
    },
    {
      field: "Actions",
      cellRenderer: (params: any) => {
        return (
          <>
            <Actions data={params?.data} gridApi={gridApi} />
          </>
        );
      },
      sortable: false,
      resizable: false,
      filter: false,

      headerValueGetter: function () {
        return translate("actions");
      },
      minWidth: 120,
    },
  ];
  const param: any = {};
  setAllowedFilters(columnDefs, FIELD_NAME_MAP);
  const defaultOrder = columnDefs.map((col: any) => col.field);
  return {
    columnDefs: sortBy(
      columnDefs,
      (col) => col?.headerValueGetter && typeof col?.headerValueGetter === "function" && col.headerValueGetter(param),
    ),
    defaultOrder,
  };
};
