import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Button, Col, Container, Modal, Row, Form, Table } from "react-bootstrap";
import { InputSelect } from "../../inputs/inputSelect";
import { ReportTemplateApis } from "../../../../../services/admin/reports/templates/reportTemplateApis";
import { ReportTemplatesDropdownPropsType } from "../../../../../services/admin/reports/reportTypes";
import {
  ReportTemplateOptionType,
  ReportTemplateDetailType,
  ReportTemplateModuleType,
} from "../../../../../services/admin/reports/templates/reportTemplateType";

const ReportTemplateDropdown = ({
  classification,
  moduleType,
  codeContains,
  onChange,
  reportTemplateChosen,
}: ReportTemplatesDropdownPropsType) => {
  const [reportTemplateOptions, setReportTemplateOptions] = useState<ReportTemplateOptionType[]>([]);

  const loadReportTemplates = async () => {
    let reportTemplates = await ReportTemplateApis.getList({
      classification: classification,
      code_contains: codeContains ?? "",
    });
    let options: ReportTemplateOptionType[] = [];
    reportTemplates
      .filter((reportTemplate) => reportTemplate)
      .forEach((reportTemplate) => {
        options.push({
          label: reportTemplate.name,
          value: reportTemplate.id,
          code: reportTemplate.code,
          module_type: reportTemplate.module_type,
          filters: reportTemplate.filters,
          export_format: reportTemplate.export_format,
          export_destination: reportTemplate.export_destination,
          filter_config: reportTemplate.filter_config,
          properties: reportTemplate.properties,
        });
      });

    setReportTemplateOptions(options);
  };

  const filterOptionsByModuleType = () => {
    const filteredOptions: ReportTemplateOptionType[] = [];

    reportTemplateOptions
      .filter((reportTemplate) => reportTemplate.module_type === moduleType)
      .forEach((reportTemplate) => {
        filteredOptions.push({
          label: reportTemplate.label,
          value: reportTemplate.value,
          code: reportTemplate.code,
          module_type: reportTemplate.module_type,
          filters: reportTemplate.filters,
          export_format: reportTemplate.export_format,
          export_destination: reportTemplate.export_destination,
          filter_config: reportTemplate.filter_config,
          properties: reportTemplate.properties,
        });
      });

    return filteredOptions;
  };

  const handleReportTemplateChosen = useCallback(
    async (input: {
      value: number;
      label: string;
      code: string;
      module_type: ReportTemplateModuleType;
      export_format: string;
      export_destination: string;
      filters: { [key: string]: any }[];
      filter_config: { [key: string]: any };
      properties?: { [key: string]: any };
    }): Promise<void> => {
      if (!input) {
        onChange({ value: 0, label: "", code: "", module_type: undefined, filters: [] });
      } else {
        onChange(input);
      }
    },
    [],
  );

  useEffect(() => {
    loadReportTemplates();
  }, []);

  return (
    <Row className="mx-0">
      <Col className="px-0">
        <InputSelect
          options={filterOptionsByModuleType()}
          label="Report Type"
          labelClassName={`font-weight-bold`}
          placeholder="Select one"
          isRequired
          onChange={handleReportTemplateChosen}
          defaultValue={reportTemplateChosen}
        />
      </Col>
    </Row>
  );
};

export default ReportTemplateDropdown;
