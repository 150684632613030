import { ColDef } from "ag-grid-community";
import TooltipRender from "components/toolTip/tooltipRender";
import { sortBy } from "lodash";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FILE_INTEGRATION_STATUSES } from "services/common/documents/documentTypes";
import { setAllowedFilters } from "services/common/gridService";
import { FieldFilterMapType } from "services/common/types/grid.type";
import { companyDateFormat } from "services/general/dateSvc";
import { translate } from "services/general/translation";
import styles from "wombatifier/components/bulk_upload/bulkUpload.module.css";
import { BulkUploadFilesRowAction } from "wombatifier/components/bulk_upload/bulkUploadFilesRowAction";
export const FIELD_NAME_MAP: FieldFilterMapType = {
  name: {
    contains: "name",
  },
  created_at: {
    inRange: "",
    lessThanOrEqual: "created_at_before",
    greaterThanOrEqual: "created_at_after",
  },
  document_type: {
    contains: "document_type_contains",
  },
};

export const getBulkUploadsHeaders = ({
  currentUser,
}: {
  currentUser: any;
}): { columnDefs: ColDef[]; defaultOrder: string[] } => {
  let columnDefs = [
    {
      field: "name",
      sortable: true,
      filter: "agTextColumnFilter",
      cellClass: "routingCellButtonImportant",
      filterParams: {
        suppressAndOrCondition: true,
      },
      onCellClicked: (event: any) => {
        if (event && event.data && event.data.assets && event.data.assets.length > 0) {
          window.open(event.data.assets[0].asset_expiring_url, "_blank");
        }
      },
      headerValueGetter: function () {
        return translate("file_name");
      },
    },
    {
      field: "status",
      headerName: "Status",
      cellRenderer: "agGroupCellRenderer",
      cellRendererParams: {
        innerRenderer: (params: any) => {
          const label = (params.data.status || "").replace(/\_/g, " ");
          if ((label || "").includes("ERROR")) {
            return <div className={`rounded-pill px-2 py-1 my-1 ${styles.errorStatusPill}`}>{label}</div>;
          }
          return <div>{label}</div>;
        },
      },
      filter: "agTextColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
      },
      floatingFilterComponent: "agCustomSelectFilter",
      floatingFilterComponentParams: {
        suppressFilterButton: true,
        options: [
          {
            label: "ALL",
            value: "",
          },
          ...FILE_INTEGRATION_STATUSES.map((status) => ({ label: status, value: status })),
        ],
      },
      headerValueGetter: function () {
        return translate("status");
      },
      headerComponent: (params: any) => {
        return (
          <>
            {params.displayName}
            <TooltipRender
              containerClassName={`p-0 m-0 ${styles.tooltip}`}
              className="icon m-0 p-0 icon-info-blue background-position-center"
              title={
                <Container className={`p-0 m-0 py-2 pb-3 px-3 text-left`}>
                  <Row className="m-0 pb-2">
                    <Col className="p-0">
                      <span className={styles.tooltipLabel}>Status Definitions</span>
                    </Col>
                  </Row>

                  <Row className="m-0">
                    <Col className="p-0">
                      <Row className="m-0">Completed:</Row>
                      <Row className="m-0">Pending Processing:</Row>
                      <Row className="m-0">Completed with Errors:</Row>
                    </Col>
                    <Col className="p-0">
                      <Row className="m-0">File successfully uploaded</Row>
                      <Row className="m-0">Upload has not been completed</Row>
                      <Row className="m-0">File uploaded with errors</Row>
                    </Col>
                  </Row>
                </Container>
              }
            />
          </>
        );
      },
    },
    {
      field: "document_type",
      headerName: "Document Type",
      sortable: true,
      filter: "agTextColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return translate("file_type");
      },
    },
    {
      field: "created_at",
      headerName: "Date",
      sortable: true,
      filter: "agDateColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
      },
      cellRenderer: (params: any) => {
        return companyDateFormat(params.value, currentUser);
      },
      headerValueGetter: function () {
        return translate("upload_date");
      },
      minWidth: 120,
    },
    {
      field: "id",
      headerName: "ID",
      sortable: true,
      filter: "agTextColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return translate("file_id");
      },
    },
    {
      field: "ZActions",
      filter: false,
      cellRenderer: (params: any) => {
        return <BulkUploadFilesRowAction data={params.data} />;
      },
      sortable: false,
      resizable: false,
      maxWidth: 200,
      headerValueGetter: function () {
        return translate("actions");
      },
    },
  ];
  setAllowedFilters(columnDefs, FIELD_NAME_MAP);
  const defaultOrder = columnDefs.map((col: any) => col.field);
  return { columnDefs: sortBy(columnDefs, (col) => col.headerValueGetter()), defaultOrder };
};
