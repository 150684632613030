import CurrencySymbolRenderer from "components/admin/commonUsed/currencySymbolRenderer";
import AccountPicker from "components/admin/pickers/reactHookFormPickers/accountPicker";
import BusinessUnitPicker from "components/admin/pickers/reactHookFormPickers/businessUnitPicker";
import DepartmentPicker from "components/admin/pickers/reactHookFormPickers/departmentPicker";
import LocationPicker from "components/admin/pickers/reactHookFormPickers/locationPicker";
import ProductItemPicker from "components/admin/pickers/reactHookFormPickers/productItemPicker";
import { ProductItemPickerType } from "components/admin/pickers/reactHookFormPickers/productItemPicker/productItemPickerTypes";
import ProjectPicker from "components/admin/pickers/reactHookFormPickers/projectPicker";
import TaxCodePicker from "components/admin/pickers/reactHookFormPickers/taxCodePicker";
import UnitPicker, { TUnitProps } from "components/admin/pickers/reactHookFormPickers/unitPicker";
import { InputField, TextAreaField } from "components/forms/hookFormFields";
import _ from "lodash";
import React, { memo, useCallback } from "react";
import { UseFieldArrayAppend, UseFieldArrayUpdate, useFormContext } from "react-hook-form";
import { BsBan, BsCheck2, BsJournalPlus, BsLink45Deg, BsPencil, BsTrash, BsXLg } from "react-icons/bs";
import { useTypedSelector } from "reducers";
import adminCommonSvc from "services/admin/commonSvc";
import invoiceCommonSvcCls from "services/admin/invoices/invoiceCommonSvc";
import { InvoiceType } from "services/admin/invoices/invoiceType";
import { useInvoiceCommonSvc } from "services/admin/invoices/useInvoiceCommonSvc";
import { IUser } from "services/common/user/userTypes";
import styles from "../materialDataTable.module.css";

type TInvoiceItemLine = {
  is3WayMatch: boolean;
  item: InvoiceType.TInvoiceItem; // chances that this should be updated to generic
  selectedItem: InvoiceType.TInvoiceItem | undefined;
  index: number;
  update: UseFieldArrayUpdate<InvoiceType.TInvoiceItem, string>;
  append: UseFieldArrayAppend<InvoiceType.TInvoiceItem, string>;
  linkPoItemCallback: (item: any) => void;
  unlinkPoItemCallback: (id: number) => void;
  cancelLinkPoItemCallback: (item: any) => void;
  showLinkActions: boolean;
};

const InvoiceItemLine = ({
  is3WayMatch,
  item,
  selectedItem,
  index,
  update,
  append,
  linkPoItemCallback,
  unlinkPoItemCallback,
  showLinkActions,
  cancelLinkPoItemCallback,
}: TInvoiceItemLine) => {
  const { getValues, setValue, trigger } = useFormContext<any>();
  const invoiceCommonSvc = useInvoiceCommonSvc();
  const currentUser: IUser = useTypedSelector((state) => state.user);

  const calculateAmount = useCallback(
    (invItem: InvoiceType.TInvoiceItem) => {
      let unitPrice =
        invItem.unit_price && !isNaN(invItem.unit_price)
          ? invoiceCommonSvc.roundUpAmount(
              Number(invItem.unit_price),
              adminCommonSvc.unitPriceDecimalLimit(currentUser),
            )
          : 0;

      let qty =
        invItem.qty && !isNaN(invItem.qty)
          ? invoiceCommonSvc.roundUpAmount(Number(invItem.qty), adminCommonSvc.unitPriceDecimalLimit(currentUser))
          : 0;
      return invoiceCommonSvc.roundUpAmount(unitPrice * qty, adminCommonSvc.unitPriceDecimalLimit(currentUser));
    },
    [currentUser, invoiceCommonSvc],
  );

  const updateItemDebitAccountAmount = useCallback(
    (invItem: InvoiceType.TInvoiceItem, index: number) => {
      if (invItem.id && invItem.id > 0 && invItem.account_id) {
        let debit_entries_attributes = getValues("debit_entries_attributes");
        let itemTotal = invoiceCommonSvc.getItemTotal(invItem);

        if (Array.isArray(debit_entries_attributes) && debit_entries_attributes.length > 0) {
          let updatedEntries = debit_entries_attributes.map((entry: any, index: number) => {
            if (entry.item_line_id === invItem.id) {
              return {
                ...entry,
                amount: itemTotal,
                account_id: invItem.account_id,
                product_item_id: invItem.product_item_id,
              };
            }
            return entry;
          });

          setValue("debit_entries_attributes", updatedEntries);
        }
      }
    },
    [getValues, invoiceCommonSvc, setValue],
  );

  const calculateItemLineTax = useCallback(
    (invItem: InvoiceType.TInvoiceItem, index: number) => {
      if (invItem.amount && invItem.tax_code) {
        let tax = invItem.tax_code?.rate
          ? invoiceCommonSvc.roundUpAmount(
              (invItem.amount / 100) * invItem.tax_code?.rate,
              adminCommonSvc.unitPriceDecimalLimit(currentUser),
            )
          : 0;
        setValue(`invoice_items_attributes.${index}.tax`, tax);
      } else if (!invItem.amount && !invItem.tax_code) {
        setValue(`invoice_items_attributes.${index}.tax_id`, null);
        setValue(`invoice_items_attributes.${index}.tax_code`, undefined);
        setValue(`invoice_items_attributes.${index}.tax`, null);
      }
      let itemTotal = invoiceCommonSvc.getItemTotal(invItem);
      invItem.total = itemTotal;
      setValue(`invoice_items_attributes.${index}.total`, invItem.total);
      updateItemDebitAccountAmount(invItem, index);
    },
    [currentUser, invoiceCommonSvc, setValue, updateItemDebitAccountAmount],
  );

  const manageUnitPrice = (index: number, unitPrice: number) => {
    let itemUnitPrice = Number(
      invoiceCommonSvc.roundUpAmount(unitPrice, adminCommonSvc.unitPriceDecimalLimit(currentUser)),
    );
    setValue(`invoice_items_attributes.${index}.unit_price`, itemUnitPrice);
    calculateTax(index);
  };

  const calculateTax = useCallback(
    (index: number) => {
      // Retrieve the latest values of the invoice item from the form using getValues(), rather than relying on the original invoice item which may contain outdated data.
      let invItem = getValues(`invoice_items_attributes.${index}`);
      invItem.amount = calculateAmount(invItem);
      setValue(`invoice_items_attributes.${index}.amount`, invItem.amount);
      calculateItemLineTax(invItem, index);
    },
    [calculateAmount, calculateItemLineTax, getValues, setValue],
  );

  const updateInvoiceDebitEntriesIfDestroy = (item: InvoiceType.TInvoiceItem) => {
    if (item._destroy === 1 && item.id && item.id > 0) {
      let debit_entries_attributes = getValues("debit_entries_attributes");
      let updatedEntries = debit_entries_attributes.map((entry: any, index: number) => {
        if (entry.item_line_id === item.id) {
          return {
            ...entry,
            _destroy: 1,
          };
        }
        return entry;
      });

      setValue("debit_entries_attributes", updatedEntries);
    }
  };
  const allowEditRow = async (item: InvoiceType.TInvoiceItem, index: number) => {
    let isValidate = true;
    if (item.allowEdit) {
      isValidate = await trigger(`invoice_items_attributes.${index}`);
    }
    if (isValidate) {
      const updatedItem = {
        ...getValues(`invoice_items_attributes.${index}`),
        id: item.id,
        allowEdit: !item.allowEdit,
      };
      // Update the specific item in the array
      setValue(`invoice_items_attributes.${index}`, updatedItem);
      update(index, updatedItem);
    }
  };

  const disableEditRow = async (item: InvoiceType.TInvoiceItem, index: number) => {
    if (!item.allowEdit) {
      return;
    }
    const updatedItem = {
      ...getValues(`invoice_items_attributes.${index}`),
      id: item.id,
      allowEdit: !item.allowEdit,
    };
    // Update the specific item in the array
    setValue(`invoice_items_attributes.${index}`, updatedItem);
    update(index, updatedItem);
  };

  const destroyItem = (index: number) => {
    let updatedItem = { ...getValues(`invoice_items_attributes.${index}`), _destroy: 1 };

    updateInvoiceDebitEntriesIfDestroy(updatedItem);
    setValue(`invoice_items_attributes.${index}`, updatedItem);
    update(index, updatedItem);
  };

  const calculateUnitPrice = (index: number, amount: number) => {
    // Retrieve the latest values of the invoice item from the form using getValues(), rather than relying on the original invoice item which may contain outdated data.
    let invItem = getValues(`invoice_items_attributes.${index}`);
    let itemAmount = invoiceCommonSvc.roundUpAmount(amount, adminCommonSvc.unitPriceDecimalLimit(currentUser));
    let unitPrice = Number(invItem.qty ? itemAmount / parseFloat(invItem.qty) : itemAmount);
    setValue(`invoice_items_attributes.${index}.amount`, itemAmount);
    setValue(`invoice_items_attributes.${index}.unit_price`, unitPrice);

    let updatedItem = { ...invItem, amount: itemAmount, unit_price: unitPrice };
    calculateItemLineTax(updatedItem, index);
    invItem.total = invoiceCommonSvc.getItemTotal(invItem);
    setValue(`invoice_items_attributes.${index}.total`, invItem.total);
  };

  const mapUnitCalculation = (selected: TUnitProps, index: number) => {
    let invItem = getValues(`invoice_items_attributes.${index}`);
    if (invItem.unit_id && selected.id !== invItem.unit_id && invItem.unit_price) {
      let unitPrice =
        invItem.unit_price && selected?.conversion_rate
          ? (Number(invItem.unit_price) * selected.conversion_rate) / invItem.unit.conversion_rate
          : undefined;

      if (unitPrice) {
        let updatedItem = { ...invItem };
        updatedItem.unit_price = invoiceCommonSvc.roundUpAmount(
          unitPrice,
          adminCommonSvc.unitPriceDecimalLimit(currentUser),
        );
        setValue(`invoice_items_attributes.${index}.unit_price`, updatedItem.unit_price);
        calculateTax(index);
      }
    }
    setValue(`invoice_items_attributes.${index}.unit_id`, selected.id);
    setValue(`invoice_items_attributes.${index}.unit`, selected);
  };

  const addDuplicateRow = (item: InvoiceType.TInvoiceItem, index: number) => {
    let updatedItem = { ...getValues(`invoice_items_attributes.${index}`) };
    delete updatedItem.id;
    updatedItem.isDuplicateItem = true; // Used to identify duplicate items
    updatedItem.linked_txn_external_line_id = undefined;
    updatedItem.linked_txn_external_id = undefined;
    updatedItem.inherit_po_item_id = undefined;
    updatedItem.rebate_link = undefined;
    updatedItem.allowEdit = true;
    append(updatedItem);
    //  remove_id_from_budget_item_links : TODO: Implement this feature after budget feature is implemented
  };

  const setItemPrice = useCallback(
    (invItem: InvoiceType.TInvoiceItem, index: number) => {
      if (invItem.product_item && invItem.product_item.id) {
        invItem.unit_price = invItem.product_item.price;
        invItem.description = invItem.product_item.description;
        invItem.qty = 1;
        invItem.product_item_id = invItem.product_item.id;
        invItem.name = "";
        invItem.amount = calculateAmount(invItem); //TODO
        if (
          _.isArray(invItem.product_item.invoice_debit_accounts) &&
          invItem.product_item.invoice_debit_accounts["0"]
        ) {
          invItem.account_id = invItem.product_item.invoice_debit_accounts[0].account_id;
        }
        if (!invItem.product_item.taxable) {
          invItem.tax_id = null;
          invItem.tax_code = undefined;
          invItem.tax = null;
        }
        if (invItem.amount && invItem.tax_code) {
          invItem.tax = invItem.tax_code?.rate
            ? invoiceCommonSvc.roundUpAmount((invItem.amount / 100) * invItem.tax_code.rate)
            : null;
        } else {
          invItem.tax = null;
        }
        calculateTax(index);
        invItem.total = invoiceCommonSvc.getItemTotal(invItem);

        setValue(`invoice_items_attributes.${index}`, invItem);
      }
    },
    [calculateAmount, calculateTax, invoiceCommonSvc, setValue],
  );

  const selectProductItem = useCallback(
    (selected: ProductItemPickerType.TProductItemOption | null | undefined) => {
      let invItem = getValues(`invoice_items_attributes.${index}`);
      invItem.name = "";
      let obj = {
        ...invItem,
        product_item_id: selected?.id ?? null,
        product_name: selected?.name ?? null,
      };
      setValue(`invoice_items_attributes.${index}`, obj);
      //setProductItemMapping(invItem); // TODO: Check mapping is working or not. If not, implement mapping for product items
      setItemPrice(invItem, index);
    },
    [getValues, index, setItemPrice, setValue],
  );

  // selectProductItem(productItem, index),
  const editIcon = item.allowEdit ? (
    <BsCheck2
      className={styles.operationIcon}
      size={16}
      onClick={() => {
        allowEditRow(item, index);
      }}
    />
  ) : (
    <BsPencil
      className={styles.operationIcon}
      size={14}
      onClick={() => {
        allowEditRow(item, index);
      }}
    />
  );

  if (_.isPlainObject(item) && item._destroy === 1) return null;

  const isLinkedToPoItem = (item as any).invoice_item_po_item_link_id && item.po_item;
  return (
    <tr key={item._id || index}>
      <td className={styles.iconsSection}>
        {!isLinkedToPoItem && !showLinkActions && item.id && (
          <BsLink45Deg
            className={styles.operationIcon}
            size={20}
            onClick={() => linkPoItemCallback(item)}
            title={"Link PO Item"}
          />
        )}
        {!isLinkedToPoItem && showLinkActions && item.id === selectedItem?.id && (
          <BsBan
            className={styles.operationIcon}
            size={16}
            onClick={cancelLinkPoItemCallback}
            title={"Cancel linking operation"}
          />
        )}
        {isLinkedToPoItem && !showLinkActions && (
          <BsLink45Deg
            className={styles.operationIcon}
            size={20}
            style={{ color: "red" }}
            title="Unlink PO Item"
            onClick={() => unlinkPoItemCallback((item as any).invoice_item_po_item_link_id)}
          />
        )}
        {editIcon}
        <BsJournalPlus className={styles.operationIcon} size={16} onClick={() => addDuplicateRow(item, index)} />
        <BsTrash className={styles.operationIcon} size={16} onClick={() => destroyItem(index)} />
        {item.allowEdit && (
          <BsXLg className={styles.operationIcon} size={16} onClick={() => disableEditRow(item, index)} />
        )}
      </td>
      <td>{item.po_item?.serial_number || item?.serial_number}</td>
      <td>{item.po_item?.po_number || item?.po_number}</td>
      <td className="px-pt-10">
        {item.allowEdit ? (
          <ProductItemPicker
            instanceId={`invoice_item_{${index}}_product_item_id`}
            name={`invoice_items_attributes.${index}.product_item_id`}
            modelData={`invoice_items_attributes.${index}`}
            required={true}
            modelName="upload_queue"
            callBack={selectProductItem}
          />
        ) : (
          item.product_item?.name
        )}
      </td>
      <td>
        {item.allowEdit ? (
          <TextAreaField
            containerClassName={styles.largeInputDesc}
            id="invoice_item_description"
            name={`invoice_items_attributes.${index}.description`}
            required={currentUser.company.invoice?.is_required_description}
          />
        ) : (
          item.description
        )}
      </td>

      {currentUser.company.has_taxes && (
        <td className="px-pt-10">
          {item.allowEdit ? (
            <TaxCodePicker
              id="invoice_item_tax_code_id"
              name={`invoice_items_attributes.${index}.tax_id`}
              modelData={`invoice_items_attributes.${index}`}
              containerClassName={styles.projectMinWidth}
              required={currentUser.company?.invoice?.is_tax_code_required_on_line_level}
              callBack={() => {
                calculateTax(index);
              }}
            />
          ) : (
            item.tax_code?.code
          )}
        </td>
      )}

      {currentUser.company.has_units && (
        <td className={`${styles.invoiceColumn} px-pt-10`}>
          {item.allowEdit ? (
            <UnitPicker
              id="invoice_item_unit_id"
              name={`invoice_items_attributes.${index}.unit_id`}
              modelData={`invoice_items_attributes.${index}`}
              containerClassName={styles.projectMinWidth}
              callBack={(unit: TUnitProps) => {
                mapUnitCalculation(unit, index);
              }}
            />
          ) : (
            item.unit?.display_name
          )}
        </td>
      )}

      <td className={styles.invoiceColumn}>
        {item.allowEdit ? (
          <InputField
            containerClassName={styles.largeInput}
            id="invoice_item_qty"
            name={`invoice_items_attributes.${index}.qty`}
            type="number"
            onChange={({ target: { value } }) => {
              setValue(`invoice_items_attributes.${index}.qty`, parseFloat(value));
              calculateTax(index);
            }}
          />
        ) : (
          item.qty
        )}
      </td>

      <td className={styles.invoiceColumn}>
        {item.allowEdit ? (
          <InputField
            containerClassName={styles.largeInput}
            id="invoice_item_unit_price"
            name={`invoice_items_attributes.${index}.unit_price`}
            type="number"
            onChange={({ target: { value } }) => manageUnitPrice(index, parseFloat(value))}
          />
        ) : (
          <CurrencySymbolRenderer name="currency_code" amount={item.unit_price || 0} />
        )}
      </td>

      {currentUser.company.allow_edit_invoice_item_subtotal && (
        <td className={styles.invoiceColumn}>
          {item.allowEdit ? (
            <InputField
              id="invoice_item_amount"
              name={`invoice_items_attributes.${index}.amount`}
              type="number"
              onChange={({ target: { value } }) => calculateUnitPrice(index, parseFloat(value))}
            />
          ) : (
            <CurrencySymbolRenderer name="currency_code" amount={item.amount || 0} />
          )}
        </td>
      )}

      {currentUser.company.has_taxes && (
        <td className={styles.invoiceColumn}>
          {item.allowEdit ? (
            <InputField
              id="invoice_item_tax"
              containerClassName={styles.largeInput}
              name={`invoice_items_attributes.${index}.tax`}
              type="number"
              disabled={!currentUser.company.enable_to_enter_tax_amount}
            />
          ) : (
            <CurrencySymbolRenderer name="currency_code" amount={item.tax || 0} />
          )}
        </td>
      )}

      <td className={styles.invoiceColumn}>
        {item.allowEdit ? (
          <InputField
            containerClassName={styles.largeInput}
            id="invoice_item_total"
            name={`invoice_items_attributes.${index}.total`}
            type="number"
            disabled
          />
        ) : (
          <CurrencySymbolRenderer name="currency_code" amount={item.total || 0} />
        )}
      </td>
      <td className={styles.poColumn}>{item.po_item?.description}</td>
      <td className={styles.poColumn}>
        {isLinkedToPoItem && <CurrencySymbolRenderer name="currency_code" amount={item.po_item?.unit_price || ""} />}
      </td>
      <td className={styles.poColumn}>{item.po_item?.qty}</td>
      <td className={styles.poColumn}>{item.po_item?.receipt_item_qty}</td>
      <td className={styles.poColumn}>
        {isLinkedToPoItem && <CurrencySymbolRenderer name="currency_code" amount={item.po_item?.amount || ""} />}
      </td>
      <td className={styles.poColumn}>{item.po_item?.tax}</td>
      <td className={styles.poColumn}>
        {isLinkedToPoItem && <CurrencySymbolRenderer name="currency_code" amount={item.po_item?.total || ""} />}
      </td>
      {is3WayMatch && (
        <>
          <td></td>
          <td></td>
        </>
      )}
      <td>
        {item.allowEdit ? (
          <AccountPicker
            containerClassName={styles.largeInputAccount}
            name={`invoice_items_attributes.${index}.account_id`}
            modelData={`invoice_items_attributes.${index}`}
            parentObj={""} //header
            disabled={true}
          />
        ) : (
          item.account?.name
        )}
      </td>

      {currentUser.company.has_departments && !currentUser.company.invoice?.items?.department?.is_hide && (
        <td>
          {item.allowEdit ? (
            <DepartmentPicker
              name={`invoice_items_attributes.${index}.department_id`}
              required={
                currentUser?.company?.invoice?.items?.department?.is_required
                // || invoiceItem.is_department_required
              }
              modelData={`invoice_items_attributes.${index}`}
              // parentObj={""} //header
              containerClassName={styles.projectMinWidth}
            />
          ) : (
            item.department?.name
          )}
        </td>
      )}

      {currentUser.company.has_locations && !currentUser.company.invoice_item_hide_location && (
        <td className="px-pt-10">
          {item.allowEdit ? (
            <LocationPicker
              name={`invoice_items_attributes.${index}.location_id`}
              required={currentUser.company.invoice?.is_required_location}
              modelDataName={`invoice_items_attributes.${index}`}
              containerClassName={styles.projectMinWidth}
            />
          ) : (
            item.location?.name
          )}
        </td>
      )}

      {currentUser.company.has_business_units && !currentUser.company.invoice_item_hide_business_unit && (
        <td className="px-pt-10">
          {item.allowEdit ? (
            <BusinessUnitPicker
              name={`invoice_items_attributes.${index}.business_unit_id`}
              modelDataName={`invoice_items_attributes.${index}`}
              required={currentUser.company.invoice?.items?.business_unit?.is_required}
              disabled={currentUser.company.readonly_business_unit_to_all}
              containerClassName={styles.projectMinWidth}
            />
          ) : (
            item.business_unit?.name
          )}
        </td>
      )}
      {currentUser.company.has_projects && (
        <td>
          {item.allowEdit ? (
            <>
              {!item.is_hide_project && (
                <ProjectPicker
                  name={`invoice_items_attributes.${index}.project_id`}
                  modelData={`invoice_items_attributes.${index}`}
                  parentObj={""} //header
                  required={invoiceCommonSvcCls.isProjectRequired(item, currentUser, true)}
                  containerClassName={styles.projectMinWidth}
                />
              )}
            </>
          ) : (
            item.project?.name
          )}
        </td>
      )}
    </tr>
  );
};
export default memo(InvoiceItemLine);
