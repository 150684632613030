import Panel from "components/common/panel/panel";
import TabNavigation from "components/navigation/tabNavigation";
import NavTabs from "pages/admin/administration/nav";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { BulkUploadsFilesGrid } from "./bulkUploadFilesGrid";

interface BulkUploadsFilesGridAllPropsType {
  destinationTypes: string[];
}

export const BulkUploadsFilesGridAll = ({ destinationTypes = [] }: BulkUploadsFilesGridAllPropsType) => {
  return (
    <Container fluid={true}>
      <Row className="m-0">
        <Col md="12" className="mt-4">
          <NavTabs activePageName={"Bulk Operations 2.0"} />
        </Col>
      </Row>

      <hr className="mt-4 mb-4" />

      <Panel>
        <TabNavigation
          navigationTab={[
            {
              path: "/ap/bulk_uploads/add",
              pageName: "Upload",
              isActive: "",
            },
            {
              path: "/ap/bulk_uploads",
              pageName: "All Uploads",
              isActive: "active",
            },
          ]}
        />
        <BulkUploadsFilesGrid destinationTypes={destinationTypes} />
      </Panel>
    </Container>
  );
};
