import RestApi from "providers/restApi";
import React, { ReactElement } from "react";
import {
  ObjectCreationResults,
  ObjectCreationResultsLineObjects,
  ParsingResults,
  Wombat,
} from "wombatifier/services/bulk_upload/bulk_upload.types";
import styles from "./bulkUpload.module.css";

let WOMBAT_SOURCE_HASH: { [key: string]: Wombat } = {};

export const BulkUploadFilesRowExpanded = (row: any) => {
  const documentObject = row.data;
  const documentFormData = documentObject.form_data;
  const parsingResults: ParsingResults = documentFormData?.parsing_results;
  const objectCreationResults: ObjectCreationResults = documentFormData?.object_creation_results;
  const creationResultArray: ObjectCreationResultsLineObjects[] = objectCreationResults?.results
    ? Object.values(objectCreationResults.results)
    : [];
  let message: ReactElement = <span></span>;
  let showResults = false;

  let redirectModuleUrl = "";
  const wombat = WOMBAT_SOURCE_HASH[documentObject.document_type];
  let docTypeCheck = wombat ? wombat.destination_type.toUpperCase() : documentObject.document_type;
  const angularBaseUrl = new RestApi().angularBaseURL();
  switch (docTypeCheck) {
    case "NVP_PAYMENT_FILE":
    case "PAYMENT_FILE":
    case "NVP_INVOICE":
    case "INVOICE":
      redirectModuleUrl = `${new RestApi().angularBaseURL()}invoices_all`;
      break;
    case "NVP_VENDOR":
    case "VENDOR":
      redirectModuleUrl = "vendors";
      break;
    case "USER":
      redirectModuleUrl = `${new RestApi().angularBaseURL()}users`;
      break;
    case "CATEGORY":
      redirectModuleUrl = `${new RestApi().angularBaseURL()}categories`;
      break;
    default:
      break;
  }

  switch (documentObject.status) {
    case "COMPLETED":
      message = (
        <span>
          This document has <span className="text-success">processed</span> successfully
        </span>
      );
      showResults = true;
      break;
    case "COMPLETED_WITH_ERRORS":
      message = (
        <span>
          This document has processed but some lines were not able to be imported. Please review the errors below and
          submit a supplemental file
        </span>
      );
      showResults = true;
      break;
    case "NEW":
      message = (
        <span>
          This document is <span className="text-success">submitted</span> and will be imported shortly.
        </span>
      );
      break;
    case "PARSING":
      message = (
        <span>
          This document is <span className="text-success">parsing</span> and will finish shortly.
        </span>
      );
      break;
    default:
      if (documentObject.status?.endsWith("FAILED")) {
        message = <></>;
        showResults = true;
      }
      break;
  }

  return (
    <div style={{ minHeight: "100px" }} className="ml-3 pt-2 d-flex justify-content-center flex-column">
      <p className={`${styles.gridStatusMessage}`}>{message}</p>
      {showResults && documentFormData && (
        <div className="mt-2">
          {redirectModuleUrl ? (
            <div className="mb-2">
              <a
                target="_blank"
                href={redirectModuleUrl && `${redirectModuleUrl}?source_document_id=${documentObject.id}`}
                rel="noreferrer"
              >
                View Created Records
              </a>
              <hr className={`${styles.errorBlock}`}></hr>
            </div>
          ) : null}
          {!!parsingResults && (
            <div>
              <label className={`${styles.statusLabel}`}>Total Records Parsed: </label>{" "}
              {parsingResults.total_records || 0}
            </div>
          )}
          {parsingResults?.failed_records && (
            <div>
              <label className={`${styles.statusLabel}`}>Failed to Parse Count: </label>{" "}
              {parsingResults.failed_records || 0}
            </div>
          )}
          <div>
            <label className={`${styles.statusLabel}`}>Total Creation Events Count: </label>{" "}
            {objectCreationResults.total_count || 0}
          </div>
          <div>
            <label className={`${styles.statusLabel}`}>Created Count: </label>{" "}
            {objectCreationResults.created_count || 0}
          </div>
          {!!objectCreationResults.error_count && (
            <div>
              <label className={`${styles.statusLabel}`}>Failure Count: </label>{" "}
              {objectCreationResults.error_count || 0}
            </div>
          )}
          {!!objectCreationResults.found_count && (
            <div>
              <label className={`${styles.statusLabel}`}>Existed Count: </label>{" "}
              {objectCreationResults.found_count || 0}
            </div>
          )}
          {!!objectCreationResults.linked_count && (
            <div>
              <label className={`${styles.statusLabel}`}>Linked Count: </label>{" "}
              {objectCreationResults.linked_count || 0}
            </div>
          )}

          {creationResultArray.length && (
            <div className="mt-2 pr-3">
              {/*creationResultArray.length > 100 && (
                <p className={`${styles.statusLabel} text-warning`}>Warning: only the first 50 creation events will be shown</p>
              )*/}

              {/* Convert this to a nested ag-grid */}
              <div className="table-responsive" style={{ height: 400 }}>
                <table className="table bg-white">
                  <thead className="bg-light position-sticky" style={{ top: -1 }}>
                    <tr>
                      <th>Row #</th>
                      <th>Entity Type</th>
                      <th>Entity Number</th>
                      <th>Message</th>
                      <th>Entity ID</th>
                    </tr>
                  </thead>
                  <tbody>
                    {creationResultArray.map((row) => {
                      // Each row has a potential to have a created a different entity type
                      let entityHref = "";
                      if (row.entity_id) {
                        switch (row.entity_type) {
                          case "Vendor":
                            entityHref = `${angularBaseUrl}vendors/${row.entity_id}`;
                            break;
                          case "Receipt":
                            entityHref = `${angularBaseUrl}receipt/${row.entity_id}`;
                            break;
                          case "Invoice":
                          case "Payment File":
                            entityHref = `${angularBaseUrl}invoices/${row.entity_id}`;
                            break;
                          case "ExpenseReport":
                            entityHref = `/ap/expenses/${row.entity_id}`;
                            break;
                          case "PurchaseOrder":
                            entityHref = `${angularBaseUrl}purchase_orders/${row.entity_id}`;
                            break;
                          case "Subsidiary":
                            entityHref = `${angularBaseUrl}subsidiaries/${row.entity_id}`;
                            break;
                        }
                      }

                      return (
                        <tr key={`${row.entity_type}-${row.row_number}`}>
                          <td>{row.row_number}</td>
                          <td>{row.entity_type}</td>
                          <td>{row.entity_number}</td>
                          <td>{row.message}</td>
                          <td>
                            {row.entity_id ? (
                              entityHref ? (
                                <a target="_blank" href={entityHref} rel="noreferrer">
                                  {row.entity_id}
                                </a>
                              ) : (
                                row.entity_id
                              )
                            ) : (
                              ""
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
