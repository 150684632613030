import React, { memo, useCallback } from "react";
import { BsEye } from "react-icons/bs";
import { Thumbnail } from "react-pdf";
import styles from "./checkedThumbnail.module.css";

type TThumbnailProps = {
  checked: boolean;
  pageNumber: number;
  selectedPage: number;
  numPages: number;
  onClickCheckBox: (idx: number, checked: boolean) => void;
  onPageSelect: (pageNumber: number) => void;
};

const CheckedThumbnail: React.FC<TThumbnailProps> = memo(
  ({ checked, pageNumber, numPages, onClickCheckBox, onPageSelect, selectedPage }) => {
    const handleChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        onClickCheckBox(pageNumber, e.target.checked);
      },
      [onClickCheckBox, pageNumber],
    );

    const handleThumbnailClick = useCallback(() => {
      onPageSelect(pageNumber);
    }, [onPageSelect, pageNumber]);

    const eyeStyle = selectedPage === pageNumber ? styles.eyeVisibleStyle : styles.eyeHiddenStyle;

    return (
      <div className={`${styles.thumbnailContainer} ${selectedPage === pageNumber ? styles.selected : ""}`}>
        <div className={styles.thumbnailHeader}>
          <BsEye className={eyeStyle} size={16} />
          <span className={styles.thumbnailHeaderText}>{pageNumber}</span>
          {numPages > 1 && (
            <input
              type="checkbox"
              className={styles.checkbox}
              name={`page_${pageNumber}`}
              checked={checked}
              onChange={handleChange}
            />
          )}
        </div>
        <Thumbnail pageNumber={pageNumber} width={155} onItemClick={handleThumbnailClick} />
      </div>
    );
  },
);

export default CheckedThumbnail;
