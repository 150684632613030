import { sortBy } from "lodash";
import { AppDispatch, RootState } from "reducers";
import { change, getFormValues } from "redux-form";
import {
  MetadataConfigurationOptions,
  MetadataSelectorFormState,
  MetadataConfigurationTemplateLink,
  MetadataTemplate,
  MetadataConfiguration,
  MergedMetadataConfiguration,
  MetadataParentHierarchy,
  MetadataLinkAttributes,
  MetadataField,
} from "./metadata.types";
import { MutableRefObject } from "react";
export class MetadataSelectorSvc {
  formName: string;
  dispatch: AppDispatch;

  constructor(formName: string, dispatch: AppDispatch) {
    this.formName = formName;
    this.dispatch = dispatch;
  }

  updateForm = (field: string, value: any) => {
    this.dispatch(change(this.formName, field, value));
  };

  static orderByPriority = (mergedConfigurations: (MetadataConfiguration & MetadataConfigurationTemplateLink)[]) => {
    return sortBy(mergedConfigurations, (item) => item.position);
  };

  static createTemplateOptions = (templates: MetadataTemplate[]) => {
    let options = [{ label: "Select One", value: "" }];
    return [...options, ...templates.map((t) => ({ label: t.name, value: t.id }))];
  };

  static createFieldOptions = (templates: MetadataConfiguration[]) => {
    let options = [{ label: "Select One", value: "" }];
    return [...options, ...templates.map((t) => ({ label: t.name, value: t.id }))];
  };

  setFormSliceState = (formState: MetadataSelectorFormState, formSlice: string): MetadataSelectorFormState => {
    // tokenization here
    let splitFormSlice = formSlice.split(".");
    let currentFormState: any = formState;
    splitFormSlice.forEach((token) => {
      let pattern = /\[\d+\]/;
      const strippedToken = token.replace(pattern, "");
      currentFormState = currentFormState[strippedToken];
      const indexMatch = token.match(pattern); // [0], [1], [2] as strings
      if (indexMatch && indexMatch.length > 0) {
        // parse out the brackets & convert back to int
        const index = parseInt(indexMatch[0].replace("[", "").replace("]", "")); // 0, 1, 2 as ints
        currentFormState = currentFormState[index];
      }
    });
    return currentFormState;
  };

  modelToPickerName = (field_name: string) => {
    switch (field_name) {
      case "BusinessUnit":
        return "business_unit_id";
      case "Subsidiary":
        return "subsidiary_id";
      case "Project":
        return "project_id";
    }
    return field_name;
  };

  setReferenceMetadataLinksAttributes = (
    state: RootState,
    ref: { current: MetadataLinkAttributes[] },
    formSlice?: string,
  ): true => {
    const formState: MetadataSelectorFormState = getFormValues(this.formName)(state) as MetadataSelectorFormState;
    if (formSlice) {
      const currentFormState = this.setFormSliceState(formState, formSlice);
      ref.current = currentFormState?.metadata_links || [];
      // if no links, serialize metadata. usually when inheriting metadata from another model on add form
      if (ref.current.length === 0) {
        ref.current = this.metadataSerialize(currentFormState);
      }
      return true;
    } else {
      ref.current = formState?.metadata_links || [];
      if (ref.current.length === 0) {
        ref.current = this.metadataSerialize(formState);
      }
      return true;
    }
  };

  // takes metadata and transforms it into metadata_links_attributes
  metadataSerialize = (formState: MetadataSelectorFormState): any[] => {
    let metadata_links_attributes = [];

    // Check if formState.metadata is empty
    if (!formState.metadata || Object.keys(formState.metadata).length === 0) {
      return [];
    }

    for (let key in formState.metadata) {
      if (formState.metadata.hasOwnProperty(key)) {
        let metadataConfiguration = formState.metadata[key];
        if (metadataConfiguration.input_type == "select") {
          // attach metadata field to the metadata_links_attributes
          metadataConfiguration.metadata_fields?.forEach(function (field: MetadataField) {
            let newMetadataLink = {
              metadata_configuration_id: metadataConfiguration.metadata_configuration_id,
              metadata_field_id: field.id,
              value: metadataConfiguration?.value,
            };
            metadata_links_attributes.push(newMetadataLink);
          });
        } else {
          let newMetadataLink = {
            metadata_configuration_id: metadataConfiguration.metadata_configuration_id,
            value: metadataConfiguration?.value,
          };
          metadata_links_attributes.push(newMetadataLink);
        }
      }
    }
    metadata_links_attributes.map((link) => ({
      metadata_configuration_id: link.metadata_configuration_id ?? undefined,
      value: link.value ?? undefined,
    }));
    return metadata_links_attributes;
  };

  getChosenParentMetadataFieldId = (
    state: RootState,
    parentHierarchy?: MetadataParentHierarchy,
    formSlice?: string,
  ): number | undefined => {
    const formState: MetadataSelectorFormState = getFormValues(this.formName)(state) as MetadataSelectorFormState;
    if (!parentHierarchy) {
      return undefined;
    }
    const field_name = this.modelToPickerName(parentHierarchy.parent_field_type);
    if (formSlice) {
      const currentFormState = this.setFormSliceState(formState, formSlice);
      const metadataLinksAttributes = (currentFormState?.metadata_links_attributes as MetadataLinkAttributes[]) || [];
      // if PO is linked, imported poItems do not inherently have metadata_links_attributes, so we check their metadata_links instead
      if (metadataLinksAttributes.length === 0 && parentHierarchy.parent_field_type === "MetadataConfiguration") {
        // if checking in metadata_links or metadata_links_attributes then parent_field_type should be "MetadataConfiguration"
        const metadataLinks = (currentFormState?.metadata_links as MetadataLinkAttributes[]) || [];
        const res = metadataLinks.find(
          (item) => item.metadata_configuration_id === parentHierarchy.parent_field_id,
        )?.metadata_field_id;
        return res;
      } else if (parentHierarchy.parent_field_type === "MetadataConfiguration") {
        const res = metadataLinksAttributes.find(
          (item) => item && item.metadata_configuration_id === parentHierarchy.parent_field_id,
        )?.metadata_field_id;
        return res;
      }
      // if parent hierarchy field is a project, subsidiary, or business unit instead of metadata
      else {
        switch (field_name) {
          case "project_id":
            return currentFormState.project_id;
          case "subsidiary_id":
            return currentFormState.subsidiary_id;
          case "business_unit_id":
            return currentFormState.business_unit_id;
        }
      }
    } else {
      if (parentHierarchy.parent_field_type === "MetadataConfiguration") {
        return (formState?.metadata_links_attributes || []).find(
          (item) => item && item.metadata_configuration_id === parentHierarchy.parent_field_id,
        )?.metadata_field_id;
      } else {
        switch (field_name) {
          case "project_id":
            return formState?.project_id;
          case "subsidiary_id":
            return formState?.subsidiary_id;
          case "business_unit_id":
            return formState?.business_unit_id;
        }
      }
    }
  };

  setDefaultTemplate = (
    state: RootState,
    metadataTemplates: MutableRefObject<MetadataTemplate[]>,
    formSlice?: string,
  ): number | "" => {
    const formState: MetadataSelectorFormState = getFormValues(this.formName)(state) as MetadataSelectorFormState;
    if (formSlice) {
      const currentFormState = this.setFormSliceState(formState, formSlice);
      // check if current metadata_template_id is in this module
      if (currentFormState.metadata_template_id) {
        const templateExists = metadataTemplates.current.some(
          (template) => template.id === currentFormState.metadata_template_id,
        );
        if (templateExists) {
          // auto populate inherited metadata template
          return currentFormState.metadata_template_id;
        }
      }
      // inherit config data but don't auto pick template
      return "";
    } else {
      if (formState.metadata_template_id) {
        const templateExists = metadataTemplates.current.some(
          (template) => template.id === formState.metadata_template_id,
        );
        if (templateExists) {
          return formState.metadata_template_id;
        }
      }
      return "";
    }
  };

  getCurrentTemplateId = (state: RootState, formSlice?: string): number | "" => {
    const formState: MetadataSelectorFormState = getFormValues(this.formName)(state) as MetadataSelectorFormState;
    if (formSlice) {
      const currentFormState = this.setFormSliceState(formState, formSlice);
      const metadataTemplateId = currentFormState?.metadata_template_id || "";
      return metadataTemplateId;
    } else {
      return formState?.metadata_template_id || "";
    }
  };

  getCurrentConfigurationFields = (state: RootState, formSlice?: string): string[] => {
    const formState: MetadataSelectorFormState = getFormValues(this.formName)(state) as MetadataSelectorFormState;
    if (formSlice) {
      const currentFormState = this.setFormSliceState(formState, formSlice);
      const metadataConfigurations = currentFormState?.metadataForm?.metadata_configurations || {};
      const metadataConfigurationsOptionsArray = Object.entries(metadataConfigurations);
      const sortedByPosition = sortBy(metadataConfigurationsOptionsArray, (mco) => mco[1].position);
      return sortedByPosition.map((item) => item[0]);
    } else {
      const metadataConfigurationsOptionsArray = Object.entries(formState?.metadataForm?.metadata_configurations || {});
      const sortedByPosition = sortBy(metadataConfigurationsOptionsArray, (mco) => mco[1].position);
      return sortedByPosition.map((item) => item[0]);
    }
  };

  getCurrentConfiguration = (state: RootState, field_name: string, formSlice?: string): MergedMetadataConfiguration => {
    const formState: MetadataSelectorFormState = getFormValues(this.formName)(state) as MetadataSelectorFormState;
    if (formSlice) {
      const currentFormState = this.setFormSliceState(formState, formSlice);
      const metadataConfigurations =
        (currentFormState?.metadataForm?.metadata_configurations &&
          currentFormState?.metadataForm?.metadata_configurations[field_name]) ||
        ({} as MergedMetadataConfiguration);
      return metadataConfigurations;
    } else {
      return (
        (formState?.metadataForm?.metadata_configurations &&
          formState.metadataForm.metadata_configurations[field_name]) ||
        ({} as MergedMetadataConfiguration)
      );
    }
  };

  getCurrentConfigurationOptions = (
    state: RootState,
    field_name: string,
    formSlice?: string,
  ): MetadataConfigurationOptions => {
    const formState: MetadataSelectorFormState = getFormValues(this.formName)(state) as MetadataSelectorFormState;
    if (formSlice) {
      const currentFormState = this.setFormSliceState(formState, formSlice);
      return (
        (currentFormState?.metadataForm?.metadata_configurations_options_hash &&
          currentFormState.metadataForm.metadata_configurations_options_hash[field_name]) ||
        []
      );
    } else {
      return (
        (formState?.metadataForm?.metadata_configurations_options_hash &&
          formState.metadataForm.metadata_configurations_options_hash[field_name]) ||
        []
      );
    }
  };

  getCurrentMetadata = (state: RootState, formSlice?: string): any => {
    const formState: MetadataSelectorFormState = getFormValues(this.formName)(state) as MetadataSelectorFormState;
    if (formSlice) {
      const currentFormState = this.setFormSliceState(formState, formSlice);
      return currentFormState.metadata;
    } else {
      return formState.metadata;
    }
  };

  static mergeConfigurationsWithTemplateLinks = (
    metadataConfigurations: MetadataConfiguration[],
    metadataTemplateLinks: MetadataConfigurationTemplateLink[],
  ): (MetadataConfiguration & MetadataConfigurationTemplateLink)[] => {
    const mtlHash = metadataTemplateLinks.reduce(
      (result, link) => {
        if (link.metadata_configuration_id) {
          result[link.metadata_configuration_id] = link;
        }
        return result;
      },
      {} as { [key: number]: MetadataConfigurationTemplateLink },
    );
    const mergedMetadataConfigurations = metadataConfigurations.map((config) => {
      if (config.id) {
        return { ...mtlHash[config.id], ...config }; // id of the metadataConfiguration will take precedence
      }
    });
    return mergedMetadataConfigurations as (MetadataConfiguration & MetadataConfigurationTemplateLink)[];
  };
}
