import { ColumnApi, GridApi, GridReadyEvent, IServerSideGetRowsParams } from "ag-grid-community";
import { restApiService } from "providers/restApi";
import { useEffect, useState } from "react";
import { getParams, saveDefaultOrder } from "services/common/gridService";
import { FIELD_NAME_MAP, getBulkUploadsHeaders } from "wombatifier/components/bulk_upload/bulkUploadsHeader";

import { AxiosResponse } from "axios";
import useShowFilterState from "components/common/hooks/useShowFilterState";
import { sortBy } from "lodash";
import { shallowEqual } from "react-redux";
import { useTypedSelector } from "reducers";
import DocumentApis from "services/common/documents/documentApis";
import { DocumentableTypes, DocumentFields, Wombat } from "wombatifier/services/bulk_upload/bulk_upload.types";

const GRID_STORAGE_NAME = "listBulkUpload";
const FIXED_PARAMS = {
  [DocumentFields.DocumentableType]: DocumentableTypes.Company,
};
let WOMBAT_SOURCE_HASH: { [key: string]: Wombat } = {};

interface BulkUploadsFilesGridPropsType {
  destinationTypes: string[];
}

export const BulkUploadGridable = ({ destinationTypes = [] }: BulkUploadsFilesGridPropsType) => {
  const [gridApi, setGridApi] = useState<GridApi>();
  const [gridColumnApi, setGridColumnApi] = useState<ColumnApi>();
  const { showFilters, updateShowFilters } = useShowFilterState("listBulkUploadFilter", true);
  const currentUser = useTypedSelector((state) => state.user, shallowEqual);

  const gridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);

    //tell the grid that we are using a custom data source
    params.api.setServerSideDatasource({ getRows: getRows });
  };

  const getRows = async (params: IServerSideGetRowsParams) => {
    try {
      let baseParams: { [key: string]: any } = { ...FIXED_PARAMS };
      if (destinationTypes.length > 0) {
        baseParams.destination_types = destinationTypes;
      }
      const result = await DocumentApis.getListPaginated({
        filter: { ...getParams(params, FIELD_NAME_MAP), ...baseParams },
      });
      params.success({
        rowData: result.data.map((document: any) => {
          if (document?.form_data?.object_creation_results?.results) {
            document.form_data.object_creation_results.results = sortBy(
              document.form_data.object_creation_results.results,
              (row) => row.row_number,
            );
          }
          return document;
        }),
        rowCount: result.meta.count,
      });
    } catch (error) {
      params.fail();
    }
  };

  useEffect(() => {
    getWombatHashPrms().then((result) => {
      WOMBAT_SOURCE_HASH = result;
    }); // assumes this will be set before the user can expand any rows
    saveDefaultOrder(GRID_STORAGE_NAME, gridHeaders.defaultOrder);
  }, []);

  const gridHeaders = getBulkUploadsHeaders({ currentUser });
  return { gridApi, gridColumnApi, showFilters, updateShowFilters, gridReady, gridHeaders };
};

const getWombatHashPrms = async () => {
  const response: AxiosResponse = await restApiService.get(
    "wombats",
    { workflow_types: ["file_integration_ingest_csv", "file_integration_ingest_xml"] },
    null,
    true,
    null,
    true,
  );
  let result: { [key: string]: Wombat } = {};
  if (response && response.data) {
    response.data.forEach((wombat: Wombat) => {
      result[wombat.source_type] = wombat;
    });
  }
  return result;
};
