import React, { createContext, Dispatch, SetStateAction, useContext, useEffect, useMemo, useState } from "react";
import { useInvoiceDetailsContext } from "./invoiceDetailsContext";

type UiStateContextType = {
  checkboxValues: Map<number, boolean>;
  setCheckboxValues: Dispatch<SetStateAction<Map<number, boolean>>>;
  isOperationRunning: boolean;
  setIsOperationRunning: Dispatch<SetStateAction<boolean>>;
  uploadBtnDisable: boolean;
  setUploadBtnDisable: Dispatch<SetStateAction<boolean>>;
  isExpanded: boolean;
  handleToggle: () => void;
};

const UiStateContext = createContext<UiStateContextType | undefined>(undefined);

export const UiStateProvider: React.FC = ({ children }) => {
  const { hasAssets, invoiceDetails } = useInvoiceDetailsContext();
  const [isExpanded, setIsExpanded] = useState(true);
  const handleToggle = () => setIsExpanded((prevExpanded) => !prevExpanded);
  const [checkboxValues, setCheckboxValues] = useState<Map<number, boolean>>(new Map());
  const [isOperationRunning, setIsOperationRunning] = useState<boolean>(false);
  const [uploadBtnDisable, setUploadBtnDisable] = useState<boolean>(false);

  useEffect(() => {
    if (invoiceDetails && invoiceDetails.invoice) {
      setIsExpanded(hasAssets);
    }
  }, [invoiceDetails, hasAssets]);

  useEffect(() => {
    setCheckboxValues(new Map());
  }, [invoiceDetails]);

  const contextValue = useMemo(
    () => ({
      checkboxValues,
      setCheckboxValues,
      isOperationRunning,
      setIsOperationRunning,
      uploadBtnDisable,
      setUploadBtnDisable,
      isExpanded,
      handleToggle,
    }),
    [checkboxValues, isExpanded, isOperationRunning, uploadBtnDisable],
  );

  return <UiStateContext.Provider value={contextValue}>{children}</UiStateContext.Provider>;
};

export const useUiStateContext = (): UiStateContextType => {
  const context = useContext(UiStateContext);
  if (!context) {
    throw new Error("useUiStateContext must be used within a UiStateProvider");
  }
  return context;
};
