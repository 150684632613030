import _ from "lodash";
import React, { memo } from "react";
import ShippingAddressPicker from "./shippingAddressPicker";
import SinglePicker from "./singlePicker";

const AddressPicker = (props: any) => {
  const modelData = !_.isNil(props.modelData) ? (props.modelData === "" ? "" : props.modelData) : null;
  return (
    <>
      {props.isShippingAddress ? (
        <ShippingAddressPicker {...props} modelData={modelData} />
      ) : (
        <SinglePicker {...props} modelData={modelData} />
      )}
    </>
  );
};
export default memo(AddressPicker);
