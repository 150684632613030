import { IDType } from "services/common/types/common.type";
import { IUser } from "services/common/user/userTypes";

/**
 * The Singleton class defines an getInstance, that lets clients access
 * the unique singleton instance.
 */
class PoCommonSvc {
  static svcInstance: PoCommonSvc;
  private currentUser: IUser | null = null;

  /**
   * The Singleton's constructor should always be private to prevent direct
   * construction calls with the `new` operator.
   */
  private constructor(currentUser: IUser) {
    this.currentUser = currentUser;
  }

  /**
   * The static getInstance that controls access to the singleton instance.
   *
   * This implementation allows you to extend the Singleton class while
   * keeping just one instance of each subclass around.
   */
  public static getInstance(currentUser: IUser): PoCommonSvc {
    // if this.currentUser is not equal to currentUser from store  create new instance of Svc else cached
    // || !Object.is(currentUser, PoCommonSvc?.svcInstance?.currentUser || {}) this condition make sure that poCommonSvc remain updated
    if (!PoCommonSvc.svcInstance || !Object.is(currentUser, PoCommonSvc?.svcInstance?.currentUser)) {
      PoCommonSvc.svcInstance = new PoCommonSvc(currentUser);
    }

    return PoCommonSvc.svcInstance;
  }

  /************************COMMON BUSINESS LOGICS START **************************/

  isHidePoRequestLines = (): boolean => {
    return Boolean(this.currentUser?.company.po_request?.hide_expense_and_item_lines);
  };

  poRequestHidePOItems = (): boolean => {
    return Boolean(this.currentUser?.company.po_request_hide_po_items);
  };

  is_hide_po_request_lines = (): boolean => Boolean(this.currentUser?.company?.po_request?.hide_expense_and_item_lines);

  hideExpenseAndItemLines = (): boolean => {
    return Boolean(this.currentUser?.company?.po_request?.hide_expense_and_item_lines);
  };

  inheritVendorSubsidiary = (response: any, subsidiaryId: IDType) => {
    let isSubsidiarySelected =
      Array.isArray(response.subsidiaries) &&
      response.subsidiaries.find((subsidiary: any) => subsidiary.id === subsidiaryId);

    if (isSubsidiarySelected && this.currentUser?.company?.po_request_allow_vendor_on_subsidiary) {
      return;
    } else if (response.subsidiary) {
      return response.subsidiary;
    }
  };

  removeIdFromBudgetItemLinks = (item: any) => {
    if (Array.isArray(item.budget_item_links) && item.budget_item_links.length > 0) {
      item.budget_item_links.forEach((link: any) => {
        if (link.id) {
          delete link.id;
        }
      });
    }
  };

  isAttachmentRequiredBasedOnAmount = (amount: number, currentUser: IUser) => {
    const minAmount = parseFloat(currentUser.company.po_request_attachment_required_on_min_amount);
    return amount && !isNaN(minAmount) && amount >= minAmount;
  };

  updateAddressFields = (address: any) => {
    if (!address.entity_name && address["properties"] && address["properties"]["entity_name"]) {
      address.entity_name = address["properties"]["entity_name"];
    }
    return address;
  };

  // TODO: add types
  setItemSubtype = (po: any, poItems: any, params: Record<string, any>) => {
    if (this.currentUser?.company.po_request_allow_inventory_item_for_inventory_type && po.sub_type === "Inventory") {
      params.sub_type = po.sub_type;
    } else {
      params.sub_type = null;
    }
  };

  // TODO: mohit Add purchaseOrder type
  getVendorPartNum = <
    T extends {
      product_item?: {
        properties?: {
          vendor_codes?: any;
        };
        vendor_codes?: any;
      };
      vendor_part_number?: any;
    },
  >({
    item,
    modalData,
  }: {
    item: T | null | undefined;
    modalData: any;
  }) => {
    if (item && item.product_item && modalData && modalData.vendor) {
      let vendorCodes =
        item.product_item.properties && item.product_item.properties.vendor_codes
          ? item.product_item.properties.vendor_codes
          : item.product_item.vendor_codes;
      const obj =
        Array.isArray(vendorCodes) &&
        vendorCodes.find((vendorCode) => vendorCode.external_id === modalData?.vendor?.external_id);
      item.vendor_part_number = obj ? obj.code : null;
    }
  };

  inheritPOHeaderProjectToExpenseLine = () => {
    return this.currentUser?.company.purchase_order?.expenses?.inherit_header_project;
  };

  inheritPOHeaderProjectToItemLine = () => {
    return this.currentUser?.company.purchase_order?.items?.inherit_header_project;
  };
}

export default PoCommonSvc;
