import ErrorBoundary from "components/common/errorBoundary/errorBoundary";
import useIsMounted from "components/common/hooks/useIsMounted";
import { restApiService } from "providers/restApi";
import React, { useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import PurchaseOrdersApis from "services/admin/purchaseOrders/purchaseOrderApi";
import { CreateNotification, NotificationType } from "services/general/notifications";
import FormRequestPO from "./form";

const EditRequestPo = () => {
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const [poData, setPoData] = useState<any>(null);

  const { id } = useParams<{ id: string }>();
  const methods = useForm<any>({
    defaultValues: {
      purchase_order: {},
    },
  });

  const { reset } = methods;

  const getPurchaseOrder = useCallback(async () => {
    if (isMounted.current) {
      const purchaseOrder = await PurchaseOrdersApis.getPurchaseOrder(id);
      setPoData(purchaseOrder);
    }
  }, [id, isMounted]);

  useEffect(() => {
    getPurchaseOrder();
  }, [getPurchaseOrder]);

  useEffect(() => {
    if (poData) {
      reset({
        purchase_order: poData,
        savedPO: poData,
      });
    }
  }, [poData, reset]);

  // TODO: Move to PoCommonSvc
  const isStartAndEndDateSame = (purchaseOrder: any) => {
    if (purchaseOrder.start_date && purchaseOrder.end_date) {
      // let start_date = new Date(obj.start_date);
      // let end_date = new Date(obj.end_date);
      // return start_date >= end_date;
    } else {
      return false;
    }
  };

  const updatePurchaseOrder = useCallback(async (purchaseOrder: any) => {
    if (Array.isArray(purchaseOrder.budget_item_links)) {
      purchaseOrder.budget_item_links_attributes = purchaseOrder.budget_item_links;
    }

    let po = { ...purchaseOrder };
    delete po.assets;
    delete po.assets_attributes;
    try {
      const response = await restApiService.patch(`po_requests/${id}`, null, { purchase_order: po });
      if (purchaseOrder?.assets_attributes?.length > 0 || purchaseOrder?.assets?.length > 0) {
        PurchaseOrdersApis.uploadPOAttachment(purchaseOrder.assets_attributes, response?.data?.id);
      }
    } catch (error) {
      console.log(error);
    }
    try {
      const response = await PurchaseOrdersApis.editPurchaseOrder(purchaseOrder.id, { purchase_order: purchaseOrder });
      //TODO: Implement await poCommonSvc.uploadPOAttachment();

      if (response?.id) {
        CreateNotification("Updated", `PO Number ${response?.number} updated`, NotificationType.success);

        // redirecting to angular app after showing success message
        setTimeout(() => {
          window.location.href = restApiService.angularBaseURL() + "/purchase_orders/" + response?.id;
        }, 2000);
      }
    } catch (error) {
      CreateNotification("Error", `Purchase oredr not able to Submit. please try again.`, NotificationType.danger);
      console.log("error: ", error);
    }
  }, []);

  const onSubmit = useCallback(
    (formValues) => {
      let purchaseOrder = formValues.purchase_order;

      if (purchaseOrder.require_virtual_card && purchaseOrder.currency_code !== "USD") {
        CreateNotification(
          "Invalid Currency",
          `Virtual cards can only be required on USD currency PO's`,
          NotificationType.success,
        );
        return;
      }
      // TODO: Implement below fetures
      // check_blank_entries();
      // poCommonSvc.assignNewItem(purchaseOrder);
      // poCommonSvc.update_fields_before_submit($scope.purchase_order); //to inherit the header data to line level

      /* TODO: Fix and uncomment
    if (!no_vendor && !new_vendor && purchaseOrder.vendor && (!purchaseOrder.vendor.name || !purchaseOrder.vendor_id)) {
      return false;
    }*/

      if (isStartAndEndDateSame(purchaseOrder)) {
        return false;
      }

      if (!(purchaseOrder.amount > 0) && Array.isArray(purchaseOrder.po_items)) {
        CreateNotification("Not Allowed", `Purchase Order total amount can not be zero`, NotificationType.warning);
        return false;
      }

      purchaseOrder.po_items.forEach((item: any) => {
        if (!item.vendor) {
          return;
        }
        item.vendor_name = item.vendor.name || item.vendor;
      });

      /* TODO: Implement this feature
    if (newVendor) {
      poCommonSvc.create_vendor()
    }else{
      updatePurchaseOrder()
    }*/
      updatePurchaseOrder(purchaseOrder); // TODO: Remove this once the poCommonSvc.create_vendor() function is fully implemented.
    },
    [updatePurchaseOrder],
  );

  return (
    <FormProvider {...methods}>
      <ErrorBoundary>
        <FormRequestPO onSubmit={onSubmit} />
      </ErrorBoundary>
    </FormProvider>
  );
};

export default EditRequestPo;
