import useFormValue from "components/admin/hooks/useFormValue";
import React, { useCallback, useState } from "react";
import { Button } from "react-bootstrap";
import { ExpensesTypes } from "services/admin/expenses/expensesType";
import { useExpenseItemFormContext } from "../expenseItemFormSectionContext";
import style from "./../expenseItem.module.css";
import AddRouteMapModal from "./addRouteMapModal";

const MapRouteButton = () => {
  const ctx = useExpenseItemFormContext();
  const mapRoute = useFormValue<ExpensesTypes.TMapRoute>(ctx?.formName, ctx?.sectionPrefix + ".map_route");
  const [routeMapModalOpen, setRouteMapModalOpen] = useState(false);
  // const mapRoute =

  const onMapModalClose = useCallback(() => {
    setRouteMapModalOpen(false);
  }, []);

  const onMapModalOpen = useCallback(() => {
    setRouteMapModalOpen(true);
  }, []);

  return (
    <>
      {routeMapModalOpen && <AddRouteMapModal show={routeMapModalOpen} handleClose={onMapModalClose} />}
      <Button variant="secondary" onClick={onMapModalOpen} className={style.mapRouteButton} size="sm">
        {!mapRoute && (
          <>
            <i className="icon icon-plus-black" /> Add Route map
          </>
        )}
        {mapRoute && "Edit Route map"}
      </Button>
    </>
  );
};

export default MapRouteButton;
