import useAdminCompanyCurrencyCode from "components/admin/hooks/useAdminCompanyCurrencyCode";
import CurrencyCodePicker, {
  CurrencyCodeOptionType,
} from "components/admin/pickers/reduxFormPickers/currencyCodePicker";
import DatePicker from "components/admin/pickers/reduxFormPickers/datePicker/datePicker";
import ExpenseReportPicker from "components/admin/pickers/reduxFormPickers/expenseReportPicker";
import PickerErrorBlock from "components/admin/pickers/reduxFormPickers/pickerErrorBlock";
import { RenderCheckBox, RenderField, RenderFieldNumber, RenderTextArea } from "components/forms/bootstrapFields";
import TooltipRender from "components/toolTip/tooltipRender";
import React, { ChangeEvent, useCallback, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "reducers";
import { selectCurrentUser } from "reducers/userReducers";
import { change, Field } from "redux-form";
import adminCommonSvc from "services/admin/commonSvc";
import expenseItemCommonSvc from "services/admin/expenses/expenseItems/expenseItemCommonSvc";
import { ExpensesTypes } from "services/admin/expenses/expensesType";
import { IDType } from "services/common/types/common.type";
import { IUser } from "services/common/user/userTypes";
import { noWhiteSpaceOnly, required } from "services/validations/reduxFormValidation";
import ExpenseItemTaxPicker, { TaxLKItem } from "../components/expenseItemTaxPicker";
import style from "./expenseItem.module.css";
import { useExpenseItemFormContext } from "./expenseItemFormSectionContext";
import { formExpenseItemFormData } from "./formExpenseItem";

const SingleExpenseForm = () => {
  // const isMounted = useIsMounted();
  const dispatch = useDispatch();
  const { companyCurrencies } = useAdminCompanyCurrencyCode();
  const ctx = useExpenseItemFormContext();
  const currentUser: IUser = useTypedSelector(selectCurrentUser);
  // const expenseItem = useFormValue<ExpensesTypes.ExpenseItemFormDataType | null | undefined>(
  //   ctx?.formName,
  //   ctx?.sectionPrefix
  // );
  const expenseFormData = useTypedSelector(formExpenseItemFormData(ctx?.formName ?? ""));
  const expenses = expenseFormData?.expenses;
  const expenseItem = expenses && ctx && expenses[ctx.index];

  const isTaxFieldEnable =
    expenseItem?.policy?.taxes_enabled &&
    expenseItemCommonSvc.isTaxFieldsEnable(expenseItem?.policy?.taxes_enabled, currentUser);

  const getCurrencyRate = async (currencyCodeObj: CurrencyCodeOptionType) => {
    if (currencyCodeObj.value && ctx && expenses) {
      expenses[ctx.index].currency = currencyCodeObj;
      expenses[ctx.index].currency_code = currencyCodeObj.value;
      dispatch(change(ctx.formName, ctx.sectionPrefix, expenses[ctx.index]));

      const wasConversionActive = expenseItem && expenseItemCommonSvc.isConversionActive(expenseItem); // need to recalculate every time

      // this function will update our base currency code as per policy and get currency conversion rate
      let updateExpenseItem = await expenseItemCommonSvc.getCurrencyRate(
        currencyCodeObj.value,
        ctx.index,
        expenses,
        companyCurrencies,
        currentUser,
      );

      const isConversionActive = expenseItem && expenseItemCommonSvc.isConversionActive(expenseItem); // need to recalculate every time
      if ((wasConversionActive || isConversionActive) && expenseItem.using_split && updateExpenseItem) {
        //in both case either conversion was active or currently conversion is active destroy debit lines
        // destroy existing splits
        updateExpenseItem = expenseItemCommonSvc.destroyAllExpenseReportDebitAccountAttr(updateExpenseItem);
        updateExpenseItem.using_split = false;
      }

      dispatch(change(ctx.formName, ctx.sectionPrefix, updateExpenseItem));
    }
  };

  const calculateExpenseAmount = (expenseItem: ExpensesTypes.ExpenseItemFormDataType, index: number) => {
    if (ctx) {
      expenseItemCommonSvc.calculateExpenseTax(expenseItem, currentUser);
      const tax = expenseItem?.tax && expenseItem?.tax > 0 ? Number(expenseItem?.tax) : 0;
      const total = expenseItem?.total ? Number(expenseItem?.total) : 0;
      expenseItem.amount = adminCommonSvc.roundUpAmount(total - tax, null, currentUser);
      expenseItemCommonSvc.calculateBaseCurrencyTax(expenseItem, currentUser);
      dispatch(change(ctx.formName, ctx.sectionPrefix, expenseItem));
    }
  };

  const onTotalAmountChange = (e: any) => {
    if (expenseItem && ctx) {
      expenseItem.total = adminCommonSvc.roundUpAmount(Number(e.target.value), null, currentUser);
      calculateExpenseAmount(expenseItem, ctx.index);
    }
  };

  const hideDraftButton = (id: IDType) => {
    if (id) {
      if (ctx?.formName) {
        dispatch(change(ctx.formName, "hideDraftBtn", true));
      }
    } else {
      if (ctx?.formName) {
        dispatch(change(ctx.formName, "hideDraftBtn", false));
      }
    }
  };

  useEffect(() => {
    if (
      (expenseItemCommonSvc.showReimbursable(currentUser) ||
        (expenseItem && expenseItemCommonSvc.disableReimbursable(expenseItem))) &&
      !expenseItem?.id &&
      ctx
    ) {
      dispatch(change(ctx.formName, ctx.sectionPrefix + ".reimbursable", true));
    }
  }, []);

  const onTaxAmountChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (expenseItem) {
      expenseItem.tax = Number(e.target.value);
      expenseItemCommonSvc.updateExpenseAmount(expenseItem, currentUser);
    }
  };

  const normalizeAmount = useCallback(() => {
    return (value: number, previousValue: number) => adminCommonSvc.normalizeAmount(value, previousValue, currentUser);
  }, []);

  return (
    <>
      <Row>
        <Col lg="8">
          <Field
            id="expense_merchant_name"
            type="text"
            label="Merchant"
            name="vendor_name"
            component={RenderField}
            disabled={!!expenseItem?.purchase?.id}
            validate={[required, noWhiteSpaceOnly]}
            required={!expenseItem?.purchase?.id}
          />
        </Col>
        <Col lg="4">
          <Field
            id="expense_date"
            label="Date"
            name="date"
            component={DatePicker}
            disabled={!!expenseItem?.purchase?.id}
            validate={[required]}
            required={!expenseItem?.purchase?.id}
          />
        </Col>
      </Row>
      {expenseItem &&
        expenseItemCommonSvc.isConversionAvailable(expenseItem) &&
        expenseItem?.show_policy_conversion_message && (
          <Row>
            <Col lg="3">
              <Field
                id="base_currency_code"
                label="Currency"
                name="base_currency_code"
                instanceId="base_currency_code"
                component={CurrencyCodePicker}
                callBack={getCurrencyRate}
                modelData={expenseItem}
                modelName={"expense_item"}
                disabled={!!expenseItem?.purchase?.id}
                validate={!expenseItem?.purchase?.id ? [required] : []}
                required={!expenseItem?.purchase?.id}
                notClearAble
              />
            </Col>
            {expenseItem?.policy?.taxes_enabled &&
              expenseItemCommonSvc.isTaxFieldsEnable(expenseItem?.policy?.taxes_enabled, currentUser) && (
                <Col lg="3">
                  <Field
                    id="base_currency_tax"
                    label={
                      <>
                        Tax Amount {expenseItem?.base_currency_code ? "(" + expenseItem?.base_currency_code + ")" : ""}
                      </>
                    }
                    name="base_currency_tax"
                    type="number"
                    normalize={normalizeAmount()}
                    component={RenderFieldNumber}
                    onChange={(e: any) => {
                      if (expenseItem?.tax_id) {
                        e.preventDefault();
                      }
                      if (expenseItem && ctx) {
                        expenseItem.base_currency_tax = Number(e.target.value);
                        expenseItemCommonSvc.calculateExchangeTax(expenseItem, currentUser);
                        dispatch(change(ctx.formName, ctx.sectionPrefix, expenseItem));
                      }
                    }}
                  />
                </Col>
              )}
            <Col lg="3">
              <Field
                id="base_currency_total"
                label={
                  <>
                    Total Amount {expenseItem?.base_currency_code ? "(" + expenseItem?.base_currency_code + ")" : ""}{" "}
                  </>
                }
                type="number"
                name="base_currency_total"
                component={RenderFieldNumber}
                normalize={normalizeAmount()}
                onChange={(e: any) => {
                  if (expenseItem && ctx) {
                    expenseItem.base_currency_total = Number(e.target.value);
                    expenseItemCommonSvc.calculateExchangeTotal(expenseItem, currentUser);
                    dispatch(change(ctx.formName, ctx.sectionPrefix, expenseItem));
                  }
                }}
                validate={[required]}
                required
              />
            </Col>

            {expenseItem &&
              expenseItemCommonSvc.isConversionAvailable(expenseItem) &&
              expenseItem?.currency_exchange.rate &&
              expenseItem?.show_policy_conversion_message && (
                <Col lg="3" className="policy_currency_warning mt-4">
                  <span>
                    Approx. rate {expenseItem?.currency_exchange.pair} is {expenseItem?.currency_exchange.rate}
                  </span>
                </Col>
              )}
          </Row>
        )}
      <Row>
        <Col lg={isTaxFieldEnable ? "3" : "4"}>
          <Field
            id="currency_code"
            label="Currency"
            name="currency_code"
            instanceId="currency_code"
            component={CurrencyCodePicker}
            callBack={getCurrencyRate}
            modelData={expenseItem}
            modelName={"expense_item"}
            notClearAble
            showCurrencyWithSymbol
            disabled={(expenseItem && expenseItem?.show_policy_conversion_message) || ctx?.expenseReport}
          />
          {expenseItem?.currency_code_error && <PickerErrorBlock error={expenseItem?.currency_code_error} />}
        </Col>

        {expenseItem?.policy?.taxes_enabled &&
          expenseItemCommonSvc.isTaxFieldsEnable(expenseItem?.policy?.taxes_enabled, currentUser) && (
            <Col lg={isTaxFieldEnable ? "3" : "4"}>
              <Field
                label={
                  <>
                    Tax Code
                    {!currentUser?.company?.expense_item?.tax_code_required && (
                      <span className="optional-field"> Optional </span>
                    )}
                  </>
                }
                callBackObj={(tax: TaxLKItem) => {
                  if (ctx && expenseItem) {
                    expenseItem.tax_id = tax.id;
                    expenseItem.tax_code = tax;
                    calculateExpenseAmount(expenseItem, ctx.index);
                  }
                }}
                modelData={expenseItem}
                name="tax_id"
                component={ExpenseItemTaxPicker}
                validate={currentUser.company.expense_item?.tax_code_required ? [required] : []}
                required={currentUser.company.expense_item?.tax_code_required}
                disabled={expenseItemCommonSvc.isPurchaseLinked({ expenseItem })}
              />
              {/* </div> */}
            </Col>
          )}
        {isTaxFieldEnable && (
          <Col lg={isTaxFieldEnable ? "3" : "4"}>
            <Field
              label={
                <>
                  Tax Amount
                  <span className="optional-field"> Optional</span>
                </>
              }
              name="tax"
              component={RenderFieldNumber}
              normalize={normalizeAmount()}
              onChange={onTaxAmountChange}
              disabled={expenseItem.show_policy_conversion_message || !!expenseItem?.purchase?.id}
            />
          </Col>
        )}
        <Col lg={isTaxFieldEnable ? "3" : "4"}>
          <Field
            label="Total Amount"
            name="total"
            component={RenderFieldNumber}
            normalize={normalizeAmount()}
            onChange={onTotalAmountChange}
            disabled={expenseItem?.show_policy_conversion_message || !!expenseItem?.purchase?.id}
            validate={
              expenseItem && !expenseItemCommonSvc.isConversionAvailable(expenseItem) && !expenseItem?.purchase?.id
                ? [required]
                : []
            }
            required={
              expenseItem && !expenseItemCommonSvc.isConversionAvailable(expenseItem) && !expenseItem?.purchase?.id
            }
          />
        </Col>
      </Row>
      <Row>
        {expenseItem && expenseItemCommonSvc.isAllowToShowErPicker(expenseItem, currentUser) && (
          <Col lg="6">
            <Field
              label={"Expense Report"}
              name="link_to_expense_report"
              modelData={expenseItem}
              component={ExpenseReportPicker}
              callBack={hideDraftButton}
              isClearable
            />
          </Col>
        )}
        {currentUser.company.expense_item?.show_billable && (
          <Col lg={"3"} className="mt-5">
            <Field
              id="billable_expense"
              label={<>Billable</>}
              type="checkbox"
              name="billable"
              component={RenderCheckBox}
            />
          </Col>
        )}
        <Col lg="3" className="mt-5">
          {expenseItem &&
            (expenseItemCommonSvc.showReimbursable(currentUser) ||
              expenseItemCommonSvc.disableReimbursable(expenseItem)) && (
              <>
                <Field
                  id="reimbursable"
                  label={
                    <label
                      className={`form-label ${
                        expenseItem && expenseItemCommonSvc.disableReimbursable(expenseItem) ? "grayed-out" : ""
                      }`}
                    >
                      For Reimbursement?
                    </label>
                  }
                  tooltip={
                    <TooltipRender
                      className={"icon-tooltips " + style.iconToolTip}
                      title="Expenses linked to a corporate card purchase are not marked as For Reimbursement. Any other expenses (like cash transactions or personal card purchases) that require reimbursement back to the employee should be marked as For Reimbursement."
                      placement="top"
                    />
                  }
                  name="reimbursable"
                  component={RenderCheckBox}
                  disabled={expenseItemCommonSvc.disableReimbursable(expenseItem)}
                  type="checkbox"
                />
              </>
            )}
        </Col>
      </Row>
      <Row>
        {!ctx?.params?.receiptID && (
          <Col lg="12">
            <Field
              id="expense_description"
              label="Description"
              name="description"
              component={RenderTextArea}
              type="checkbox"
              required={
                currentUser.company.expense_item?.required_description ||
                expenseItem?.policy_violations?.missing_description_by_category ||
                expenseItem?.policy_violations?.missing_description_by_policy
              }
              validate={currentUser.company.expense_item?.required_description ? [required] : []}
            />
          </Col>
        )}
      </Row>
    </>
  );
};

export default SingleExpenseForm;
