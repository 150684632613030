import { restApiService } from "providers/restApi";
import React, { useEffect, useState } from "react";
import DateFilter from "../reportFilters/date";
import DropdownFilter from "../reportFilters/dropdown";

type CardProgram = {
  account_code: string;
  external_id: string;
};

const BlockedAndExpiredCard = () => {
  async function fetchCardPrograms() {
    try {
      const response = await restApiService.get("card_programs", {}, null, true, null, true);
      return response.data;
    } catch (error) {
      console.error("Error fetching card programs:", error);
    }
  }

  const [accountCodes, setAccountCodes] = useState<string[]>([]);
  const [externalIds, setExternalIds] = useState<string[]>([]);

  useEffect(() => {
    fetchCardPrograms().then((cardPrograms: CardProgram[]) => {
      const accountCodes = Array.from(
        new Set(cardPrograms.map((program: CardProgram) => program.account_code).filter(Boolean)),
      );
      const externalIds = Array.from(
        new Set(cardPrograms.map((program: CardProgram) => program.external_id).filter(Boolean)),
      );
      setAccountCodes(accountCodes);
      setExternalIds(externalIds);
    });
  }, []);

  const account_code_options = [
    { value: "", label: "ALL" },
    ...accountCodes.map((code) => ({ value: code, label: code })),
  ];
  const customer_id_options = [{ value: "", label: "ALL" }, ...externalIds.map((id) => ({ value: id, label: id }))];
  const card_type_options = [
    { value: "", label: "ALL" },
    { value: "PHYSICAL", label: "PHYSICAL" },
    { value: "GHOST", label: "GHOST" },
    { value: "VIRTUAL", label: "VIRTUAL" },
  ];
  return (
    <div>
      <DropdownFilter name={"Account Code"} code={"card_program_comdata_account_id"} options={account_code_options} />
      <DropdownFilter name={"Customer ID"} code={"card_program_customer_id"} options={customer_id_options} />
      <DateFilter name={"Start Date"} code={"issue_date_after"} options={[]} />
      <DateFilter name={"End Date"} code={"issue_date_before"} options={[]} />
      <DropdownFilter name={"Card Type"} code={"card_type"} options={card_type_options} />
    </div>
  );
};

export default BlockedAndExpiredCard;
