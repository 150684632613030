import BusinessUnitPicker, { BusinessUnitObjType } from "components/admin/pickers/reduxFormPickers/businessUnitPicker";
import CustomFieldPicker from "components/admin/pickers/reduxFormPickers/customFieldPicker";
import departmentPicker from "components/admin/pickers/reduxFormPickers/departmentPicker";
import locationPicker from "components/admin/pickers/reduxFormPickers/locationPicker";
import ProjectPicker from "components/admin/pickers/reduxFormPickers/projectPicker/projectPicker";
import ErrorBoundary from "components/common/errorBoundary/errorBoundary";
import _ from "lodash";
import React, { useCallback } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "reducers";
import { selectCurrentUser } from "reducers/userReducers";
import { change, Field } from "redux-form";
import { CategoryTypes } from "services/admin/category/CategoryTypes";
import { CommonType } from "services/admin/commonTypes";
import { ExpensesTypes } from "services/admin/expenses/expensesType";
import { IUser } from "services/common/user/userTypes";
import { required } from "services/validations/reduxFormValidation";
import { MetadataFieldSelector } from "wombatifier/components/metadata_field/selector";
import ExpenseItemCategoryPicker from "../components/expenseItemCategoryPicker";
import { useExpenseItemFormContext } from "./expenseItemFormSectionContext";
import { useFormValue } from "./formExpenseItem";

const AllocationsFields = () => {
  const ctx = useExpenseItemFormContext();
  const currentExpenseItemFormLocationString = ctx?.sectionPrefix ?? "";
  const expenseItem = useFormValue<ExpensesTypes.ExpenseItemFormDataType>(ctx?.formName, ctx?.sectionPrefix);
  const currentUser: IUser = useTypedSelector(selectCurrentUser);
  const dispatch = useDispatch();

  const updateExpenseItem = useCallback(
    (updatedExpenseItem: ExpensesTypes.ExpenseItemFormDataType) => {
      if (ctx) {
        dispatch(change(ctx.formName, ctx?.sectionPrefix, updatedExpenseItem));
      }
    },
    [ctx, dispatch],
  );

  const onChangeProject = (project: CommonType.ProjectLk | null) => {
    if (_.isPlainObject(project) && expenseItem) {
      const updatedExpenseItem: ExpensesTypes.ExpenseItemFormDataType = {
        ...expenseItem,
        project_id: project?.id,
        project: project,
        project_name: project?.name,
      };
      updateExpenseItem(updatedExpenseItem);
    } else if (!project) {
      const updatedExpenseItem: ExpensesTypes.ExpenseItemFormDataType = {
        ...expenseItem,
        project_id: "",
        project: null,
        project_name: null,
      };
      updateExpenseItem(updatedExpenseItem);
    }
  };

  const onChangeCategory = (category: CategoryTypes.Item | null) => {
    // TODO: remove before merge
    // TODO: remove once backend fix it
    const account = Array.isArray(category?.accounts) && category?.accounts.find((acc, index) => index === 0);
    const updatedExpenseItem: ExpensesTypes.ExpenseItemFormDataType = {
      ...expenseItem,
      category_id: category?.id ?? null,
      category: category ?? null,
      category_name: category?.name ?? null,
      account_id: account ? account.id : null,
      account_name: account ? account.name : null,
      per_diem_id: "",
      per_diem: null,
    };
    updateExpenseItem(updatedExpenseItem);
  };

  const onChangeDepartment = (department: Record<string, any> | null) => {
    const updatedExpenseItem: ExpensesTypes.ExpenseItemFormDataType = {
      ...expenseItem,
      department_id: department?.id ? department?.id : null,
      department: department ?? null,
      department_name: department?.name ?? null,
    };
    updateExpenseItem(updatedExpenseItem);
  };

  const onChangeBusinessUnit = useCallback(
    (businessUnit: BusinessUnitObjType | null) => {
      let updatedExpenseItem: ExpensesTypes.ExpenseItemFormDataType;
      updatedExpenseItem = {
        ...expenseItem,
        business_unit_id: businessUnit?.id ?? null,
        business_unit: businessUnit ?? null,
        business_unit_name: businessUnit?.name ?? null,
      };
      updateExpenseItem(updatedExpenseItem);
    },
    [expenseItem, updateExpenseItem],
  );

  const onChangeLocation = (location: Record<string, any> | null) => {
    const updatedExpenseItem: ExpensesTypes.ExpenseItemFormDataType = {
      ...expenseItem,
      location_id: location?.id ?? null,
      location: (location as any) ?? null,
      location_name: location?.name ?? null,
    };
    updateExpenseItem(updatedExpenseItem);
  };

  if (ctx === undefined) return null;
  return (
    <Row className="mb-4">
      <Col lg="12">
        <Row>
          {expenseItem?.policy_id && (
            <>
              <Col md="6" lg="6">
                <Field
                  label="Category"
                  name="category_id"
                  component={ExpenseItemCategoryPicker}
                  modelData={expenseItem}
                  validate={[required]}
                  callBackObj={(category: CategoryTypes.Item) => onChangeCategory(category)}
                  required
                />
              </Col>

              {currentUser.company.expense_item?.show_project && (
                <Col lg="6">
                  <Field
                    label="Project"
                    name="project"
                    component={ProjectPicker}
                    modelData={expenseItem}
                    validate={currentUser.company.expense_item?.project_required ? [required] : []}
                    required={currentUser.company.expense_item?.project_required}
                    callBack={onChangeProject}
                    isClearable={false}
                  />
                </Col>
              )}

              {currentUser.company?.expense_item?.show_department && !expenseItem?.hide_department && (
                <Col lg="6">
                  <Field
                    label="Department"
                    name="department_id"
                    component={departmentPicker}
                    modelData={expenseItem}
                    parentObjData={expenseItem}
                    validate={currentUser.company.expense_item?.required_department ? [required] : []}
                    required={currentUser.company.expense_item?.required_department}
                    callBack={(department: any) => onChangeDepartment(department)}
                    restrictOptionsByEmployeeDepartment={currentUser.company?.expense_item?.inherit_employee_department}
                  />
                </Col>
              )}

              {currentUser.company?.has_business_units && currentUser.company?.expense_item?.show_business_unit && (
                <Col lg="6">
                  <Field
                    label="Business Unit"
                    name="business_unit_id"
                    component={BusinessUnitPicker}
                    modelData={expenseItem}
                    parentObjData={expenseItem}
                    validate={currentUser.company.expense_item?.required_business_unit ? [required] : []}
                    required={currentUser.company.expense_item?.required_business_unit}
                    callBack={onChangeBusinessUnit}
                  />
                </Col>
              )}
            </>
          )}

          {currentUser.company.expense_item?.show_location && (
            <Col lg="6">
              <Field
                label="Location"
                name="location_id"
                component={locationPicker}
                type="checkbox"
                required={currentUser.company.expense_item?.location_required}
                validate={currentUser.company.expense_item?.location_required ? [required] : []}
                parentObjData={expenseItem?.employee}
                modelData={expenseItem}
                callBack={(location: any) => onChangeLocation(location)}
              />
            </Col>
          )}
        </Row>

        <Row>
          <Col lg="12">
            <CustomFieldPicker
              formFieldName={"custom_fields"}
              formName={ctx.formName}
              parentObjData={expenseItem}
              modelData={expenseItem}
              modelDataFieldName={currentExpenseItemFormLocationString}
              absPath={`${ctx.sectionPrefix}.custom_fields`}
              modelName="ExpenseItem"
            />
            <Row>
              <ErrorBoundary>
                <MetadataFieldSelector
                  formName={ctx.formName}
                  formSlice={ctx.sectionPrefix}
                  modules="ExpenseItem"
                  noContainer
                />
              </ErrorBoundary>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default AllocationsFields;
