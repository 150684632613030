import Panel from "components/common/panel/panel";
import { InputField, RenderInputGroupField } from "components/forms/hookFormFields";
import _ from "lodash";
import React, { memo, useCallback, useMemo } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useFormContext, useWatch } from "react-hook-form";
import { useTypedSelector } from "reducers";
import { selectCurrentUser } from "reducers/userReducers";
import { useCategoryService } from "services/admin/category/useCategorySvc";
import adminCommonSvc from "services/admin/commonSvc";
import invoiceCommonSvc from "services/admin/invoices/invoiceCommonSvc";
import { IDType } from "services/common/types/common.type";
import { IUser } from "services/common/user/userTypes";
import { roundUpAmount } from "services/vp/services/roundUpAmount";
import useAdminCompanyCurrencyCode from "../hooks/useAdminCompanyCurrencyCode";
import { AbstractListPickerTypes } from "../pickers/reactHookFormPickers/abstractListPicker/abstractListPickerTypes";
import AccountPicker from "../pickers/reactHookFormPickers/accountPicker";
import CategoryPicker from "../pickers/reactHookFormPickers/categoryPicker";
import { CategoryPickerTypes } from "../pickers/reactHookFormPickers/categoryPicker/categoryPickerType";
import EventCodePicker from "../pickers/reactHookFormPickers/eventCodePicker";
import InterCompanyPicker from "../pickers/reactHookFormPickers/interCompanyPickers";
import ProjectPicker from "../pickers/reactHookFormPickers/projectPicker";
import { ProjectPickerTypes } from "../pickers/reactHookFormPickers/projectPicker/projectPickerTypes";
import TaxCodePicker from "../pickers/reactHookFormPickers/taxCodePicker/index2";
import { TaxCodePickerTypes } from "../pickers/reactHookFormPickers/taxCodePicker/taxCodePickerTypes";

const AccountEntry = ({
  index,
  accountType,
  accountEntries,
  modelDataName,
  commonFieldsCondition,
  isPoRequest,
  subsidiaryId,
  noVendor,
  accountGroupName,
  paymentType,
  readonly,
  disabled,
}: {
  index: number;
  accountType: string;
  accountEntries: any;
  modelDataName: string;
  commonFieldsCondition: Record<string, any>;
  isPoRequest: boolean;
  subsidiaryId: IDType;
  noVendor: boolean;
  accountGroupName: string;
  paymentType: string;
  readonly: boolean;
  disabled: boolean;
}) => {
  const { getValues, setValue } = useFormContext();
  const currentUser: IUser = useTypedSelector(selectCurrentUser);
  const isHideProject = useWatch({ name: `${accountEntries}.${index}.is_hide_project` });
  const accountEntryAccountId = useWatch({ name: `${accountEntries}.${index}.account_id` });
  const accountEntryIsProjectRequired = useWatch({ name: `${accountEntries}.${index}.is_project_required` });
  const { mapCategoryAccount } = useCategoryService();
  const { companyCurrencies } = useAdminCompanyCurrencyCode();
  const currencyCode = useWatch({ name: `${modelDataName}.currency_code` });

  const handleCategoryCallback = useCallback(
    (category?: CategoryPickerTypes.TCategoryPickerOption | null) => {
      mapCategoryAccount(category, `${accountEntries}.${index}`);
    },
    [accountEntries, index],
  );

  const mapAccountRequiredFields = useCallback((account: any) => {
    // TODO: add prper types here
    const accountEntry: any = getValues(`${accountEntries}.${index}`);

    invoiceCommonSvc.mapAccountRequiredFields(
      account,
      currentUser,
      accountEntry,
      paymentType == "CREDIT" ? "credit_memo" : modelDataName,
    );
    setValue(`${accountEntries}.${index}`, accountEntry);
  }, []);

  const handlePercentChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const percent = Number(e.target.value);
      setValue(`${accountEntries}.${index}.percent`, percent);
      const amount = getValues(`${modelDataName}.amount`);
      setValue(`${accountEntries}.${index}.amount`, adminCommonSvc.roundUpAmount((amount * Number(percent)) / 100, 4));
    },
    [accountEntries, getValues, index, modelDataName, setValue],
  );

  const calculateExpenseLineAmount = useCallback((accountEntry) => {
    const modelData: any = getValues(modelDataName);
    let tax = parseFloat(accountEntry.tax);
    let subAmount = parseFloat(accountEntry.sub_amount);
    accountEntry.tax = !isNaN(tax) ? tax : 0;
    accountEntry.sub_amount = !isNaN(subAmount) ? subAmount : 0;

    if (modelData.is_account_used_tax) {
      accountEntry.amount = adminCommonSvc.roundUpAmount(accountEntry.sub_amount, null, currentUser);
    } else {
      invoiceCommonSvc.makeWhTaxNegative(accountEntry, currentUser);
      accountEntry.amount = adminCommonSvc.roundUpAmount(
        parseFloat(accountEntry.tax) + parseFloat(accountEntry.sub_amount),
        null,
        currentUser,
      );
      if (accountEntry.wh_tax_amount) {
        accountEntry.amount = accountEntry.amount + accountEntry.wh_tax_amount;
      }
    }

    if (accountEntry.rebate_amount) {
      accountEntry.amount = accountEntry.amount + accountEntry.rebate_amount;
    }

    setValue(`${accountEntries}.${index}`, accountEntry);

    // if (invoiceCommonSvc.isActiveHeaderTax(modelData, currentUser)) {
    //   if (commonFieldsCondition.isInvoice) {
    //     invoiceCommonSvc.calculateHeaderLevelTax(modelData);
    //   } else if (scope.is_credit_memo()) {
    //     invoiceCommonSvc.calculate_header_level_credit_memo_tax(scope.modelData);
    //   }
    // }
  }, []);

  const calculateExpenseTax = useCallback(
    (
      taxCode?: AbstractListPickerTypes.TPickerValue<TaxCodePickerTypes.TTaxCodeOption>,
      isRebateCalculated?: boolean,
    ) => {
      const modelData: any = getValues(modelDataName);
      let accountObj = getValues(`${accountEntries}.${index}`);
      if (!invoiceCommonSvc.isActiveHeaderTax(modelData, currentUser)) {
        let amount =
          _.isPlainObject(accountObj.tax_code) && accountObj.tax_code?.rate && accountObj.tax_code.rate > 0
            ? (accountObj.sub_amount * accountObj.tax_code.rate) / 100
            : 0;
        accountObj.tax = isNaN(amount) ? null : adminCommonSvc.roundUpAmount(amount, null, currentUser);
      }
      if (!isRebateCalculated) {
        accountObj = invoiceCommonSvc.calculateExpenseLineRebate(accountObj, currentUser);
      }
      calculateExpenseLineAmount(accountObj);
    },
    [accountEntries, calculateExpenseLineAmount, currentUser, getValues, index, modelDataName],
  );

  const handleTaxChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const taxAmount = e.target.value ? Number(e.target.value) : null;
      setValue(`${accountEntries}.${index}.tax`, taxAmount);
      const accountEntry = getValues(`${accountEntries}.${index}`);
      calculateExpenseLineAmount(accountEntry);
    },
    [accountEntries, calculateExpenseLineAmount, getValues, index, setValue],
  );

  const manageSubAmount = useCallback(
    (value: any) => {
      setValue(`${accountEntries}.${index}.actual_sub_amount`, value);
      calculateExpenseTax();
    },
    [accountEntries, calculateExpenseTax, index, setValue],
  );

  const handleAmountChange = useCallback((value: any) => {
    const headerAmount = getValues(`${modelDataName}.amount`);
    setValue(`${accountEntries}.${index}.percent`, roundUpAmount(value / headerAmount) * 100);
  }, []);

  const showProject = useMemo(() => {
    if (modelDataName === "payment" && accountType === "Debit Accounts") {
      return false;
    } else if (modelDataName === "purchase_order" && currentUser.company.purchase_order?.expenses?.project?.is_hide) {
      return false;
    }
    return true;
  }, [currentUser.company.purchase_order?.expenses?.project?.is_hide, accountType, modelDataName]);

  const isProjectRequired = useMemo(() => {
    const company = currentUser.company;
    // TODO: add prper types here
    const modelData: any = getValues(modelDataName);
    return (
      accountEntryIsProjectRequired ||
      company?.virtual_card?.require_project ||
      (modelDataName === "purchase_order" && company.purchase_order?.expenses?.project?.is_required) ||
      (modelDataName === "payment" &&
        modelData.payment_type === "CREDIT" &&
        company.credit_memo?.expenses?.project?.is_required)
    );
  }, [accountEntryIsProjectRequired, currentUser.company, getValues, modelDataName]);

  const inheritLinkedDataByProject = useCallback(
    (project: AbstractListPickerTypes.TPickerValue<ProjectPickerTypes.TProjectPickerOption>) => {
      const lineObject = getValues(`${accountEntries}.${index}`);
      if (project && lineObject && _.isPlainObject(project) && _.isPlainObject(lineObject)) {
        //to check project has mapping of accounts or not
        if (project.has_accounts || lineObject?.project?.has_accounts) {
          lineObject.project_id = project.id;
          lineObject.project = project;
          lineObject.only_show_mapped_account = true;

          setValue(`${accountEntries}.${index}`, lineObject);
        }
      }
    },
    [accountEntries, getValues, index, setValue],
  );

  const getTotalDropDown = useMemo(() => {
    var total = 0;
    total += currentUser.company.has_locations ? 1 : 0;
    total += currentUser.company.has_departments ? 1 : 0;
    total += currentUser.company.has_business_units ? 1 : 0;
    total += currentUser.company.has_projects ? 1 : 0;
    total += currentUser.company.has_categories ? 1 : 0;
    total += currentUser.company.has_grants ? 1 : 0;
    return total;
  }, [
    currentUser.company.has_business_units,
    currentUser.company.has_categories,
    currentUser.company.has_departments,
    currentUser.company.has_grants,
    currentUser.company.has_locations,
    currentUser.company.has_projects,
  ]);

  const getDropdownColumnClass = useMemo(() => {
    let cls = "col-md-3";
    let c = getTotalDropDown;
    if (c === 4) {
      cls = "col-md-3";
    } else if (c === 3 || c === 5) {
      cls = "col-md-4";
    } else if (c === 2) {
      cls = "col-md-6";
    } else if (c === 1) {
      cls = "col-md-12";
    }
    return cls;
  }, [getTotalDropDown]);

  const isPaymentDebit = useMemo(() => {
    const modelData = getValues(modelDataName);
    return modelData?.payment_type === "DEBIT";
  }, [getValues, modelDataName]);

  return (
    <Panel
      header={
        <Row>
          <Col sm={11}>{accountType}</Col>
          <Col sm={1} className="px-pl-0">
            <Button
              // onClick={() => duplicateAccountEntry(index)}
              className="bg-transparent border-0 m-0 p-0 justify-content-end"
            >
              <i className="icon duplicate m-0" />
            </Button>
            <Button
              // onClick={() => deleteAccountEntry(index)}
              className="bg-transparent border-0 m-0 p-0 px-pl-15"
            >
              <i className="icon icon-delete m-0" />
            </Button>
          </Col>
        </Row>
      }
    >
      {/* ALl pickers will come here */}
      <Container fluid>
        <Row>
          {/* //TODO-SANKET : ADD VENDOR BY NAME */}
          {/* {    <div className="mb-3">
          <VendorPicker
            name={`${accountEntries}.${index}.vendor_name`}
            label={"Vendor"}
            excludeStatuses="INACTIVE"
            callBack={(selectedVendor) => handleVendorChange(selectedVendor)}
          />
        </div>} */}

          {commonFieldsCondition.showCategoryAtFirst && (
            <Col sm={noVendor || commonFieldsCondition.enableTax ? 3 : 6}>
              <CategoryPicker
                name={`${accountEntries}.${index}.category_id`}
                instanceId={`${accountEntries}.${index}.category`}
                required={currentUser?.company?.po_request?.expenses?.category?.is_required}
                label="Category"
                modelData={`${accountEntries}.${index}`}
                parentObj={modelDataName}
                callBack={handleCategoryCallback}
                disabled={disabled}
              />
            </Col>
          )}
          {!commonFieldsCondition.showCategoryAtFirst && (
            <Col sm={noVendor || commonFieldsCondition.enableTax ? 2 : 6}>
              <AccountPicker
                name={`${accountEntries}.${index}.account_id`}
                required
                label="Account"
                modelData={`${accountEntries}.${index}`}
                parentObj={modelDataName}
                accountGroupName={accountGroupName}
                callBack={mapAccountRequiredFields}
              />
            </Col>
          )}
          {commonFieldsCondition.showPercentField && !commonFieldsCondition.enableTaxAndRebate && (
            <Col sm="1">
              <InputField
                name={`${accountEntries}.${index}.percent`}
                type="number"
                label="Percent"
                readOnly={readonly}
                disabled={
                  disabled
                  //TODO-SANKET-VCARD vCardCommonSvc.permit_edits_to_card_purchase_accounts
                  // || !permitted_to_edit_purchases()
                }
                onChange={handlePercentChange}
              />
            </Col>
          )}
          {commonFieldsCondition.enableTax && (
            <Col sm="2">
              <TaxCodePicker
                instanceId={`${accountEntries}.${index}.tax_code_picker`}
                name={`${accountEntries}.${index}.tax_id`}
                label="Tax Code"
                objectPropertyKey={`${accountEntries}.${index}.tax_code`} // make sure that taxcode id should store in tax_id and object should store on tax_code
                modelData={`${accountEntries}.${index}`}
                parentObj={modelDataName}
                subsidiaryId={subsidiaryId}
                disabled={
                  disabled
                  //TODO-SANKET-VCARD vCardCommonSvc.permit_edits_to_card_purchase_accounts
                  // || !permitted_to_edit_purchases()
                }
                //todod-sanket-make field required using new function
                // required={}
                callBack={calculateExpenseTax}
              />
            </Col>
          )}
          {commonFieldsCondition.enableTax && commonFieldsCondition.showTaxAmount && (
            <Col sm="1">
              <InputField
                name={`${accountEntries}.${index}.tax`}
                type="number"
                label="Tax"
                readOnly={!currentUser.company.enable_to_enter_tax_amount}
                onChange={handleTaxChange}
              />
            </Col>
          )}
          {commonFieldsCondition.enableTaxAndRebate && (
            <Col sm={"2"}>
              <RenderInputGroupField
                name={`${accountEntries}.${index}.sub_amount`}
                type="number"
                label="Sub Amount"
                required={accountEntryAccountId ? true : false}
                inputGroupText={adminCommonSvc.getSymbolFromIsoCode(currencyCode, companyCurrencies)}
                onChangeCallback={manageSubAmount}
              />
            </Col>
          )}
          <Col sm="2">
            <RenderInputGroupField
              label={"Amount"}
              name={`${accountEntries}.${index}.amount`}
              type="number"
              disabled={
                disabled || commonFieldsCondition.enableTaxAndRebate
                //TODO-SANKET-VCARD vCardCommonSvc.permit_edits_to_card_purchase_accounts
                // || !permitted_to_edit_purchases()
              }
              onChangeCallback={handleAmountChange}
              inputGroupText={adminCommonSvc.getSymbolFromIsoCode(currencyCode, companyCurrencies)}
            />
          </Col>
        </Row>
        <Row>
          {showProject && currentUser.company.has_projects && !isHideProject && (
            <Col className={getDropdownColumnClass}>
              <ProjectPicker
                instanceId={`${accountEntries}.${index}.project`}
                label="Project"
                name={`${accountEntries}.${index}.project_id`}
                required={isProjectRequired}
                modelData={`${accountEntries}.${index}`}
                isPoRequest={isPoRequest}
                parentObj={modelDataName}
                callBack={inheritLinkedDataByProject}
                // disabled={} TODO: add disabled code here
              />
            </Col>
          )}

          {currentUser.company.has_event_codes && (
            <Col>
              <EventCodePicker
                instanceId={`account_entry_event_code_id_${index}`}
                label="Event Code"
                name={`${accountEntries}.${index}.event_code`}
              />
            </Col>
          )}
          {currentUser.company.has_projects && currentUser.company.allow_for_project && !isPaymentDebit && (
            <>
              <Col className={getDropdownColumnClass}>
                <ProjectPicker
                  instanceId={`${accountEntries}.${index}.for_project`}
                  label="For Project"
                  name={`${accountEntries}.${index}.for_project_id`}
                  modelData={`${accountEntries}.${index}`}
                  placeholder="-- For Project --"
                  isForProject
                  // disabled={} TODO: add disabled code here
                />
              </Col>
            </>
          )}
          {currentUser.company.has_inter_companies && (
            <Col sm="2">
              <InterCompanyPicker
                label="Inter Company"
                name={`${accountEntries}.${index}.inter_company`}
                modelData={`${accountEntries}.${index}`}
                parentObj={modelDataName}
              />
            </Col>
          )}
        </Row>
      </Container>
      {/* -------------------------- */}
    </Panel>
  );
};

export default memo(AccountEntry);
