import { restApiService } from "providers/restApi";
import React, { useCallback, useMemo } from "react";
import commonService from "services/common/commonSvc";
import AbstractListPicker from "../abstractListPicker/index2";
import { TaxSechedulePickerTypes } from "./taxScheduleTypes";

const TaxSchedulePicker = ({
  name,
  label,
  required = false,
  modelData: modelDataStr,
  objectPropertyKey,
}: TaxSechedulePickerTypes.TaxSchedulePickerProps) => {
  const { modelData } = commonService.modifyParentModalDataRefStr<{ modelData?: any }>({
    modelData: modelDataStr,
  });

  const mergeInactive = useCallback(
    async (currentId, taxSchedules) => {
      let inactiveOption = null;
      if (currentId) {
        const taxSchedule = Array.isArray(taxSchedules)
          ? taxSchedules.filter((taxSchedule) => taxSchedule.id === currentId)
          : [];
        if (taxSchedule.length === 0) {
          try {
            const response = await restApiService.get("tax_schedules", currentId);
            inactiveOption = response.data;
          } catch (error) {
            console.log(error);
          }
        } else {
          inactiveOption = taxSchedule[0];
        }
      }
      return { inactiveOption };
    },
    [modelData],
  );

  const params = useMemo(() => ({ status: "ACTIVE" }), []);
  return (
    <AbstractListPicker<TaxSechedulePickerTypes.TTaxScheduleOption>
      name={name}
      label={label}
      required={required}
      params={params}
      objectPropertyKey={objectPropertyKey ?? (modelData ? `${modelData}tax_schedule` : null)}
      mergeInactive={mergeInactive}
      fetchUrl="tax_schedules"
    />
  );
};

export default TaxSchedulePicker;
