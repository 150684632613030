import React from "react";
import { Spinner } from "react-bootstrap";

const LoadingBox = ({ isLoading, children, className = "" }) => {
  if (isLoading)
    return (
      <div className={className ? className : "w-100 h-100 d-flex justify-content-center align-items-center"}>
        <Spinner animation="border" variant="info" />
      </div>
    );
  return <>{children}</>;
};

export default LoadingBox;
