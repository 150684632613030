import LoadingBox from "components/common/loaders/loadingBox";
import React, { ReactElement, useMemo, useState } from "react";

interface LoaderPropsType {
  className?: string;
  children: ReactElement;
}

export const Loadable = () => {
  const [isLoading, setIsLoading] = useState(false);
  const Loader = useMemo(
    () =>
      ({ className, children }: LoaderPropsType) => {
        return (
          <LoadingBox isLoading={true} className={className}>
            {children}
          </LoadingBox>
        );
      },
    [isLoading],
  );
  return { isLoading, setIsLoading, Loader };
};
