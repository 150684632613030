import useAdminCompanyCurrencyCode from "components/admin/hooks/useAdminCompanyCurrencyCode";
import AccountPicker, { AccountObjType } from "components/admin/pickers/reduxFormPickers/accountPicker";
import BusinessUnitPicker from "components/admin/pickers/reduxFormPickers/businessUnitPicker";
import DepartmentPicker from "components/admin/pickers/reduxFormPickers/departmentPicker";
import InterCompanyPicker from "components/admin/pickers/reduxFormPickers/interCompanyPicker";
import LocationPicker from "components/admin/pickers/reduxFormPickers/locationPicker";
import Panel from "components/common/panel/panel";
import { RenderField, RenderInputGroupField, RenderTextArea } from "components/forms/bootstrapFields";
import React, { ReactNode, useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "reducers";
import { Field, FieldArrayFieldsProps, change } from "redux-form";
import adminCommonSvc from "services/admin/commonSvc";
import { CustomLabelSvc } from "services/admin/customLabels/customLabelsSvc";
import invoiceCommonSvc from "services/admin/invoices/invoiceCommonSvc";
import { IUser } from "services/common/user/userTypes";
import { required } from "services/validations/reduxFormValidation";
import { roundUpAmount } from "services/vp/services/roundUpAmount";
import styles from "../invoices.module.css";

type ManageInvoiceExpensesType = {
  amortization_schedule_name?: ReactNode;
  product_item?: any;
  tax_id?: number;
  unit_id?: number;
  qty?: number;
  percent?: number;
  unit_price?: number;
  amount?: number;
  tax?: number;
  business_unit_id?: number;
  department_id?: number;
  location_id?: number;
  budget_id?: number;
  account_id?: number;
  is_department_required?: boolean;
  is_location_required?: boolean;
  is_business_unit_required?: boolean;
  is_inter_company_required?: boolean;
};

type ManageInvoiceCreditEntriesPropsType = {
  fields: FieldArrayFieldsProps<ManageInvoiceExpensesType>;
  invoice: any;
  submitWithAccounts?: boolean;
  customLabels?: { [key: string]: string[] };
  allowSubmitWithGLErrors?: boolean;
};

const ManageInvoiceCreditEntries = ({
  fields,
  invoice,
  submitWithAccounts,
  customLabels,
  allowSubmitWithGLErrors,
}: ManageInvoiceCreditEntriesPropsType) => {
  const currentUser: IUser = useTypedSelector((state) => state.user);
  const [hideCreditEntry, setHideCreditEntry] = useState<number[]>([]);
  const [focusedIndex, setFocusedIndex] = useState<any>(null);
  const { companyCurrencies } = useAdminCompanyCurrencyCode();
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const formName = "InvoiceForm";

  useEffect(() => {
    if (!(fields.length > 0)) {
      let obj = {};
      invoiceCommonSvc.assign1099Eligible(invoice, obj, currentUser);
      fields.push(obj);
    }
  }, [!submitWithAccounts]);

  const deleteLine = (creditEntry: any, index: number) => {
    creditEntry._destroy = 1;
    setHideCreditEntry([...hideCreditEntry, index]);
  };

  const duplicateLine = (creditEntry: any) => {
    const duplicateRow = invoiceCommonSvc.getDuplicateRow(creditEntry, "account");
    fields.push(duplicateRow);
  };

  useEffect(() => {
    if (focusedIndex != null) {
      let invAmt = parseFloat(invoice?.amount ? invoice?.amount : 0);
      let creditEntry = fields.get(focusedIndex);
      let creditEntryAmount = creditEntry.amount ? creditEntry.amount : 0;
      creditEntry.percent = adminCommonSvc.roundUp((creditEntryAmount / invAmt) * 100);
      dispatch(change(formName, `${fields.get(focusedIndex)}.percent`, creditEntry.percent));
    }
  }, [focusedIndex, invoice?.amount, fields.get(focusedIndex)?.amount]);

  const updateCreditEntryAmount = (creditEntry: any, percent: number, index: number) => {
    let amt = Number(invoice.amount ? invoice.amount : 0);
    creditEntry.percent = percent;
    creditEntry.amount = Number(roundUpAmount((amt * percent) / 100, 4));
    creditEntry.percent = parseFloat(creditEntry.percent);
    // TODO: Implement update_discount_from_account feature
  };

  return (
    <>
      {submitWithAccounts && (
        <>
          <Row className="invoiceCreditEntryLine">
            <Col>
              {fields &&
                fields.length > 0 &&
                fields.map((creditEntry: any, index) => {
                  if (!hideCreditEntry.includes(index)) {
                    return (
                      <Panel
                        key={index}
                        wrapperClass={styles.wrapperClass}
                        header={
                          <Row>
                            <Col sm={11} className={styles.panelTitle}>
                              CREDIT ACCOUNTS
                            </Col>
                            <Col sm={1} className="px-pl-0">
                              <Button
                                onClick={() => duplicateLine(fields.get(index))}
                                className="bg-transparent border-0 m-0 p-0 justify-content-end"
                              >
                                <i className="icon duplicate m-0" />
                              </Button>
                              <Button
                                onClick={() => deleteLine(fields.get(index), index)}
                                className="bg-transparent border-0 m-0 p-0 px-pl-15"
                              >
                                <i className="icon icon-delete m-0" />
                              </Button>
                            </Col>
                          </Row>
                        }
                      >
                        <Row>
                          <Col>
                            <Field
                              instanceId={`credit-lines-${index}-account`}
                              component={AccountPicker}
                              name={`${creditEntry}.account_id`}
                              label={CustomLabelSvc.setCustomLabel(
                                customLabels,
                                t("admin.pages.invoice.invoiceItems.account"),
                              )}
                              accountGroupName="INVOICE_CREDIT_GROUPS"
                              required={invoiceCommonSvc.isFieldRequired(
                                fields.get(index).amount ? true : false,
                                allowSubmitWithGLErrors,
                              )}
                              validate={
                                invoiceCommonSvc.isFieldRequired(
                                  fields.get(index).amount ? true : false,
                                  allowSubmitWithGLErrors,
                                )
                                  ? [required]
                                  : []
                              }
                              placeHolder="-- Search For Accounts --"
                              callBack={(account: AccountObjType) =>
                                invoiceCommonSvc.mapAccountRequiredFields(account, currentUser, fields.get(index))
                              }
                            />
                          </Col>
                          <Col>
                            <Field
                              name={`${creditEntry}.percent`}
                              component={RenderField}
                              type="text"
                              label={CustomLabelSvc.setCustomLabel(
                                customLabels,
                                t("admin.pages.invoice.expenseItems.percent"),
                              )}
                              id={`credit-lines-${index}-percent`}
                              onChange={(e: any) => updateCreditEntryAmount(fields.get(index), e.target.value, index)}
                            />
                          </Col>
                          <Col>
                            <Field
                              id={`debit-line-${index}-amount`}
                              name={`${creditEntry}.amount`}
                              component={RenderInputGroupField}
                              type="text"
                              inputGroupText={adminCommonSvc.getSymbolFromIsoCode(
                                invoice?.currency_code,
                                companyCurrencies,
                              )}
                              label={CustomLabelSvc.setCustomLabel(customLabels, t("admin.pages.invoice.amount"))}
                              onChange={(e: any) => setFocusedIndex(index)}
                              required={fields.get(index).account_id ? true : false}
                              validate={fields.get(index).account_id ? [required] : []}
                            />
                          </Col>
                        </Row>

                        <Row>
                          {currentUser?.company?.has_departments &&
                            !currentUser?.company?.invoice?.expenses?.department?.is_hide && (
                              <Col sm={4}>
                                <Field
                                  instanceId={`credit-lines-${index}-department`}
                                  name={`${creditEntry}.department_id`}
                                  label={CustomLabelSvc.setCustomLabel(
                                    customLabels,
                                    t("admin.pages.invoice.invoiceItems.department"),
                                  )}
                                  component={DepartmentPicker}
                                  modelData={fields.get(index)}
                                  parentObjData={invoice}
                                  required={invoiceCommonSvc.isFieldRequired(
                                    fields.get(index).is_department_required ||
                                      currentUser?.company?.invoice?.expenses?.department?.is_required,
                                    allowSubmitWithGLErrors,
                                  )}
                                  validate={
                                    invoiceCommonSvc.isFieldRequired(
                                      fields.get(index).is_department_required ||
                                        currentUser?.company?.invoice?.expenses?.department?.is_required,
                                      allowSubmitWithGLErrors,
                                    )
                                      ? [required]
                                      : null
                                  }
                                  menuPosition="fixed"
                                />
                              </Col>
                            )}
                          {currentUser?.company?.has_locations &&
                            !currentUser?.company?.invoice_account_hide_location && (
                              <Col sm={4}>
                                <Field
                                  instanceId={`credit-lines-${index}-location`}
                                  name={`${creditEntry}.location_id`}
                                  label={CustomLabelSvc.setCustomLabel(
                                    customLabels,
                                    t("admin.pages.invoice.invoiceItems.location"),
                                  )}
                                  component={LocationPicker}
                                  modelData={fields.get(index)}
                                  parentObjData={invoice}
                                  callBack={(location: any) =>
                                    dispatch(change(formName, `${creditEntry}.location`, location))
                                  }
                                  required={invoiceCommonSvc.isFieldRequired(
                                    fields.get(index).is_location_required ||
                                      currentUser?.company?.invoice_expense_location_required,
                                    allowSubmitWithGLErrors,
                                  )}
                                  validate={
                                    invoiceCommonSvc.isFieldRequired(
                                      fields.get(index).is_location_required ||
                                        currentUser?.company?.invoice_expense_location_required,
                                      allowSubmitWithGLErrors,
                                    )
                                      ? [required]
                                      : null
                                  }
                                />
                              </Col>
                            )}
                          {currentUser?.company?.has_business_units &&
                            !currentUser?.company?.invoice_account_hide_business_unit && (
                              <Col sm={4}>
                                <Field
                                  instanceId={`credit-lines-${index}-business-unit`}
                                  name={`${creditEntry}.business_unit_id`}
                                  label={CustomLabelSvc.setCustomLabel(
                                    customLabels,
                                    t("admin.pages.invoice.invoiceItems.businessUnit"),
                                  )}
                                  component={BusinessUnitPicker}
                                  parentObjData={invoice}
                                  modelData={fields.get(index)}
                                  callBack={(businessUnit: any) =>
                                    dispatch(change(formName, `${creditEntry}.business_unit`, businessUnit))
                                  }
                                  bySubsidiary={currentUser?.company?.invoice?.show_business_unit_by_subsidiary}
                                  disabled={currentUser?.company?.readonly_business_unit_to_all}
                                  required={invoiceCommonSvc.isFieldRequired(
                                    fields.get(index).is_business_unit_required ||
                                      currentUser?.company?.invoice?.expenses?.business_unit?.is_required,
                                    allowSubmitWithGLErrors,
                                  )}
                                  validate={
                                    invoiceCommonSvc.isFieldRequired(
                                      fields.get(index).is_business_unit_required ||
                                        currentUser?.company?.invoice?.expenses?.business_unit?.is_required,
                                      allowSubmitWithGLErrors,
                                    )
                                      ? [required]
                                      : null
                                  }
                                  floating={true}
                                />
                              </Col>
                            )}
                          {currentUser?.company?.has_inter_companies && (
                            <Col sm={4}>
                              <Field
                                instanceId={`credit-lines-${index}-inter-company`}
                                name={`${creditEntry}.inter_company_id`}
                                label={CustomLabelSvc.setCustomLabel(
                                  customLabels,
                                  t("admin.pages.invoice.invoiceItems.interCompany"),
                                )}
                                component={InterCompanyPicker}
                                params={{
                                  account_id: fields.get(index).account_id,
                                  subsidiary_id: invoice?.subsidiary_id,
                                }}
                                required={fields.get(index).is_inter_company_required}
                                validate={fields.get(index).is_inter_company_required ? [required] : []}
                              />
                            </Col>
                          )}
                        </Row>
                        <Row>
                          <Col sm={12}>
                            <Field
                              id={`credit-lines-${index}-memo`}
                              name={`${creditEntry}.memo`}
                              label={CustomLabelSvc.setCustomLabel(
                                customLabels,
                                t("admin.pages.invoice.expenseItems.memo"),
                              )}
                              rows={2}
                              component={RenderTextArea}
                              required={invoiceCommonSvc.isFieldRequired(
                                currentUser?.company?.invoice?.expenses?.memo?.is_required,
                                allowSubmitWithGLErrors,
                              )}
                              validate={
                                invoiceCommonSvc.isFieldRequired(
                                  currentUser?.company?.invoice?.expenses?.memo?.is_required,
                                  allowSubmitWithGLErrors,
                                )
                                  ? [required]
                                  : []
                              }
                            />
                          </Col>
                        </Row>
                      </Panel>
                    );
                  }
                })}
            </Col>
          </Row>
          <Row className="px-mt-15">
            <Col>
              <Button variant="btn btn-primary" onClick={() => fields?.push({})}>
                <i className="btn-icon icon-add-btn"></i> Credit Accounts
              </Button>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default ManageInvoiceCreditEntries;
