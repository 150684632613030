import ReportTemplateCheckboxes from "components/admin/pickers/reduxFormPickers/reportTemplatePicker/checkboxes";
import ConfirmationModal from "components/common/modals/confirmation";
import LocationPicker from "components/pickers/reduxFormPicker/locationPicker";
import SubsidiaryPicker from "components/pickers/reduxFormPicker/subsidiaryPicker";
import TooltipRender from "components/toolTip/tooltipRender";
import { cloneDeep, omit } from "lodash";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Button, Col, Container, Form, Modal, Row, Table } from "react-bootstrap";
import { shallowEqual, useDispatch } from "react-redux";
import { Field, InjectedFormProps, change, reduxForm } from "redux-form";
import { SchedulesApis } from "services/admin/reports/schedules/schedulesApis";
import {
  DAY_OF_WEEK_OPTIONS,
  HOURS_OF_DAY_OPTIONS,
  SCHEDULE_INTERVALS_OPTIONS,
} from "services/admin/reports/schedules/schedulesSvc";
import {
  IntervalsTypes,
  ScheduleServiceNamesType,
  SchedulesFormType,
} from "services/admin/reports/schedules/schedulesType";
import {
  ReportTemplateModuleType,
  ReportTemplateOptionType,
} from "services/admin/reports/templates/reportTemplateType";
import { WombatType } from "wombatifier/services/wombat.types";
import { WombatApis } from "wombatifier/services/wombatApis";
import { useTypedSelector } from "../../../../reducers";
import { ReportApis } from "../../../../services/admin/reports/reportApis";
import {
  OUTBOUND_FILTERS_BY_MODEL,
  ReportSvc,
  TRANSACTION_CLASSIFICATION_OPTIONS,
} from "../../../../services/admin/reports/reportSvc";
import {
  DataExtractModuleTypes,
  ReportCategoryOptionsType,
  ReportClassificationType,
  ReportDetailType,
  ReportFormType,
} from "../../../../services/admin/reports/reportTypes";
import { CompanyType } from "../../../../services/common/user/userTypes";
import { CreateNotification, NotificationType } from "../../../../services/general/notifications";
import {
  afterDate,
  beforeDate,
  maxValue,
  minValue,
  required,
  wholeNumber,
} from "../../../../services/validations/reduxFormValidation";
import {
  ComparisonField,
  Mandatory,
  RenderCheckBox,
  RenderFieldNumber,
  RenderSelect,
} from "../../../forms/bootstrapFields";
import { InputSelect } from "../../pickers/inputs/inputSelect";
import ReportTemplateDropdown from "../../pickers/reduxFormPickers/reportTemplateDropdown";
import ReportTemplateFilters from "../templates";
import DateFilter from "../templates/reportFilters/date";
import ScheduledDateRange from "../templates/reportFilters/scheduledDateRange";
import { StandardFilter } from "../templates/reportFilters/standardFilter";
import templateStyles from "../templates/templates.module.css";
import styles from "./reports.module.css";

const isGreaterThanZero = minValue(0);
const isMaxDayofMonth = maxValue(31);

const TRANSACTION_CLASSIFICATION_TOOLTIP = (
  <Table className={`${styles.tooltipTable} table-sm bg-white`} bordered>
    <tbody>
      <tr>
        <td>New</td>
        <td>Expense is ready to be submitted.</td>
      </tr>
      <tr>
        <td>Pending</td>
        <td>Expense is associated with an Expense Report that is waiting for approval.</td>
      </tr>
      <tr>
        <td>Open</td>
        <td>Expense Report has been approved.</td>
      </tr>
      <tr>
        <td>Rejected</td>
        <td>Expense Report has been rejected by the approver.</td>
      </tr>
      <tr>
        <td>Paid</td>
        <td>Expense Report has been paid by your employer.</td>
      </tr>
    </tbody>
  </Table>
);

interface AddDataExtractPropsType {
  close: () => void;
  scheduled?: boolean;
  scheduleServiceName?: ScheduleServiceNamesType;
  editing?: boolean;
  options?: ReportCategoryOptionsType;
  classification: ReportClassificationType;
  formLabel: string;
  dataExtractModule?: DataExtractModuleTypes;
  refreshGrid?: () => void;
}

const AddDataExtract = ({
  handleSubmit,
  close,
  valid,
  scheduled = false,
  scheduleServiceName,
  editing,
  options,
  classification,
  formLabel,
  refreshGrid,
  dataExtractModule,
}: InjectedFormProps<ReportDetailType, AddDataExtractPropsType> & AddDataExtractPropsType) => {
  const reportState = useTypedSelector((state) => ReportSvc.getReportFormValues(state), shallowEqual);
  const company: CompanyType = useTypedSelector((state) => state.user?.company, shallowEqual);
  const interval: IntervalsTypes | undefined = useTypedSelector((state) => ReportSvc.getScheduleInterval(state));
  const endOfMonth: boolean | undefined = useTypedSelector((state) => ReportSvc.getEndOfMonth(state));
  const isMountingRef = useRef<boolean>(true);
  const wombatSourceTypeHash = useRef<{ [key: string]: WombatType }>({});
  const [wombatSourceTypeOptions, setWombatSourceTypeOptions] = useState<{ value: string; label: string }[]>([]);

  const dispatch = useDispatch();
  const [typeChosen, setTypeChosen] = useState<ReportTemplateModuleType>();
  const [reportTemplateChosen, setReportTemplateChosen] = useState<ReportTemplateOptionType>();
  const [markExportedVisible, setMarkExportedVisible] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(false);
  const startDateRef = useRef<Date>();
  const endDateRef = useRef<Date>();

  const beforeDateValidation = useMemo(() => beforeDate(endDateRef), []);
  const afterDateValidation = useMemo(() => afterDate(startDateRef), []);

  const exportDestinationOptions = useMemo(
    () => [
      { label: "download", value: "download" },
      { label: "email", value: "email" },
      { label: "sftp", value: "sftp" },
    ],
    [],
  );
  const exportFormatOptions = useMemo(
    () => [
      { label: "xlsx", value: "xlsx" },
      { label: "csv", value: "csv" },
    ],
    [],
  );

  const setExcludeDescriptionHeader = useCallback(
    (value) => {
      dispatch(change(ReportSvc.formName, "properties.exclude_description_header", value));
    },
    [dispatch],
  );

  const uncheckMarkExported = useCallback(() => {
    dispatch(change(ReportSvc.formName, "properties.mark_records_exported", false));
    setMarkExportedVisible(false);
  }, [dispatch]);

  const uncheckIncludePrevious = useCallback(() => {
    dispatch(change(ReportSvc.formName, "properties.filters.include_external_id_has_data", false));
  }, [dispatch]);

  const clearChosenClassifications = useCallback(() => {
    dispatch(change(ReportSvc.formName, "properties.filters.transaction_classifications_chosen", ["All"]));
  }, [dispatch]);

  const updateReportName = useCallback(
    (reportName: string) => {
      dispatch(change(ReportSvc.formName, "name", reportName));
    },
    [dispatch],
  );

  const handleSetTypeChosen = useCallback(
    async (input: { value: ReportTemplateModuleType; label: string } | undefined) => {
      classification === "DataExtract" && setIsValid(true);
      if (!input) {
        setTypeChosen("");
      } else {
        setTypeChosen(input.value);
      }
    },
    [],
  );

  const handleReportTemplateChosen = useCallback(
    (input) => {
      dispatch(change(ReportSvc.formName, "report_template_id", input.value));
      setReportTemplateChosen(input);
      let validTemplate = input.value !== 0; // If the value is 0, it means a report template hasn't been chosen
      setIsValid(validTemplate);
    },
    [dispatch],
  );

  const formTitle = scheduled ? `Scheduled ${formLabel}` : formLabel;

  const getReportStatusByCode = (code: string) => {
    let reportStatus;
    if (["visa_s_balance_detail", "visa_s_transactions_listing"].includes(code)) {
      reportStatus = "NEW";
    } else if (
      [
        "payment_reissue_listing",
        "payment_refund_listing",
        "outstanding_check_listing",
        "unprocessed_nvp_transactions",
      ].includes(code)
    ) {
      reportStatus = "PROCESSING";
    } else {
      reportStatus = "PENDING";
    }
    return reportStatus;
  };

  const correctReportDateFilterValue = (date: Date): string => {
    /*
        Normalize Date to a Date string that backend processes unambiguously.
        IE: Sets it so 3/14/2024 will be processed as 3/14/2024 in the backend
    */
    let month = date.getMonth() + 1;
    let dayOfMonth = ("0" + date.getDate()).slice(-2);
    let year = date.getFullYear();
    return `${year}-${month}-${dayOfMonth}T07:00:00.000Z`;
  };

  const correctReportDateFilters = (
    filters: { [key: string]: any } | undefined,
  ): { [key: string]: any } | undefined => {
    if (filters === undefined) {
      return;
    }
    if (filters.start_date !== undefined) {
      filters.start_date = correctReportDateFilterValue(filters.start_date);
    }
    if (filters.end_date !== undefined) {
      filters.end_date = correctReportDateFilterValue(filters.end_date);
    }
    return filters;
  };

  const createReport = async (formValues: ReportDetailType, template: ReportTemplateOptionType) => {
    let exportFormat =
      template.export_format === "csv" || template.export_format === "xlsx" || template.export_format === "pdf"
        ? template.export_format
        : formValues.export_format;
    let status = getReportStatusByCode(template.code);

    if (formValues.properties?.filters) {
      formValues.properties.filters = correctReportDateFilters(formValues.properties?.filters);
    }
    const payload = {
      ...omit(formValues, "report_templates"),
      report_template_id: template.value,
      name: formValues.name || template.label,
      export_format: exportFormat,
      company_id: company?.id,
      status: status,
    };
    await ReportApis.create(payload);
  };

  const createSchedule = async (formValues: ReportFormType, template: ReportTemplateOptionType) => {
    let exportFormat =
      template.export_format === "csv" || template.export_format === "xlsx"
        ? template.export_format
        : formValues.export_format;
    let status = getReportStatusByCode(template.code);
    let parameters = {
      ...omit(formValues, "report_templates", "schedule"),
      report_template_id: template.value,
      name: formValues.name || template.label,
      export_format: exportFormat,
      status: status,
    };
    switch (formValues.schedule?.interval) {
      case "DAILY":
        delete formValues.schedule.day_of_week;
        delete formValues.schedule.day_of_month;
        break;
      case "WEEKLY":
        delete formValues.schedule.hour_of_day;
        delete formValues.schedule.day_of_month;
        break;
      case "MONTHLY":
        delete formValues.schedule.hour_of_day;
        delete formValues.schedule.day_of_week;
        break;
    }
    let params: SchedulesFormType;
    if (editing) {
      params = {
        ...(formValues.schedule ?? {}),
        id: formValues.schedule?.id,
        parameters,
        is_active: true,
        recipients: parameters.recipients,
        schedulable_id: template.value,
        schedulable_type: "ReportTemplate",
      };
      await SchedulesApis.patch(params);
    } else {
      params = {
        ...formValues.schedule,
        parameters,
        id: null,
        company_id: company?.id,
        is_active: true,
        name: parameters.name,
        recipients: parameters.recipients,
        schedulable_id: template.value,
        schedulable_type: "ReportTemplate",
        service_name: scheduleServiceName,
      };
      await SchedulesApis.create(params);
    }
  };

  const submitReport = async (values: ReportFormType) => {
    let formValues: ReportDetailType = cloneDeep(values);
    ReportSvc.setReportParams(formValues);
    let errors: string[] = [];
    switch (classification) {
      case "Standard":
      case "FileOutbound":
        if (reportTemplateChosen) {
          try {
            if (scheduled) {
              await createSchedule(formValues, reportTemplateChosen);
            } else {
              await createReport(formValues, reportTemplateChosen);
            }
            if (refreshGrid) {
              refreshGrid();
            }
          } catch (err: any) {
            errors.push(err);
          }
        }
        break;
      case "DataExtract":
        if (formValues.report_templates && formValues.report_templates.length > 0) {
          formValues.report_templates.forEach(async (template) => {
            try {
              if (scheduled) {
                await createSchedule(formValues, template);
              } else {
                await createReport(formValues, template);
              }
              if (refreshGrid) {
                refreshGrid();
              }
            } catch (err: any) {
              errors.push(err);
            }
          });
        }
        break;
    }
    handleSubmissionErrors(errors);
    setTimeout(() => close(), 1000);
  };

  const handleSubmissionErrors = useCallback(
    (errors: string[]) => {
      const title = `New ${formTitle}`;
      const message = `${formTitle} submitted`;
      if (errors.length) {
        CreateNotification(title, errors.join(" | "), NotificationType.danger);
      } else {
        CreateNotification(title, message, NotificationType.success);
      }
    },
    [formTitle],
  );

  // This function always returns the same length array of booleans, not violating TS useEffect dependency rule
  const classificationComparisonFn = useCallback((reportState: ReportFormType | undefined): boolean[] => {
    let classificationOptionsHash: { [key: string]: any } = {};
    const transactionClassificationsChosen = reportState?.properties?.filters?.transaction_classifications_chosen;
    TRANSACTION_CLASSIFICATION_OPTIONS.forEach((item) => {
      classificationOptionsHash[item.value] = false;
    });
    if (transactionClassificationsChosen?.length) {
      transactionClassificationsChosen.forEach((name: string) => {
        classificationOptionsHash[name] = true;
      });
    }
    return Object.values(classificationOptionsHash);
  }, []);

  const setWombatOptions = async (destinationType: string) => {
    try {
      let wombats = (await WombatApis.outbounds({
        filter: { destination_type: destinationType, by_paymerang_outbound_integrated: 1 },
      })) as WombatType[];
      let options = [{ label: "None", value: "" }];
      options = [
        ...options,
        ...wombats.map((wombat) => ({
          value: wombat.source_type,
          label: wombat.name,
        })),
      ];
      wombatSourceTypeHash.current = wombats.reduce(
        (result, wombat) => {
          result[wombat.source_type] = wombat;
          return result;
        },
        {} as { [key: string]: WombatType },
      );
      setWombatSourceTypeOptions(options);
    } catch (err) {}
  };

  useEffect(() => {
    // if called, it means that at least one of the transaction_classifications_chosen has changed
    // we check if all is included. If so, will clear out all other classifications as required
    const transactionClassificationsChosen = reportState?.properties?.filters?.transaction_classifications_chosen;
    if (
      classification === "DataExtract" &&
      transactionClassificationsChosen?.length &&
      transactionClassificationsChosen.includes("All")
    ) {
      clearChosenClassifications();
    }
  }, classificationComparisonFn(reportState));

  useEffect(() => {
    // when user checks mark records exported, open confirm modal
    if (isMountingRef.current) {
      return;
    }
    if (classification === "DataExtract" && reportState?.properties?.mark_records_exported) {
      setMarkExportedVisible(true);
    }
  }, [reportState?.properties?.mark_records_exported]);

  useEffect(() => {
    // when user presses 'yes' on mark records exported confirm modal which closes modal
    if (isMountingRef.current) {
      return;
    }
    if (classification === "DataExtract" && !markExportedVisible && reportState?.properties?.mark_records_exported) {
      uncheckIncludePrevious(); // set 'include previous exported' to 'No'
    }
  }, [markExportedVisible]);

  useEffect(() => {
    // when user tries to set 'Yes' on 'include previously exported' while mark_records_exported is checked
    if (isMountingRef.current) {
      return;
    }
    if (
      classification === "DataExtract" &&
      reportState?.properties?.filters?.include_external_id_has_data &&
      reportState?.properties?.mark_records_exported
    ) {
      uncheckIncludePrevious(); // always set 'include previously exported' to 'No'
    }
  }, [reportState?.properties?.filters?.include_external_id_has_data]);

  useEffect(() => {
    if (classification === "FileOutbound" && destinationType) {
      setWombatOptions(destinationType);
    }
  }, [reportTemplateChosen]);

  useEffect(() => {
    if (reportState?.properties?.wombat_source_type && wombat) {
      updateReportName(wombat.name);
    }
  }, [reportState?.properties?.wombat_source_type]);

  useEffect(() => {
    isMountingRef.current = false;
    if (reportState?.schedule?.schedulable) {
      const { id, code, name, module_type } = reportState.schedule.schedulable;
      setReportTemplateChosen({ value: id, code, label: name });
      setTypeChosen(module_type as ReportTemplateModuleType);
    }
    if (classification === "FileOutbound") {
      setExcludeDescriptionHeader(1);
    }

    if (classification === "DataExtract") {
      if (dataExtractModule) {
        if (dataExtractModule === "Card & Expense") {
          setTypeChosen("Card & Expense");
        } else {
          setTypeChosen("Invoice");
        }
      }
    }
  }, []);

  const destinationType =
    reportTemplateChosen?.properties?.wombat_destination_type ||
    reportTemplateChosen?.code?.replace("file_outbound_", "").replace("_version", "") ||
    "";
  const wombatSourceType = reportState?.properties?.wombat_source_type;
  const wombat = wombatSourceType ? wombatSourceTypeHash.current[wombatSourceType] : undefined;
  const outboundFilter = reportTemplateChosen?.filter_config
    ? reportTemplateChosen?.filter_config
    : OUTBOUND_FILTERS_BY_MODEL[destinationType];
  const outboundGroup = wombat?.group;

  return (
    <Modal show={true} size="lg" centered contentClassName={styles.addModalContent}>
      <Modal.Header>
        <Container fluid>
          <Row>
            <Col className={`font-weight-bold ${styles.addHeaderTitle}`}>
              {editing ? "Update " + formTitle : "Create " + formTitle}
            </Col>
            <Col style={{ flex: 0 }} className={`${styles.addHeaderClose} px-0`}>
              <i className={`icon icon-close ${styles.addHeaderCloseIcon}`} onClick={close}></i>
            </Col>
          </Row>
        </Container>
      </Modal.Header>
      <Modal.Body>
        <ConfirmationModal
          title=""
          show={markExportedVisible}
          saveCallback={() => setMarkExportedVisible(false)}
          closeCallback={uncheckMarkExported}
          confirmText="Yes"
          cancelText="No"
          body="The transactions in this data extract will be marked as exported in the database. Are you sure you want to continue?"
        />
        <Container fluid>
          <Col>
            {!editing && classification === "Standard" && (
              <>
                <Row className="mx-0">
                  <Col className="px-0">
                    <InputSelect
                      options={options ? options : []}
                      label={`Category`}
                      labelClassName={`font-weight-bold ${templateStyles.filterLabel}`}
                      placeholder="Select one"
                      isRequired
                      onChange={handleSetTypeChosen}
                    />
                  </Col>
                </Row>
                <Row className="mx-0">
                  <Col className="px-0">
                    <hr></hr>
                  </Col>
                </Row>
              </>
            )}

            {(typeChosen || classification !== "Standard") && (
              <>
                {classification === "DataExtract" && (
                  <>
                    <Row className={`mx-0 font-weight-bold pb-2 ${templateStyles.filterLabel}`}>
                      Status
                      <Mandatory required={true} />
                      <TooltipRender
                        title={TRANSACTION_CLASSIFICATION_TOOLTIP}
                        placement="right"
                        className="icon-sm icon-questionmark mt-1"
                        containerClassName={styles.toolTipOverride}
                      />
                    </Row>
                    <Row className="mx-0">
                      <Col className="px-0">
                        <Field
                          name="properties.filters.transaction_classifications_chosen"
                          component={RenderSelect}
                          options={TRANSACTION_CLASSIFICATION_OPTIONS}
                          label=""
                          placeholder="Select one(s)"
                          validate={[required]}
                          multiple
                        />
                      </Col>
                    </Row>
                  </>
                )}

                {classification === "Standard" && (
                  <>
                    {!editing && (
                      <ReportTemplateDropdown
                        classification={classification}
                        moduleType={typeChosen}
                        onChange={handleReportTemplateChosen}
                      />
                    )}
                    <ReportTemplateFilters reportTemplateChosen={reportTemplateChosen} scheduled={scheduled} />
                  </>
                )}

                {classification === "FileOutbound" && (
                  <>
                    {!editing && (
                      <ReportTemplateDropdown
                        classification={[classification]}
                        moduleType="Custom"
                        onChange={handleReportTemplateChosen}
                      />
                    )}
                    <Row className="m-0 pb-2">
                      <Col className="px-0">
                        <Field
                          name="properties.wombat_source_type"
                          label="Export Type"
                          labelClassName="font-weight-bold"
                          className="m-0"
                          component={RenderSelect}
                          options={wombatSourceTypeOptions}
                        />
                      </Col>
                    </Row>
                  </>
                )}

                {scheduled && (
                  <>
                    <Row className="mx-0">
                      <Col className="px-0">
                        <hr></hr>
                      </Col>
                    </Row>
                    <Row className="mx-0">
                      <Col className="px-0">
                        <Field
                          component={RenderSelect}
                          name="schedule.interval"
                          options={SCHEDULE_INTERVALS_OPTIONS}
                          label="Frequency"
                          labelClassName={`font-weight-bold ${templateStyles.filterLabel}`}
                          required
                        />
                      </Col>
                    </Row>
                    {interval === "DAILY" ? (
                      <Row className="mx-0">
                        <Col className="px-0">
                          <Field
                            component={RenderSelect}
                            name="schedule.hour_of_day"
                            options={HOURS_OF_DAY_OPTIONS}
                            label="Deliver On"
                            labelClassName={`font-weight-bold ${templateStyles.filterLabel}`}
                            required
                          />
                        </Col>
                      </Row>
                    ) : interval === "WEEKLY" ? (
                      <Row className="mx-0">
                        <Col className="px-0">
                          <Field
                            component={RenderSelect}
                            name="schedule.day_of_week"
                            options={DAY_OF_WEEK_OPTIONS}
                            label="Deliver On"
                            labelClassName={`font-weight-bold ${templateStyles.filterLabel}`}
                            required
                          />
                        </Col>
                      </Row>
                    ) : interval === "MONTHLY" ? (
                      <>
                        {!endOfMonth && (
                          <Row className="mx-0">
                            <Col className="px-0">
                              <Field
                                name="schedule.day_of_month"
                                options={HOURS_OF_DAY_OPTIONS}
                                label="Deliver On"
                                labelClassName={`font-weight-bold ${templateStyles.filterLabel}`}
                                required
                                type="number"
                                component={RenderFieldNumber}
                                min={1}
                                max={31}
                                validate={[required, wholeNumber, isGreaterThanZero, isMaxDayofMonth]}
                              />
                            </Col>
                          </Row>
                        )}
                        <Row className="mx-0 py-1">
                          <Col className="p-0">
                            <Field name="schedule.end_of_month" label="End of month ?" component={RenderCheckBox} />
                          </Col>
                        </Row>
                      </>
                    ) : null}
                  </>
                )}

                {classification === "DataExtract" && (
                  <>
                    <Row className="mx-0">
                      <Col className="px-0">
                        <hr></hr>
                      </Col>
                    </Row>
                    <StandardFilter filters={reportState?.properties?.filters} label="Select Date Range" />

                    {company.has_subsidiaries && (
                      <Row className="m-0">
                        <Col>
                          <Field
                            name="properties.filters.subsidiary_ids_original"
                            label="Subsidiary"
                            labelClassName="font-weight-bold"
                            component={SubsidiaryPicker}
                            placeholder="All"
                            isMulti
                            filter={{ active: true }}
                          />
                        </Col>
                      </Row>
                    )}
                    <Row className="mx-0">
                      <Col className="px-0">
                        <hr></hr>
                      </Col>
                    </Row>

                    <Row className={`mx-0 font-weight-bold pb-2 ${templateStyles.filterLabel}`}>Export Preferences</Row>
                    <Row className="mx-0">
                      <Col md={5} className="pr-0">
                        <Form.Label>Include Previously Exported Records</Form.Label>
                      </Col>
                      <Col md={3}>
                        <Field
                          name="properties.filters.include_external_id_has_data"
                          className="d-flex flex-row"
                          label=""
                          options={[
                            { value: false, label: "No" },
                            { value: true, label: "Yes" },
                          ]}
                          component={RenderSelect}
                          type="boolean"
                        />
                      </Col>
                    </Row>
                    <Row className="m-0">
                      <Col>
                        <Field
                          name="report_templates"
                          label="Files to be Exported"
                          component={ReportTemplateCheckboxes}
                          labelClassName="font-weight-bold"
                          classification="DataExtract"
                          placeholder="Select one"
                          validate={[required]}
                          required
                          onChange={handleReportTemplateChosen}
                          moduleType={typeChosen}
                          isMulti
                        />
                      </Col>
                    </Row>
                    <Row className="m-0">
                      <Col>
                        <Field
                          name="export_format"
                          label="Export Format"
                          labelClassName="font-weight-bold"
                          component={RenderSelect}
                          options={exportFormatOptions}
                          required
                          validate={[required]}
                        />
                      </Col>
                    </Row>
                    <Row className="m-0">
                      <Col>
                        <Field
                          name="export_destination"
                          label="Export Destination"
                          labelClassName="font-weight-bold"
                          component={RenderSelect}
                          options={exportDestinationOptions}
                          required
                          validate={[required]}
                        />
                      </Col>
                    </Row>
                  </>
                )}

                {classification === "Standard" && (
                  <>
                    <Row className={`mx-0 font-weight-bold pb-2 ${templateStyles.filterLabel}`}>Export Preferences</Row>
                    <Row className="m-0">
                      <Col>
                        {reportTemplateChosen?.export_format ? (
                          <>
                            <label className="font-weight-bold form-label">Export Format</label>
                            <h5>{reportTemplateChosen.export_format}</h5>
                          </>
                        ) : (
                          <Field
                            name="export_format"
                            label="Export Format"
                            labelClassName="font-weight-bold"
                            component={RenderSelect}
                            options={exportFormatOptions}
                            required
                            validate={[required]}
                          />
                        )}
                      </Col>
                    </Row>
                    <Row className="m-0">
                      <Col>
                        <Field
                          name="export_destination"
                          label="Export Destination"
                          labelClassName="font-weight-bold"
                          component={RenderSelect}
                          options={exportDestinationOptions}
                          required
                          validate={[required]}
                        />
                      </Col>
                    </Row>
                  </>
                )}

                {classification === "FileOutbound" && (
                  <>
                    <Row className="mx-0">
                      <Col className="px-0">
                        <hr></hr>
                      </Col>
                    </Row>
                    <Row className="m-0">
                      <Col className="px-0">
                        <Field
                          name="export_format"
                          label="Export Format"
                          labelClassName="font-weight-bold"
                          component={RenderSelect}
                          options={exportFormatOptions}
                          required
                          validate={[required]}
                        />
                      </Col>
                    </Row>
                    <br />

                    {scheduled ? (
                      <ScheduledDateRange
                        name={"Schedule Date Range"}
                        code={"schedule_date_range"}
                        options={[]}
                        isRequired
                      />
                    ) : outboundFilter?.date?.enabled && outboundGroup && outboundFilter.date[outboundGroup] ? (
                      <>
                        <DateFilter
                          name={outboundFilter.date[outboundGroup][wombat.wombat_type ?? ""]?.labelFrom ?? "Start Date"}
                          code={outboundFilter.date[outboundGroup][wombat.wombat_type ?? ""]?.fieldFrom ?? "start_date"}
                          isRequired
                          valueRef={startDateRef}
                          validations={[beforeDateValidation]}
                        />
                        <DateFilter
                          name={outboundFilter.date[outboundGroup][wombat.wombat_type ?? ""]?.labelTo ?? "End Date"}
                          code={outboundFilter.date[outboundGroup][wombat.wombat_type ?? ""]?.fieldTo ?? "end_date"}
                          isRequired
                          valueRef={endDateRef}
                          validations={[afterDateValidation]}
                        />
                      </>
                    ) : outboundFilter?.date?.enabled ? (
                      <>
                        <DateFilter
                          name={outboundFilter.date.labelFrom ?? "Start Date"}
                          code={"start_date"}
                          isRequired
                          valueRef={startDateRef}
                          validations={[beforeDateValidation]}
                        />
                        <DateFilter
                          name={outboundFilter.date.labelTo ?? "End Date"}
                          code={"end_date"}
                          isRequired
                          valueRef={endDateRef}
                          validations={[afterDateValidation]}
                        />
                      </>
                    ) : null}

                    {company.has_subsidiaries && outboundFilter?.subsidiary?.enabled ? (
                      <Row className="m-0">
                        <Col className="px-0">
                          <Field
                            name={`properties.filters.${outboundFilter.subsidiary.field}`}
                            label="Subsidiary"
                            labelClassName="font-weight-bold"
                            component={SubsidiaryPicker}
                            placeholder="All"
                            isMulti
                            filter={{ active: true }}
                          />
                        </Col>
                      </Row>
                    ) : null}

                    {company.has_locations && outboundFilter?.location?.enabled ? (
                      <Row className="m-0">
                        <Col className="px-0">
                          <Field
                            name={`properties.filters.${outboundFilter.location.field}`}
                            label="Location"
                            labelClassName="font-weight-bold"
                            component={LocationPicker}
                            placeholder="All"
                            isMulti
                            filter={{ active: true }}
                          />
                        </Col>
                      </Row>
                    ) : null}

                    <Row className="m-0">
                      <Col className="px-0">
                        <Field
                          name="export_destination"
                          label="Export Destination"
                          labelClassName="font-weight-bold"
                          component={RenderSelect}
                          options={exportDestinationOptions}
                          required
                          validate={[required]}
                        />
                      </Col>
                    </Row>
                  </>
                )}

                {reportState?.export_destination === "email" && (
                  <Row>
                    <Col>
                      <Field
                        name="recipients_original"
                        label="Deliver Report to these E-mails (separated with commas)"
                        component={ComparisonField}
                        transparentDisabled={false}
                        validate={[required]}
                      />
                    </Col>
                  </Row>
                )}

                <Row className="mx-0">
                  <Col className="px-0">
                    <hr></hr>
                  </Col>
                </Row>

                {classification === "DataExtract" && (
                  <>
                    <Row className="mx-0 py-1">
                      <Col className="p-0">
                        <Field
                          name="properties.mark_records_exported"
                          label="Mark Records Exported"
                          component={RenderCheckBox}
                        />
                      </Col>
                    </Row>
                  </>
                )}

                <Row className="mx-0">
                  <Col className="p-0">
                    <Field
                      name="properties.exclude_description_header"
                      label="Exclude Header Description"
                      component={RenderCheckBox}
                    />
                  </Col>
                </Row>
              </>
            )}
            <Row className="mx-0 pt-3 pb-4">
              <Col>
                <Row className="mx-0 justify-content-end">
                  <Button type="button" variant="secondary" onClick={close} className="mx-2">
                    Cancel
                  </Button>
                  <Button
                    type="button"
                    className={`${styles.submitButton}`}
                    disabled={!valid || !isValid}
                    onClick={handleSubmit(submitReport)}
                  >
                    Create
                  </Button>
                </Row>
              </Col>
            </Row>
          </Col>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

const AddReportForm = reduxForm<ReportFormType, AddDataExtractPropsType>({
  touchOnChange: true,
  touchOnBlur: false,
  destroyOnUnmount: true,
  form: ReportSvc.formName,
})(AddDataExtract);

export default AddReportForm;
