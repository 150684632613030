import { restApiService } from "providers/restApi";
import React, { useCallback, useMemo } from "react";
import { AbstractListPickerTypes } from "../abstractListPicker/abstractListPickerTypes";
import AbstractListPicker from "../abstractListPicker/index2";
import { WarehousePickerTypes } from "./warehousePickerTypes";

const WarehousePicker = ({
  name,
  label,
  required = false,
  callBack,
  modelData,
  objectPropertyKey,
  instanceId,
}: WarehousePickerTypes.TWarehousePickerProps) => {
  const params = useMemo(() => ({ status: "ACTIVE" }), []);

  const handlePickerSelection = useCallback(
    (selected: AbstractListPickerTypes.TPickerValue<WarehousePickerTypes.TWarehousePickerOption>) => {
      if (callBack) {
        callBack(selected);
      }
    },
    [callBack],
  );

  const mergeInactive = useCallback(async (currentId, warehouses) => {
    let inactiveOption = null;
    if (currentId) {
      const warehouse = Array.isArray(warehouses) ? warehouses.filter((warehouse) => warehouse.id === currentId) : [];
      if (warehouse.length === 0) {
        try {
          const response = await restApiService.get("warehouses", currentId);
          inactiveOption = response.data;
        } catch (error) {
          console.log(error);
        }
      } else {
        inactiveOption = warehouse[0];
      }
    }
    return { inactiveOption };
  }, []);

  return (
    <>
      <AbstractListPicker<WarehousePickerTypes.TWarehousePickerOption>
        name={name}
        label={label}
        required={required}
        params={params}
        objectPropertyKey={objectPropertyKey ?? (modelData ? `${modelData}.warehouse` : null)}
        instanceId={instanceId}
        callBack={handlePickerSelection}
        mergeInactive={mergeInactive}
        fetchUrl="warehouses"
      />
    </>
  );
};

export default WarehousePicker;
