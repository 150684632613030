import { IAttachment, IDType } from "services/common/types/common.type";
import { IUser } from "services/common/user/userTypes";
import { CategoryTypes } from "../category/CategoryTypes";
import { PolicyTypes } from "../policy/policyTypes";

export namespace ExpensesTypes {
  type Purchase = {
    id?: number;
    number?: string;
  };

  export type TMapRouteFormData = {
    map_route: ExpensesTypes.TMapRoute;
  };

  export type TMapRouteWaypoints = {
    id?: number;
    address?: string;
    lat?: number;
    lng?: number;
    place_id?: string;
  };

  export enum MILAGE_UNITS {
    MILE = "mi",
    KILOMETER = "km",
  }

  export type TMapRoute = {
    id?: number;
    distance?: number;
    round_trip?: boolean;
    units?: MILAGE_UNITS;
    map_route_waypoints?: TMapRouteWaypoints[];
    map_route_waypoints_attributes?: TMapRouteWaypoints[];
  };

  export type Details = {
    accounts_number_and_name_str?: string;
    expense_report_debit_accounts_attributes?: ExpenseDebitAccounts | null;
    purchase?: Purchase;
    selected_type?: any;
    metadata_configurations?: any;
    amount?: number;
    assets?: Asset[];
    base_currency_amount?: number;
    base_currency_code?: string;
    base_currency_tax?: number;
    base_currency_total?: number;
    billable?: null;
    business_unit_id?: IDType;
    business_unit?: {
      id?: IDType;
      name?: string;
    };
    category?: Category;
    category_id?: IDType;
    concierge_status?: null;
    currency_code?: string;
    currency_rate?: number;
    custom_fields?: any[];
    metadata_links_attributes?: any;
    date?: string;
    department?: Department;
    department_id?: IDType;
    dept_manager_name?: string;
    description?: string;
    receipt_notes?: string;
    destination_address?: null;
    distance?: number;
    distance_unit?: string;
    employee?: Employee;
    employee_id?: IDType;
    external_id?: string;
    expense_report?: ExpenseReport;
    expense_report_debit_accounts?: ExpenseDebitAccount[];
    expense_report_id?: IDType;
    geolocations?: null;
    has_assets?: boolean;
    id?: IDType;
    inherit_po_item_id?: IDType;
    is_expense_item?: boolean;
    item_type?: string;
    location?: {
      id?: IDType;
      name: string;
    };
    reimbursable?: boolean;
    map_route?: TMapRoute | null;
    number?: string;
    pending_assets?: null;
    per_diem_id?: IDType;
    policy?: Policy;
    policy_id?: IDType;
    policy_violations?: PolicyViolations;
    project?: Project;
    project_id?: IDType;
    purchase_order_id?: IDType;
    purchase_order?: { id?: IDType; number?: string };
    qty?: number;
    starting_address?: null;
    status?: string;
    status_label?: string;
    submit_date?: string;
    submit_method?: null;
    submitter?: Submitter;
    subsidiary_id?: IDType;
    subsidiary?: { id?: IDType; name?: string };
    tax?: number;
    tax_code?: {
      id?: IDType;
      name?: string;
      rate?: string;
    };
    tax_id?: IDType;
    total?: number;
    vendor_name?: string;
    rejection_comment?: string;
    violations_messages?: string[];
  };

  export type List = ListItem[];

  export type PaginatedList = {
    data: ListItem[];
    meta: {
      count: number;
      items: number;
      page: number;
      pages: number;
    };
  };

  export type ListItem = {
    amount?: number;
    assets?: any[];
    approver_started?: boolean;
    billable?: boolean;
    category_id?: IDType;
    contact_id?: IDType;
    currency_code?: string;
    date?: string;
    description?: string;
    distance?: null;
    distance_unit?: string;
    employee_id?: IDType;
    expense_report?: ExpenseReport;
    expense_report_id?: number;
    has_assets?: boolean;
    has_violations?: boolean;
    id?: IDType;
    inherit_po_item_id?: IDType;
    is_expense_item?: boolean;
    item_type?: string;
    location_id?: number;
    reimbursable?: boolean;
    number?: string;
    nonreimbursable?: boolean;
    pending_assets?: null;
    per_diem_id?: null;
    policy_id: number;
    project_name?: null;
    purchase_order_id?: null;
    qty?: number;
    receipt_required?: string;
    status?: string;
    submit_date?: string;
    submit_method?: null;
    submitter?: Submitter;
    subsidiary_id?: number;
    tax?: null;
    tax_id?: null;
    total?: number;
    vendor_name?: string;
    purchase_id: number;
  };

  type Asset = {
    asset_expiring_thumb_url?: string;
    asset_expiring_url?: string;
    asset_file_file_name?: string;
    assetable_id?: IDType;
    assetable_type?: string;
    id?: IDType;
    linked_document_id?: IDType;
    updated_at?: string;
  };

  type Category = {
    account?: Account;
    id?: IDType;
    name?: string;
  };

  type Account = {
    account_type_id?: IDType;
    ancestry?: null;
    balance?: null;
    company_id?: IDType;
    created_at?: string;
    department_id?: IDType;
    description?: null;
    external_id?: IDType;
    id?: IDType;
    is_department_required?: null;
    is_hide_project?: null;
    is_location_required?: null;
    is_project_required?: null;
    label?: null;
    name?: string;
    number?: null;
    properties?: null;
    status?: string;
    updated_at?: string;
  };

  type Department = {
    id?: number;
    name?: string;
  };

  type Employee = {
    avatar?: Avatar;
    currency_code?: string;
    first_name?: string;
    id?: IDType;
    last_name?: string;
    subsidiary?: Subsidiary;
  };

  type Avatar = {
    asset_expiring_thumb_url?: string;
    asset_expiring_url?: string;
    id?: IDType;
  };

  export type Subsidiary = {
    allow_line_tax?: null;
    company_id?: IDType;
    country?: string;
    created_at?: string;
    currency_code?: string;
    exchange_rate?: null;
    external_id?: string;
    id?: IDType;
    location_id?: IDType;
    name?: string;
    settings?: null;
    status?: string;
    sub_type?: null;
    updated_at?: string;
  };

  type ExpenseReport = {
    approver_started?: boolean;
    id?: number;
    number?: string;
  };

  export type Policy = {
    allowed_currencies?: null;
    convert_to_base_currency?: boolean;
    convert_to_employee_currency?: boolean;
    convert_to_subsidiary_currency?: boolean;
    currency_code?: string;
    currency_setting?: string;
    custom_fields?: null;
    excluded_currencies?: null;
    id?: IDType;
    max_expense_amount?: number;
    max_expense_days?: number;
    name?: string;
    per_diems_enabled?: boolean;
    policy_category_links?: PolicyCategoryLink[];
    rate_per_km?: number;
    rate_per_mi?: number;
    receipt_required?: boolean;
    receipt_required_amount?: number;
    taxes_enabled?: boolean;
    violations_enabled?: boolean;
    properties?: any;
  };

  export type PolicyViolations = {
    missing_description_by_category?: boolean;
    missing_description_by_policy?: boolean;
    missing_category_by_policy?: boolean;
    amount_exceeds_policy_max?: boolean;
    expense_date_exceeds_policy_max?: boolean;
    future_expense_date?: boolean;
    missing_receipt_by_policy?: boolean;
    missing_receipt_by_category?: boolean;
    amount_exceeds_category_max?: boolean;
  };

  export type PolicyCategoryLink = {
    accounts?: any[];
    category_id?: IDType;
    description_required?: boolean;
    id?: number;
    max_expense_amount?: number;
    name?: string;
    policy_id?: IDType;
    receipt_required?: boolean;
    reporting_type?: string;
    status?: string;
  };

  type Project = {
    description?: string;
    id?: IDType;
    name?: string;
  };

  type Submitter = {
    contact_id?: IDType;
    department_name?: string;
    email?: string;
    id?: IDType;
    name?: string;
    source?: string;
    submitter_type?: string;
    updated_at?: string;
  };

  export type actionRequired = {
    unsubmitted_expense_item_count?: number | string;
    expense_items_with_violations_count?: number | string;
    unsubmitted_expense_report_count?: number | string;
    expense_report_pending_count?: number | string;
  };
  export type reportSummary = {
    new_expense_reports?: number | string;
    pending_expense_reports?: number | string;
    open_expense_reports?: number | string;
    paid_expense_reports?: number | string;
    posted_expense_reports?: number | string;
    closed_expense_reports?: number | string;
  };

  export type accountBalance = {
    balance: number;
    currency_code: string;
    program_id: string;
  };

  export type ExpenseCategoryType = {
    category_name: string;
    currency: { symbol: string; iso_code: string };
    expenses_count: number;
    total_spent: number;
    refId?: string;
  };

  export type ViolationsCategoryType = {
    category_name: string;
    violations_count: number;
    refId: string;
  };

  export type ViolationsCategoryReturnType = {
    violation_counts: { [key: string]: number };
  };

  export type MonthlySpendType = {
    amount_spent: number | string;
    currency: { symbol: string; iso_code: string };
    month: string;
    year: string;
  };

  export type MerchantSpendType = {
    merchant_name: string;
    expenses_count: number;
    total_spent: number;
    currency: {
      symbol: string;
      iso_code: string;
    };
  };

  export type TopTenMerchantSpendListType = MerchantSpendType[];

  export type ExpenseFormDataType = {
    hideDraftBtn?: boolean;
    is_submit_disabled?: boolean;
    expenses: ExpenseItemFormDataType[];
    is_draft?: boolean;
  };

  export type ExpenseItemFormDataType = {
    policy_violations?: Record<string, any>;
    department_id?: IDType | null;
    department?: Department | null;
    department_name?: string | null;
    per_diem_id?: IDType;
    qty?: number;
    per_diem?: any;
    itemTypes?: any;
    reimbursable?: boolean;
    attachments?: IAttachment[];
    finalPrimaryKey?: IDType;
    error?: string;
    status?: string;
    is_draft?: boolean;
    is_expense_item?: boolean;
    finalId?: IDType;
    warning?: string;
    expense_report?: { id?: IDType };
    tax_id?: IDType;
    purchase?: {
      id?: IDType;
    };
    project?: { id?: IDType; name?: string } | null;
    project_id?: IDType | null;
    project_name?: string | null;
    business_unit_id?: IDType | null;
    business_unit?: { id?: IDType; name?: string } | null;
    business_unit_name?: string | null;
    location_id?: IDType | null;
    location?: { id?: IDType; name: string } | null;
    location_name?: string | null;
    hide_department?: boolean;
    hideCategory?: boolean;
    id?: IDType;
    distance?: number;
    current_tab?: string;
    view_transaction?: any; // TODO: fix this type
    description?: string;
    auto_create_pdf?: boolean;
    date?: string;
    vendor_name?: string;
    is_expense_call_from_po_detail_page?: boolean;
    purchase_order?: {
      id?: IDType;
      number?: string;
    } | null;
    purchase_order_id?: IDType | null;
    amount?: number;
    tax?: number | null;
    base_currency_amount?: number | null;
    base_currency_tax?: number | null;
    tax_code?: any; // TODO: fix this type
    base_currency_total?: number | null;
    currency_rate?: any; // TODO: fix this type
    currency_exchange?: any; // TODO: fix this type
    currency?: any; // TODO: fix this type
    base_currency_code?: string | null;
    distance_unit?: string;
    hide_item_type?: boolean;
    policy?: PolicyTypes.Details;
    link_to_expense_report?: IDType;
    nonreimbursable?: null | boolean;
    subsidiary_id?: IDType;
    subsidiary?: Subsidiary;
    currency_code?: string;
    currency_code_error?: string;
    employee?: IUser["contact"];
    employee_id?: IDType;
    policy_id?: IDType;
    item_type?: string;
    selected_type?: SelectedTypeItem;
    assets_attributes?: IAttachment[];
    assets?: any;
    total?: number | null;
    is_loaded?: boolean;
    hide_subsidiary?: boolean;
    expense_report_id?: IDType;
    show_policy_conversion_message?: boolean;
    category_id?: IDType | null;
    category?: CategoryTypes.Item | null;
    category_name?: string | null;
    default_mileage_value?: PolicyTypes.MileageRate;
    set_mileage_rate_error?: boolean;
    expense_report_debit_accounts?: any[];
    custom_fields?: any;
    map_route?: TMapRoute | null;
    map_route_attributes?: TMapRoute | null;
    metadata?: null | Record<string, any>;
    metadata_links?: any;
    metadata_template_id?: IDType | null;
    metadata_links_attributes?: any;
    expense_debit_accounts?: ExpenseDebitAccounts;
    expense_report_debit_accounts_attributes?: ExpenseDebitAccounts | null;
    using_split?: boolean;
    account?: Account | null;
    account_id?: IDType | null;
    account_name?: string | null;
    remaining_amount?: number | null;
    remaining_percentage?: number | null;
    is_split_100_percent?: boolean;
    metadataForm?: any;
    violations_messages?: string[];
  };

  export type ExpenseDebitAccounts = ExpenseDebitAccount[];

  export type ExpenseDebitAccount = {
    id?: IDType | null; // id
    metadata_template_id?: IDType | null;
    metadataForm?: any;
    account?: CategoryTypes.Account | undefined | null;
    account_id?: IDType | null;
    account_number?: string | number;
    account_name?: string | null;

    _destroy?: number;
    base_currency_amount?: string | number;
    amount?: string | number;
    percentage?: string | number;
    percent?: string | number;

    category?: CategoryTypes.Item | null;
    category_id?: IDType | null;
    category_name?: string | null;

    department?: Department | null;
    department_id?: IDType | null;
    department_name?: string | null;

    location_id?: IDType | null;
    location?: { id?: IDType; name?: string } | null;
    location_name?: string | null;

    business_unit_id?: IDType | null;
    business_unit?: { id?: IDType; name?: string } | null;
    business_unit_name?: string | null;

    project_id?: IDType | null;
    project?: { id?: IDType; name?: string } | null;
    project_name?: string | null;

    subsidiary_id?: IDType;
    subsidiary?: Subsidiary | undefined;

    policy_id?: IDType;
    policy?: PolicyTypes.Details | undefined;

    custom_fields?: any;
    metadata_links?: any;
    metadata_links_attributes?: any;
  };

  export type SelectedTypeItem = {
    value: string;
    label: string;
    icon: string;
  };

  export type NewExpenseItem = {
    amount?: number;
    billable?: boolean;
    category_id?: IDType;
    currency_code?: string;
    date?: string;
    description?: string;
    distance?: number;
    distance_unit?: string;
    employee_id?: number;
    is_expense_item?: boolean;
    item_type?: string;
    number?: string;
    per_diem_id?: IDType;
    policy_id?: IDType;
    project?: Project;
    qty?: number;
    submit_method?: string;
    tax?: number;
    tax_id?: IDType;
    total?: number | null;
  };

  export type SplitExpenseCodingFormData = {
    expenseItem: ExpenseItemFormDataType;
    remaining_amount: number;
    remaining_percentage: number;
    target_currency_remaining_amount: number;
    currency_conversion_active: boolean;
    expense_report_debit_accounts_attributes: ExpensesTypes.ExpenseDebitAccounts;
    currency_rate: number;
    currency_code: string;
    total: number | string;
    base_currency_total: number;
    base_currency_code: string;
    subsidiary_id: IDType;
    subsidiary?: Subsidiary | undefined;
    policy_id: IDType;
    policy?: PolicyTypes.Details | undefined;
  };

  export type ExpenseItemPayload = {};

  export type EmployeeType = {
    id?: IDType;
  };

  export type ExpenseReportDebitAccountType = {
    amount?: number;
    percent?: string;
    tax_id?: number | null;
    tax?: number | null;
    sub_amount?: number | null;
    tax_code?: string | null;
    budget_id?: number | null;
    business_unit_id?: number;
    business_unit_name?: string | null;
    department_id?: number;
    department_name?: string | null;
    account_id?: number;
    account_number?: string;
    account_name?: string;
    location_id?: number;
    location_name?: string | null;
    project_id?: number | null;
    project_name?: string | null;
    category_id?: number | null;
    category_name?: string | null;
  };

  export type ExpenseSplitMetaType = {
    displayable: boolean;
    header_label: string;
    values: string[];
  };

  export type SplitExpenseCategoryType = {
    category_name: ExpenseSplitMetaType;
    department_name: ExpenseSplitMetaType;
    location_name: ExpenseSplitMetaType;
    business_unit_name: ExpenseSplitMetaType;
    project_name: ExpenseSplitMetaType;
    metadata: ExpenseSplitMetaType;
    amount: ExpenseSplitMetaType;
    percentage: ExpenseSplitMetaType;
  };
}
