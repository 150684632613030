import { AdvancedMarker } from "@vis.gl/react-google-maps";
import React, { memo } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { ExpensesTypes } from "services/admin/expenses/expensesType";
import style from "./map.module.css";

/* A component that displays markers for each point of interest (POI) on a map, where each POI is shown
 * with a letter (A, B, C, etc.) according to its sequence
 */

type Poi = ExpensesTypes.TMapRouteWaypoints;

const MarkerComponent = ({ title }: { title: string }) => {
  return <div className={style.mapPin}>{title && <div className={style.pinTitle}>{title}</div>}</div>;
};

const PoiMarkers = () => {
  const { control } = useFormContext<ExpensesTypes.TMapRouteFormData>();
  const wayPoints = useWatch({ name: "map_route.map_route_waypoints", control }) ?? [];
  return (
    <>
      {wayPoints.map((poi: Poi, index: number) =>
        poi.lat && poi.lng ? (
          <AdvancedMarker
            key={index}
            position={{
              lat: poi.lat!,
              lng: poi.lng!,
            }}
          >
            <MarkerComponent title={String.fromCharCode(65 + index)} />
          </AdvancedMarker>
        ) : null,
      )}
    </>
  );
};

export default memo(PoiMarkers);
