import { ColDef, GridApi, ICellRendererParams } from "ag-grid-community";
import { sortBy } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { setAllowedFilters } from "services/common/gridService";
import { FieldFilterMapType } from "services/common/types/grid.type";
import { formattedAmount } from "services/general/helpers";
import ReceiptsApis from "../../../../services/admin/expenses/receipts/receiptsApis";
import receiptService from "../../../../services/admin/expenses/receipts/receiptsSvc";
import { ReceiptsTypes } from "../../../../services/admin/expenses/receipts/receiptsType";
import { companyDateFormat } from "../../../../services/general/dateSvc";
import { CreateNotification, NotificationType } from "../../../../services/general/notifications";
import { translate } from "../../../../services/general/translation";
import gridService from "../../../../services/grid/gridSvc";
import useConfirmModal from "../../../modals/confirmModal/useConfirmModalHook";

const Actions = ({ data, gridApi }: { data: ReceiptsTypes.ListItem; gridApi?: GridApi }) => {
  const { createConfirmModal } = useConfirmModal();
  const { t } = useTranslation();

  const onDeleteConfirm = async () => {
    try {
      if (data.id && gridApi) {
        const result = await ReceiptsApis.deleteReceipt(data.id);
        gridService.removeRowFromGrid(gridApi, String(data.id));
        CreateNotification(
          t("success"),
          t("admin.pages.receipts.successfulDelete", { id: data.id, name: data.name }),
          NotificationType.success,
        );
      }
    } catch (error) {}
  };

  const createDeleteConformation = () => {
    createConfirmModal({
      title: t("admin.pages.receipts.deleteDialog.header"),
      body: t("admin.pages.receipts.deleteDialog.content"),
      confirmButtonLabel: t("admin.pages.receipts.deleteDialog.deleteButtonLabel"),
      callBackData: null,
      cancelCallBack: null,
      saveCallBack: onDeleteConfirm,
    });
  };

  return (
    <>
      <Link className="link" to={"/ap/inbound_receipts/" + data?.id}>
        <button type="button" className="actionButtons view" data-toggle="tooltip" title="View"></button>
      </Link>
      <button
        type="button"
        className="actionButtons icon-delete"
        data-toggle="tooltip"
        title="Delete"
        onClick={createDeleteConformation}
      ></button>
    </>
  );
};

export const FIELD_NAME_MAP: FieldFilterMapType = {
  created_at: {
    inRange: "",
    lessThanOrEqual: "created_at_before",
    greaterThanOrEqual: "created_at_after",
  },
};

export const getMatchedReceiptsHeaders = ({
  gridApi,
  currentUser,
}: {
  gridApi?: GridApi;
  currentUser: any;
}): { columnDefs: ColDef[]; defaultOrder: string[] } => {
  let columnDefs = [
    {
      field: "select",
      checkboxSelection: true,
      headerCheckboxSelection: true,
      filter: false,
      maxWidth: 30,
    },
    {
      field: "documentable_type",
      cellRenderer: (params: { data: ReceiptsTypes.ListItem } & Omit<ICellRendererParams, "data">) => {
        if (params?.data?.documentable_type) {
          return <>{receiptService.getLegibleRecieptType(params?.data?.documentable_type)}</>;
        } else {
          return null;
        }
      },
      headerName: "Transaction Type",
      filter: "agTextColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
      },
      floatingFilterComponent: "agCustomSelectFilter",
      floatingFilterComponentParams: {
        suppressFilterButton: true,
        options: [
          { label: "Expense", value: JSON.stringify(["InvoiceItem", "ExpenseItem"]) },
          { label: "Card", value: JSON.stringify(["Purchase", "BankCardTransaction"]) },
        ],
      },
      headerValueGetter: function () {
        return translate("transactionType");
      },
    },
    {
      field: "id",
      headerName: "ID",
      cellRenderer: (params: { data: ReceiptsTypes.ListItem } & Omit<ICellRendererParams, "data">) => {
        return (
          <Link className="link" to={"/ap/inbound_receipts/" + params?.data?.id}>
            {params?.data?.id}
          </Link>
        );
      },
      filter: false,
      headerValueGetter: function () {
        return translate("ID");
      },
    },
    {
      field: "created_at",
      headerName: "Submitted Date",
      filter: "agDateColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
        browserDatePicker: true,
      },
      cellRenderer: (params: any) => {
        return companyDateFormat(params.value, currentUser);
      },
      headerValueGetter: function () {
        return translate("submittedDate");
      },
    },
    {
      field: "documentable.bank_card_transaction",
      headerName: "Transaction Date",
      sortable: false,
      filter: false,
      cellRenderer: (params: any) => {
        const documentable = params?.data?.documentable;
        if (!documentable) {
          return "";
        }
        let renderedValue = "";
        switch (params.data.documentable_type) {
          case "Purchase":
            renderedValue = documentable.bank_card_transaction
              ? companyDateFormat(documentable.bank_card_transaction.payment_request_date, currentUser)
              : "";
            break;
          case "BankCardTransaction":
            renderedValue = documentable ? companyDateFormat(documentable.payment_request_date, currentUser) : "";
            break;
          case "InvoiceItem":
          case "ExpenseItem":
            renderedValue = companyDateFormat(documentable.date, currentUser);
            break;
        }
        return renderedValue;
      },
      headerValueGetter: function () {
        return translate("transactionDate");
      },
    },
    {
      field: "documentable.merchant",
      headerName: "Merchant",
      filter: false,
      cellRenderer: (params: { data: ReceiptsTypes.ListItem } & Omit<ICellRendererParams, "data">) => {
        return receiptService.getMerchantName(params?.data);
      },
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return translate("merchant");
      },
    },
    {
      field: "documentable.amount",
      sortable: false,
      headerName: "Amount",
      cellRenderer: (param: any) => {
        let amount: number;
        let currencyCode: string = param.data?.documentable?.currency_code ?? "";
        switch (param.data.documentable_type) {
          case "InvoiceItem":
          case "ExpenseItem":
            amount = param.data?.documentable?.total ?? "";
            break;
          case "BankCardTransaction":
            amount = param.data?.documentable?.payment_gross_amount ?? "";
            break;
          case "Purchase":
            amount = param.data?.documentable?.amount ?? "";
            break;
          default:
            return null;
        }
        return amount && currencyCode ? (formattedAmount(`${amount}`, currencyCode, 2, true) ?? "") : "";
      },
      filter: false,
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return translate("rAmount");
      },
    },
    {
      field: "documentable.number",
      headerName: "Purchase Or Expense Number",
      sortable: false,
      filter: false,
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return translate("purchaseOrExpenseNumber");
      },
    },
    {
      field: "note",
      headerName: "Note",
      filter: "agTextColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return translate("note");
      },
    },
    {
      field: "ZActions",
      cellRenderer: (params: any) => {
        return <Actions data={params?.data} gridApi={gridApi} />;
      },
      filter: false,
      sortable: false,
      resizable: false,
      maxWidth: 200,
      headerValueGetter: function () {
        return translate("actions");
      },
    },
  ];
  setAllowedFilters(columnDefs, FIELD_NAME_MAP);
  const defaultOrder = [...columnDefs.map((col: any) => col.field)];
  return {
    columnDefs: [...sortBy(columnDefs, (col) => typeof col.headerValueGetter == "function" && col.headerValueGetter())],
    defaultOrder,
  };
};
