export const imageMimeType = "image/";
export const mimeTypeMap: { [key: string]: string } = {
  pdf: "application/pdf",
  txt: "text/plain",
  jpg: "image/jpeg",
  jpeg: "image/jpeg",
  png: "image/png",
  gif: "image/gif",
  //doc: "application/msword",
  //docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  xls: "application/vnd.ms-excel",
  xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  csv: "text/csv",
};

export const getFileExtension = (fileName: string) => fileName.split(".").pop()?.toLowerCase();
export const getMimeType = (extension: string): string => mimeTypeMap[extension] || "application/octet-stream";
export const getMimeTypeFromFileName = (fileName: string): string => {
  const extension = getFileExtension(fileName);
  return getMimeType(extension!);
};
