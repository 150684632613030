import CurrencySymbolRenderer from "components/admin/commonUsed/currencySymbolRenderer";
import AccountPicker from "components/admin/pickers/reactHookFormPickers/accountPicker";
import BussinessUnitPicker from "components/admin/pickers/reactHookFormPickers/businessUnitPicker";
import DepartmentPicker from "components/admin/pickers/reactHookFormPickers/departmentPicker";
import LocationPicker from "components/admin/pickers/reactHookFormPickers/locationPicker";
import ProjectPicker from "components/admin/pickers/reactHookFormPickers/projectPicker";
import TaxCodePicker from "components/admin/pickers/reactHookFormPickers/taxCodePicker";
import { InputField, TextAreaField } from "components/forms/hookFormFields";
import _ from "lodash";
import { restApiService } from "providers/restApi";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { BsCheck2, BsJournalPlus, BsPencil, BsPlusLg, BsTrash, BsXLg } from "react-icons/bs";
import { useTypedSelector } from "reducers";
import { CommonApis } from "services/admin/commonApis";
import adminCommonSvc from "services/admin/commonSvc";
import invoiceCommonSvc from "services/admin/invoices/invoiceCommonSvc";
import { InvoiceType } from "services/admin/invoices/invoiceType";
import { useInvoiceCommonSvc } from "services/admin/invoices/useInvoiceCommonSvc";
import commonService from "services/common/commonSvc";
import { IUser } from "services/common/user/userTypes";
import styles from "../materialDataTable.module.css";

const InvoiceDebitLineSection = () => {
  const { control, getValues, setValue, trigger } = useFormContext<any>();
  const currentUser: IUser = useTypedSelector((state) => state.user);
  const [recommendedAccounts, setRecommendedAccounts] = useState<InvoiceType.recommendedAccounts>();

  const { fields, append, update } = useFieldArray<any>({
    control,
    name: "debit_entries_attributes",
    keyName: "_id" as "id",
  });

  const vendorId = useWatch({ name: "vendor_id" });

  const debitEntries = fields as unknown as InvoiceType.TInvoiceExpenseLineEntry[];
  const invoiceCommonSvcHook = useInvoiceCommonSvc();

  // Calculate the number of columns dynamically
  const dynamicColumnCount = useMemo(() => {
    let columnCount = 5; // Actions, Account, Sub Amount, Memo, Amount (static columns)
    if (currentUser.company.has_taxes) columnCount += 2; // Tax Code, Tax
    if (invoiceCommonSvcHook.enabledExpenseTaxOrRebate()) columnCount += 1; // Sub Amount
    if (currentUser.company.has_departments && !currentUser.company.invoice?.expenses?.department?.is_hide)
      columnCount += 1; // Department
    if (currentUser.company.has_locations && !currentUser.company.invoice_account_hide_location) columnCount += 1; // Location
    if (currentUser.company.has_business_units && !currentUser.company.invoice_account_hide_business_unit)
      columnCount += 1; // Business Unit
    if (currentUser.company.has_projects) {
      columnCount += 1; // Project
    }
    return columnCount;
  }, [currentUser, invoiceCommonSvcHook]);

  const addNewRow = () => {
    let obj = { allowEdit: true };
    append(obj);
  };

  const getRecommendedAccounts = useCallback(async () => {
    try {
      const response = await CommonApis.getVendorRecommendedAccounts(vendorId);
      setRecommendedAccounts(response);
    } catch (error) {
      commonService.handleError(error);
    }
  }, [vendorId]);

  useEffect(() => {
    if (vendorId) {
      getRecommendedAccounts();
    }
  }, [getRecommendedAccounts, vendorId]);

  const disableEditRow = async (entry: InvoiceType.TInvoiceExpenseLineEntry, index: number) => {
    if (!entry.allowEdit) {
      return;
    }
    const updatedItem = {
      ...getValues(`debit_entries_attributes[${index}]`),
      id: entry.id,
      allowEdit: !entry.allowEdit,
    };
    update(index, updatedItem);
  };

  const allowEditRow = async (entry: InvoiceType.TInvoiceExpenseLineEntry, index: number) => {
    let isValidate = true;
    if (entry.allowEdit) {
      isValidate = await trigger(`debit_entries_attributes.${index}`);
    }
    if (isValidate) {
      const updatedItem = {
        ...getValues(`debit_entries_attributes[${index}]`),
        id: entry.id,
        allowEdit: !entry.allowEdit,
      }; // setValue(`debit_entries_attributes[${index}]`, updatedItem);
      update(index, updatedItem);
    }
  };

  const destroyExpense = (item: any, index: number) => {
    let updatedEntry = { ...getValues(`debit_entries_attributes[${index}]`), _destroy: 1 };

    // updateInvoiceDebitEntriesIfDestroy(updatedItem);
    // setValue(`invoice_items_attributes.${index}`, updatedItem);
    update(index, updatedEntry);
  };

  const isItemDebitLine = (index: number) => {
    let debitEntry = getValues(`debit_entries_attributes.${index}`);
    return debitEntry?.item_line_id || debitEntry?.product_item_id;
  };

  const calculateExpenseLinemAmount = (accountEntry: InvoiceType.TInvoiceExpenseLineEntry) => {
    accountEntry.tax = _.isNumber(Number(accountEntry.tax)) ? Number(accountEntry.tax) : 0;
    accountEntry.sub_amount = _.isNumber(Number(accountEntry.sub_amount)) ? Number(accountEntry.sub_amount) : 0;
    let tax = accountEntry.tax;
    let subAmount = accountEntry.sub_amount;
    accountEntry.amount = adminCommonSvc.roundUpAmount(subAmount + tax, null, currentUser);
    return accountEntry;
  };

  const onTaxAmountChange = ({ tax, index }: { tax: number; index: number }) => {
    const debitEntry: InvoiceType.TInvoiceExpenseLineEntry = getValues(`debit_entries_attributes.${index}`);
    debitEntry.tax = tax;
    if (debitEntry) {
      let updatedEntry = calculateExpenseLinemAmount(debitEntry);
      setValue(`debit_entries_attributes.${index}.amount`, updatedEntry.amount);
      setValue(`debit_entries_attributes.${index}`, updatedEntry);
    }
  };

  // TODO: this should be done form amount component
  // const updateDiscountAmount = () => {
  //   const invoice = getValues();
  //   const discAmount = adminCommonSvc.calculateDiscountAmt(invoice, currentUser);
  //   setValue("amount_disc", discAmount);
  // };

  // const updateDiscountFromAccount = () => {
  //   updateDiscountAmount();
  // };

  const calculateExpensesTax = ({ index }: { index: number }) => {
    const debitEntry: InvoiceType.TInvoiceExpenseLineEntry = getValues(`debit_entries_attributes.${index}`);
    if (debitEntry) {
      let amount = 0; // tax amount
      if (debitEntry?.tax_code && debitEntry?.tax_code?.rate && debitEntry?.tax_code?.rate > 0) {
        amount = ((debitEntry.sub_amount ? debitEntry.sub_amount : 0) * debitEntry.tax_code.rate) / 100;
      } else {
        amount = 0;
      }
      debitEntry.tax = Number(adminCommonSvc.roundUpAmount(amount, null, currentUser));
      let updatedEntry = calculateExpenseLinemAmount(debitEntry);
      setValue(`debit_entries_attributes.${index}`, updatedEntry);
    }
  };

  const manageSubAmount = ({ subAmount, index }: { subAmount: number; index: number }) => {
    const debitEntry: InvoiceType.TInvoiceExpenseLineEntry = getValues(`debit_entries_attributes.${index}`);
    debitEntry.sub_amount = subAmount;
    debitEntry.actual_sub_amount = debitEntry.sub_amount;
    calculateExpensesTax({ index });
  };

  const removeAmortizationSchedule = (data: InvoiceType.TInvoiceExpenseLineEntry) => {
    delete data.amortization;
    delete data.amortization_id;
    delete data.amortization_name;
    delete data.amortization_schedule_name;
    delete data.start_date;
    delete data.end_date;
  };

  const addDuplicateRow = (item: InvoiceType.TInvoiceExpenseLineEntry, index: number) => {
    const debitEntry: InvoiceType.TInvoiceExpenseLineEntry = { ...getValues(`debit_entries_attributes.${index}`) };
    delete debitEntry.id;
    debitEntry.rebate_id = undefined;
    debitEntry.is_expense_received = undefined;
    debitEntry.is_matched = undefined;
    debitEntry.isDuplicateItem = true;
    debitEntry.allowEdit = true;
    removeAmortizationSchedule(debitEntry);
    append(debitEntry);
  };

  const isProjectRequired = useCallback(
    ({ index }: { index: number }) => {
      const debitEntry: InvoiceType.TInvoiceExpenseLineEntry = getValues(`debit_entries_attributes.${index}`);
      return invoiceCommonSvc.isProjectRequired(debitEntry, currentUser);
    },
    [currentUser, getValues],
  );

  const showTax = useMemo(() => {
    let invoice = getValues();
    return (
      currentUser.company.is_tax_to_invoice_expenses_line &&
      !invoiceCommonSvc.isActiveHeaderTax(invoice, currentUser) &&
      (currentUser.company.has_taxes || currentUser.company.enable_to_enter_tax_amount)
    );
  }, [currentUser, getValues]);

  const copyRecommendedAccounts = (debitEntry: InvoiceType.TInvoiceExpenseLineEntry) => {
    if (debitEntry) {
      delete debitEntry.id;
      removeAmortizationSchedule(debitEntry);
      append(debitEntry);
    }
  };

  return (
    <>
      <span className="mt-1 mb-2">
        <h5 className={styles.cardTitle}>Debit Entries</h5>
      </span>
      {((recommendedAccounts?.vendor_invoice_debit_entries?.length ?? 0) > 0 ||
        (recommendedAccounts?.expense_item_debit_entries?.length ?? 0) > 0) && (
        <>
          <span className="mb-2">
            <h6>Recommended GL Codes</h6>
          </span>
          <div className="overflow-auto custom-overflow">
            <Table size="sm" className={styles.tableMaterial}>
              <thead>
                <tr>
                  <th>Actions</th>
                  <th>Source</th>
                  <th>Account</th>
                  {currentUser.company.is_tax_to_invoice_expenses_line && <th>Tax Code</th>}
                  {showTax && <th>Tax</th>}
                  {invoiceCommonSvcHook.enabledExpenseTaxOrRebate() && <th>Sub Amount</th>}
                  <th>Memo</th>
                  {currentUser.company.has_departments &&
                    !currentUser.company.invoice?.expenses?.department?.is_hide && <th>Department</th>}
                  {currentUser.company.has_locations && !currentUser.company.invoice_account_hide_location && (
                    <th>Location</th>
                  )}
                  {currentUser.company.has_business_units &&
                    !currentUser.company.invoice_account_hide_business_unit && <th>Business Unit</th>}
                  {currentUser.company.has_projects && <th>Project</th>}
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {!currentUser.company.invoice.hide_gl_recommendations_from_legacy_invoices &&
                  recommendedAccounts?.expense_item_debit_entries.map((debitEntry, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <span className={styles.copyLink} onClick={() => copyRecommendedAccounts(debitEntry)}>
                            Add
                          </span>
                        </td>
                        <td>
                          <a
                            href={restApiService.angularBaseURL() + `invoices/${debitEntry.invoice?.id}`}
                            className={styles.copyLink}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {debitEntry.invoice?.number}
                          </a>
                        </td>
                        <td>{debitEntry.account_number + "-" + debitEntry.account_name || "--"}</td>
                        {currentUser.company.is_tax_to_invoice_expenses_line && (
                          <td>{debitEntry.tax_code?.code || "--"}</td>
                        )}
                        {showTax && <td>{debitEntry.tax || "--"}</td>}
                        {invoiceCommonSvcHook.enabledExpenseTaxOrRebate() && <td>{debitEntry.sub_amount || "--"}</td>}
                        <td>{debitEntry.memo || "--"}</td>
                        {currentUser.company.has_departments &&
                          !currentUser.company.invoice?.expenses?.department?.is_hide && (
                            <td>
                              {(debitEntry.department?.name
                                ? debitEntry.department.name
                                : debitEntry.department_name) || "--"}
                            </td>
                          )}
                        {currentUser.company.has_locations && !currentUser.company.invoice_account_hide_location && (
                          <td>
                            {(debitEntry.location?.name ? debitEntry.location.name : debitEntry.location_name) || "--"}
                          </td>
                        )}
                        {currentUser.company.has_business_units &&
                          !currentUser.company.invoice_account_hide_business_unit && (
                            <td>
                              {(debitEntry.business_unit?.name
                                ? debitEntry.business_unit.name
                                : debitEntry.business_unit_name) || "--"}
                            </td>
                          )}
                        {currentUser.company.has_projects && (
                          <td>
                            {(debitEntry?.project?.name ? debitEntry.project.name : debitEntry.project_name) || "--"}
                          </td>
                        )}
                        <td>
                          <CurrencySymbolRenderer name="currency_code" amount={debitEntry.amount || 0} />
                        </td>
                      </tr>
                    );
                  })}
                {recommendedAccounts?.vendor_invoice_debit_entries.map((debitEntry, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <span className={styles.copyLink} onClick={() => copyRecommendedAccounts(debitEntry)}>
                          Add
                        </span>
                      </td>
                      <td>
                        {debitEntry.vendor?.id && (
                          <a
                            href={restApiService.angularBaseURL() + `vendors/${debitEntry.vendor?.id}`}
                            className={styles.copyLink}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Vendor Default
                          </a>
                        )}
                      </td>
                      <td>{debitEntry.account_number + "-" + debitEntry.account_name}</td>
                      {currentUser.company.is_tax_to_invoice_expenses_line && (
                        <td>{debitEntry.tax_code?.code || "--"}</td>
                      )}
                      {showTax && <td>{debitEntry.tax || "--"}</td>}
                      {invoiceCommonSvcHook.enabledExpenseTaxOrRebate() && <td>{debitEntry.sub_amount || "--"}</td>}
                      <td>{debitEntry.memo || "--"}</td>
                      {currentUser.company.has_departments &&
                        !currentUser.company.invoice?.expenses?.department?.is_hide && (
                          <td>
                            {debitEntry.location?.name ? debitEntry.location.name : debitEntry.location_name || "--"}
                          </td>
                        )}
                      {currentUser.company.has_locations && !currentUser.company.invoice_account_hide_location && (
                        <td>
                          {debitEntry.department?.name
                            ? debitEntry.department.name
                            : debitEntry.department_name || "--"}
                        </td>
                      )}
                      {currentUser.company.has_business_units &&
                        !currentUser.company.invoice_account_hide_business_unit && (
                          <td>
                            {debitEntry.business_unit?.name
                              ? debitEntry.business_unit.name
                              : debitEntry.business_unit_name || "--"}
                          </td>
                        )}
                      {currentUser.company.has_projects && (
                        <td>{debitEntry?.project?.name ? debitEntry.project.name : debitEntry.project_name || "--"}</td>
                      )}
                      <td>
                        <CurrencySymbolRenderer name="currency_code" amount={debitEntry.amount || 0} />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </>
      )}

      <div className="overflow-auto custom-overflow pb-3">
        <Table size="sm" className={styles.tableMaterial}>
          <thead>
            <tr>
              <th>Actions</th>
              <th>Account</th>
              {currentUser.company.has_taxes && <th>Tax Code</th>}
              {currentUser.company.has_taxes && <th>Tax</th>}
              {invoiceCommonSvcHook.enabledExpenseTaxOrRebate() && <th>Sub Amount</th>}

              <th>Memo</th>
              {currentUser.company.has_departments && !currentUser.company.invoice?.expenses?.department?.is_hide && (
                <th>Department</th>
              )}
              {currentUser.company.has_locations && !currentUser.company.invoice_account_hide_location && (
                <th>Location</th>
              )}
              {currentUser.company.has_business_units && !currentUser.company.invoice_account_hide_business_unit && (
                <th>Business Unit</th>
              )}
              {currentUser.company.has_projects && <th>Project</th>}
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {debitEntries.map((entry, index) => {
              const editIcon = entry.allowEdit ? (
                <BsCheck2 className={styles.operationIcon} size={16} />
              ) : (
                <BsPencil className={styles.operationIcon} size={14} />
              );

              return (
                _.isPlainObject(entry) &&
                entry._destroy !== 1 &&
                !isItemDebitLine(index) && (
                  <tr key={entry._id || index}>
                    <td className={styles.iconsSection}>
                      <button
                        type="button"
                        className={styles.operationBtn}
                        onClick={() => {
                          allowEditRow(entry, index);
                        }}
                      >
                        {editIcon}
                      </button>
                      <button
                        type="button"
                        className={styles.operationBtn}
                        onClick={() => addDuplicateRow(entry, index)}
                      >
                        <BsJournalPlus className={styles.operationIcon} size={16} />
                      </button>
                      <button
                        type="button"
                        className={styles.operationBtn}
                        onClick={() => destroyExpense(entry, index)}
                      >
                        <BsTrash className={styles.operationIcon} size={16} />
                      </button>
                      {entry.allowEdit && (
                        <button
                          type="button"
                          className={styles.operationBtn}
                          onClick={() => disableEditRow(entry, index)}
                        >
                          <BsXLg className={styles.operationIcon} size={16} />
                        </button>
                      )}
                    </td>
                    <td className="px-pt-10">
                      {entry.allowEdit ? (
                        <AccountPicker
                          name={`debit_entries_attributes.${index}.account_id`}
                          accountGroupName="INVOICE_DEBIT_GROUPS"
                          modelData={`debit_entries_attributes.${index}`}
                          parentObj={""} //header
                          required
                          containerClassName={styles.minWidth175}
                        />
                      ) : (
                        <>{`${entry.account_number ? entry.account_number : (entry?.account?.number ?? "")} - ${entry.account_name ? entry.account_name : (entry?.account?.name ?? "")}`}</>
                      )}
                    </td>
                    {currentUser.company.has_taxes && (
                      <td className="px-pt-10">
                        {entry.allowEdit ? (
                          <TaxCodePicker
                            name={`debit_entries_attributes.${index}.tax_id`}
                            modelData={`debit_entries_attributes.${index}`}
                            callBack={(tax) => calculateExpensesTax({ index })}
                            required={currentUser?.company?.invoice?.is_tax_code_required_on_line_level}
                            containerClassName={styles.minWidth175}
                          />
                        ) : (
                          entry.tax_code?.code
                        )}
                      </td>
                    )}

                    {currentUser.company.has_taxes && (
                      <td className="px-pt-10">
                        {entry.allowEdit ? (
                          <InputField
                            name={`debit_entries_attributes.${index}.tax`}
                            type="number"
                            readOnly={!currentUser.company.enable_to_enter_tax_amount}
                            onChange={(e) => {
                              onTaxAmountChange({ tax: Number(e.target.value), index });
                            }}
                            containerClassName={styles.minWidth100}
                          />
                        ) : (
                          <CurrencySymbolRenderer name="currency_code" amount={Number(entry.tax) || 0} />
                        )}
                      </td>
                    )}

                    {invoiceCommonSvcHook.enabledExpenseTaxOrRebate() && (
                      <td className="px-pt-10">
                        {entry.allowEdit ? (
                          <InputField
                            name={`debit_entries_attributes.${index}.sub_amount`}
                            type="number"
                            onChange={(e) => {
                              manageSubAmount({ subAmount: Number(e.target.value), index });
                            }}
                            required
                            containerClassName={styles.minWidth100}
                          />
                        ) : (
                          <CurrencySymbolRenderer name="currency_code" amount={entry.sub_amount || 0} />
                        )}
                      </td>
                    )}

                    <td className="px-pt-10">
                      {entry.allowEdit ? (
                        <TextAreaField
                          name={`debit_entries_attributes.${index}.memo`}
                          containerClassName={styles.minWidth175}
                          required={currentUser.company?.invoice?.expenses?.memo?.is_required}
                        />
                      ) : (
                        entry.memo
                      )}
                    </td>

                    {currentUser.company.has_departments &&
                      !currentUser.company.invoice?.expenses?.department?.is_hide && (
                        <td>
                          {" "}
                          {entry.allowEdit ? (
                            <DepartmentPicker
                              name={`debit_entries_attributes.${index}.department_id`}
                              modelData={`debit_entries_attributes.${index}`}
                              // parentObj={""}
                              containerClassName={styles.minWidth175}
                              required={currentUser?.company?.invoice?.expenses?.department?.is_required}
                            />
                          ) : (
                            (entry.department?.name ?? entry.department_name)
                          )}
                        </td>
                      )}

                    {currentUser.company.has_locations && !currentUser.company.invoice_account_hide_location && (
                      <td>
                        {entry.allowEdit ? (
                          <LocationPicker
                            name={`debit_entries_attributes.${index}.location_id`}
                            containerClassName={styles.projectMinWidth}
                            modelDataName={`debit_entries_attributes.${index}`}
                            required={currentUser?.company?.invoice_expense_location_required}
                          />
                        ) : (
                          (entry.location?.name ?? entry.location_name)
                        )}
                      </td>
                    )}
                    {currentUser.company.has_business_units &&
                      !currentUser.company.invoice_account_hide_business_unit && (
                        <td>
                          {entry.allowEdit ? (
                            <BussinessUnitPicker
                              name={`debit_entries_attributes.${index}.business_unit_id`}
                              containerClassName={styles.minWidth175}
                              modelDataName={`debit_entries_attributes.${index}`}
                              required={currentUser?.company?.invoice?.expenses?.business_unit?.is_required}
                              disabled={currentUser?.company?.readonly_business_unit_to_all}
                            />
                          ) : (
                            (entry.business_unit?.name ?? entry.business_unit_name)
                          )}
                        </td>
                      )}
                    {currentUser.company.has_projects && (
                      <td>
                        {entry.allowEdit ? (
                          <ProjectPicker
                            name={`debit_entries_attributes.${index}.project_id`}
                            modelData={`debit_entries_attributes.${index}`}
                            parentObj={""}
                            containerClassName={styles.minWidth175}
                            required={isProjectRequired({ index })}
                          />
                        ) : (
                          (entry.project?.name ?? entry.project_name)
                        )}
                      </td>
                    )}

                    <td>
                      {entry.allowEdit ? (
                        <InputField
                          id="debit_entry_amount"
                          name={`debit_entries_attributes.${index}.amount`}
                          type="number"
                          disabled
                          containerClassName={styles.minWidth175}
                        />
                      ) : (
                        <CurrencySymbolRenderer name="currency_code" amount={Number(entry.amount) || 0} />
                      )}
                    </td>
                  </tr>
                )
              );
            })}
            {debitEntries.length < 1 && (
              <tr>
                <td colSpan={dynamicColumnCount}>
                  <span className={styles.noRecords}>Records not found!</span>
                </td>
              </tr>
            )}
          </tbody>
          <tfoot>
            <tr>
              <td className={styles.left} colSpan={dynamicColumnCount - 2}>
                Total
              </td>
              <td className={styles.right}>
                <CurrencySymbolRenderer name="currency_code" amount={invoiceCommonSvcHook.getAccountsTotal() || 0} />
              </td>
            </tr>
          </tfoot>
        </Table>
      </div>
      <span>
        <Button variant="light" className={styles.actionBtn} onClick={() => addNewRow()}>
          <BsPlusLg className={styles.actionIcon} />
          Add Debit Line
        </Button>
      </span>
    </>
  );
};

export default InvoiceDebitLineSection;
