import useGetValue from "components/admin/hooks/hookFormHooks/useGetValue";
import useGetUrLQuery from "components/admin/hooks/useGetUrlQuery";
import { AbstractListPickerTypes } from "components/admin/pickers/reactHookFormPickers/abstractListPicker/abstractListPickerTypes";
import AddressPicker from "components/admin/pickers/reactHookFormPickers/addressPicker";
import BudgetPicker from "components/admin/pickers/reactHookFormPickers/budgetPicker";
import ContactPicker from "components/admin/pickers/reactHookFormPickers/contactPicker";
import { ContactTypeEnum } from "components/admin/pickers/reactHookFormPickers/contactPicker/contactPicker.types";
import CurrencyCodePicker from "components/admin/pickers/reactHookFormPickers/currencyCodePicker";
import CustomDataListPicker from "components/admin/pickers/reactHookFormPickers/customDataListPicker";
import DepartmentPicker from "components/admin/pickers/reactHookFormPickers/departmentPicker";
import LocationPicker from "components/admin/pickers/reactHookFormPickers/locationPicker";
import PaymentTermPicker from "components/admin/pickers/reactHookFormPickers/paymentTerms";
import ProductItemPicker from "components/admin/pickers/reactHookFormPickers/productItemPicker";
import { ProductItemPickerType } from "components/admin/pickers/reactHookFormPickers/productItemPicker/productItemPickerTypes";
import ProjectPicker from "components/admin/pickers/reactHookFormPickers/projectPicker";
import { ProjectPickerTypes } from "components/admin/pickers/reactHookFormPickers/projectPicker/projectPickerTypes";
import SubsidiaryPicker from "components/admin/pickers/reactHookFormPickers/subsidiaryPicker/singlePicker";
import VendorLocationPicker from "components/admin/pickers/reactHookFormPickers/vendorLocationPicker";
import VendorPicker from "components/admin/pickers/reactHookFormPickers/vendorPicker";
import { poSubtypes, poTypes } from "components/app.svc.Lookup";
import ErrorBoundary from "components/common/errorBoundary/errorBoundary";
import TabNavigator from "components/common/tabNavigator/tabNavigator";
import { DatePickerField, InputField, Select, TextAreaField } from "components/forms/hookFormFields";
import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "reducers";
import { CommonApis } from "services/admin/commonApis";
import PoCommonSvc from "services/admin/purchaseOrders/poCommonSvc";
import { PORequestFormTypes } from "services/admin/purchaseOrders/poRequestFormTypes";
import { NavbarTabType } from "services/common/types/common.type";
import { IUser } from "services/common/user/userTypes";
import Attachments from "./subSections/attachments";
import ManagePoExpenses from "./subSections/managePoExpenses";
import ManagePoItems from "./subSections/managePoItems";

const FormRequestPO = ({ onSubmit }: any) => {
  const { t } = useTranslation();

  const currentUser: IUser = useTypedSelector((state) => state.user);
  const [activeTab, setActiveTab] = useState<string>("lineItem");
  const [poType, setPotype] = useState<string>("SINGLE VENDOR");
  const [noVendor, setNoVendor] = useState(false);

  const [mappedShippingAddress, setMappedShippingAddress] = useState<any>([]);

  const { handleSubmit, control, getValues, setValue } = useFormContext<any>(); // add types here

  const purchaseOrder = getValues("purchase_order");
  const savedPO = getValues("savedPO");

  const poCommonSvc = PoCommonSvc.getInstance(currentUser);
  const [departmentDisabled, setDepartmentDisabled] = useState<boolean>(false);
  const [departmentLabel, setDepartmentLabel] = useState<string>(t("admin.pages.purchaseOrder.department"));

  let vendorId = useGetValue("purchase_order.vendor_id");

  const vendor = useWatch({
    control,
    name: "purchase_order.vendor",
  });

  const allowContactAddresses = useWatch({
    control,
    name: "purchase_order.allow_contact_addresses",
  });
  const subType = useWatch({ name: "purchase_order.sub_type" });
  const poTypeWatched = useWatch({ name: "purchase_order.po_type" });
  const isHideProject = useWatch({ name: "purchase_order.is_hide_project" });
  const accountId = useWatch({ name: "purchase_order.account_id" });
  const isProjectRequired = useWatch({ name: "purchase_order.is_project_required" });

  const showProductItemHeaderLevel = useMemo(() => {
    return currentUser.company.show_product_item_po_header_level && (subType === "Project" || subType === "Other");
  }, [currentUser.company.show_product_item_po_header_level, subType]);

  const showProjectHeaderLevel = useMemo(() => {
    let is_allow = false;
    if (currentUser.company.has_projects && !isHideProject) {
      if (currentUser.company.purchase_order?.project?.is_show) {
        is_allow = true;
      } else if (accountId && showProductItemHeaderLevel) {
        is_allow = true;
      }
    }
    return is_allow;
  }, [
    accountId,
    currentUser.company.has_projects,
    currentUser.company.purchase_order?.project?.is_show,
    isHideProject,
    showProductItemHeaderLevel,
  ]);

  const removeHeaderProductItemInfo = useCallback(
    (e: any) => {
      setValue("purchase_order.sub_type", e.target.value);
      const purchaseOrder = getValues("purchase_order");
      if (purchaseOrder.sub_type === "Inventory" && purchaseOrder.product_item_id > 0) {
        purchaseOrder.product_item_id = null;
        purchaseOrder.account_id = null;
        if (purchaseOrder.project_id > 0) {
          purchaseOrder.project_id = null;
        }
      }
      setValue("purchase_order", purchaseOrder);
    },
    [getValues, setValue],
  );

  //TODO: Uncomment after subsidiary picker changes
  /*
  const inheritVendorSubsidiary = (response: any) => {
    let subsidiaryId = getValues("purchase_order.subsidiary_id");

    let vendorSubsidiary = poCommonSvc.inheritVendorSubsidiary(response, subsidiaryId);
    if (_.isPlainObject(vendorSubsidiary)) {
      setValue("purchase_order.subsidiary", response.subsidiary);
      setValue("purchase_order.subsidiary_id", response.subsidiary.id);
    }
  };
  */

  const vendorContacts =
    vendor?.contacts?.map((contact: any) => ({
      ...contact,
      label: `${contact.first_name} ${contact.last_name}`,
      value: contact.id,
    })) ?? [];

  const resetDepartment = useCallback(() => {
    if (
      currentUser.company.po_request_show_all_department &&
      (!currentUser.company.purchase_order || currentUser.company?.purchase_order?.default_department > 0)
    ) {
      setValue("purchase_order.department", null);
      setValue("purchase_order.department_id", null);
    }
  }, [currentUser.company, setValue]);

  const onVendorChange = useCallback(
    async (vendorId: number) => {
      if (vendorId == null) {
        return;
      }

      try {
        const response = await CommonApis.getVendor(vendorId);

        if (response) {
          // TODO: inherit_vendor_expense_line(response)
          let vendor = getValues("purchase_order.vendor");
          vendor.po_not_required = response.po_not_required;
          vendor.vendor_classification = response.vendor_classification;
          vendor.contacts = response.contacts;
          vendor.has_product_items = response.has_product_items;
          vendor.has_vendor_locations = response.has_vendor_locations;
          setValue("purchase_order.allow_contact_addresses", response.allow_contact_addresses_on_purchase_orders);
          setValue("purchase_order.vendor", vendor);
        }
        if (_.isPlainObject(response.term)) {
          setValue("purchase_order.term", response.term);
          setValue("purchase_order.term_id", response.term.id);
          //TODO: Implement updateReceiveByDate();
        }

        if (_.isPlainObject(response.subsidiary)) {
          let subsidiaryId = getValues("purchase_order.subsidiary_id");

          let vendorSubsidiary = poCommonSvc.inheritVendorSubsidiary(response, subsidiaryId);
          if (vendorSubsidiary) {
            setValue("purchase_order.subsidiary", vendorSubsidiary);
            setValue("purchase_order.subsidiary_id", vendorSubsidiary.id);
          }

          // inheritVendorSubsidiary(response);
          let locationId;
          if (currentUser.company.po_request_inherit_location_from_requestor && currentUser.contact.location) {
            locationId = currentUser.contact.location.id;
          } else if (response.subsidiary.location_id) {
            locationId = response.subsidiary.location_id;
          } else {
            locationId = getValues("purchase_order.location_id");
          }
          setValue("purchase_order.location_id", locationId);
          resetDepartment();
        }

        if (response.currency_code) {
          setValue("purchase_order.currency_code", response.currency_code);
        } else if (currentUser.company.currency) {
          setValue("purchase_order.currency_code", currentUser.company.currency.iso_code);
        }
      } catch (error) {
        console.log(error);
      }
    },
    [currentUser, getValues, poCommonSvc, resetDepartment, setValue],
  );

  useEffect(() => {
    if (vendorId) {
      onVendorChange(vendorId);
    }
  }, [onVendorChange, vendorId]);

  const changePoType = (poType: string) => {
    setPotype(poType);
    const isVendor = poType === "SINGLE VENDOR" || poType === "CORPORATE CARD";
    setNoVendor(!isVendor);
  };

  const isVendorRequired = () => {
    let po = getValues("purchase_order");
    if (currentUser.company?.vendor?.po_not_required && !currentUser.company.vendor.po_not_required.show_selected) {
      return true;
    } else if (po?.vendor?.po_not_required == true || po?.vendor?.vendor_classification?.required_po === false) {
      return false;
    }
    /* TODO: Uncomment once the newVendor feature is implemented
    else if (
      po?.vendor_attributes?.vendor_classification_id &&
      getClassificationById(po?.vendor_attributes?.vendor_classification_id)?.required_po === false
    ) {
      return false;
    }*/
    return true;
  };

  let query = useGetUrLQuery();
  const punchOutId = query.get("punchoutId");

  const changeTab = (tab: string) => {
    setActiveTab(tab);
  };

  const mappingPoPrefix = useCallback(
    (selectedLocation) => {
      const location = _.isPlainObject(selectedLocation) ? selectedLocation : {};
      const po = getValues("purchase_order");
      if (!po) return;

      if (location?.po_number && po?.number) {
        const splittedPONumber = po.number.split("-");
        const suffix = splittedPONumber.length > 1 ? splittedPONumber.at(-1) : po.number; // Use .at(-1) for readability
        const updatedPONumber = `${location.po_number}-${suffix}`;
        setValue("purchase_order.number", updatedPONumber);
      }

      setValue("purchase_order.is_location_map_to_project", location.is_location_map_to_project ?? false);

      // TODO: implement departmentLocationMapping.is_department_mapped_with_location(location, purchase_order);

      if (currentUser.company?.po_request_allow_location_project_shipping_address) {
        if (_.isPlainObject(location?.address)) {
          setValue("purchase_order.address_id", location.address.id ?? "");
        } else {
          setValue("purchase_order.address_id", "");
          setValue("purchase_order.address", "");
        }
        // TODO: Test this feature while implementating project-location mapping.
        if (po.project?.address) {
          setMappedShippingAddress([po.project.address]);
        }
      }
    },
    [currentUser.company?.po_request_allow_location_project_shipping_address, getValues, setValue],
  );

  // const mapAccountRequiredFields = useCallback(
  //   <T,>(account: PORequestFormTypes.TAccount, obj: T) => {
  //     invoiceCommonSvc.mapAccountRequiredFields(account, currentUser, obj);
  //   },
  //   [currentUser],
  // );

  const setHeaderLevelItem = useCallback(() => {
    setValue("purchase_order.enable_account_dir", true);
    const productItem: PORequestFormTypes.TProductItem = getValues("purchase_order.product_item");
    if (
      _.isPlainObject(productItem) &&
      Array.isArray(productItem.invoice_debit_accounts) &&
      productItem.invoice_debit_accounts["0"]
    ) {
      setValue("purchase_order.account_id", productItem.invoice_debit_accounts[0].account_id);
    }
    // else if (!_.isPlainObject(productItem)) {
    //   // TODO: check if this code in is use in angular
    //   setValue("purchase_order.account_id", null);
    //   const account = getValues("purchase_order.account");
    //   const po = getValues("purchase_order");
    //   mapAccountRequiredFields(account, po);
    //   setValue("purchase_order.is_department_required", po.is_department_required);
    //   setValue("purchase_order.is_location_required", po.is_location_required);
    //   setValue("purchase_order.is_project_required", po.is_project_required);
    //   setValue("purchase_order.is_hide_project", po.is_hide_project);
    //   setValue("purchase_order.is_grant_required", po.is_grant_required);
    //   setValue("purchase_order.is_business_unit_required", po.is_business_unit_required);
    //   setValue("purchase_order.is_inter_company_required", po.is_inter_company_required);
    //   setValue("purchase_order.is_warehouse_required", po.is_warehouse_required);
    //   setValue("purchase_order.project_id", po.project_id);
    // }
  }, [getValues, setValue]);

  const selectProductItem = useCallback(
    (selected: ProductItemPickerType.TProductItemOption | null | undefined) => {
      setValue("purchase_order.product_item_id", selected?.id ?? null);
      setHeaderLevelItem();
    },
    [setHeaderLevelItem, setValue],
  );

  useEffect(() => {
    if (currentUser.company.po_request_auto_set_department) {
      if (currentUser.contact?.department?.id) {
        const disabledName = t("admin.pages.purchaseOrder.departmentAutoSetActive");
        setValue("purchase_order.department_id", currentUser.contact.department.id);
        setValue("purchase_order.department", currentUser.contact.department);
        setDepartmentDisabled(true);
        setDepartmentLabel(disabledName);
      } else {
        setDepartmentLabel(t("admin.pages.purchaseOrder.departmentAutoSetActiveNoContact"));
      }
    }
  }, [currentUser.company.po_request_auto_set_department, currentUser.contact.department, setValue, t]);

  const tabData: NavbarTabType = useMemo(
    () => [
      { activeTab: "lineItem", label: "Line Items" },
      {
        activeTab: "expenses",
        label: "Expenses",
        hide: !(currentUser.company.po_request_allow_expenses || punchOutId),
      },
    ],
    [currentUser, punchOutId],
  );

  const inheritProjectLevelData = useCallback(
    (lineDataFieldName: string, modelDataFieldName: string, line: string) => {
      const modelData = getValues(modelDataFieldName);
      const lineData = getValues(lineDataFieldName);

      //it will inherit po project data to po expense line level
      if (
        poCommonSvc.inheritPOHeaderProjectToExpenseLine() &&
        _.isPlainObject(modelData?.project) &&
        line === "expense"
      ) {
        setValue(lineDataFieldName + ".project_id", modelData.project.id);
        setValue(lineDataFieldName + ".project", modelData.project);

        //it is used to inherit project level location
        if (modelData.project?.location_id) {
          setValue(lineDataFieldName + ".location_id", modelData.project.location_id);
        }

        //it is used to inherit project level department
        if (modelData.project?.department_id && !currentUser.company.po_request_hide_expense_line_department) {
          setValue(lineDataFieldName + ".department_id", modelData.project.department_id);
          setValue(lineDataFieldName + ".department", modelData.project.department);

          //it will inherit project -> department level bussiness unit
          if (modelData.project?.department?.business_unit_id) {
            setValue(lineDataFieldName + ".business_unit_id", modelData.project.department.business_unit_id);
          }
        }
      } //end

      //it will inherit po project data to po item line level
      if (poCommonSvc.inheritPOHeaderProjectToItemLine() && _.isPlainObject(modelData.project) && line === "item") {
        setValue(lineDataFieldName + ".project_id", modelData.project.id);
        setValue(lineDataFieldName + ".project", modelData.project);

        //it is used to inherit project level location
        if (modelData.project?.location_id) {
          setValue(lineDataFieldName + ".location_id", modelData.project.location_id);
        }

        //it is used to inherit project level department
        if (modelData.project?.department_id && !currentUser.company.po_request_disabled_itemline_department) {
          setValue(lineDataFieldName + ".department_id", modelData.project.department_id);
          setValue(lineDataFieldName + ".department", modelData.project.department);

          //it will inherit project -> department level bussiness unit
          if (modelData.project?.department?.business_unit_id) {
            setValue(lineDataFieldName + ".business_unit_id", modelData.project.department.business_unit_id);
          }
        }
      }

      //it will inherit po project data to po header level
      if (_.isPlainObject(modelData?.project) && line === "header") {
        setValue(lineDataFieldName + ".project_id", modelData.project.id);
        setValue(lineDataFieldName + ".project", modelData.project);

        //it is used to inherit project level location
        if (modelData.project?.location_id) {
          setValue(lineDataFieldName + ".location_id", modelData.project.location_id);
          setValue(lineDataFieldName + ".location", modelData.project.location);
        }

        //it is used to inherit project level department
        if (modelData.project?.department_id) {
          setValue(lineDataFieldName + ".department_id", modelData.project.department_id);
          setValue(lineDataFieldName + ".department", modelData.project.department);

          //it will inherit project -> department level bussiness unit
          if (lineData.project?.department && modelData.project?.department?.business_unit_id) {
            setValue(lineDataFieldName + ".business_unit_id", modelData.project.department.business_unit_id);
          }
        }

        //it is used to inherit project level subsidiary
        if (modelData.project?.subsidiary_id) {
          setValue(lineDataFieldName + ".subsidiary_id", modelData.project.department.subsidiary_id);
          setValue(lineDataFieldName + ".subsidiary", modelData.project.department.subsidiary_id);
        }
      }
    },
    [
      currentUser.company.po_request_disabled_itemline_department,
      currentUser.company.po_request_hide_expense_line_department,
      getValues,
      poCommonSvc,
      setValue,
    ],
  );

  const showLocations = useMemo(() => {
    return (
      currentUser.company.has_locations && !currentUser.company.po_request_disabled_header_location && purchaseOrder
    );
  }, [currentUser.company.has_locations, currentUser.company.po_request_disabled_header_location, purchaseOrder]);

  const isLocationRequired = useMemo(() => {
    let purchaseOrder: PORequestFormTypes.TPurchaseOrder = getValues("purchase_order");
    return currentUser.company?.po_request_is_location_required || purchaseOrder.is_location_required;
  }, [currentUser.company?.po_request_is_location_required, getValues]);

  const inheritHeaderProjectToLineLevel = useCallback(
    (project: AbstractListPickerTypes.TPickerValue<ProjectPickerTypes.TProjectPickerOption>) => {
      setValue("purchase_order.project", project);
      let purchaseOrder: PORequestFormTypes.TPurchaseOrder = getValues("purchase_order");
      if (Array.isArray(purchaseOrder?.invoice_debit_accounts) && purchaseOrder?.invoice_debit_accounts?.length > 0) {
        purchaseOrder?.invoice_debit_accounts.forEach((debitAccount, index) => {
          inheritProjectLevelData(`purchase_order.invoice_debit_accounts.${index}`, "purchase_order", "expense");
        });
      }

      if (Array.isArray(purchaseOrder?.po_items) && purchaseOrder.po_items.length > 0) {
        purchaseOrder?.po_items.forEach((debitAccount, index) => {
          inheritProjectLevelData(`purchase_order.po_items.${index}`, "purchase_order", "item");
        });
      }
    },
    [getValues, inheritProjectLevelData, setValue],
  );

  // this code should be in service
  const invokeProjectMappings = useCallback(
    (project: AbstractListPickerTypes.TPickerValue<ProjectPickerTypes.TProjectPickerOption>) => {
      if (poCommonSvc.inheritPOHeaderProjectToExpenseLine() || poCommonSvc.inheritPOHeaderProjectToItemLine()) {
        inheritHeaderProjectToLineLevel(project);
      }
    },
    [inheritHeaderProjectToLineLevel, poCommonSvc],
  );

  const mapShippingAddress = useCallback(
    (project: AbstractListPickerTypes.TPickerValue<ProjectPickerTypes.TProjectPickerOption>) => {
      if (project && _.isPlainObject(project) && "address" in project && project.address) {
        setValue("purchase_order.project", project);
      }
      invokeProjectMappings(project);
    },
    [invokeProjectMappings, setValue],
  );

  return (
    <Container fluid>
      <form id="request_po_form" noValidate onSubmit={handleSubmit(onSubmit)}>
        <Row className="px-mt-25">
          <Col sm="8">
            <h2>Request a New Purchase Order</h2>
          </Col>
          <Col sm="4" style={{ textAlign: "end" }}>
            {/* TODO: show buttons conditionally*/}
            {/* TODO: add message and submit buttons */}
            <Button variant="btn btn-secondary" className="px-mr-10">
              {t("admin.pages.purchaseOrder.cancel")}
            </Button>
            {savedPO?.status == "DRAFT" && (
              <Button type="submit" variant="btn btn-secondary" className="px-mr-10">
                {t("admin.pages.purchaseOrder.saveAsDraft")}
              </Button>
            )}
            {!purchaseOrder.requestor_can_edit && (
              <Button type="submit" variant={`btn btn-primary`} className="px-mr-10">
                {t("admin.pages.purchaseOrder.submitForApproval")}
              </Button>
            )}

            {purchaseOrder.requestor_can_edit && (
              <Button type="submit" variant={`btn btn-primary`} className="px-mr-10">
                {t("admin.pages.purchaseOrder.submit")}
              </Button>
            )}
            {/* TODO: Add Message button here  */}
          </Col>
        </Row>
        <hr style={{ borderTop: "1px solid #76777A" }} />
        {/*TODO: Implement <punchout-button/> */}

        <Row className="px-mt-40">
          <Col md="11">
            <Row>
              <Col md="6">
                <InputField
                  id="po_request-form-number"
                  name="purchase_order.number"
                  label="New PO # (Auto-gen if blank)"
                  disabled={currentUser.company.po_request_readonly_po_number}
                />
              </Col>
              {!currentUser.company.po_request_hide_po_type && (
                <Col md="6">
                  <Select
                    id="po_request-form-po_type"
                    name="purchase_order.po_type"
                    label="PO Type"
                    options={poTypes}
                    onChange={(e: any) => {
                      changePoType(e.target.value);
                    }}
                  />
                </Col>
              )}
            </Row>
            <Row>
              {!noVendor && (
                <Col md="6">
                  <VendorPicker
                    id="po_request-form-vendor"
                    name="purchase_order.vendor"
                    label="Vendor/Supplier"
                    modelData="purchase_order"
                    required={!noVendor && isVendorRequired()}
                    excludeStatuses="INACTIVE"
                  />
                </Col>
              )}
              {currentUser.company.has_subsidiaries && (
                <Col md="6">
                  <SubsidiaryPicker
                    name="purchase_order.subsidiary_id"
                    label="Subsidiary"
                    sectionName="poRequest"
                    modelData="purchase_order"
                    // required={true}    // TODO: Remove this
                    // onChange={() => {
                    //   changePoType(e.target.value);
                    // }}
                    disabled={currentUser.company.allow_only_inherited_subsidiary_and_fields_from_vendor}
                  />
                </Col>
              )}
            </Row>
            {vendor?.has_vendor_locations && poType !== "MULTIPLE VENDOR" && !noVendor && (
              <Row>
                <Col md="6">
                  <VendorLocationPicker
                    name="purchase_order.vendor_location_id"
                    label="Vendor Location"
                    modelData="purchase_order"
                  />
                </Col>
              </Row>
            )}

            <Row>
              <Col md="6">
                {poTypeWatched === "SINGLE VENDOR" && currentUser.company.allow_po_sub_type && (
                  <Select
                    id="po_request-form-po_subtype"
                    name="purchase_order.sub_type"
                    label="PO Subtype"
                    required={poType === "SINGLE VENDOR" && currentUser.company.allow_po_sub_type}
                    options={poSubtypes}
                    onChange={removeHeaderProductItemInfo}
                  />
                )}
                {currentUser.company.allow_change_requestor && (
                  <ContactPicker
                    id="po_request_contact"
                    name="purchase_order.requestor_id"
                    objectName="purchase_order.requestor"
                    label="Company Requestor"
                    contactType={ContactTypeEnum.COMPANY}
                  />
                )}
                {currentUser.company.purchase_order?.show_point_of_contact && (
                  <InputField
                    id="po_request_point_of_contact"
                    name="purchase_order.point_of_contact"
                    label="Internal Point of Contact Email"
                  />
                )}
                {!currentUser.company.po_request_disable_payment_term && (
                  <PaymentTermPicker
                    id="po_request_term"
                    name="purchase_order.term_id"
                    objectName="purchase_order.term"
                    required
                    label="Payment Terms"
                    /*TODO: Implement callBack={updateReceiveByDate} */
                  />
                )}
                <InputField
                  id="po_request_amount"
                  name="purchase_order.amount"
                  label="Amount"
                  type="number"
                  //   onChange={onAmountChange}
                  //   value={isTotalAmountDisable ? getInvoiceTotalAmount() : undefined}
                  //   disabled={isTotalAmountDisable}
                  required
                  disabled={!currentUser.company.po_request?.hide_expense_and_item_lines}
                />
                {!currentUser.company.po_request_hide_start_date && (
                  <DatePickerField
                    id="po_request-form-start-date"
                    name="purchase_order.start_date"
                    required={currentUser.company.po_request_start_end_date_required || undefined} //TODO: Fix to use undefined
                    label="Service Start Date"
                    disabled={currentUser.company.po_request_freeze_date || undefined} //TODO: Fix to use undefined
                  />
                )}
                {!currentUser.company.po_request_hide_end_date && (
                  <DatePickerField
                    id="po_request-form-end-date"
                    name="purchase_order.end_date"
                    label="Service End Date"
                    required={currentUser.company.po_request_start_end_date_required || undefined} //TODO: Fix to use undefined
                  />
                )}
                {showLocations && (
                  <LocationPicker
                    instanceId="po_request_location"
                    name="purchase_order.location_id"
                    label="Location"
                    parentObj="purchase_order"
                    modelDataName="purchase_order"
                    callBack={(selectedLocation) => mappingPoPrefix(selectedLocation)}
                    required={isLocationRequired}
                  />
                )}
                {/* TODO: Check is this workign or not */}
                {currentUser.company.has_ordered && (
                  <CustomDataListPicker
                    id="po_request_ordered"
                    name="purchase_order.ordered"
                    model="Ordered"
                    objectName="purchase_order.ordered"
                  />
                )}
                {showProductItemHeaderLevel && (
                  <ProductItemPicker
                    label={"Item Name"}
                    name={`purchase_order.product_item_id`}
                    modelName="purchase_order"
                    modelData={`purchase_order`}
                    parentObj={"purchase_order"}
                    callBack={selectProductItem}
                    required
                    // TODO: mohit add free text item feature
                    // TODO: mohit add functionality to add external component.
                    // externalOptionComponent={
                    //   <Button style={{ width: "100%" }} onClick={() => console.log("some action")}>
                    //     Add new
                    //   </Button>
                    // }
                  />
                )}
                {showProjectHeaderLevel && (
                  <ProjectPicker
                    name="purchase_order.project_id"
                    label={"Project"}
                    objectPropertyKey="purchase_order.project"
                    modelData={"purchase_order"}
                    required={isProjectRequired}
                    callBack={mapShippingAddress}
                  />
                )}
                {/* TODO: Implement feature to use account-picker for header level*/}
                {/* TODO: Implement feature to use project-picker for header level*/}
                {!currentUser.company.po_request_hide_header_description && (
                  <TextAreaField
                    id="po_request-form-description"
                    name="purchase_order.description"
                    label="Description"
                    rows={3}
                    required={!currentUser.company.po_request_is_optional_description}
                  />
                )}
                {currentUser.company.purchase_order?.show_instruction && (
                  <TextAreaField
                    id="po_request-form-instruction"
                    name="purchase_order.instruction"
                    label="Instructions"
                    rows={3}
                  />
                )}
              </Col>

              <Col>
                {currentUser.company.po_request_vendor_contact && (vendor?.name || vendorId) && (
                  <Select
                    id="vendor_contact_id"
                    name="purchase_order.vendor_contact_id"
                    label="Vendor Contact"
                    options={vendorContacts}
                    onChange={(e: any) => {
                      // TODO: Implement create_contact();
                    }}
                  />
                )}
                {!currentUser?.company?.po_request_hide_department && (
                  <DepartmentPicker
                    name="purchase_order.department_id"
                    label={departmentLabel}
                    required={!!currentUser?.company?.po_request_department_required}
                    disabled={departmentDisabled}
                  />
                )}

                {/* TODO: Implement <static-workflow-picker/> */}

                {currentUser.company.has_budgets && !currentUser?.company?.po_request_hide_budgets && (
                  <BudgetPicker
                    name="purchase_order.budget_id"
                    objectName="purchase_order.budget"
                    label={t("admin.pages.purchaseOrder.budget")}
                    multiple
                    departmentIdName="purchase_order.department_id"
                    currencyCodeName="purchase_order.currency_code"
                    accountIdName="purchase_order.account_id"
                    required={currentUser?.company?.po_request_budget_required}
                  />
                )}
                {!currentUser.company.po_request_hide_currency_code && (
                  <CurrencyCodePicker
                    id="po_request_vendor_currency"
                    name="purchase_order.currency_code"
                    label="Currency Code"
                  />
                )}
                <div className="px-mb-15">
                  <AddressPicker
                    instanceId="po_request_address_id"
                    name="purchase_order.address_id"
                    label="Shipping Address"
                    modelData="purchase_order"
                    isPoRequest={true}
                    isShippingAddress={true}
                    hideAddressUser={allowContactAddresses}
                    extraAddressableId={currentUser.contact.id}
                    extraAddressableType="Contact"
                    allowMappingAddress={currentUser.company.po_request_allow_location_project_shipping_address}
                    required={currentUser?.company?.po_request?.is_required_shipping_address}
                  />
                </div>
                {!currentUser.company?.purchase_order?.hide_billing_address && (
                  <div className="px-mb-15">
                    <AddressPicker
                      instanceId="po_request_billing_address"
                      name="purchase_order.billing_address"
                      label="Billing Address"
                      modelData="purchase_order"
                      isBillingAddress={true}
                    />
                  </div>
                )}

                <DatePickerField
                  id="po_request-form-date"
                  name="purchase_order.date"
                  label="Date"
                  required={currentUser.company.po_request_freeze_date || undefined} //TODO: Fix to use undefined
                />
                {!currentUser.company.po_request_hide_request_by && (
                  <DatePickerField
                    id="po_request-form-receive_by_date"
                    name="purchase_order.receive_by_date"
                    label="Receive By"
                  />
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="px-mb-25">
          <Col md="11">
            <Row>
              <Col>
                <TabNavigator activeTab={activeTab} handleTabChange={changeTab} tabData={tabData} />
              </Col>
            </Row>
          </Col>
        </Row>

        {!poCommonSvc.isHidePoRequestLines() && !poCommonSvc.poRequestHidePOItems() && (
          <Row>
            <Col md="11">
              <ErrorBoundary>
                <div style={{ display: activeTab === "lineItem" ? "block" : "none" }} id="po_item_tab">
                  <ManagePoItems noVendor={noVendor} />
                </div>
              </ErrorBoundary>
            </Col>
          </Row>
        )}
        <Row>
          <Col md="11">
            <ErrorBoundary>
              <div style={{ display: activeTab === "expenses" ? "block" : "none" }}>
                <ManagePoExpenses />
              </div>
            </ErrorBoundary>
          </Col>
        </Row>
        <Row>
          <Col md="11">
            <div className="clearfix"></div>
            <hr />
            <ErrorBoundary>
              <Attachments />
            </ErrorBoundary>
          </Col>
        </Row>

        <hr style={{ borderTop: "1px solid #76777A" }} />
        <Row className="px-mb-10">
          <Col sm={{ span: 4, offset: 8 }} style={{ textAlign: "end" }}>
            {/* TODO: show buttons conditionally*/}
            {/* TODO: add message and submit buttons */}
            <Button variant="btn btn-secondary" className="px-mr-10">
              {t("admin.pages.purchaseOrder.cancel")}
            </Button>
            {savedPO?.status == "DRAFT" && (
              <Button type="submit" variant="btn btn-secondary" className="px-mr-10">
                {t("admin.pages.purchaseOrder.saveAsDraft")}
              </Button>
            )}
            {!purchaseOrder.requestor_can_edit && (
              <Button type="submit" variant={`btn btn-primary`} className="px-mr-10">
                {t("admin.pages.purchaseOrder.submitForApproval")}
              </Button>
            )}

            {purchaseOrder.requestor_can_edit && (
              <Button type="submit" variant={`btn btn-primary`} className="px-mr-10">
                {t("admin.pages.purchaseOrder.submit")}
              </Button>
            )}
          </Col>
        </Row>
      </form>
    </Container>
  );
};

export default FormRequestPO;
