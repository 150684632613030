import { AxiosResponse } from "axios";
import AddressPicker from "components/admin/pickers/reactHookFormPickers/addressPicker";
import BusinessUnitPicker from "components/admin/pickers/reactHookFormPickers/businessUnitPicker";
import ContactPicker from "components/admin/pickers/reactHookFormPickers/contactPicker";
import { ContactTypeEnum } from "components/admin/pickers/reactHookFormPickers/contactPicker/contactPicker.types";
import CurrencyCodePicker from "components/admin/pickers/reactHookFormPickers/currencyCodePicker";
import CustomNotesPicker from "components/admin/pickers/reactHookFormPickers/customNotesPicker";
import DepartmentPicker from "components/admin/pickers/reactHookFormPickers/departmentPicker";
import LocationPicker from "components/admin/pickers/reactHookFormPickers/locationPicker";
import PostingPeriodPicker from "components/admin/pickers/reactHookFormPickers/postingPeriod";
import SubsidiaryPicker from "components/admin/pickers/reactHookFormPickers/subsidiaryPicker";
import VendorPicker from "components/admin/pickers/reactHookFormPickers/vendorPicker";
import { VendorPickerType } from "components/admin/pickers/reactHookFormPickers/vendorPicker/vendorPickerTypes";
import { DatePickerField, InputField, Select, TextAreaField } from "components/forms/hookFormFields";
import _ from "lodash";
import { restApiService } from "providers/restApi";
import React, { useEffect, useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useTypedSelector } from "reducers";
import useCustomLabel from "services/admin/customLabels/useCustomLabel";
import { InvoiceType } from "services/admin/invoices/invoiceType";
import { useInvoiceCommonSvc } from "services/admin/invoices/useInvoiceCommonSvc";
import { IUser } from "services/common/user/userTypes";
import { useInvoiceDetailsContext } from "./invoiceDetailsContext";
import styles from "./invoiceHeaderLineSection.module.css";
import InvoiceDetailsAmount from "./subSections/InvoiceDetailsAmount";
import InvoiceDetailsDate from "./subSections/InvoiceDetailsDate";
import InvoiceDetailsPaymentTerm from "./subSections/InvoiceDetailsPaymentTerm";
import ManagePurchaseOrder from "./subSections/managePurchaseOrder";
import { useUiStateContext } from "./uiStateContext";

const InvoiceHeaderLineSection = () => {
  const { getValues, setValue, trigger } = useFormContext<InvoiceType.InvoiceDetailType>();
  const { invoiceDetails, isFetchingInvoiceDetails } = useInvoiceDetailsContext();
  const { isOperationRunning } = useUiStateContext();
  const currentUser: IUser = useTypedSelector((state) => state.user);
  const watchedVendor = useWatch({ name: "vendor" });
  const invoiceCommonHookSvc = useInvoiceCommonSvc();
  const { getCustomLabel } = useCustomLabel();

  const shouldBlur = isOperationRunning || isFetchingInvoiceDetails || !invoiceDetails;

  const invoiceFormClass = shouldBlur ? styles.formBlur : styles.form;

  const invoice = getValues();

  const statusOptions = useMemo(
    () => [
      { label: "New", value: "NEW" },
      { label: "Pending", value: "PENDING" },
      { label: "Open", value: "OPEN" },
    ],
    [],
  );

  const handleVendorChange = async (selectedVendor: VendorPickerType.TVendorOptions) => {
    try {
      const response: AxiosResponse = await restApiService.get("vendors/" + selectedVendor.id);
      const responseId = response?.data?.id;
      if (responseId) {
        const vendor = response.data;

        if (vendor?.term) {
          setValue("term", vendor.term);
          setValue("term_id", vendor.term?.id);
        }

        if (vendor?.vendor_id) {
          let updatedVendor = {
            ...selectedVendor,
            vendor_id: vendor.vendor_id,
            po_not_required: vendor.po_not_required,
            is_1099_eligible: vendor.is_1099_eligible,
            has_vendor_locations: vendor.has_vendor_locations,
          };
          setValue("vendor", updatedVendor);
          setValue("vendorVendorId", updatedVendor.vendor_id);
          setValue("vendor.vendor_id", updatedVendor.vendor_id);
        }

        const vendorsSubsidiary = _.find(vendor.subsidiaries, { id: invoice.subsidiary_id });
        if (!vendorsSubsidiary) {
          setValue("subsidiary", vendor.subsidiary);
          setValue("subsidiary_id", vendor.subsidiary?.id);
        }

        invoiceCommonHookSvc.updateDueDate();
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const serviceStartDate = useWatch({ name: "service_start_date" });

  const isServiceEndDateValid = () => {
    const serviceEndDate = getValues("service_end_date");
    if (serviceStartDate && serviceEndDate) {
      const formattedServiceStartDate = new Date(serviceStartDate).toISOString();
      const formattedServiceEndDate = new Date(serviceEndDate).toISOString();
      return formattedServiceEndDate > formattedServiceStartDate
        ? true
        : "Service End Date should be greater than Service Start Date";
    } else {
      return true;
    }
  };

  useEffect(() => {
    trigger("service_end_date");
  }, [serviceStartDate, trigger]);

  return (
    <>
      <div className={`${invoiceFormClass} ${styles.gridContainer}`}>
        <div className={styles.gridItem}>
          <InputField id="invoice_number" name="number" label={getCustomLabel("admin.pages.invoice.number")} required />
        </div>
        <div className={styles.gridItem}>
          <Select
            id="invoice_status"
            name="status"
            label={getCustomLabel("admin.pages.invoice.status")}
            options={statusOptions}
          />
        </div>
        {currentUser.company.has_subsidiaries && (
          <div className={styles.gridItem}>
            <SubsidiaryPicker name="subsidiary_id" required label={"admin.pages.invoice.subsidiary"} />
          </div>
        )}
        <div className={styles.gridItem}>
          <VendorPicker
            name="vendor"
            required
            label={getCustomLabel("admin.pages.invoice.vendor")}
            excludeStatuses="INACTIVE"
            callBack={(selectedVendor) => handleVendorChange(selectedVendor)}
          />
        </div>
        {watchedVendor?.vendor_id && !currentUser.company.invoice?.hide_vendor_address && (
          <div className={styles.gridItem}>
            <AddressPicker
              name="address"
              addressableType="Vendor"
              modelName="invoice"
              label={getCustomLabel("admin.pages.invoice.vendorAddress")}
            />
          </div>
        )}
        <div className={styles.gridItem}>
          <InvoiceDetailsDate />
        </div>
        {currentUser.company.invoice_show_vendor_id && watchedVendor?.vendor_id && (
          <div className={styles.gridItem}>
            <InputField
              id="invoice_vendor_vendor_id"
              name="vendor.vendor_id"
              label={getCustomLabel("admin.pages.vendor.vendorId")}
              disabled
            />
          </div>
        )}
        {currentUser.company.has_posting_period && (
          <div className={styles.gridItem}>
            <PostingPeriodPicker name="posting_period" />
          </div>
        )}
        <div className={styles.gridItem}>
          <ManagePurchaseOrder hideMultipleVendorExpensesCorpCardCheckbox={true} />
        </div>
        {currentUser.company.invoice_show_vendor_id && invoice?.default_payment_method && (
          <div className={styles.gridItem}>
            <InputField
              id="vendor_payment_method_type"
              name="default_payment_method_type_payment_type"
              label={getCustomLabel("admin.pages.invoice.default_payment_method_type_payment_type")}
              disabled
            />
          </div>
        )}
        <div className={styles.gridItem}>
          <InvoiceDetailsAmount />
        </div>
        {!currentUser.company.invoice_hide_discount && (
          <div className={styles.gridItem}>
            <InputField
              id="invoice_discount_amount"
              name="amount_disc"
              label={getCustomLabel("admin.pages.invoice.discountAmount")}
              type="number"
            />
          </div>
        )}
        {currentUser.company.has_locations && !currentUser.company.invoice_hide_location && (
          <div className={styles.gridItem}>
            <LocationPicker
              instanceId="po_request_location"
              name="location_id"
              label={getCustomLabel("admin.pages.invoice.location")}
              required={currentUser.company.invoice?.is_required_location}
            />
          </div>
        )}
        {currentUser.company.has_business_units && !currentUser.company?.invoice?.hide_business_unit && (
          <div className={styles.gridItem}>
            <BusinessUnitPicker
              name="business_unit_id"
              label={getCustomLabel("admin.pages.invoice.businessUnit")}
              disabled={currentUser.company.readonly_business_unit_to_all}
            />
          </div>
        )}
        {currentUser.company.has_custom_notes && (
          <div className={styles.gridItem}>
            <CustomNotesPicker name="custom_note" label={getCustomLabel("admin.pages.invoice.customNote")} />
          </div>
        )}
        {!currentUser.company?.invoice_hide_company_requestor && (
          <div className={styles.gridItem}>
            <ContactPicker
              label={getCustomLabel("admin.pages.invoice.requestor")}
              name="requestor_id"
              contactType={ContactTypeEnum.COMPANY}
            />
          </div>
        )}
        {currentUser.company?.has_departments && !currentUser.company?.invoice_hide_department && (
          <div className={styles.gridItem}>
            <DepartmentPicker
              name="department_id"
              required={currentUser?.company?.invoice_department_required}
              label={getCustomLabel("admin.pages.invoice.department")}
            />
          </div>
        )}
        <div className={styles.gridItem}>
          <InvoiceDetailsPaymentTerm />
        </div>
        <div className={styles.gridItem}>
          <CurrencyCodePicker name="currency_code" label={"admin.pages.invoice.currencyCode"} showSymbol />
        </div>
        <div className={styles.gridItem}>
          <DatePickerField
            id="invoice_due_date"
            name="due_date"
            label={getCustomLabel("admin.pages.invoice.dueDate")}
            required
          />
        </div>
        <div className={styles.gridItem}>
          <DatePickerField
            id="invoice_submit_date"
            name="submit_date"
            label={getCustomLabel("admin.pages.invoice.submitDate")}
          />
        </div>
        {!currentUser.company.invoice_hide_discount && (
          <div className={styles.gridItem}>
            <DatePickerField
              id="invoice_discount_expire_date"
              name="amount_disc_date"
              label={getCustomLabel("admin.pages.invoice.discountExpireDate")}
            />
          </div>
        )}
        {!currentUser.company?.invoice_hide_service_start_date && (
          <>
            <div className={styles.gridItem}>
              <DatePickerField
                id="invoice_start_date"
                name="service_start_date"
                label={getCustomLabel("admin.pages.invoice.serviceStartDate")}
                required={currentUser.company.invoice_service_start_date_required}
              />
            </div>
            <div className={styles.gridItem}>
              <DatePickerField
                id="invoice_end_date"
                name="service_end_date"
                label={getCustomLabel("admin.pages.invoice.serviceEndDate")}
                required={currentUser.company.invoice_service_end_date_required}
                validate={true}
                validationFunction={isServiceEndDateValid}
              />
            </div>
          </>
        )}
        {!currentUser.company.invoice_hide_reference_number && (
          <div className={styles.gridItem}>
            <InputField
              id="invoice_reference_number"
              name="reference_number"
              label={getCustomLabel("admin.pages.invoice.referenceNumber")}
              required={currentUser.company.invoice?.is_required_reference_number}
            />
          </div>
        )}
        {currentUser.company.invoice?.show_posted_date && (
          <div className={styles.gridItem}>
            <DatePickerField
              id="invoice_posted_date"
              name="posted_date"
              label={getCustomLabel("admin.pages.invoice.postedDate")}
              required
            />
          </div>
        )}
        {currentUser.company.invoice.show_customer_account_number && (
          <div className={styles.gridItem}>
            <InputField
              id="customer_account_number"
              name="customer_account_number"
              label={getCustomLabel("admin.pages.invoice.customerAccountNumber")}
            />
          </div>
        )}
      </div>
      <div className={styles.gridItem}>
        <TextAreaField
          id="invoice_description"
          name="description"
          rows={2}
          label={getCustomLabel("admin.pages.invoice.description")}
          required={currentUser.company.invoice?.is_required_description}
        />
      </div>
    </>
  );
};

export default InvoiceHeaderLineSection;
