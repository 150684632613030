import CustomModal from "components/modals/customModal";
import React from "react";
import { useDispatch } from "react-redux";
import { change } from "redux-form";
import { ExpensesTypes } from "services/admin/expenses/expensesType";
import { useExpenseItemFormContext } from "../expenseItemFormSectionContext";
import { useFormValue } from "../formExpenseItem";
import style from "./../expenseItem.module.css";
import MapRouteForm from "./mapRouteForm";

type AddRouteMapModalProps = {
  show: boolean;
  handleClose: () => void;
};

const AddRouteMapModal = ({ show, handleClose }: AddRouteMapModalProps) => {
  const ctx = useExpenseItemFormContext();
  const expenseItem = useFormValue<ExpensesTypes.ExpenseItemFormDataType>(ctx?.formName, ctx?.sectionPrefix);
  const mapRoute = expenseItem?.map_route;
  const defaultMapRoute = (mapRoute && { map_route: mapRoute }) ?? { map_route: {} };
  const dispatch = useDispatch();
  const isAdd = !mapRoute; // if milage expense item mapRoute is having id it's in edit mode.

  const handleRouteMapFormSubmit = (mapDataRouteData: ExpensesTypes.TMapRouteFormData) => {
    const updatedMapRoute: ExpensesTypes.TMapRouteFormData = {
      map_route: {
        ...mapDataRouteData.map_route,
        map_route_waypoints_attributes: mapDataRouteData.map_route.map_route_waypoints,
      },
    };

    const updatedExpenseItem: ExpensesTypes.ExpenseItemFormDataType = {
      ...expenseItem,
      map_route: updatedMapRoute.map_route,
      map_route_attributes: updatedMapRoute.map_route,
      distance: mapDataRouteData.map_route.distance,
      distance_unit: mapDataRouteData.map_route.units,
    };

    if (ctx) {
      dispatch(change(ctx?.formName, ctx?.sectionPrefix, updatedExpenseItem));
    }
    handleClose();
  };

  return (
    <CustomModal
      show={show}
      onHide={handleClose}
      header={isAdd ? "Add Route Map" : "Edit Route Map"}
      size={"lg"}
      headerClass={style.headerClass}
      modalBodyClass={style.modalBody}
      contentClassName={style.mapModalContent}
      body={<MapRouteForm defaultValues={defaultMapRoute} onSubmit={handleRouteMapFormSubmit} onCancel={handleClose} />}
    />
  );
};

export default AddRouteMapModal;
