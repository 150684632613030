import { BulkUploadsFilesGridAll } from "wombatifier/components/bulk_upload/bulkUploadFilesGridAll";
import BulkUploadsFilesGridInvoice from "wombatifier/components/bulk_upload/bulkUploadFilesGridInvoice";
import BulkUploadsFilesGridVendor from "wombatifier/components/bulk_upload/bulkUploadFilesGridIVendor";
import BulkUploadForm from "../../../wombatifier/components/bulk_upload/bulkUploadFiles";
import EditCompanyProfile from "../settings/companyProfile";
import CustomLabelsPage from "./customLabels/customLabels";
import DefaultRulesPage from "./defaultRules/defaultRules";
import FraudAudits from "./fraudAudit";
import ShowFraudAudit from "./fraudAudit/show";
import MccDefaultsForm from "./mccDefaults/mccDefaults";
import MetadataConfigurationForm from "./metadata/metadataConfiguration";
import MetadataHierarchyForm from "./metadata/metadataHierarchy";
import MetadataTemplatePage from "./metadata/metadataTemplate";
import WorkFlow from "./workflow/approvalWorkFlow/workFlow";
import StaticWorkFlow from "./workflow/static_workflows/staticWorkFlow";

const administrationRoutes = [
  {
    path: ["/ap/approval_workflows", "/ap/approval_workflows/:id"],
    exact: true,
    component: WorkFlow,
    authorization: {
      I: "allow",
      a: "ApprovalSetting",
    },
  },
  {
    path: ["/ap/static_workflows", "/ap/static_workflows/:id"],
    exact: true,
    component: StaticWorkFlow,
    authorization: {
      I: "allow",
      a: "ApprovalSetting",
    },
  },
  {
    path: "/ap/fraud_audits",
    exact: true,
    component: FraudAudits,
    authorization: {
      I: "allow",
      a: "SystemRole",
    },
  },
  {
    path: "/ap/fraud_audits/:id",
    exact: true,
    component: ShowFraudAudit,
    authorization: {
      I: "allow",
      a: "SystemRole",
    },
  },
  {
    path: "/ap/bulk_uploads/add",
    exact: true,
    component: BulkUploadForm,
    authorization: {
      I: "import",
      a: "BulkOperations",
    },
  },
  {
    path: "/ap/bulk_uploads/vendors",
    exact: true,
    component: BulkUploadsFilesGridVendor,
    authorization: {
      I: "import",
      a: "BulkOperations2",
    },
  },
  {
    path: "/ap/bulk_uploads/invoices",
    exact: true,
    component: BulkUploadsFilesGridInvoice,
    authorization: {
      I: "import",
      a: "BulkOperations2",
    },
  },
  {
    path: "/ap/bulk_uploads",
    exact: true,
    component: BulkUploadsFilesGridAll,
    authorization: {
      I: "import",
      a: "BulkOperations",
    },
  },
  {
    path: "/ap/dynamic_coding",
    exact: true,
    component: MetadataTemplatePage,
    authorization: {
      I: "import",
      a: "BulkOperations",
    },
  },
  {
    path: "/ap/default_rules",
    exact: true,
    component: DefaultRulesPage,
    authorization: {
      I: "import",
      a: "BulkOperations",
    },
  },
  {
    path: "/ap/custom_labels",
    exact: true,
    component: CustomLabelsPage,
    authorization: {
      I: "import",
      a: "BulkOperations",
    },
  },
  {
    path: "/ap/metadata_configurations/edit",
    exact: true,
    component: MetadataConfigurationForm,
    authorization: {
      I: "import",
      a: "BulkOperations",
    },
  },
  {
    path: "/ap/metadata_hierarchy/edit",
    exact: true,
    component: MetadataHierarchyForm,
    authorization: {
      I: "import",
      a: "BulkOperations",
    },
  },
  {
    path: "/ap/mcc_defaults",
    exact: true,
    component: MccDefaultsForm,
    authorization: {
      I: "list",
      a: "ExpenseItems",
    },
  },
  {
    path: "/ap/company",
    exact: true,
    component: EditCompanyProfile,
    authorization: {
      I: "edit",
      a: "Company",
    },
  },
];

export default administrationRoutes;
