import React, { useState } from "react";
import { ButtonGroup, Col, Dropdown, DropdownButton, Row } from "react-bootstrap";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "reducers";
import { selectCurrentUser } from "reducers/userReducers";
import PoCommonSvc from "services/admin/purchaseOrders/poCommonSvc";
import { IUser } from "services/common/user/userTypes";
import styles from "../../purchaseOrders.module.css";
import POLineItem from "./poLineItem";

type TManagePOItemsProps = {
  noVendor: boolean;
};

const ManagePoItems = ({ noVendor }: TManagePOItemsProps) => {
  const currentUser: IUser = useTypedSelector(selectCurrentUser);
  const poCommonSvc = PoCommonSvc.getInstance(currentUser);
  const [itemLineCount, setItemLineCount] = useState<number>(1);
  const { control, getValues, setValue, trigger } = useFormContext<any>();
  const { t } = useTranslation();

  const { fields, append, update } = useFieldArray({
    control,
    name: "purchase_order.po_items",
    keyName: "_id" as "id",
  });

  const poitems = fields as unknown as any[];

  const addPOItems = (count: number) => {
    // TODO: Create type
    let item: { department_id?: number | string; business_unit_id?: number | string; view_meta?: { edit?: boolean } } =
      {};
    let purchaseOrder = getValues("purchase_order");

    if (
      purchaseOrder.is_request_PO &&
      !currentUser.company.po_request_disabled_itemline_department &&
      purchaseOrder.department
    ) {
      item.department_id = purchaseOrder.department.id;
      item.business_unit_id =
        purchaseOrder.department.business_unit_id > 0 ? purchaseOrder.department.business_unit_id : "";
    }
    for (let i = 0; i < Number(count); i++) {
      item.view_meta = { edit: true };
      append(item);
    }
  };

  return (
    <>
      <Row>
        <Col>
          <div>PO Line Items</div>
        </Col>
      </Row>
      {poitems.map((item, index) => (
        <POLineItem index={index} key={item._id} lineKey={item._id} noVendor={noVendor} />
      ))}
      <Row className="px-mt-25">
        <Col>
          <DropdownButton
            as={ButtonGroup}
            variant={`btn btn-primary`}
            title={
              <span>
                <i className={`btn-icon icon-add-btn ${styles.btnAddItemsIcon}`}></i>
                {t("admin.pages.purchaseOrder.addItems")} ({itemLineCount})
              </span>
            }
            onSelect={(eventKey) => {
              if (eventKey) {
                setItemLineCount(Number(eventKey));
                addPOItems(Number(eventKey));
              }
            }}
          >
            <Dropdown.Item eventKey="1">1</Dropdown.Item>
            <Dropdown.Item eventKey="5">5</Dropdown.Item>
            <Dropdown.Item eventKey="20">20</Dropdown.Item>
            <Dropdown.Item eventKey="50">50</Dropdown.Item>
          </DropdownButton>
        </Col>
      </Row>
    </>
  );
};

export default ManagePoItems;
