import FileUploader from "components/common/fileUploader/fileUploader";
import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useFormContext, useWatch } from "react-hook-form";
import { useTypedSelector } from "reducers";
import { selectCurrentUser } from "reducers/userReducers";
import PoCommonSvc from "services/admin/purchaseOrders/poCommonSvc";
import { IAttachment } from "services/common/types/common.type";
import { IUser } from "services/common/user/userTypes";

const Attachments = () => {
  const { getValues, setValue, register, formState, trigger } = useFormContext();
  const { errors } = formState as any;
  const attachments = useWatch({
    name: "purchase_order.assets_attributes",
    defaultValue: [],
  });
  const currentUser: IUser = useTypedSelector(selectCurrentUser);
  const poCommonSvc = PoCommonSvc.getInstance(currentUser);
  const purchaseOrder = getValues("purchase_order");

  const assets = getValues("purchase_order.assets");
  const punchoutId = useWatch({ name: "purchase_order.punchout_id" });
  const amount = useWatch({ name: "purchase_order.amount" });
  const uploadAttachments = (files: File[]) => {
    const currentAttachments = getValues("purchase_order.assets_attributes") || [];
    const updateAttachments = [...currentAttachments, ...files];

    setValue("purchase_order.assets_attributes", updateAttachments);
    trigger("purchase_order.assets_attributes");
  };
  const rmAttachment = (index: number) => {
    const updatedAttachments = [...attachments];
    updatedAttachments.splice(index, 1);
    setValue("purchase_order.assets_attributes", updatedAttachments);
    trigger("purchase_order.assets_attributes");
  };
  const deleteAsset = (assetId: number, index: number) => {
    const updatedAttachments = [...attachments, { id: assetId, _destroy: 1 }];
    setValue("purchase_order.assets_attributes", updatedAttachments);
    const updateAssets = assets;
    updateAssets.splice(index, 1);
    setValue("purchase_order.assets", updateAssets);
    trigger("purchase_order.assets_attributes");
  };

  const validateAttachments = (attachments: IAttachment[]) => {
    if (punchoutId) {
      return true;
    }
    let filterAssetAttribute;
    if (attachments && attachments.length > 0) {
      filterAssetAttribute = attachments.filter((assets_attribute: IAttachment) => !assets_attribute._destroy);
    }
    const hasAttachments = (assets && assets.length > 0) || (filterAssetAttribute && filterAssetAttribute.length > 0);
    if (
      (currentUser.company?.po_request_is_attachment_required && !hasAttachments) ||
      (poCommonSvc.isAttachmentRequiredBasedOnAmount(amount, currentUser) && !hasAttachments)
    ) {
      return "Attachments are required";
    }
    return true;
  };

  register("purchase_order.assets_attributes", {
    validate: validateAttachments,
  });

  const isAttachmentRequired = () => {
    if (punchoutId) {
      return false;
    }
    let attachmentRequired = false;
    if (
      currentUser?.company?.po_request_is_attachment_required ||
      poCommonSvc.isAttachmentRequiredBasedOnAmount(amount, currentUser)
    ) {
      attachmentRequired = true;
    }
    return attachmentRequired;
  };
  const getAttachmentMsg = () => {
    let errMsg;
    if (isAttachmentRequired()) {
      errMsg = "(Required)";
    } else {
      errMsg = "(Optional)";
    }
    return errMsg;
  };

  const renderAssetList = (assetList: IAttachment[], isAttachment = false) => (
    <ul>
      {assetList.map((item, index) =>
        item._destroy !== 1 ? (
          <li key={index}>
            <a href={!isAttachment ? item.asset_expiring_url : undefined} className="Info link">
              {isAttachment ? item.name : item.asset_file_file_name || ""}
            </a>
            <a>
              <span
                onClick={() => (isAttachment ? rmAttachment(index) : item.id && deleteAsset(Number(item.id), index))}
              >
                X
              </span>
            </a>
          </li>
        ) : null,
      )}
    </ul>
  );

  return (
    <>
      <Row>
        <Col md="6">
          <div className="d-flex align-items-center">
            <FileUploader
              customButton={<Button variant="btn btn-secondary">Add Attachments</Button>}
              showUploadBtn
              uploadAttachments={(files: File[]) => uploadAttachments(files)}
              multiple={false}
            />
            <label className="pl-1"> {getAttachmentMsg()}</label>
          </div>

          {errors?.purchase_order?.assets_attributes && (
            <p className="text-danger">{errors.purchase_order.assets_attributes.message}</p>
          )}

          {!currentUser?.company?.po_request_is_attachment_required && (
            <small>
              "(For example - Copy of Contract for review, Approved Quotations, Purchase Contract Agreement, Purchase
              Order etc)"
            </small>
          )}
          {currentUser.company.po_request_is_attachment_required && (
            <ol>
              <li>Copy of Contract for review</li>
              <li>Security Questionnaire</li>
              <li>If no formal contract, link to online terms and services</li>
              <li>If available, copy of last years contract</li>
              <li>Quotation in the required attachment section.</li>
            </ol>
          )}
        </Col>
        {(attachments?.length > 0 || assets?.length > 0) && (
          <Col md="6">
            {attachments?.length > 0 && renderAssetList(attachments, true)}
            {assets?.length > 0 && renderAssetList(assets)}
          </Col>
        )}
      </Row>
      <div className="clearfix"></div>
    </>
  );
};

export default Attachments;
