import useIsMounted from "components/common/hooks/useIsMounted";
import PickerErrorBlock from "components/common/pickers/pickerErrorBlock";
import _ from "lodash";
import { restApiService } from "providers/restApi";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { useTypedSelector } from "reducers";
import { selectCurrentUser } from "reducers/userReducers";
import { WrappedFieldProps, change } from "redux-form";
import commonService from "services/common/commonSvc";
import { IDType } from "services/common/types/common.type";
import { compare, onBlurSingle } from "services/general/helpers";
import { Mandatory } from "wombatifier/components/pickers/mandatory";

export type TaxLKItem = {
  code: string;
  credit_account_id: IDType;
  id: IDType;
  rate: number;
  tax_rates: any[];
};

export type TaxLKItemOptionType = TaxLKItem & {
  label: string;
  value: IDType | string;
  raw?: TaxLKItem;
};

export interface ExpenseItemTaxPickerPropsType extends WrappedFieldProps {
  modelData?: any;
  parentObjData?: any;
  className?: string;
  labelClassName?: string;
  label?: string;
  tooltip?: string;
  required?: boolean;
  instanceId?: string;
  placeholder?: string;
  disabled?: boolean;
  callBack?: (value: any) => void;
  callBackObj?: (value: any) => void;
  formName?: string;
  formLocation?: string; // local of js object where gone save changes
}

// this component is specific to expense item form only
const ExpenseItemTaxPicker = (props: ExpenseItemTaxPickerPropsType) => {
  const isMounted = useIsMounted();
  const [options, setOptions] = useState<TaxLKItemOptionType[]>([]);
  const {
    input,
    meta: { error, touched },
    className,
    disabled,
    instanceId,
    label,
    labelClassName,
    modelData,
    callBack,
    parentObjData,
    placeholder,
    required,
    tooltip,
    callBackObj,
    formName,
    formLocation,
  } = props;
  const dispatch = useDispatch();
  const currentUser = useTypedSelector(selectCurrentUser);

  const findSelected = () => {
    let selectedValues = null; //set to null because pickers will have blank default value instead of "select... placeholder"
    const obj = _.find(options, (option: any) => option?.value === input.value);
    if (obj) selectedValues = obj;
    return selectedValues ? selectedValues : null;
  };

  const initTaxObj = (obj: any) => {
    if (_.isPlainObject(modelData) && _.isPlainObject(obj)) {
      if (formName && formLocation) {
        dispatch(change(formName, formLocation + ".tax", obj));
      }
    }
    input.onChange(obj.id);
  };

  const parseForSelect = (options: any[]) => {
    return options
      .map((option) => {
        return {
          value: option.id,
          label: option.code,
          raw: option,
          ...option,
        };
      })
      .sort(compare);
  };

  const setValue = (selected: any) => {
    if (selected && selected.id > 0) {
      input.onChange(selected.valued);
      if (_.isPlainObject(modelData) && formName && formLocation) {
        dispatch(change(formName, formLocation + "tax_code", selected));
      }
      if (callBack) {
        callBack(selected);
      }
    } else {
      if (_.isPlainObject(modelData) && formName && formLocation) {
        dispatch(change(formName, formLocation + "tax_code", null));
      }
      input.onChange("");
    }
  };

  // Merge inactive expense category into list
  const mergeInactiveTax = function (categories: any[]) {
    if (input.value > 0) {
      const filteredCategories = categories.filter((category: any) => category.id === input.value);
      if (filteredCategories.length === 0) {
        initTaxObj(null);
        setValue({});
      } else {
        initTaxObj(filteredCategories[0]);
      }
    }
  };

  const getTaxes = async () => {
    try {
      const result = await restApiService.get("taxes.lk", { policy_id: modelData?.policy_id }, null, true, null, true);
      if (Array.isArray(result.data)) {
        const parsedOptions = parseForSelect(result.data);
        if (
          !_.isPlainObject(currentUser.company.expense_item) ||
          !currentUser.company.expense_item?.tax_code_required
        ) {
          parsedOptions.unshift({ label: "-- Select Tax --", value: "", id: "", name: "" });
        }
        mergeInactiveTax(parsedOptions);
        if (isMounted.current) {
          setOptions(parsedOptions);
        }
      }
    } catch (error) {
      commonService.handleError(error);
    }
  };

  const onChange = (selected: any) => {
    input.onChange(selected.value);
    setValue(selected);
    if (callBackObj) {
      callBackObj(selected);
    }
  };

  useEffect(() => {
    if (modelData?.policy_id) {
      getTaxes();
    }
  }, [modelData?.policy_id]);

  return (
    <>
      <Form.Group>
        {label && (
          <Form.Label className={labelClassName}>
            {label}
            <Mandatory required={required} />
            {tooltip}
          </Form.Label>
        )}
        <Select
          {...input}
          value={findSelected()}
          onChange={(selected) => onChange(selected)}
          formatOptionLabel={(option) => {
            return (
              <>
                {option.id ? (
                  <>
                    {option.code} {option.rate && <span>- {option.rate}</span>}{" "}
                  </>
                ) : (
                  <>{option.label}</>
                )}
              </>
            );
          }}
          onBlur={() => onBlurSingle(input, input.value)}
          instanceId={instanceId}
          options={options}
          isDisabled={disabled}
        />
        {error && touched && <PickerErrorBlock error={error} />}
      </Form.Group>
    </>
  );
};

export default ExpenseItemTaxPicker;
