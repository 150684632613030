import useConfirmModal from "components/modals/confirmModal/useConfirmModalHook";
import _ from "lodash";
import React, { Fragment } from "react";
import { Accordion, Button, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "reducers";
import { IUser } from "services/common/user/userTypes";
import PaymentMethod from "../../../components/paymentMethods/paymentMethods";
import companyApis from "../../../services/common/company/companyApis";
import { CompanyDetailType } from "../../../services/common/company/companyTypes";
import { PaymentMethodType } from "../../../services/common/paymentMethod/paymentMethodsTypes";
import paymentTypeService from "../../../services/common/paymentMethod/paymentTypeSvc";
import { CreateNotification, NotificationType } from "../../../services/general/notifications";
import { PurchaserListType } from "../../../services/vp/purchaser/purchaserTypes";
import Panel from "../../common/panel/panel";
import UnlinkedPurchasers from "../../widgets/paymentMethodLinks/unlinkedPurchasers";
import styles from "./bankingInfo.module.css";

type BankInfoPropsType = {
  paymentMethods: CompanyDetailType["payment_methods"];
  refreshPage: (showSuccessModal?: boolean) => void;
  toggleShowPaymentForm: (payment_method: PaymentMethodType) => void;
  openDeleteModal: (id: number) => void;
  showPaymentForm?: boolean;
  showPaymentFormNew?: boolean;
  paymentMethodId?: number;
  companyDetail?: CompanyDetailType;
  addPaymentMethod: () => void;
  purchasers: PurchaserListType[];
  closePayment: (fullClear: boolean) => void;
  closeCreateAndEdit: () => void;
};

const BankInfo = ({
  paymentMethods,
  refreshPage,
  toggleShowPaymentForm,
  openDeleteModal,
  showPaymentForm,
  paymentMethodId,
  companyDetail,
  showPaymentFormNew,
  addPaymentMethod,
  purchasers,
  closePayment,
  closeCreateAndEdit,
}: BankInfoPropsType) => {
  const { t } = useTranslation();
  const { createConfirmModal } = useConfirmModal();
  const currentUser: IUser = useTypedSelector((state) => state.user);
  const onTogglePrimary = async ({
    id,
    is_primary,
    paymentMethod,
  }: {
    is_primary: boolean;
    id: number | string;
    paymentMethod: PaymentMethodType;
  }) => {
    if (paymentMethod.payment_type == "credit_card") {
      CreateNotification("Failed", "Credit Cards Can Not be a Default Payment Method", NotificationType.danger);
      return;
    }

    let payment_methods: PaymentMethodType[] = [];
    if (_.isArray(paymentMethods)) {
      payment_methods = paymentMethods.map((item: PaymentMethodType, index: number) => {
        if (item.id === id) {
          return { ...item, is_primary: true };
        } else {
          return { ...item, is_primary: false };
        }
      });
    }

    let data = {
      company: { payment_methods_attributes: payment_methods },
    };
    try {
      const response = await companyApis.editCompanyDetail(data);
      if (response) {
        CreateNotification("Update", "Primary Payment Method updated", NotificationType.success);
        refreshPage();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onPrimaryPaymentMethodChange = ({
    id,
    is_primary,
    paymentMethod,
  }: {
    is_primary: boolean;
    id: number | string;
    paymentMethod: PaymentMethodType;
  }) => {
    createConfirmModal({
      title: "Update Primary Payment Method",
      body: `Are you sure you want to update Primary Payment Method to ${id}`,
      callBackData: { id, is_primary, paymentMethod },
      saveCallBack: onTogglePrimary,
      cancelCallBack: null,
    });
  };

  return (
    <Fragment>
      <Row>
        <Col md="12" className="pb-2 mt-2 mx-1">
          <Panel
            cardHeaderClass="pb-0 pt-1 mt-1"
            header={
              <div className="d-flex justify-content-between">
                <div className="mr-auto my-2 banking-info-title">
                  <i className="icon icon-bank"></i>
                  <h3 className="panel-heading"> {t("paymentMethod.bankInformation")}</h3>
                  <h6 className="mt-2">{t("paymentMethod.addAvailablePayments")}</h6>
                </div>

                {purchasers.every((purchaser) => purchaser.has_only_corpay_nvp_integration == false) && (
                  <div className="p-2">
                    <Button variant="primary" onClick={addPaymentMethod}>
                      <i className="btn-icon icon-add-btn"></i>
                      {t("paymentMethod.paymentMethod")}
                    </Button>
                  </div>
                )}
              </div>
            }
          >
            {showPaymentFormNew && (
              <div>
                <PaymentMethod
                  companyDetail={companyDetail}
                  closeModel={closePayment}
                  refreshPage={refreshPage}
                  purchasers={purchasers}
                  closeCreateAndEdit={closeCreateAndEdit}
                />
              </div>
            )}
            <Accordion>
              <Row className={"m-0 headerContainer"}>
                <Col className={`${styles.paymentTableHeading}`}>{t("paymentMethod.bankName")}</Col>
                <Col className={`${styles.paymentTableHeading}`}>{t("paymentMethod.accountType")}</Col>
                <Col className={`${styles.paymentTableHeading}`}>{t("paymentMethod.paymentType")}</Col>
                <Col className={`${styles.paymentTableHeading}`}>{t("paymentMethod.accountNumber")}</Col>
                <Col className={`${styles.paymentTableHeading}`}>{t("paymentMethod.accountHolder")}</Col>
                <Col className={`${styles.paymentTableHeading}`}>{t("currency")}</Col>
                <Col className={`${styles.paymentTableHeading}`}></Col>
              </Row>

              {_.isArray(paymentMethods) &&
                _.orderBy(paymentMethods, ["id"], ["desc"]).map((paymentMethod, index) => (
                  <Row key={paymentMethod.id} className={`${styles.paymentRowContainer}`}>
                    <Col className={`${styles.paymentTableRow}`}>{paymentMethod.bank_name}</Col>
                    <Col className={`${styles.paymentTableRow}`}>{paymentMethod.account_type}</Col>
                    <Col className={`${styles.paymentTableRow}`}>
                      {paymentMethod.payment_type &&
                        paymentTypeService.getPaymentMethodLabel(paymentMethod.payment_type, currentUser)}
                    </Col>
                    <Col className={`${styles.paymentTableRow}`}>{paymentMethod.account_number}</Col>
                    <Col className={`${styles.paymentTableRow}`}>{paymentMethod.account_name}</Col>
                    <Col className={`${styles.paymentTableRow}`}>{paymentMethod.currency_code}</Col>

                    <Col>
                      {paymentMethod.is_primary ? (
                        <Button className={`${styles.paymentAction}`}>
                          <i className="icon icon-star-active"></i>
                        </Button>
                      ) : (
                        <Button
                          className={`${styles.paymentAction}`}
                          onClick={() =>
                            onPrimaryPaymentMethodChange({ is_primary: true, id: paymentMethod.id, paymentMethod })
                          }
                        >
                          <i className="icon icon-star"></i>
                        </Button>
                      )}
                      <Accordion.Toggle
                        as={Button}
                        variant="link"
                        eventKey={`${index}`}
                        onClick={() => toggleShowPaymentForm(paymentMethod)}
                      >
                        <i className="icon icon-edit"></i>
                      </Accordion.Toggle>
                      <Button
                        className={`${styles.paymentAction} px-0`}
                        onClick={() => {
                          openDeleteModal(paymentMethod.id);
                        }}
                      >
                        <i className="icon icon-delete"></i>
                      </Button>
                    </Col>
                    {paymentMethod.payoneer_status === "INVALID" && paymentMethod.payoneer_response_desc && (
                      <Col xs={12}>
                        <p className="text-danger mb-0" style={{ whiteSpace: "pre" }}>
                          {paymentMethod.payoneer_response_desc}
                        </p>
                      </Col>
                    )}
                    {showPaymentForm && paymentMethodId == paymentMethod.id && (
                      <Col md={12}>
                        <PaymentMethod
                          companyDetail={companyDetail}
                          editAccountDetail={paymentMethod}
                          closeModel={closePayment}
                          refreshPage={refreshPage}
                          purchasers={purchasers}
                          closeCreateAndEdit={closeCreateAndEdit}
                        />
                      </Col>
                    )}
                  </Row>
                ))}
            </Accordion>
          </Panel>
        </Col>
      </Row>

      {_.isArray(paymentMethods) && paymentMethods.length > 0 && (
        <Row className="mt-3">
          <Col>
            <UnlinkedPurchasers
              companyPaymentMethods={companyDetail?.payment_methods}
              heading={"Linked Payment Methods"}
              showAll={true}
              hideHeaderText={true}
            />
          </Col>
        </Row>
      )}
    </Fragment>
  );
};

export default BankInfo;
