import { AxiosResponse } from "axios";
import { payReimbursementType } from "services/admin/expenses/payReimbursementType";
import { IDType, getListOptionsType, getPaginatedListOptionsType } from "services/common/types/common.type";
import { restApiService } from "../../../../providers/restApi";
import { ExpenseReportTypes, expensesCountType } from "./expenseReportType";

class ExpenseReportApis {
  static async addExpenseReport(expenseReportPayload: ExpenseReportTypes.AddPayload) {
    try {
      const response: AxiosResponse<ExpenseReportTypes.Details> = await restApiService.post(
        "expense_reports",
        null,
        expenseReportPayload,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async getDetail(id: IDType) {
    try {
      const response: AxiosResponse<ExpenseReportTypes.Details> = await restApiService.get("expense_reports/" + id);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async getList({ filter, cache }: getListOptionsType = {}) {
    try {
      const response: AxiosResponse<ExpenseReportTypes.List> = await restApiService.get(
        "expense_reports",
        filter,
        null,
        true,
        null,
        cache,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  static async getPayExpenseList({ filter, cache }: getListOptionsType = {}) {
    try {
      const response: AxiosResponse<ExpenseReportTypes.PaginatedList> = await restApiService.get(
        "expense_reports/payable",
        filter,
        null,
        true,
        null,
        cache,
      );

      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async editExpenseReport(id: IDType, expenseReportPayload: any) {
    try {
      const response: AxiosResponse<ExpenseReportTypes.Details> = await restApiService.patch(
        "expense_reports/" + id,
        null,
        expenseReportPayload,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async linkExpenseItem(id: IDType, payload: { item_ids: IDType[] }) {
    try {
      const response: AxiosResponse<{ status: "success" }> = await restApiService.patch(
        `expense_reports/${id}/link_expense_item`,
        null,
        payload,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async getPaginatedList({ filter, cache }: getPaginatedListOptionsType) {
    try {
      const response: AxiosResponse<ExpenseReportTypes.PaginatedList> = await restApiService.get(
        "expense_reports/index",
        filter,
        null,
        true,
        null,
        cache,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async delete(id: IDType) {
    try {
      const response: AxiosResponse<ExpenseReportTypes.PaginatedList> = await restApiService.delete(
        "expense_reports/" + id,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async patchAttachaments({ id, formData }: { id: IDType; formData: FormData }) {
    try {
      const response: AxiosResponse<ExpenseReportTypes.Details> = await restApiService.formWithImage(
        "expense_reports/" + id,
        formData,
        "PATCH",
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async getStats() {
    try {
      const response: AxiosResponse<ExpenseReportTypes.statusCountResponse> =
        await restApiService.get("expense_reports/stats_count");
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async getUnsubmittedExpensesCount() {
    try {
      const response: AxiosResponse<expensesCountType> = await restApiService.get("expense_reports/unsubmitted_count");
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static payNow = async ({ id, payload }: { id: IDType; payload: any }) => {
    try {
      const response: AxiosResponse<ExpenseReportTypes.statusCountResponse> = await restApiService.post(
        "expense_reports/" + id + "/pay_now",

        null,

        payload,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static submitExpenseReport = async (id: IDType) => {
    try {
      const response: AxiosResponse<ExpenseReportTypes.Details> = await restApiService.post(
        "expense_reports/" + id + "/submit",
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  static bulkPayApproval = async (payload: { expense_reports: payReimbursementType.bulkInvoice[] }) => {
    try {
      const response: AxiosResponse<payReimbursementType.bulkReceiptType[]> = await restApiService.post(
        "expense_reports" + "/batch_pay_now",

        null,

        payload,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static createPaymentRun = async (payload: { expenses: payReimbursementType.bulkInvoice[] }) => {
    try {
      const response: AxiosResponse<payReimbursementType.bulkReceiptType[]> = await restApiService.post(
        "expense_reports" + "/create_payment_run",
        null,
        payload,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static async getRejectedExpense() {
    try {
      const response: AxiosResponse<ExpenseReportTypes.RejectedExpenseMessageType> = await restApiService.get(
        "expense_reports/rejected_expenses",
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async markRejectedExpenseMessageRead({ ids }: { ids: IDType[] }) {
    try {
      const response: AxiosResponse<ExpenseReportTypes.RejectedExpenseMessageType> = await restApiService.patch(
        "messages/mark_expense_rejected_message_as_read",
        null,
        { message_ids: ids },
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}

export default ExpenseReportApis;
