import { useQuery } from "@tanstack/react-query";
import { angularBaseURL } from "providers/restApi";
import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import InvoiceApis from "services/admin/invoices/invoiceApis";
import { fetchInvoiceDetails, getPoItemsAsync, getQueryParams, handleError } from "./invoiceDetailsService";
import { TAsset, TInvoiceDetails } from "./invoices.type";

type TInvoiceDetailsProviderProps = {
  invoiceId: number;
  goTo: string;
  portal: boolean;
  gridParams: any;
  children: React.ReactNode;
};

type TInvoiceDetailsContextType = {
  invoiceId: number | undefined;
  invoiceDetails: TInvoiceDetails | undefined;
  poItems: any[] | undefined;
  assets: TAsset[] | undefined;
  hasAssets: boolean;
  isSingleAsset: boolean;
  selectedAsset: TAsset | undefined;
  setSelectedAsset: Dispatch<SetStateAction<TAsset | undefined>>;
  isSelectedAssetPdf: boolean | undefined;
  isFetchingInvoiceDetails: boolean;
  prevInvoiceId: number | undefined;
  nextInvoiceId: number | undefined;
  goToUrl: string;
  refetchInvoiceDetails: () => void;
};

const InvoiceDetailsContext = createContext<TInvoiceDetailsContextType | undefined>(undefined);

const QUERY_DETAILS_KEY = "invoiceDetailsQuery";
const QUERY_NEXT_ID_KEY = "invoiceDetailsNextIdQuery";
const QUERY_PREV_ID_KEY = "invoiceDetailsPrevIdQuery";
const QUERY_PO_ITEMS_KEY = "invoiceGetPoItemsQuery";

export const InvoiceDetailsProvider: React.FC<TInvoiceDetailsProviderProps> = ({
  invoiceId,
  goTo,
  portal,
  gridParams,
  children,
}) => {
  const { t } = useTranslation();
  const [selectedAsset, setSelectedAsset] = useState<TAsset | undefined>(undefined);
  const queryParams = useMemo(() => getQueryParams(gridParams), [gridParams]);
  const getFileExtension = useCallback((fileName: string) => fileName.split(".").pop()?.toLowerCase(), []);
  const isSelectedAssetPdf = useMemo(
    () => selectedAsset && getFileExtension(selectedAsset.asset_file_file_name) === "pdf",
    [getFileExtension, selectedAsset],
  );
  const goToUrl = useMemo(() => {
    const baseUrl = portal ? origin : angularBaseURL;
    return baseUrl + "/" + goTo;
  }, [goTo, portal]);

  const { data: nextInvoiceId } = useQuery(
    [QUERY_NEXT_ID_KEY, invoiceId, queryParams],
    () => InvoiceApis.getNextInvoiceIdAsync(invoiceId.toString(), queryParams),
    {
      enabled: !!invoiceId,
    },
  );

  const { data: prevInvoiceId } = useQuery(
    [QUERY_PREV_ID_KEY, invoiceId, queryParams],
    () => InvoiceApis.getPrevInvoiceIdAsync(invoiceId.toString(), queryParams),
    {
      enabled: !!invoiceId,
    },
  );

  const {
    data: invoiceDetails,
    isFetching: isFetchingInvoiceDetails,
    error: detailsError,
    refetch: refetchInvoiceDetails,
  } = useQuery([QUERY_DETAILS_KEY, invoiceId], () => fetchInvoiceDetails(invoiceId), {
    enabled: !!invoiceId,
  });

  const { data: poItems } = useQuery({
    queryKey: [QUERY_PO_ITEMS_KEY, invoiceId],
    queryFn: ({ signal }) => getPoItemsAsync(invoiceId, signal),
    enabled: !!invoiceId && invoiceDetails?.invoice?.po_type !== "",
  });

  const assets = useMemo(() => {
    if (!invoiceDetails?.invoice) return undefined;
    return (invoiceDetails.invoice.assets || []).sort((a, b) => a.id - b.id);
  }, [invoiceDetails]);
  const hasAssets = useMemo(() => (invoiceDetails && assets && assets.length > 0) || false, [invoiceDetails, assets]);
  const isSingleAsset = useMemo(
    () => (invoiceDetails && assets && assets.length === 1) || false,
    [assets, invoiceDetails],
  );

  useEffect(() => {
    if (detailsError) {
      handleError(detailsError, t);
    }
  }, [detailsError, t]);

  useEffect(() => {
    if (!hasAssets) {
      setSelectedAsset(undefined);
    }
  }, [hasAssets]);

  const contextValue = useMemo(
    () => ({
      invoiceId,
      invoiceDetails,
      assets,
      hasAssets,
      isSingleAsset,
      selectedAsset,
      setSelectedAsset,
      isSelectedAssetPdf,
      prevInvoiceId,
      nextInvoiceId,
      goToUrl,
      refetchInvoiceDetails,
      isFetchingInvoiceDetails,
      poItems,
    }),
    [
      assets,
      goToUrl,
      hasAssets,
      isSingleAsset,
      invoiceDetails,
      invoiceId,
      isFetchingInvoiceDetails,
      isSelectedAssetPdf,
      nextInvoiceId,
      prevInvoiceId,
      refetchInvoiceDetails,
      selectedAsset,
      poItems,
    ],
  );

  return <InvoiceDetailsContext.Provider value={contextValue}>{children}</InvoiceDetailsContext.Provider>;
};

export const useInvoiceDetailsContext = (): TInvoiceDetailsContextType => {
  const context = useContext(InvoiceDetailsContext);
  if (!context) {
    throw new Error("useInvoiceDetailsContext must be used within an InvoiceDetailsProvider");
  }
  return context;
};
