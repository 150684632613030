import _ from "lodash";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "../../../../reducers";
import { getVendorDocumentLinks } from "../../../../reducers/vp/companyProfileReducer";
import DocumentApis from "../../../../services/common/documents/documentApis";
import documentService from "../../../../services/common/documents/documentSvc";
import { DocumentTypes } from "../../../../services/common/documents/documentTypes";
import VendorDocumentLinkApis from "../../../../services/common/documentVendorLinks/vendorDocumentLinkApis";
import vendorDocumentLinkService from "../../../../services/common/documentVendorLinks/vendorDocumentLinkSvc";
import { VendorDocumentLinkTypes } from "../../../../services/common/documentVendorLinks/vendorDocumentLinkTypes";
import { CreateNotification, NotificationType } from "../../../../services/general/notifications";
import { DocumentRequirementsType, PurchaserListType } from "../../../../services/vp/purchaser/purchaserTypes";
import VendorDocumentPreview from "../../../common/vendorDocumentPreview/vendorDocumentPreview";
import VendorDocumentUploadModal from "../../../common/vendorDocumentUploadModal/vendorDocumentUploadModal";
import useConfirmModal from "../../../modals/confirmModal/useConfirmModalHook";
import style from "./../companyProfile.module.css";

type DocumentRequiredmentPropType = {
  purchaser: PurchaserListType;
  documentRequirement: DocumentRequirementsType;
};
const DocumentRequirement = ({ purchaser, documentRequirement }: DocumentRequiredmentPropType) => {
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState<boolean>(false);

  const dispatch = useDispatch();
  const vendorDocumentLinks = useTypedSelector((state) => state.vpCompanyProfileReducer.vendorDocumentLinks);
  const company = useTypedSelector((state) => state.vpCompanyProfileReducer.company);
  const { createConfirmModal } = useConfirmModal();
  const openDocumentUploader = () => {
    setShowUploadModal(true);
  };

  const openPreviewModal = () => {
    setShowPreviewModal(true);
  };

  const closePreviewModal = () => {
    setShowPreviewModal(false);
  };

  // find a document link that has been linked to this requirement. Might be null if nothing has been linked yet
  const alreadyLinkedDocument = vendorDocumentLinks.find(
    (link) => link.document_requirement_id === documentRequirement?.id,
  );

  const confirmDeleteVendorDocumentLink = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    createConfirmModal({
      title: "Confirm",
      body: `You really want to delete Document Vendor Link ${alreadyLinkedDocument?.id} with file ${alreadyLinkedDocument?.document?.name}`,
      callBackData: alreadyLinkedDocument,
      cancelCallBack: null,
      saveCallBack: deleteVendorDocumentLink,
    });
  };

  const onCloseUploadModal = () => {
    setShowUploadModal(false);
  };

  const onClickVendorDocumentLink = () => {
    if (alreadyLinkedDocument) {
      openPreviewModal();
    } else {
      openDocumentUploader();
    }
  };

  const deleteVendorDocumentLink = async (vendorDocumentLink: VendorDocumentLinkTypes.ListItem) => {
    try {
      await VendorDocumentLinkApis.deleteVendorDocumentLink(vendorDocumentLink.id);

      const documentId = vendorDocumentLink.document_id;
      //also deleted linked document
      if (documentId) {
        await DocumentApis.delete(documentId);
      }

      // refresh link to update document bubbles
      dispatch(getVendorDocumentLinks());
    } catch (error) {}
  };

  const onSubmitDocumentUploadModal = async (data: {
    file?: File | undefined;
    w8PageData?: string | undefined;
    w8BenPageData?: string | undefined;
    w9PageData?: File | undefined;
    diversityFormData?: Record<string, any> | undefined;
    signReference?: string;
    signatureValue?: string;
  }) => {
    try {
      setIsSubmitting(true);
      if (company?.id) {
        let document: DocumentTypes.AddResponse | undefined = await documentService.onSubmitDocumentUploadModal({
          ...data,
          documentableId: company.id,
          documentableType: "Company",
        });

        if (document && document.id && purchaser?.vendor_detail?.id) {
          await vendorDocumentLinkService.linkDocumentWithVendorDocumentRequirement(
            {
              document,
              documentRequirement,
              vendorId: purchaser.vendor_detail.id,
            },
            "vendor_document_links",
          );

          await dispatch(getVendorDocumentLinks());
        }
        onCloseUploadModal();
        setIsSubmitting(false);
      }
    } catch (error) {
      let errorObj: any = error;
      if (_.isArray(errorObj.response?.data["assets.asset_file"])) {
        CreateNotification("Error", errorObj.response?.data["assets.asset_file"].toString(), NotificationType.danger);
      } else {
        CreateNotification("Error", "Document not uploaded", NotificationType.danger);
      }
      setIsSubmitting(false);
      onCloseUploadModal();
    }
  };

  return (
    <>
      {showPreviewModal && alreadyLinkedDocument && (
        <VendorDocumentPreview onHide={closePreviewModal} vendorDocumentLink={alreadyLinkedDocument} />
      )}

      {showUploadModal && (
        <VendorDocumentUploadModal
          showUploadModal={showUploadModal}
          closeUploadModal={onCloseUploadModal}
          documentRequirement={documentRequirement}
          submitCallback={onSubmitDocumentUploadModal}
          isSubmitting={isSubmitting}
          showTemplateSection
        />
      )}

      {/*Document Requirement Bubble  */}
      <div
        onClick={() => onClickVendorDocumentLink()}
        role="button"
        className={(alreadyLinkedDocument ? style.bubbleSuccess : style.bubbleInitial) + " d-flex align-items-center"}
      >
        {alreadyLinkedDocument && <i className="icon icon-approved m-0 mr-1"></i>}
        <span title={documentRequirement?.document_type} className={style.documentName}>
          {documentRequirement?.document_type}
        </span>
        {documentRequirement?.required && !alreadyLinkedDocument && <span className="text-danger">*</span>}
        {alreadyLinkedDocument && (
          <button onClick={(e) => confirmDeleteVendorDocumentLink(e)} className={"border-0 bg-transparent"}>
            <i className={style.icon8 + " icon-cross-centered"}></i>
          </button>
        )}
      </div>
    </>
  );
};

export default DocumentRequirement;
