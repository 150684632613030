import PickerErrorBlock from "components/common/pickers/pickerErrorBlock";
import moment from "moment";
import React, { useCallback } from "react";
import { Form, InputGroup } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Controller, useFormContext } from "react-hook-form";
import adminCommonSvc from "services/admin/commonSvc";
import {
  TCheckbox,
  TDatePicker,
  TInputField,
  TRenderField,
  TRenderSwitch,
  TSelect,
  TTextAreaField,
} from "services/hookFormTypes";
import styles from "./hookFormFields.module.css";

export const Mandatory = ({ required }: { required?: boolean }) => {
  return required ? <span style={{ color: "#dc3545" }}>*</span> : null;
};

const InputField = ({
  id,
  label,
  name,
  type,
  required,
  disabled,
  onChange,
  defaultValue,
  value,
  step = "any",
  readOnly,
  containerClassName,
  placeholder,
  maxLength,
  inputClassName,
  ...rest
}: TInputField) => {
  const {
    register,
    formState: { errors },
    getFieldState,
  } = useFormContext();

  const parsedError = getFieldState(name)?.error;

  return (
    <Form.Group className={containerClassName ?? ""}>
      {label && (
        <label className={styles.label}>
          {label}
          <Mandatory required={required} />
        </label>
      )}
      <input
        {...register(name, { required: required ? { value: true, message: "This field is required" } : undefined })}
        id={id}
        value={value}
        autoComplete="off"
        defaultValue={defaultValue}
        type={type || "text"}
        step={step}
        className={`${inputClassName} form-control ${parsedError ? "is-invalid" : ""}`}
        disabled={disabled}
        {...(onChange
          ? {
              onChange,
            }
          : {})}
        readOnly={readOnly}
        placeholder={placeholder}
        maxLength={maxLength}
      />
      {parsedError?.message && name && <PickerErrorBlock error={parsedError.message} />}
    </Form.Group>
  );
};

const Select = ({
  id,
  label,
  name,
  required,
  disabled,
  onChange,
  onBlur,
  options,
  defaultValue,
  selectClassName,
  ...rest
}: TSelect) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const error = errors && name && errors[name as keyof typeof errors]?.message;
  return (
    <Form.Group>
      <label className={styles.label}>
        {label}
        <Mandatory required={required} />
      </label>
      <select
        {...register(name, { required: required ? { value: true, message: "This field is required" } : undefined })}
        id={id}
        className={selectClassName ?? `form-control ${error ? "is-invalid" : ""}`}
        disabled={disabled}
        onChange={onChange}
        onBlur={onBlur}
        defaultValue={defaultValue}
      >
        {options?.map((option: { label: string; value: string }) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </Form.Group>
  );
};

const TextAreaField = ({ id, label, name, required, disabled, containerClassName, ...restProps }: TTextAreaField) => {
  const { control } = useFormContext();
  return (
    <Form.Group className={containerClassName ?? ""}>
      {label && (
        <label className={styles.label}>
          {label}
          <Mandatory required={required} />
        </label>
      )}
      <Controller
        control={control}
        name={name}
        rules={required ? { required: { value: true, message: "This field is required" } } : undefined}
        render={({ field: { value, onChange, ...otherProps }, fieldState: { error } }) => (
          <>
            <textarea
              {...otherProps}
              id={id}
              className={`form-control ${error ? "is-invalid" : ""} ${!label ? styles.fieldValue : ""}`}
              value={value || ""}
              rows={restProps.rows ? restProps.rows : 1}
              onChange={onChange}
              disabled={disabled}
            />
            {error?.message && <PickerErrorBlock error={error.message} />}
          </>
        )}
      />
    </Form.Group>
  );
};

const Checkbox = ({ id, label, name, onChange, ...rest }: TCheckbox) => {
  const { register } = useFormContext();
  return (
    <>
      <input
        {...register(name)}
        id={id}
        type="checkbox"
        {...(onChange ? { onChange } : {})}
        className={`form-control}`}
      />
      <label className={styles.inlineLabel}>{label}</label>
    </>
  );
};

const DatePickerField = ({ label, name, required, validate, validationFunction, ...props }: TDatePicker) => {
  const { control, trigger } = useFormContext();

  const getSelectDate = useCallback((date: string): Date | null => {
    if (!date) return null;
    return adminCommonSvc.getDateObjFromDateStr(date);
  }, []);

  return (
    <Form.Group>
      <div className="date-picker-field">
        <label className={styles.label}>
          {label}
          <Mandatory required={required} />
        </label>
        <Controller
          control={control}
          name={name}
          rules={{
            required: required ? { value: true, message: "This field is required" } : undefined,
            validate: validate ? validationFunction : undefined,
          }}
          render={({ field: { value, onChange, ...otherProps }, fieldState: { error } }) => (
            <>
              <ReactDatePicker
                className={`icon-calender`}
                selected={getSelectDate(value)}
                autoComplete="off"
                disabled={props.disabled}
                onChange={(date) => {
                  const formattedDate = date ? moment(date).format("YYYY-MM-DD") : null;
                  onChange(formattedDate); // Update the form state
                  if (props.onChange) {
                    props.onChange(formattedDate);
                  }
                  if (validate) {
                    trigger(name);
                  }
                }}
              >
                {/* <div>
              <Row>
                <Col className="d-flex justify-content-center align-items-center">
                  <div className={`mb-2`}>
                    <Button variant="secondary" className={styles.btnSmToday}>
                      Today
                    </Button>
                    <Button className={styles.btnSmClear}>Clear</Button>
                  </div>
                </Col>
              </Row>
            </div> */}
              </ReactDatePicker>
              {error && <PickerErrorBlock error={error.message || ""} />}
            </>
          )}
        />
      </div>
    </Form.Group>
  );
};

const RenderInputGroupField = ({
  name,
  inputGroupText,
  FieldClassName,
  labelClassName = "",
  min,
  max,
  type,
  label,
  tooltip,
  placeholder,
  autoComplete,
  required,
  defaultValue,
  disabled = false,
  id,
  shouldUnregister = true,
  rules,
  onChangeCallback,
}: TRenderField) => {
  const { control } = useFormContext();
  // This function, handleChangeInput, is responsible for handling user input in an input field.
  // It validates the input to ensure it is a number and clears the input field if it is not a number,
  // but only if both 'validateNumberOnly' and 'isError' props are set to true.
  const handleChangeInput = (e: any, onChange: any) => {
    let value = e.target.value;
    if (type === "number") {
      value = value === "" ? undefined : Number(value);
    }
    onChange(value);
    if (onChangeCallback) {
      onChangeCallback(value);
    }
  };

  return (
    <div>
      <Form.Group>
        {label && (
          <label className={styles.label}>
            {label}
            <Mandatory required={required} />
          </label>
        )}

        <Controller
          control={control}
          name={name}
          defaultValue=""
          shouldUnregister={shouldUnregister}
          rules={{ required: required, min: min, max: max }}
          render={({ field: { onChange, onBlur, value, ref }, fieldState: { invalid, isTouched, error } }) => (
            <>
              <InputGroup className="mb-3">
                <InputGroup.Text>{inputGroupText}</InputGroup.Text>

                <Form.Control
                  as="input"
                  ref={ref}
                  id={id}
                  className={FieldClassName}
                  type={type ?? "text"}
                  autoComplete={autoComplete}
                  placeholder={placeholder}
                  isInvalid={isTouched && invalid}
                  isValid={false}
                  value={value}
                  defaultValue={defaultValue}
                  disabled={disabled}
                  onChange={(value: any) => handleChangeInput(value, onChange)}
                  min={min}
                  max={max}
                />
              </InputGroup>
              {error && <PickerErrorBlock error={error.message || ""} />}
            </>
          )}
        />
      </Form.Group>
    </div>
  );
};

const RenderSwitch = ({
  id,
  name,
  className,
  tooltip,
  label,
  required,
  disabled,
  validate,
  validationFunction,
  callBack,
}: TRenderSwitch) => {
  const { control } = useFormContext();
  return (
    <Form.Group className={className ? className : ""}>
      <Controller
        control={control}
        name={name}
        rules={{
          required: required ? { value: true, message: "This field is required" } : undefined,
          validate: validate ? validationFunction : undefined,
        }}
        render={({ field: { onChange, onBlur, value, ref }, fieldState: { invalid, isTouched, error } }) => (
          <>
            <Form.Switch
              id={id ?? "defaultSwitchId"}
              type="switch"
              checked={value}
              onChange={(e) => {
                if (callBack) callBack(e.target.checked);
                onChange(e.target.checked);
              }}
              onBlur={onBlur}
              label={label ?? null}
              disabled={disabled}
            />
            {tooltip ?? ""}
            {error && <PickerErrorBlock error={error.message || ""} />}
          </>
        )}
      />
    </Form.Group>
  );
};

export { Checkbox, DatePickerField, InputField, RenderInputGroupField, RenderSwitch, Select, TextAreaField };
