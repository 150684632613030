import adminCommonSvc from "services/admin/commonSvc";
import { IUser } from "../user/userTypes";

class PaymentType {
  isCheck = (paymentType: string) => {
    return paymentType === "check";
  };

  isAch = (paymentType: string) => {
    return paymentType === "ach";
  };

  isCanEft = (paymentType: string) => {
    return paymentType === "can-eft";
  };

  isUkEft = (paymentType: string) => {
    return paymentType === "uk-eft";
  };

  isNzEft = (paymentType: string) => {
    return paymentType === "nz-eft";
  };

  isAusPayNet = (paymentType: string) => {
    return paymentType === "aus-pay-net";
  };

  isJanpanEft = (paymentType: string) => {
    return paymentType === "japan-eft";
  };

  isSgEFT = (paymentType: string) => {
    return paymentType === "sg-eft";
  };

  isTransfterwise = (paymentType: string) => {
    return paymentType === "international";
  };

  isPayoneer = (paymentType: string) => {
    return paymentType === "payoneer";
  };

  isExteranl = (paymentType: string) => {
    return paymentType === "external";
  };

  isWire = (paymentType: string) => {
    return paymentType === "wire";
  };

  isSwiftWire = (paymentType: string) => {
    return paymentType === "swift-wire";
  };

  isFedWire = (paymentType: string) => {
    return paymentType === "fed-wire";
  };

  isPayoneerBranched = (paymentType: string) => {
    return paymentType === "payoneer_branded";
  };

  isCambridge = (paymentType: string) => {
    return paymentType === "cambridge";
  };

  isCreditCard = (paymentType: string) => {
    return paymentType === "credit_card";
  };

  isSepa = (paymentType: string) => {
    return paymentType === "sepa";
  };

  getPaymentMethodLabel = (pmType: string, currentUser: IUser) => {
    switch (pmType) {
      case "check":
        return "Check";
      case "PrintCheck":
        return "Check";
      case "ach":
        return "ACH";
      case "credit_card":
        if (adminCommonSvc.isNvpOnlyCustomer(currentUser)) {
          return "Mastercard";
        } else {
          return "Virtual Card";
        }
      case "corpay_card":
        return "CorpayCard";
      default:
        return pmType;
    }
  };
}

const paymentTypeService = new PaymentType();
export default paymentTypeService;
