import _ from "lodash";
import { restApiService } from "providers/restApi";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useFormContext } from "react-hook-form";
import { useTypedSelector } from "reducers";
import { selectCurrentUser } from "reducers/userReducers";
import commonService from "services/common/commonSvc";
import { IDType } from "services/common/types/common.type";
import AbstractListPicker from "../abstractListPicker/index2";
import { AbstractListPickerTypes } from "./../abstractListPicker/abstractListPickerTypes";
import { TaxCodePickerTypes } from "./taxCodePickerTypes";

const TaxCodePicker = ({
  name,
  callBack,
  containerClassName,
  disabled,
  instanceId = "tax-code-picker",
  label,
  labelClassName,
  menuPosition = "fixed",
  menuPlacement = "auto",
  modelData, // in case of angular it's ngObject
  objectPropertyKey,
  parentObj, // in case of angular it's ngData
  placeholder,
  required,
  tooltip,
  validate,
  isUseTax,
  subsidiaryId,
}: TaxCodePickerTypes.TTaxCodePickerProps) => {
  const { getValues, setValue } = useFormContext();
  const currentUser = useTypedSelector(selectCurrentUser);
  const isSubsidiaryChangedRef = useRef(false);
  const oldSubsidiaryIdRef = useRef<IDType>();

  const { modelData: modelDataFieldName, parentObj: parentObjFieldName } = commonService.modifyParentModalDataRefStr({
    modelData,
    parentObj,
  });

  const getTaxIds = useCallback(
    (fieldName: string) => {
      if (!_.isNil(modelDataFieldName)) {
        const modelDataObj = getValues(modelDataFieldName);
        return modelDataObj?.custom_fields[fieldName] &&
          modelDataObj.custom_fields[fieldName]["value"] &&
          modelDataObj.custom_fields[fieldName]["value"]["mapping"] &&
          modelDataObj.custom_fields[fieldName]["value"]["mapping"]["items"]
          ? modelDataObj.custom_fields[fieldName]["value"]["mapping"]["items"]
          : null;
      }
      return null;
    },
    [getValues, modelDataFieldName],
  );

  const getModelDataParams = useCallback(
    (apiParams: Record<string, any>) => {
      if (!_.isNil(modelDataFieldName)) {
        const modelDataObj = getValues(modelDataFieldName);
        if (isUseTax) {
          apiParams = { TYPES: "USED_TAX", ACTIVE: "YES" };
          if (modelDataObj?.is_tax_map_to_location) {
            apiParams.location_id = modelDataObj.location_id;
          }
        }

        if (modelDataFieldName && currentUser.company.has_account_structures) {
          setValue(modelDataFieldName + "only_show_mapped_tax", true);
        }
      }
    },
    [currentUser.company.has_account_structures, getValues, isUseTax, modelDataFieldName, setValue],
  );

  const getParentObjParams = useCallback(
    (apiParams: Record<string, any>) => {
      if (!_.isNil(parentObjFieldName)) {
        const parentObjData = getValues(parentObjFieldName);
        if (currentUser.company.show_subsidiary_mapped_taxes && subsidiaryId && subsidiaryId > 0) {
          apiParams.subsidiary_id = subsidiaryId;
          if (parentObjData?.subsidiary?.country) {
            apiParams.country_code = parentObjData.subsidiary.country;
          }
        }

        if (parentObjData?.filter_data_field_name && parentObjData?.filter_data_to === "tax_id") {
          let taxIds = getTaxIds(parentObjData.filter_data_field_name);
          if (Array.isArray(taxIds) && taxIds.length > 0) {
            setValue(modelDataFieldName + "only_show_mapped_tax", true);
            apiParams.tax_ids = taxIds.toString();
          }
        }
      }
    },
    [
      currentUser.company.show_subsidiary_mapped_taxes,
      getTaxIds,
      getValues,
      modelDataFieldName,
      parentObjFieldName,
      setValue,
      subsidiaryId,
    ],
  );

  const params = useMemo(() => {
    let obj: Record<string, any> = { ACTIVE: "YES" };

    if (!_.isNil(modelDataFieldName)) {
      getModelDataParams(obj);
    }
    if (!_.isNil(parentObjFieldName)) {
      getParentObjParams(obj);
    }

    return obj;
  }, [getModelDataParams, getParentObjParams, modelDataFieldName, parentObjFieldName]);

  const handleSelection = useCallback(
    (selected: AbstractListPickerTypes.TPickerValue<TaxCodePickerTypes.TTaxCodeOption>) => {
      if (callBack) {
        callBack(selected);
      }
    },
    [callBack],
  );

  const setDefaultTax = useCallback((taxes: any) => {
    let tax = Array.isArray(taxes) ? taxes.filter((tax) => tax.is_default === true) : [];
    let defaultValue = null;
    if (tax.length > 0) {
      // scope.init_tax_obj(taxes[0]);
      // scope.set_value(taxes[0]);
      defaultValue = tax[0];
    }
    isSubsidiaryChangedRef.current = false;
    return defaultValue;
  }, []);

  //merge tax if selected tax got inactivated
  const mergeInactiveTaxes = useCallback(
    async (currentId, taxes) => {
      let inactiveOption = null;
      const modelDataObj = !_.isNil(modelDataFieldName) && getValues(modelDataFieldName);
      if (currentId > 0) {
        const tax = Array.isArray(taxes) ? taxes.filter((tax) => tax?.id === currentId) : [];
        if (tax.length === 0 && modelDataObj?.only_show_mapped_tax) {
          setValue(modelDataFieldName + "only_show_mapped_tax", true);
          inactiveOption = null;
        } else if (tax.length === 0) {
          try {
            const response = await restApiService.get("taxes/" + currentId);
            inactiveOption = response.data;
          } catch (error) {
            commonService.handleError(error);
          }
        } else {
          inactiveOption = tax[0];
        }
      } else if (
        Array.isArray(taxes) &&
        taxes.length >= 1 &&
        isSubsidiaryChangedRef.current &&
        _.isPlainObject(modelDataObj) &&
        !modelDataObj.id
      ) {
        // scope.set_default_tax();
        inactiveOption = setDefaultTax(taxes);
      }
      return { inactiveOption };
    },
    [getValues, modelDataFieldName, setDefaultTax, setValue],
  );

  const formatOptionLabel = useCallback((tax: TaxCodePickerTypes.TTaxCodeOption) => {
    return (
      <small>
        {tax.id && <strong>Code: </strong>}
        <span>{tax.code} </span>
        {tax.rate && tax.rate > 0 && (
          <span>
            <strong>Rate: </strong> {tax.rate}%
          </span>
        )}
      </small>
    );
  }, []);

  useEffect(() => {
    if (
      subsidiaryId &&
      subsidiaryId !== oldSubsidiaryIdRef.current &&
      currentUser.company.show_subsidiary_mapped_taxes
    ) {
      oldSubsidiaryIdRef.current = subsidiaryId;
      isSubsidiaryChangedRef.current = true;
    }
  }, [currentUser.company.show_subsidiary_mapped_taxes, subsidiaryId]);

  return (
    <AbstractListPicker<TaxCodePickerTypes.TTaxCodeOption>
      name={name}
      fetchUrl="taxes.lk"
      params={params}
      instanceId={instanceId}
      required={required}
      objectPropertyKey={objectPropertyKey ?? (!_.isNil(modelData) ? modelData + "tax_code" : "")}
      mergeInactive={mergeInactiveTaxes}
      callBack={handleSelection}
      containerClassName={containerClassName}
      disabled={disabled}
      label={label}
      formatOptionLabel={formatOptionLabel}
      menuPlacement={menuPlacement}
      menuPosition={menuPosition}
      tooltip={tooltip}
      validate={validate}
      placeholder={placeholder ?? "Select..."}
      labelClassName={labelClassName}
    />
  );
};

export default TaxCodePicker;
