import styles from "./buttons.module.css";

const TextButton = ({ className = "", onClick, children, disabled = false }) => {
  return (
    <button disabled onClick={() => onClick()} className={`${styles.textButton} ${className}`}>
      {children}
    </button>
  );
};

export default TextButton;
