import React from "react";
import { Col, Row, Toast } from "react-bootstrap";
import { ExpenseReportTypes } from "services/admin/expenses/expenseReport/expenseReportType";
import styles from "./expenseItem.module.css";

type RejectionWarningProps = {
  onToggle: () => void;
  expenseWarningData?: ExpenseReportTypes.RejectedExpenseMessageType;
};

const ExpenseItemWarning = ({ onToggle, expenseWarningData }: RejectionWarningProps) => {
  return expenseWarningData ? (
    <Toast className={styles.banner}>
      <Row>
        <Col md="1" sm="2" className="d-flex justify-content-center">
          <i className="icon icon-system-warning m-auto" />
        </Col>
        <Col md="10" sm="8" className="text-center">
          {expenseWarningData.rejected_expense_items_count} expense item(s) were rejected from{" "}
          {expenseWarningData.expense_report_numbers?.join(",")} and returned to your queue in NEW status. Review and
          update expenses before resubmitting for approval.
        </Col>
        <Col sm="1">
          <button className={styles.closeBtn} onClick={onToggle}>
            <i className="icon icon-cross" />
          </button>
        </Col>
      </Row>
    </Toast>
  ) : null;
};

export default ExpenseItemWarning;
