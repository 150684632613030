import { TAsset } from "components/admin/invoices/invoiceDetails/invoices.type";
import React, { memo, ReactNode, useCallback, useEffect, useMemo } from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  BsArrowLeftCircle,
  BsArrowRightCircle,
  BsDownload,
  BsFilesAlt,
  BsFiletypeCsv,
  BsFiletypeGif,
  BsFiletypeJpg,
  BsFiletypePdf,
  BsFiletypePng,
  BsFiletypePptx,
  BsFiletypeTxt,
  BsFiletypeXls,
  BsPrinter,
  BsTrash,
  BsUpload,
} from "react-icons/bs";
import { FaSpinner } from "react-icons/fa6";
import { format } from "services/general/dateSvc";
import styles from "./collapsibleViewerCard.module.css";
import { getFileExtension } from "./mimeUtils";

interface CollapsibleViewerCardProps {
  isSingleAsset?: boolean;
  assets?: TAsset[];
  selectedAsset?: TAsset;
  onFileThumbnailClick: (asset: TAsset) => void;
  onAttachFilesClick?: () => void;
  onDeleteFileClick?: () => void;
  onPrintFileClick?: () => void;
  onDownloadFileClick?: () => void;
  children: ReactNode;
  onToggleCard: () => void;
  isExpanded: boolean;
  isOperationRunning: boolean;
}

const CollapsibleViewerCard: React.FC<CollapsibleViewerCardProps> = ({
  assets,
  selectedAsset,
  onAttachFilesClick,
  onDeleteFileClick,
  onPrintFileClick,
  onDownloadFileClick,
  onFileThumbnailClick,
  isExpanded,
  children,
  onToggleCard,
  isOperationRunning,
}) => {
  const { t } = useTranslation();

  const getFileLabel = (asset: TAsset) => `Uploaded on ${format(asset.updated_at, null, "MMMM Do YYYY, h:mm:ss a")}`;

  const getFileIcon = (fileName: string) => {
    const fileExtension = getFileExtension(fileName)?.toLowerCase();
    switch (fileExtension) {
      case "pdf":
        return BsFiletypePdf;
      case "txt":
        return BsFiletypeTxt;
      case "csv":
        return BsFiletypeCsv;
      case "xls":
      case "xlsx":
        return BsFiletypeXls;
      case "ppt":
      case "pptx":
        return BsFiletypePptx;
      case "jpg":
      case "jpeg":
        return BsFiletypeJpg;
      case "png":
        return BsFiletypePng;
      case "gif":
        return BsFiletypeGif;
      default:
        return BsFilesAlt;
    }
  };

  const onFileThumbnailClickCollapsed = useCallback(
    (asset: TAsset) => {
      onToggleCard();
      onFileThumbnailClick(asset);
    },
    [onToggleCard, onFileThumbnailClick],
  );

  const fileIcons = useMemo(() => {
    if (!assets) return null;

    return assets.map((asset) => {
      const IconComponent = getFileIcon(asset.asset_file_file_name);
      const isSelected = selectedAsset?.asset_expiring_url === asset.asset_expiring_url;
      const buttonClass = isSelected ? `${styles.fileButton} ${styles.fileButtonSelected}` : styles.fileButton;
      const label = getFileLabel(asset);

      return (
        <button
          key={asset.id}
          className={buttonClass}
          onClick={() => (isExpanded ? onFileThumbnailClick(asset) : onFileThumbnailClickCollapsed(asset))}
          aria-label={asset.asset_file_file_name}
          role="listitem"
          title={label}
          type="button"
        >
          <IconComponent size={26} />
        </button>
      );
    });
  }, [assets, selectedAsset?.asset_expiring_url, isExpanded, onFileThumbnailClick, onFileThumbnailClickCollapsed]);

  const collapsibleToggleIcon = (
    <button
      className={styles.toggleButton}
      onClick={onToggleCard}
      aria-label={isExpanded ? "Collapse" : "Expand"}
      title={isExpanded ? "Collapse" : "Expand"}
      type="button"
    >
      {isExpanded ? <BsArrowLeftCircle size={24} /> : <BsArrowRightCircle size={24} />}
    </button>
  );

  useEffect(() => {
    if (!assets || assets.length === 0) return;
    for (const asset of assets) {
      const fileExtension = getFileExtension(asset.asset_file_file_name)?.toLowerCase();
      if (fileExtension === "pdf") {
        onFileThumbnailClick(asset);
        break;
      }
      onFileThumbnailClick(assets[0]);
    }
  }, [assets, onFileThumbnailClick]);

  return (
    <Card className={`${styles.card} ${isExpanded ? styles.expanded : styles.collapsed}`}>
      <Card.Header className={`p-0 border-0 ${styles.header}`}>
        {isOperationRunning && (
          <div className={styles.spinnerContainer}>
            <div className={styles.spinnerContainer}>
              <FaSpinner className={styles.spinner} />
            </div>
          </div>
        )}
        <div className={`${styles.filesSection}  ${isExpanded ? styles.expanded : styles.collapsed}`} role="list">
          {fileIcons}
        </div>
        <div className={styles.actionsSection}>
          {onAttachFilesClick && isExpanded && (
            <button
              className={styles.actionButton}
              onClick={onAttachFilesClick}
              aria-label="Attach File"
              title="Attach File"
              type="button"
            >
              <BsUpload size={24} />
            </button>
          )}
          {onDownloadFileClick && isExpanded && (
            <button
              className={styles.actionButton}
              onClick={onDownloadFileClick}
              aria-label="Download"
              title="Download"
              type="button"
              disabled={!selectedAsset}
            >
              <BsDownload size={24} />
            </button>
          )}
          {onPrintFileClick && isExpanded && (
            <button
              className={styles.actionButton}
              onClick={onPrintFileClick}
              aria-label="Print"
              title="Print"
              type="button"
              disabled={!selectedAsset}
            >
              <BsPrinter size={24} />
            </button>
          )}
          {onDeleteFileClick && isExpanded && (
            <button
              className={styles.actionButton}
              onClick={onDeleteFileClick}
              aria-label="Delete"
              title="Delete"
              type="button"
              disabled={!selectedAsset}
            >
              <BsTrash size={24} />
            </button>
          )}
        </div>
        <div className={styles.separator} />
        <div className={styles.toggleSection}>{collapsibleToggleIcon}</div>
      </Card.Header>
      {isExpanded && <Card.Body className={`p-0 border-0 ${styles.body}`}>{children}</Card.Body>}
    </Card>
  );
};

export default memo(CollapsibleViewerCard);
