import Approval from "components/approval/approval";
import usePermission from "components/common/hooks/usePermission";
import React from "react";
import { useFormContext } from "react-hook-form";
import { InvoiceType } from "services/admin/invoices/invoiceType";
import { useInvoiceDetailsContext } from "./invoiceDetailsContext";

export type TApprovalSectionProps = {
  saveAsyncCallback: (notify: boolean, skipWorkflowTriggerApi?: boolean) => Promise<void>;
};

const ApprovalSection: React.FC<TApprovalSectionProps> = ({ saveAsyncCallback }) => {
  const { hasUserPermission } = usePermission();
  const { refetchInvoiceDetails } = useInvoiceDetailsContext();
  const { getValues } = useFormContext<InvoiceType.InvoiceDetailType>();
  const invoice = getValues();

  return (
    <Approval
      endpoint="invoices"
      modalData={invoice}
      modelName="invoice"
      approvableType="Invoice"
      allowAccess={hasUserPermission("approvalWorkflowInvoices") || hasUserPermission("resetworkflowInvoices")}
      refreshApprovable={async () => {
        try {
          /* approval component callbacks (adding/deleting approvals) should not 
               start the triggerProactiveApprovals api call since the only thing being edited/saved
               on the invoice is new approval info, not invoice form info that a workflow trigger would
               care about */
          await saveAsyncCallback(false, true);
        } catch (error) {
          throw error;
        } finally {
          refetchInvoiceDetails();
        }
      }}
      isInvoiceInboxEdit={true}
    />
  );
};

export default ApprovalSection;
