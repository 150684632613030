import { ColDef, ColumnApi, GridApi, ICellRendererParams } from "ag-grid-community";
import AngularRedirect from "components/common/tabNavigator/angularRedirect";
import { sortBy } from "lodash";
import React from "react";
import { setAllowedFilters } from "services/common/gridService";
import { FieldFilterMapType } from "services/common/types/grid.type";
import { ReceiptsTypes } from "../../../services/admin/expenses/receipts/receiptsType";
import { VendoListType } from "../../../services/admin/vendors/vendorTypes";
import paymentTypeService from "../../../services/common/paymentMethod/paymentTypeSvc";
import { companyDateFormat } from "../../../services/general/dateSvc";
import { formattedAmount } from "../../../services/general/helpers";
import { translate } from "../../../services/general/translation";

export const FIELD_NAME_MAP: FieldFilterMapType = {
  po_not_required: { equals: "PO_NOT_REQUIRED" },
  email: { contains: "email" },
  vendor_id: {
    contains: "vendor_id_contains",
    equals: "vendor_id",
  },
  gross_po_balance: {
    inRange: "",
    lessThanOrEqual: "gross_po_balance_less_than",
    greaterThanOrEqual: "gross_po_balance_greater_than",
  },
  open_po_balance: {
    inRange: "",
    lessThanOrEqual: "open_po_balance_less_than",
    greaterThanOrEqual: "open_po_balance_greater_than",
  },
  accrual_balance: {
    inRange: "",
    lessThanOrEqual: "accrual_balance_less_than",
    greaterThanOrEqual: "accrual_balance_greater_than",
  },
  invoice_balance: {
    inRange: "",
    lessThanOrEqual: "invoice_balance_less_than",
    greaterThanOrEqual: "invoice_balance_greater_than",
  },
  version_seq: {
    inRange: "",
    lessThanOrEqual: "version_seq_less_than",
    greaterThanOrEqual: "version_seq_greater_than",
  },
  subsidiaries_str: {
    // 'subsidiaries_str' does not exist as a back-end filter param, so we need to place default
    contains: "subsidiary_name",
    blank: "subsidiary_has_data",
    notBlank: "subsidiary_has_data",
  },
  external_id: {
    contains: "external_id",
    blank: "external_id_has_data",
    notBlank: "external_id_has_data",
  },
  tax_id: {
    // we already have a back-end filter param 'tax_id', so no need to place a default attribute here
    both: "",
    blank: "tax_has_data",
    notBlank: "tax_has_data",
  },
  "payment_method.name": {
    contains: "payment_method",
    blank: "payment_method_has_data",
    notBlank: "payment_method_has_data",
  },
  "vendor_payment_method.name": {
    contains: "vendor_payment_method",
    blank: "vendor_payment_method_has_data",
    notBlank: "vendor_payment_method_has_data",
  },
  name: {
    contains: "name",
    blank: "name_has_data",
    notBlank: "name_has_data",
  },
  legal_name: {
    equals: "legal_name",
  },
  "requestor.name": {
    contains: "requestor_name",
    blank: "requestor_name_has_data",
    notBlank: "requestor_name_has_data",
  },
  "receiver.name": {
    contains: "receiver_name",
    blank: "receiver_name_has_data",
    notBlank: "receiver_name_has_data",
  },
  "term.code": {
    contains: "term_code",
    blank: "term_code_has_data",
    notBlank: "term_code_has_data",
  },
  classification_category: {
    contains: "classification_category",
    blank: "classification_category_has_data",
    notBlank: "classification_category_has_data",
  },
  contract_end_date: {
    inRange: "",
    lessThanOrEqual: "contract_end_date_before",
    greaterThanOrEqual: "contract_end_date_after",
  },
  created_at: {
    inRange: "",
    lessThanOrEqual: "created_at_before",
    greaterThanOrEqual: "created_at_after",
  },
  updated_at: {
    inRange: "",
    lessThanOrEqual: "updated_at_before",
    greaterThanOrEqual: "updated_at_after",
  },

  "source_document.id": { equals: "source_document" },
};

export const getVendorsHeaders = ({
  gridApi,
  currentUser,
  deleteVendor,
  gridColumnApi,
  checkBoxRef,
}: {
  gridApi?: GridApi;
  currentUser: any;
  deleteVendor: (vendor: VendoListType) => void;
  gridColumnApi?: ColumnApi;
  checkBoxRef: any;
}): { columnDefs: ColDef[]; defaultOrder: string[] } => {
  let columnDefs: ColDef[] = [
    {
      // headerName: "Select",
      field: "select",
      filter: false,
      headerCheckboxSelection: true,
      // headerComponent: () => {
      //   if (gridApi && gridColumnApi) {
      //     return <SelectAll gridApi={gridApi} columnApi={gridColumnApi} ref={checkBoxRef} />;
      //   } else {
      //     return null;
      //   }
      // },
      // headerValueGetter: function() {
      //   return "";
      // },
      resizable: false,
      checkboxSelection: true,
      minWidth: 35,
    },
    {
      field: "id",
      headerName: "ID",
      sortable: true,
      hide: true,
      cellRenderer: (params: { data: ReceiptsTypes.ListItem } & Omit<ICellRendererParams, "data">) => {
        return <span>{params.data?.id}</span>;
      },
      filter: "agTextColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return translate("ID");
      },
      minWidth: 100,
    },
    {
      field: "external_id",
      headerName: "External ID",
      sortable: true,
      filter: "agTextColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
      },
      hide: true,
      headerValueGetter: function () {
        return translate("externalId");
      },
      minWidth: 100,
    },
    {
      field: "vendor_id",
      headerName: "Vendor ID",
      sortable: true,
      filter: "agTextColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return translate("vendor_id");
      },
      minWidth: 100,
    },
    {
      field: "no_push",
      headerName: "Flagged Not To Push",
      cellRenderer: (params: any) => {
        return params.value ? "Yes" : "No";
      },
      filter: "agTextColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
      },
      hide: true,
      floatingFilterComponent: "agCustomSelectFilter",
      floatingFilterComponentParams: {
        suppressFilterButton: true,
        options: [
          { label: "Yes", value: true },
          { label: "No", value: false },
        ],
      },
      headerValueGetter: function () {
        return translate("no_push");
      },
      minWidth: 200,
    },
    {
      field: "version_seq",
      headerName: "Version Seq",
      filter: "agNumberColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
      },
      hide: true,
      headerValueGetter: function () {
        return translate("version_seq");
      },
      minWidth: 120,
    },
    {
      field: "name",
      headerName: "Name",
      sortable: true,
      filter: "agTextColumnFilter",
      cellRenderer: (params: any) => {
        return <AngularRedirect to={`vendors/${params.data.id}`}>{params.value}</AngularRedirect>;
      },
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return translate("name");
      },
      minWidth: 180,
    },
    {
      field: "legal_name",
      headerName: "Legal Name",
      filter: "agTextColumnFilter",
      hide: true,
      cellRenderer: "randomChildCellRouting",
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return translate("legal_name");
      },
      minWidth: 180,
    },
    {
      field: "email",
      headerName: "Company Email",
      filter: "agTextColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return translate("company_email");
      },
      minWidth: 180,
    },
    {
      field: "requestor.name",
      headerName: "Account Manager",
      filter: "agTextColumnFilter",
      hide: true,
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return translate("requestor");
      },
      minWidth: 180,
    },
    {
      field: "paid_outside",
      headerName: "Paid Outside",
      filter: false,
      cellRenderer: (params: any) => {
        return <div className={`${params.value ? "icon icon-true" : "icon icon-false"}`}> </div>;
      },
      hide: true,
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return translate("paid_outside");
      },
      minWidth: 120,
    },
    {
      field: "receiver.name",
      headerName: "Receiver Manager",
      filter: "agTextColumnFilter",
      hide: true,
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return translate("receiverMgr");
      },
      minWidth: 120,
    },
    {
      field: "total_payment_paid",
      headerName: "Total Payment Paid",
      filter: false,
      cellRenderer: (params: any) => formattedAmount(params.data?.total_payment_paid, params.data?.currency_code),
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return translate("total_payment_paid");
      },
      minWidth: 150,
    },
    {
      field: "gross_po_balance",
      headerName: "Gross Po Balance",
      filter: "agNumberColumnFilter",
      cellRenderer: (params: any) => formattedAmount(params.data?.gross_po_balance, params.data?.currency_code),
      hide: true,
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return translate("grossPoBal");
      },
      minWidth: 150,
    },
    {
      field: "open_po_balance",
      headerName: "Open PO Balance",
      filter: "agNumberColumnFilter",
      cellRenderer: (params: any) => formattedAmount(params.data?.open_po_balance, params.data?.currency_code),
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return translate("openPoBal");
      },
      minWidth: 150,
    },
    {
      field: "accrual_balance",
      headerName: "Accrual",
      filter: "agNumberColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return translate("accrual_balance");
      },
      cellRenderer: (params: any) => formattedAmount(params.data?.accrual_balance, params.data?.currency_code),
      minWidth: 150,
    },
    {
      field: "invoice_balance",
      headerName: "Invoice",
      filter: "agNumberColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return translate("invoice_balance");
      },
      cellRenderer: (params: any) => formattedAmount(params.data?.invoice_balance, params.data?.currency_code),
      minWidth: 150,
    },
    {
      field: "status",
      headerName: "Status",
      filter: "agTextColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
      },
      floatingFilterComponent: "agCustomSelectFilter",
      floatingFilterComponentParams: {
        suppressFilterButton: true,
        options: [
          { label: "ACTIVE", value: "ACTIVE" },
          { label: "INACTIVE", value: "INACTIVE" },
          { label: "NEW", value: "NEW" },
          { label: "PENDING", value: "PENDING" },
          { label: "REJECTED", value: "REJECTED" },
        ],
      },
      headerValueGetter: function () {
        return translate("status");
      },
      minWidth: 80,
    },
    {
      field: "payoneer_status",
      headerName: "Payoneer Status",
      filter: false,
      hide: true,
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return translate("payoneer_status");
      },
      minWidth: 150,
    },
    {
      field: "payee_id",
      headerName: "Payee ID",
      filter: false,
      hide: true,
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return translate("payee_id");
      },
      minWidth: 100,
    },
    {
      field: "payoneer_program",
      headerName: "Payoneer Program",
      filter: false,
      hide: true,
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return translate("payoneer_program");
      },
      minWidth: 150,
    },
    {
      field: "is_system_error",
      headerName: "System Notes",
      filter: false,
      hide: true,
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return translate("system_notes");
      },
      minWidth: 150,
    },
    {
      field: "vendor_type",
      headerName: "Vendor Type",
      cellRenderer: "randomChildCellRouting",
      filter: "agTextColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
      },
      floatingFilterComponent: "agCustomSelectFilter",
      floatingFilterComponentParams: {
        suppressFilterButton: true,
        options: [
          { label: "COMPANY", value: "COMPANY" },
          { label: "EMPLOYEE", value: "EMPLOYEE" },
          { label: "INTERNAL", value: "INTERNAL" },
        ],
      },
      headerValueGetter: function () {
        return translate("vendor_type");
      },
      minWidth: 120,
    },
    {
      field: "subsidiaries_str",
      headerName: "Subsidiaries",
      filter: "agTextColumnFilter",
      hide: true,
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return translate("subsidiaries");
      },
      minWidth: 120,
    },
    {
      field: "type_id",
      headerName: "Type ID",
      filter: "agTextColumnFilter",
      hide: true,
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return translate("type_id");
      },
      minWidth: 120,
    },
    {
      field: "contract_end_date",
      headerName: "contract_end_date",
      filter: "agDateColumnFilter",
      hide: true,
      filterParams: {
        suppressAndOrCondition: true,
        browserDatePicker: true,
      },
      headerValueGetter: function () {
        return translate("contract_end_date");
      },
      minWidth: 150,
    },
    {
      field: "master_admin_contact.email",
      headerName: "Master Admin Email",
      filter: false,
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return translate("master_admin_contact_email");
      },
      minWidth: 150,
    },
    {
      field: "last_payment.payment_date",
      headerName: "last_payment_payment_date",
      filter: false,
      hide: true,
      cellRenderer: (params: any) => {
        return companyDateFormat(params.value, currentUser);
      },
      headerValueGetter: function () {
        return translate("last_payment_date");
      },
      minWidth: 150,
    },
    {
      field: "last_accrual_request.number",
      headerName: "LastAccrualRequest",
      filter: false,
      hide: true,
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return translate("last_accrual_request");
      },
      minWidth: 160,
    },
    {
      field: "last_invoice_request_sent.sent_date",
      headerName: "last_invoice_request_sent_date",
      filter: false,
      hide: true,
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return translate("last_invoice_request");
      },
      minWidth: 150,
    },
    {
      field: "term.code",
      headerName: "Terms",
      filter: "agTextColumnFilter",
      hide: true,
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return translate("terms");
      },
      minWidth: 120,
    },
    {
      field: "tax_id",
      headerName: "Tax ID",
      filter: "agTextColumnFilter",
      hide: true,
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return translate("vatId");
      },
      minWidth: 150,
    },
    {
      field: "has_w9",
      headerName: "Has W9",
      filter: "agTextColumnFilter",
      cellRenderer: (params: any) => {
        return params.value ? "Yes" : "No";
      },
      filterParams: {
        suppressAndOrCondition: true,
      },
      hide: true,
      floatingFilterComponent: "agCustomSelectFilter",
      floatingFilterComponentParams: {
        suppressFilterButton: true,
        options: [
          { label: "Yes", value: true },
          { label: "No", value: false },
        ],
      },
      headerValueGetter: function () {
        return translate("has_w9");
      },
      minWidth: 120,
    },
    {
      field: "is_1099_eligible",
      headerName: "is_1099_eligible",
      filter: "agTextColumnFilter",
      cellRenderer: (params: any) => {
        return params.value ? "Yes" : "No";
      },
      filterParams: {
        suppressAndOrCondition: true,
      },
      hide: true,
      floatingFilterComponent: "agCustomSelectFilter",
      floatingFilterComponentParams: {
        suppressFilterButton: true,
        options: [
          { label: "Yes", value: true },
          { label: "No", value: false },
        ],
      },
      headerValueGetter: function () {
        return translate("is_1099_eligible");
      },
      minWidth: 150,
    },
    {
      field: "po_not_required",
      headerName: "po_not_required",
      filter: "agTextColumnFilter",
      cellRenderer: (params: any) => {
        return <div className={`${params.value ? "icon icon-true" : "icon icon-false"}`}> </div>;
      },
      filterParams: {
        suppressAndOrCondition: true,
      },
      hide: true,
      floatingFilterComponent: "agCustomSelectFilter",
      floatingFilterComponentParams: {
        suppressFilterButton: true,
        options: [
          { label: "Yes", value: true },
          { label: "No", value: false },
        ],
      },
      headerValueGetter: function () {
        return translate("po_not_required");
      },
      minWidth: 150,
    },
    {
      field: "vendor_payment_method.name",
      headerName: "Vendor Entered Payment Method",
      filter: "agTextColumnFilter",
      hide: true,
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return translate("vendor_entered_payment_method");
      },
      minWidth: 220,
    },
    {
      field: "created_by.email",
      headerName: "created_by",
      filter: false,
      hide: true,
      headerValueGetter: function () {
        return translate("created_by");
      },
      minWidth: 120,
    },
    {
      field: "created_at",
      headerName: "created_at",
      sortable: true,
      filter: "agDateColumnFilter",
      hide: true,
      filterParams: {
        suppressAndOrCondition: true,
      },
      cellRenderer: (params: any) => {
        return companyDateFormat(params.value, currentUser);
      },
      headerValueGetter: function () {
        return translate("created_at");
      },
      minWidth: 120,
    },
    {
      field: "updated_at",
      headerName: "updated_at",
      sortable: true,
      filter: "agDateColumnFilter",
      hide: true,
      filterParams: {
        suppressAndOrCondition: true,
      },
      cellRenderer: (params: any) => {
        return companyDateFormat(params.value, currentUser);
      },
      headerValueGetter: function () {
        return translate("updated_at");
      },
      minWidth: 120,
    },
    {
      field: "classification_category",
      headerName: "classification_category",
      filter: "agTextColumnFilter",
      hide: true,
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return translate("classification");
      },
      minWidth: 150,
    },
    {
      field: "payment_method.name",
      headerName: "Payment Method",
      filter: "agTextColumnFilter",
      hide: true,
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return translate("payment_method_name");
      },
      minWidth: 150,
    },
    {
      field: "payment_method.payment_type",
      headerName: "Payment Provider",
      cellRenderer: (params: any) =>
        params.data?.payment_method?.payment_type
          ? paymentTypeService.getPaymentMethodLabel(params.data?.payment_method?.payment_type, currentUser)
          : "",
      filter: false,
      hide: true,
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return translate("payment_provider");
      },
      minWidth: 150,
    },
    {
      field: "default_payment_method_type",
      headerName: "Payment Method Type",
      cellRenderer: (params: any) =>
        params.data?.default_payment_method_type
          ? paymentTypeService.getPaymentMethodLabel(params.data?.default_payment_method_type, currentUser)
          : "",
      filter: false,
      hide: true,
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return translate("payment_method_type");
      },
      minWidth: 180,
    },
    {
      field: "accrual_credit_account",
      headerName: "Accrual Credit Account",
      filter: false,
      hide: true,
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return translate("accrual_credit_account");
      },
      minWidth: 180,
    },
    {
      field: "accrual_debit_account",
      headerName: "Accrual Debit Account",
      filter: false,
      hide: true,
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return translate("accrual_debit_account");
      },
      minWidth: 180,
    },
    {
      field: "invoice_credit_account",
      headerName: "Invoice Credit Account",
      filter: false,
      hide: true,
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return translate("invoice_credit_account");
      },
      minWidth: 180,
    },
    {
      field: "invoice_debit_account",
      headerName: "Invoice Debit Account",
      filter: false,
      hide: true,
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return translate("invoice_debit_account");
      },
      minWidth: 180,
    },
    {
      field: "payment_credit_account",
      headerName: "Payment Credit Account",
      filter: false,
      hide: true,
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return translate("payment_credit_account");
      },
      minWidth: 180,
    },
    {
      field: "payment_debit_account",
      headerName: "Payment Debit Account",
      filter: false,
      hide: true,
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return translate("payment_debit_account");
      },
      minWidth: 180,
    },
    {
      field: "currency_codes_str",
      headerName: "Currency Codes",
      filter: false,
      hide: true,
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return translate("currency_code");
      },
      minWidth: 180,
    },
    {
      field: "has_profile_complete",
      headerName: "has_profile_complete",
      filter: false,
      cellRenderer: (params: any) => {
        return <div className={`${params.value ? "icon icon-true" : "icon icon-false"}`}> </div>;
      },
      hide: true,
      headerValueGetter: function () {
        return translate("has_profile_complete");
      },
      minWidth: 180,
    },
    {
      field: "final_approval_date",
      headerName: "Final Approval Date",
      hide: true,
      filter: false,
      headerValueGetter: function () {
        return translate("final_approval_date");
      },
      minWidth: 150,
    },
    {
      field: "source_document.id",
      headerName: "Source Document",
      filter: "agTextColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return translate("source_document");
      },
      minWidth: 150,
    },
    {
      field: "ZActions",
      cellRenderer: "childMessageRenderer",
      sortable: false,
      resizable: false,
      filter: false,
      cellRendererParams: {
        viewDetailsLink: "/ap/vendor/",
        viewAuthorization: { I: "read", a: "Vendors" },
        editVendorLink: "/ap/vendor/",
        editAuthorization: { I: "edit", a: "Vendors" },
        deleteCallback: deleteVendor,
        deleteAuthorization: { I: "delete", a: "Vendors" },
      },
      headerValueGetter: function () {
        return translate("actions");
      },
      minWidth: 120,
    },
  ];
  const obj: any = {};
  setAllowedFilters(columnDefs, FIELD_NAME_MAP);
  const defaultOrder = columnDefs.map((col: any) => col.field);
  return {
    columnDefs: sortBy(columnDefs, (col) => typeof col.headerValueGetter == "function" && col?.headerValueGetter(obj)),
    defaultOrder,
  };
};
