import { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { shallowEqual, useDispatch } from "react-redux";
import { useTypedSelector } from "reducers";
import { INTEGRATION_FORM_NAME } from "reducers/admin/integrationsReducer";
import { change, Field, getFormValues } from "redux-form";
import { WombatSelect } from "wombatifier/components/pickers/wombatSelect";
import { WombatApis } from "wombatifier/services/wombatApis";
import AuthenticationMethod from "../../components/integrationComponents/authenticationMethod";
import IntegrationSftp from "../../components/integrationComponents/integrationSftp";
const IntegrationFileOutbound = () => {
  const currentUser = useTypedSelector((state) => state.user, shallowEqual);
  const settlementWombatSourceType = useTypedSelector((state) => {
    const integrationState = getFormValues(INTEGRATION_FORM_NAME)(state);
    return integrationState?.form?.wombat_settings?.invoice_payment_link?.settlement_source_type;
  });

  const wombatSettings = useTypedSelector((state) => {
    const integrationState = getFormValues(INTEGRATION_FORM_NAME)(state);
    return integrationState?.form?.wombat_settings || [];
  }, shallowEqual);

  const dispatch = useDispatch();
  const handleDefaultSettlement = useCallback(() => {
    dispatch(
      change(
        INTEGRATION_FORM_NAME,
        "form.wombat_settings.invoice_payment_link.settlement_source_type",
        "standard_invoice_payment_link_standard_settlement",
      ),
    );
  }, []);

  const [paymerangSettlementOptions, setPaymerangSettlementOptions] = useState([]);
  const getPaymerangSettlementWombats = useCallback(async () => {
    const wombats = await WombatApis.outbounds({
      filter: { group: "paymerang", wombat_type: "settlement", defaults: 1 },
      cache: true,
    });
    const options = wombats.map((w) => ({ label: w.name, value: w.source_type }));
    setPaymerangSettlementOptions(options);
  }, []);

  useEffect(() => {
    !settlementWombatSourceType && handleDefaultSettlement();
    getPaymerangSettlementWombats();
  }, []);

  return (
    <>
      <Col lg="12 mt-3">
        <h4>Outbound Settings</h4>
        <hr />
      </Col>

      {currentUser?.company?.is_paymerang && paymerangSettlementOptions.length ? (
        <Col lg="12" className="p-0">
          <Row className="m-0">
            <Col>
              <Field
                name="form.wombat_settings.invoice_payment_link.settlement_source_type"
                label="Settlement File Configuration"
                component={WombatSelect}
                options={paymerangSettlementOptions}
              />
            </Col>
          </Row>
          <br />
        </Col>
      ) : null}

      <Col lg="12" className="p-0">
        <Row className="m-0">
          <AuthenticationMethod />
        </Row>
        <Row className="m-0">
          <IntegrationSftp sftpSublabel={"upload daily white list checks to bank"} />
        </Row>
      </Col>
    </>
  );
};

export { IntegrationFileOutbound };
