import _ from "lodash";
import { restApiService } from "providers/restApi";
import React, { useCallback, useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import AbstractListPicker from "../abstractListPicker/index2";
import { InterCompanyPickerType } from "./interCompanyTypes";

const InterCompanyPicker = ({
  name,
  label,
  required,
  modelData,
  parentObj,
}: InterCompanyPickerType.TInterCompanyPickerProps) => {
  const { getValues, setValue } = useFormContext();

  const subsidiaryIdFieldName = useMemo((): string => {
    const field = "subsidiary_id";
    if (_.isNil(parentObj)) return "";
    return `${parentObj}.${field}`;
  }, [parentObj]);

  const accountIdFieldName = useMemo((): string => {
    const field = "account_id";
    if (_.isNil(modelData)) return "";
    return `${modelData}.${field}`;
  }, [modelData]);

  const [subsidiaryId, accountId] = useWatch({
    name: [subsidiaryIdFieldName, accountIdFieldName],
  });

  const mergeInactive = useCallback(
    async (currentId, interCompanies) => {
      let inactiveOption = null;
      if (currentId) {
        const interCompany = Array.isArray(interCompanies)
          ? interCompanies.filter((interCompany) => interCompany?.id === currentId)
          : [];
        const onlyShowMappedInterCompany = getValues(modelData + "only_show_mapped_inter_company");

        if (interCompany.length === 0 && onlyShowMappedInterCompany) {
          setValue(modelData + "only_show_mapped_inter_company", false);
          inactiveOption = null;
        } else if (interCompany.length === 0) {
          try {
            const response = await restApiService.get("inter_companies", currentId);
            inactiveOption = response.data;
          } catch (error) {
            console.log(error);
          }
        } else {
          inactiveOption = interCompany[0];
        }
      }
      return { inactiveOption };
    },
    [getValues, modelData, setValue],
  );

  const params = useMemo(() => {
    let apiParams: Record<string, any> = {};
    apiParams.status = "ACTIVE";
    if (subsidiaryId) {
      apiParams.subsidiary_id = subsidiaryId;
    }

    if (accountId) {
      apiParams.account_id = accountId;
    }

    return apiParams;
  }, [parentObj, modelData, subsidiaryId, accountId]);

  return (
    <>
      <AbstractListPicker<InterCompanyPickerType.TInterCompanyOption>
        name={name}
        label={label}
        fetchUrl="inter_companies.lk"
        params={params}
        required={required}
        objectPropertyKey={"inter_company"}
        mergeInactive={mergeInactive}
      />
    </>
  );
};

export default InterCompanyPicker;
