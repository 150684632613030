import Loader from "components/spinners/loader";
import moment from "moment";
import { PDFDocument } from "pdf-lib";
import React, { ChangeEvent, Fragment, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { pdfjs } from "react-pdf";
import { CreateNotification } from "services/general/notifications";
import Sign from "./sign";
import styles from "./w9.module.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const fieldMappingText: Record<string, any> = {
  name: "topmostSubform[0].Page1[0].f1_01[0]",
  businessName: "business_name",
  taxClass: "text_56efff",
  otherText: "otherInstruction",
  payee: "text_55dbrj",
  fatca: "text_54wxcz",
  address: "address",
  city: "addressCity",
  accountNos: "accountno",
  requestor: "requestor",
  ssn1: ["tax0", "tax1", "tax2"],
  ssn2: ["tax4", "tax5"],
  ssn3: ["tax6", "tax7", "tax8", "tax9"],
  ein1: ["e1", "e2"],
  ein2: ["e3", "e4", "e5", "e6", "e7", "e8", "e9"],
};

const fieldMappingCheckbox: Record<string, any> = {
  form0_1: "topmostSubform[0].Page1[0].Boxes3a-b_ReadOrder[0].c1_2[0]",
  form1_1: "topmostSubform[0].Page1[0].Boxes3a-b_ReadOrder[0].c1_1[3]",
  form2_1: "topmostSubform[0].Page1[0].Boxes3a-b_ReadOrder[0].c1_1[4]",
  form3_1: "topmostSubform[0].Page1[0].Boxes3a-b_ReadOrder[0].c1_1[0]",
  form4_1: "topmostSubform[0].Page1[0].Boxes3a-b_ReadOrder[0].c1_1[1]",
  form5_1: "topmostSubform[0].Page1[0].Boxes3a-b_ReadOrder[0].c1_1[6]",
  form6_1: "topmostSubform[0].Page1[0].Boxes3a-b_ReadOrder[0].c1_1[2]",
  form7_1: "topmostSubform[0].Page1[0].Boxes3a-b_ReadOrder[0].c1_1[5]",
};
const formCheckBox = [
  {
    id: "form0_1",
    name: "form0_1",
  },
  {
    id: "form1_1",
    name: "form1_1",
  },
  {
    id: "form2_1",
    name: "form2_1",
  },
  {
    id: "form3_1",
    name: "form3_1",
  },
  {
    id: "form4_1",
    name: "form4_1",
  },
  {
    id: "form5_1",
    name: "form5_1",
  },
  {
    id: "form6_1",
    name: "form6_1",
  },
  {
    id: "form7_1",
    name: "form7_1",
  },
];

const formTextFields = [
  { name: "name", id: "form22_1", type: "text", tabIndex: 1, className: "name" },
  { name: "businessName", id: "form21_1", type: "text", tabIndex: 2, className: "businessName" },
  { name: "accountNos", id: "form20_1", type: "text", tabIndex: 2, className: "accountNos" },
  { name: "address", id: "form18_1", type: "text", tabIndex: 15, className: "address" },
  { name: "city", id: "form17_1", type: "text", tabIndex: 14, className: "city" },
  { name: "otherText", id: "form16_1", type: "text", tabIndex: 11, className: "otherText" },
  { name: "ein2", id: "form15_1", type: "text", tabIndex: 22, className: "ein2", maxLength: 7 },
  { name: "ssn3", id: "form14_1", type: "text", tabIndex: 20, className: "ssn3", maxLength: 4 },
  { name: "ssn1", id: "form13_1", type: "text", tabIndex: 18, className: "ssn1", maxLength: 3 },
  { name: "fatca", id: "form12_1", type: "text", tabIndex: 13, className: "fatca" },
  { name: "ein1", id: "form11_1", type: "text", tabIndex: 13, className: "ein1", maxLength: 2 },
  { name: "ssn2", id: "form10_1", type: "text", tabIndex: 19, className: "ssn2", maxLength: 2 },
  { name: "payee", id: "form9_1", type: "text", tabIndex: 12, className: "payee" },
  { name: "taxClass", id: "form8_1", type: "text", tabIndex: 9, className: "taxClass", maxLength: 1 },
];
const useW9Form = () => {
  const [loaded, setLoaded] = useState<boolean>(false);
  const [company, setCompany] = useState<any>();
  const [pdfDoc, setPdfDoc] = useState<any>();
  const [signatureW9Form, setSignatureW9Form] = useState<{ sign_reference: string; signature_value: string }>({
    sign_reference: "",
    signature_value: "",
  });
  const [pdfUrl, setPdfUrl] = useState<string>();
  const [signPage, setSignPage] = useState(false);
  // checkbox
  const [formData, setFormData] = useState<any>({
    form1_1: "",
    form2_1: "",
    form3_1: false,
    form4_1: false,
    form5_1: false,
    form6_1: false,
    form7_1: false,
    form8_1: false,
    taxClass: "",
    payee: "",
    ssn2: "",
    ein1: "",
    fatca: "",
    ssn1: "",
    ssn3: "",
    ein2: "",
    otherText: "",
    city: "",
    address: "",
    requestor: "",
    accountNos: "",
    businessName: "",
    name: "",
    form23_1: "",
    certificateDate: moment(new Date()).format("MM/DD/YYYY"),
  });

  const fillPdfValues = () => {
    try {
      const form = pdfDoc.getForm();
      Object.keys(fieldMappingText).forEach((formDataKey) => {
        if (Array.isArray(fieldMappingText[formDataKey])) {
          if (formData[formDataKey] !== "" || formData[formDataKey] !== null) {
            if (formData[formDataKey].length == fieldMappingText[formDataKey].length) {
              fieldMappingText[formDataKey].forEach((arrKey: string, index: number) => {
                const field = form.getTextField(arrKey);
                field.enableReadOnly();
                field.setText(formData[formDataKey][index]);
              });
            }
          }
        } else {
          const field = form.getTextField(`${fieldMappingText[formDataKey]}`);
          field.enableReadOnly();
          field.setText(formData[formDataKey]);
        }
      });
      Object.keys(fieldMappingCheckbox).forEach((formDataKey) => {
        const checkboxField = form.getCheckBox(`${fieldMappingCheckbox[formDataKey]}`);
        checkboxField.enableReadOnly();
        if (formData[formDataKey]) {
          checkboxField.check();
        } else {
          checkboxField.uncheck();
        }
        checkboxField.defaultUpdateAppearances();
      });

      const signatureField = form.getTextField("sign");
      signatureField.setText(signatureW9Form.signature_value);
      signatureField.enableReadOnly();

      const signatureRefField = form.getTextField("signref");
      signatureRefField.setText(" SignRef: " + signatureW9Form.sign_reference);
      signatureRefField.enableReadOnly();
      form.flatten();
    } catch (error) {
      throw error;
    }
  };

  const getW9FormData = async () => {
    // Notification.error({title: "Error", message:'Select only one checkbox for tax classNameification.'});
    if (validateW9Form()) {
      return await getFile();
    }
  };

  const getW9Signature = () => {
    return signatureW9Form;
  };

  const validateW9Form = () => {
    if (formData.name === "" || formData.name === null) {
      CreateNotification("Error", "Name field is required.", "danger");
      return false;
    }

    // var inputs = document.getElementsByTagName("input");
    // var checkbox_count =0;
    //
    // forEach(inputs, function (val, key) {
    //     if (val.type.toUpperCase() === 'CHECKBOX' && val.checked){
    //         checkbox_count++;
    //     }
    //     console.log(checkbox_count)
    // });

    // var inputs = document.forms["vendor_w9form"]?.getElementsByTagName("input");
    // var checkbox_count = 0;
    // for (let item of inputs) {
    //   if (item.type.toUpperCase() === "CHECKBOX" && item.checked) {
    //     checkbox_count++;
    //   }
    // }

    // if (checkbox_count === 0 || checkbox_count > 1) {
    //   CreateNotification("Error", "Select only one checkbox for tax classNameification.", "danger");
    //   return false;
    // }

    if (formData.address === "" || formData.address === null) {
      CreateNotification("Error", "Address is required.", "danger");
      return false;
    }

    if (formData.city === "" || formData.city === null) {
      CreateNotification("Error", "City, state, zipcode is required.", "danger");
      return false;
    }

    if ((formData.ssn1 === "" || formData.ssn1 === null) && (formData.ein1 === "" || formData.ein1 === null)) {
      CreateNotification("Error", "TIN number is required.", "danger");
      return false;
    }

    if (formData.ein1 === "" || formData.ein1 === null) {
      if (formData.ssn1 === "" || formData.ssn1 === null || formData.ssn1.length !== 3) {
        CreateNotification("Error", "Social security number is required.(first 3 digit)", "danger");
        return false;
      }

      if (formData.ssn2 === "" || formData.ssn2 === null || formData.ssn2.length !== 2) {
        CreateNotification("Error", "Social security number is required.(4th & 5th)", "danger");
        return false;
      }

      if (formData.ssn3 === "" || formData.ssn3 === null || formData.ssn3.length !== 4) {
        CreateNotification("Error", "Social security number is required.(last 4 digit)", "danger");
        return false;
      }
    } else {
      if (formData.ein1 === "" || formData.ein1 === null || formData.ein1.length !== 2) {
        CreateNotification("Error", "Employer identification number is required.(first 2 digit)", "danger");
        return false;
      }

      if (formData.ein2 === "" || formData.ein2 === null || formData.ein2.length !== 7) {
        CreateNotification("Error", "Employer identification number is required.(last 7 digit)", "danger");
        return false;
      }
    }

    if (signatureW9Form?.sign_reference === "" || signatureW9Form?.sign_reference === null) {
      CreateNotification("Error", "Please add your signature.", "danger");
      return false;
    }
    return true;
  };

  const getFile = async () => {
    try {
      fillPdfValues();
      const pdfBytes = await pdfDoc.save();
      const pdfFile = new File([pdfBytes], `w9_${new Date().toLocaleString()}.pdf`, { type: "application/pdf" });
      return pdfFile;
    } catch (error) {
      console.log(error);
    }
  };

  const handleSignForm = (event: any) => {
    event.preventDefault();
    // setState({ signPage: true });
    setSignPage(true);
  };
  const submitSignForm = () => {
    setSignPage((prev) => !prev);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
    const { name, type, value } = e.target;
    const checked = type === "checkbox" && (e.target as HTMLInputElement).checked;
    setFormData((prev: any) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const getW9Pdf = async () => {
    try {
      const formPdfBytes = await fetch(process.env.PUBLIC_URL + "/fw9.pdf").then((res) => res.arrayBuffer());
      const pdfDoc = await PDFDocument.load(formPdfBytes);
      const form = pdfDoc.getForm();

      const dateField = form.getTextField("date");
      dateField.setText(formData.certificateDate);
      dateField.enableReadOnly();
      form.getTextField("signref").enableReadOnly();
      form.getTextField("sign").enableReadOnly();
      const pdfBytes = await pdfDoc.save();
      const blob = new Blob([pdfBytes], { type: "application/pdf" });
      const fileUrl = window.URL.createObjectURL(blob);
      setPdfDoc(pdfDoc);
      setPdfUrl(fileUrl);
      setLoaded(true);
    } catch (error) {}
  };

  useEffect(() => {
    getW9Pdf();
  }, []);

  const W9Form = (
    <Loader loaded={loaded}>
      {signPage && (
        <Modal dialogClassName="signatgure-modal" show={signPage}>
          <Sign
            label="W9 Document Signature"
            updateSignUpW9Form={(value: { sign_reference: string; signature_value: string }) =>
              setSignatureW9Form(value)
            }
            onHide={submitSignForm}
          />
        </Modal>
      )}

      {pdfUrl && (
        <div className={styles.pdfWrapper}>
          <div className={styles.w9PdfPreview}>
            {/* PDF Viewer */}
            <iframe title="w9_form" src={pdfUrl + "#toolbar=0&navpanes=0"} height="100%" width="100%"></iframe>
            <textarea
              className={`${styles.showInput} ${styles.requestor}`}
              tabIndex={16}
              id="form19_1"
              name="requestor"
              value={formData.requestor}
              onChange={handleChange}
            ></textarea>

            {formTextFields.map((field: any) => (
              <input
                key={field.id}
                type={field.type}
                name={field.name}
                id={field.id}
                tabIndex={field.tabIndex}
                maxLength={field.maxLength}
                className={`${styles.showInput} ${styles[field.className]}`}
                value={formData[field.name]}
                onChange={handleChange}
              />
            ))}
            {formCheckBox.map((field: any) => (
              <Fragment key={field.id}>
                <input
                  id={field.id}
                  name={field.name}
                  className={styles[field.name]}
                  type={"checkbox"}
                  checked={formData[field.name]}
                  onChange={handleChange}
                />
              </Fragment>
            ))}

            <div className={styles.signButton}>
              {!signatureW9Form?.sign_reference ? (
                <div>
                  <Button size="sm" value="Click Here To Sign" onClick={handleSignForm}>
                    Click Here To Sign
                  </Button>
                </div>
              ) : (
                <div>
                  <p className={styles.signRef}>SignRef:{signatureW9Form?.sign_reference}</p>
                  <p className={styles.sign}>{signatureW9Form?.signature_value}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </Loader>
  );

  return { W9Form, getW9FormData, getW9Signature };
};

export default useW9Form;
