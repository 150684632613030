import useGetValue from "components/admin/hooks/hookFormHooks/useGetValue";
import PurchaseOrderPicker from "components/admin/pickers/reactHookFormPickers/purchaseOrderPicker";
import { PurchaseOrderPickerType } from "components/admin/pickers/reactHookFormPickers/purchaseOrderPicker/purchaseOrderPickerTypes";
import AngularRedirect from "components/common/tabNavigator/angularRedirect";
import { Checkbox, RenderInputGroupField } from "components/forms/hookFormFields";
import TooltipRenderQues from "components/toolTip/tooltipRenderQues";
import _ from "lodash";
import { restApiService } from "providers/restApi";
import React, { Fragment, memo, useEffect, useRef } from "react";
import { Col, Row } from "react-bootstrap";
import { useFormContext, useWatch } from "react-hook-form";
import { useTypedSelector } from "reducers";
import adminCommonSvc from "services/admin/commonSvc";
import useCustomLabel from "services/admin/customLabels/useCustomLabel";
import invoiceCommonSvc from "services/admin/invoices/invoiceCommonSvc";
import { InvoiceType } from "services/admin/invoices/invoiceType";
import { useInvoiceCommonSvc } from "services/admin/invoices/useInvoiceCommonSvc";
import PurchaseOrdersApis from "services/admin/purchaseOrders/purchaseOrderApi";
import { PurchaseOrderType } from "services/admin/purchaseOrders/purchaseOrderType";
import { IDType } from "services/common/types/common.type";
import { IUser } from "services/common/user/userTypes";
import { isDefined } from "services/general/helpers";
import { CreateNotification, NotificationType } from "services/general/notifications";
import styles from "./managePurchaseOrder.module.css";

const PurchaseOrderLinks = ({ invoicePoLinks }: { invoicePoLinks: InvoiceType.PurchaseOrderLinksType[] }) => {
  const { getValues } = useFormContext();
  const currencyCodeSymbol = getValues("currency.symbol");
  return (
    <>
      {invoicePoLinks?.length > 0 &&
        invoicePoLinks.map(
          (po: InvoiceType.PurchaseOrderLinksType, index: number) =>
            po._destroy !== 1 && (
              <Row key={po.purchase_order_id} className="p-0 m-0">
                <Col md={"3"} className="p-0 m-0">
                  <div>
                    <AngularRedirect to={"purchase_orders/" + po.purchase_order.id}>
                      {po.purchase_order.number}
                    </AngularRedirect>
                  </div>
                </Col>
                <Col md={"8"} className="p-0 m-0">
                  <RenderInputGroupField
                    type="number"
                    inputGroupText={currencyCodeSymbol}
                    name={`invoice_purchase_order_links_attributes.${index}.amount`}
                    shouldUnregister={false}
                    placeholder="amount to deduct from PO..."
                  />
                </Col>
              </Row>
            ),
        )}
    </>
  );
};

const PurchaseOrdersLinksMatching = () => {
  const currentUser: IUser = useTypedSelector((state) => state.user);
  const { getValues } = useFormContext();
  const invoicePoLinks = useWatch({ name: "invoice_purchase_order_links_attributes" }) || [];

  const getDifferenceLinkAmount = (poLink: InvoiceType.PurchaseOrderLinksType) => {
    let difference_amount = 0;
    const purchaseOrderLinks = getValues("purchase_order_links");
    //since we are not changing purchase_order_links, we can use it as original value
    if (_.isArray(purchaseOrderLinks)) {
      let originalPoLink = purchaseOrderLinks.find((link) => link.id === poLink.id);
      if (originalPoLink) {
        difference_amount = originalPoLink.amount - poLink.amount;
      }
    }
    return difference_amount;
  };

  const calculateUpdatedAbsoluteNetAmount = (poLink: InvoiceType.PurchaseOrderLinksType) => {
    let amount = getDifferenceLinkAmount(poLink);
    return poLink.purchase_order.absolute_net + amount;
  };

  //to get variance percent
  const getVariancePercent = () => {
    let variance = currentUser.company.matching_variance;
    switch (variance) {
      case "zero_percent":
        return 0;
      case "five_percent":
        return 5;
      case "ten_percent":
        return 10;
      case "fifteen_percent":
        return 15;
      case "twenty_percent":
        return 20;
      case "twenty_five_percent":
        return 25;
      default:
        return 0;
    }
  };

  //calculate absolute amount with considering matching variance
  const absoluteAmountWithVariance = (absolute_net: number) => {
    let amount = absolute_net;
    let variancePercent = getVariancePercent();
    if (variancePercent > 0) {
      amount = amount + (amount / 100) * variancePercent;
    }
    return amount;
  };

  const showWarningMsg = (poLink: InvoiceType.PurchaseOrderLinksType) => {
    //return false if there is no po link or absolute_net amount is not available
    if (!poLink.purchase_order || !poLink.purchase_order.absolute_net) {
      return false;
    }
    poLink.amount = Number(poLink.amount);
    getDifferenceLinkAmount(poLink);

    let linkAmount = poLink.amount ? poLink.amount : poLink.purchase_order.open_balance;
    let absoluteNet = poLink.id ? calculateUpdatedAbsoluteNetAmount(poLink) : poLink.purchase_order.absolute_net;
    let absoluteNetAmount = absoluteAmountWithVariance(absoluteNet);

    if (poLink._destroy == 1) {
      return false;
    } else if (!poLink.id && poLink.purchase_order && absoluteNetAmount - linkAmount < 0) {
      return true;
    } else if (poLink.id && poLink.purchase_order && absoluteNetAmount < 0) {
      return true;
    }

    return false;
  };

  return (
    <Fragment>
      {currentUser.company.has_advance_two_way_matching && (
        <Row className="p-0 m-0">
          {invoicePoLinks &&
            invoicePoLinks.map((purchaseOrderLink: InvoiceType.PurchaseOrderLinksType) => (
              <Fragment key={purchaseOrderLink.purchase_order_id}>
                {purchaseOrderLink._destroy !== 1 ? (
                  <Col sm="4" className="p-0 m-0">
                    <a
                      className="link"
                      href={restApiService.angularBaseURL() + "purchase_orders/" + purchaseOrderLink.purchase_order.id}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {purchaseOrderLink.purchase_order.number}
                    </a>
                  </Col>
                ) : null}
              </Fragment>
            ))}
        </Row>
      )}
      {!currentUser.company.has_advance_two_way_matching && invoicePoLinks?.length > 0 && (
        <PurchaseOrderLinks invoicePoLinks={invoicePoLinks} />
      )}
      {_.isArray(invoicePoLinks) && invoicePoLinks?.length > 0 && (
        <Row className="p-0 m-0">
          <Col md="12" className="p-0 m-0">
            {invoicePoLinks.map((poLink) => (
              <Fragment key={poLink.purchase_order_id}>
                {showWarningMsg(poLink) && (
                  <span className={styles.poWarning + " separatedPoNumber"}>{poLink.purchase_order.number}</span>
                )}
              </Fragment>
            ))}
          </Col>
        </Row>
      )}
    </Fragment>
  );
};

interface ManagePurchaseOrderProps {
  hideMultipleVendorExpensesCorpCardCheckbox?: boolean;
}

const ManagePurchaseOrder: React.FC<ManagePurchaseOrderProps> = ({ hideMultipleVendorExpensesCorpCardCheckbox }) => {
  const { setValue, getValues } = useFormContext();
  const vendorId: IDType = useGetValue("vendor_id");
  const currentUser: IUser = useTypedSelector((state) => state.user);
  const oldVendor = useRef<IDType>();
  const invoiceCommonHookSvc = useInvoiceCommonSvc();
  const { getCustomLabel } = useCustomLabel();

  const calculateInvoiceTotalAmount = () => {
    const totalAmount = invoiceCommonHookSvc.getInvoiceTotalAmount();
    if (isDefined(totalAmount) && totalAmount >= 0) {
      setValue("amount", totalAmount); // Set the total amount to the form field
      invoiceCommonHookSvc.calculateDiscountAmt();
    }
    invoiceCommonHookSvc.updateCreditEntries();
  };

  const inheritPoToInvoice = (po: PurchaseOrderPickerType.TPurchaseOrderOptionType) => {
    if (po.id) {
      if (_.isPlainObject(po.subsidiary) && po.subsidiary) {
        setValue("subsidiary", po.subsidiary);
        setValue("subsidiary_id", po.subsidiary.id);
      }
      if (po.currency) {
        setValue("currency_code", po.currency.iso_code);
        setValue("currency", po.currency);
      }

      // if (po.vendor_location && po.vendor_location.vendor_id == getValues("vendor_id")) {
      //   setValue("vendor_location_id", po.vendor_location_id);
      // }
    }
  };

  const callPosBySubsidiary = (subsidiaryId: number | null) => {
    if (subsidiaryId && subsidiaryId > 0) {
      setValue(`subsidiary_id`, subsidiaryId);
    } else {
      setValue(`subsidiary_id`, "");
    }
  };

  const assignPoTermToInvoice = (po: PurchaseOrderType.DetailType) => {
    if (_.isPlainObject(po.term)) {
      setValue("term_id", po.term_id);
      setValue("term", po.term);
    }
  };

  const inheritPOHeaderToInvoiceHeader = (po: PurchaseOrderType.DetailType) => {
    assignPoTermToInvoice(po);
    invoiceCommonHookSvc.updateDueDate();
    if (_.isPlainObject(po)) {
      if (po.department_id) {
        setValue("department_id", po.department_id);
      }
      if (po.location_id) {
        setValue("location_id", po.location_id);
        //temp assign object so that it can show in picker
        setValue("location", po.location);
      }
    }
    // if(po.custom_fields){
    //     customFieldService.inherit_custom_field($scope.invoice, po.custom_fields);
    // }
    // if(po.metadata){
    //     customFieldService.inherit_metadata($scope.invoice, po);
    // }
  };

  const inheritPoItemQtyToInvoiceItemQty = (item: any) => {
    return currentUser.company.has_three_way_matching &&
      _.isPlainObject(currentUser.company.invoice) &&
      currentUser.company.invoice.inherit_only_received_lines
      ? item.receipt_item_qty - item.billed_item_qty
      : item.qty_balance;
  };

  const calculateBalance = (item: any, po: PurchaseOrderType.DetailType) => {
    item.qty = item.qty > 0 ? inheritPoItemQtyToInvoiceItemQty(item) : "";
    item.amount = invoiceCommonHookSvc.roundUpAmount(item.qty * item.unit_price, null);
    if (item.qty && po && invoiceCommonSvc.isActiveHeaderTax(po, currentUser)) {
      item.tax = null;
      item.tax_id = null;
      item.tax_code = undefined;
    } else if (item.qty != item.qty_balance) {
      if (item.qty > 0 && item.tax > 0) {
        item.tax = (item.tax / item.qty) * inheritPoItemQtyToInvoiceItemQty(item);
      }
    }
  };

  //inherits po header level item
  const inheritHeaderlevelItem = (val: any, po: any) => {
    if (currentUser.company.show_product_item_po_header_level && po.product_item_id) {
      val.product_item_id = po.product_item_id;
      val.product_name = po.product_item.display_name;
      val.product_item = po.product_item;
      val.account_id = po.product_item.account_id;
    }
  };

  const inheritPoLinesToInvoice = (response: PurchaseOrderType.DetailType, val: any) => {
    if (val.qty_balance && val.qty_balance > 0) {
      val.inherit_po_item_id = val.id;
      val.id = null;
      val.po_number = response.number;
      //   inheritPODepartmentToInvoiceItems(response, val);
      calculateBalance(val, response);
      // inheritHeaderlevelItem(val, response);
      //   if (!val.product_item) {
      //     val.product_item = { display_name: val.product_name };
      //     val.name = val.product_name;
      //     assignFreeTextItem(val);
      //   }
      //   if (val.account_id) {
      //     dispatch(change(formName, "submitWithAccounts", true));
      //   }
      let opObj = _.cloneDeep(val);
      opObj.description = opObj.memo;
      //   if (
      //     currentUser.company.amortization_scheduled &&
      //     currentUser.company.po_line_service_date_inherite_to_inv_amortization
      //   ) {
      //     opObj.amortization_start_date = val.service_start_date;
      //     opObj.amortization_end_date = val.service_end_date;
      //   }
      if (opObj && opObj.metadata_links) {
        opObj.metadata_links = null;
      }
      if (
        opObj.product_item_id
        //  || (!opObj.product_item_id && !invoiceCommonSvc.disabledFreetextItem(currentUser))
      ) {
        opObj.do_not_set_default_unit = true;
        adminCommonSvc.removeIdFromBudgetItemLinks(opObj);
        const invoiceItems = getValues("invoice_items_attributes") || [];
        invoiceItems.push(opObj);
        setValue("invoice_items_attributes", invoiceItems);
      }
    }
  };

  const assignPoItemsToInvoice = (response: PurchaseOrderType.DetailType) => {
    if (response.po_items) {
      response.po_items.forEach((val, index) => {
        if (
          currentUser?.company?.has_three_way_matching &&
          currentUser.company?.invoice?.inherit_only_received_lines &&
          val.receipt_item_qty
        ) {
          inheritPoLinesToInvoice(response, val);
        } else if (
          !currentUser.company.has_three_way_matching ||
          (currentUser.company.has_three_way_matching && !_.isPlainObject(currentUser.company.invoice)) ||
          (currentUser.company.has_three_way_matching &&
            _.isPlainObject(currentUser.company.invoice) &&
            !currentUser.company.invoice.inherit_only_received_lines)
        ) {
          inheritPoLinesToInvoice(response, val);
        }
      });
    }
  };

  const assignPoItemsToInvoiceExpenses = (po: PurchaseOrderType.DetailType) => {
    if (_.isPlainObject(po) && _.isArray(po.po_items)) {
      let total = 0;
      po.po_items.forEach((val: any) => {
        if (val.id && !val.is_closed) {
          val.id = null;

          // if (currentUser.company.inherit_po_department_to_invoice_expense && po.department_id) {
          //   val.department_id = po.department_id;
          // }

          //TODO-SANKET -enbale rebate
          if (invoiceCommonSvc.enabledExpenseTaxOrRebate(currentUser)) {
            val.sub_amount = val.amount;
            let tax = val.tax || 0;
            let rebate = val.rebate_amount || 0;
            val.amount = val.sub_amount + tax + rebate;
          }
          val.product_item_id = undefined;

          total += val.amount ? val.amount : 0;
          adminCommonSvc.removeIdFromBudgetItemLinks(val);
          const debitEntries = getValues("debit_entries_attributes") || [];
          debitEntries.push(val);
          setValue("debit_entries_attributes", debitEntries);
        }
      });
      // if (!(invoiceCommonSvc.isItemExist(invoice?.invoice_items_attributes).length > 0) && total > 0) {
      //   dispatch(change(formName, "amount", (invoice?.amount ? invoice.amount : 0) + total));
      // }
    }
  };

  const assignToInvoiceDebitSection = (po: PurchaseOrderType.DetailType, val: any) => {
    val.account_link_id = val.id;
    val.id = null;

    // if (currentUser.company.inherit_po_department_to_invoice_expense && po.department_id) {
    //   val.department_id = po.department_id;
    // }

    if (val.tax && invoiceCommonSvc.isActiveHeaderTax(po, currentUser)) {
      val.tax = null;
      val.tax_id = null;
      val.tax_code = undefined;
      val.amount = val.sub_amount;
    }

    if (val && val.metadata_links) {
      val.metadata_links = null;
    }

    adminCommonSvc.removeIdFromBudgetItemLinks(val);
    const debitEntries = getValues("debit_entries_attributes") || [];
    debitEntries.push(val);
    setValue("debit_entries_attributes", debitEntries);
  };

  const assignPoExpenseToInvoice = (po: PurchaseOrderType.DetailType) => {
    if (_.isPlainObject(po) && _.isArray(po.invoice_debit_accounts)) {
      let total = 0;
      po.invoice_debit_accounts.forEach((val: any) => {
        if (
          val.id &&
          !val.is_matched &&
          !val.is_closed &&
          (!currentUser.company.has_three_way_matching ||
            (currentUser.company.has_three_way_matching && !_.isPlainObject(currentUser.company.invoice)) ||
            (currentUser.company.has_three_way_matching &&
              _.isPlainObject(currentUser.company.invoice) &&
              !currentUser.company.invoice.inherit_only_received_lines))
        ) {
          assignToInvoiceDebitSection(po, val);
          total += val.amount;
        } else if (
          currentUser.company.has_three_way_matching &&
          _.isPlainObject(currentUser.company.invoice) &&
          currentUser.company.invoice.inherit_only_received_lines &&
          val.is_expense_received &&
          !_.isPlainObject(val.account_entry)
        ) {
          assignToInvoiceDebitSection(po, val);
          total += val.amount;
        }
      });
      // if (!(invoiceCommonSvc.isItemExist(invoice?.invoice_items_attributes).length > 0) && total > 0) {
      //   dispatch(change(formName, "amount", (invoice?.amount ? invoice.amount : 0) + total));
      // }
    }
  };

  const assignPoLineToInvoiceLine = async (po: PurchaseOrderPickerType.TPurchaseOrderOptionType) => {
    if (po) {
      try {
        const response = await PurchaseOrdersApis.getPurchaseOrder(po.id);
        const invoice = getValues();
        if (!invoice.autoSubmit) {
          CreateNotification(
            "Warning",
            "The invoice amount may have changed. Please select Invoice Items tab to the unit price field to make any edits.",
            NotificationType.warning,
          );
        }
        const purchaseOrderLinks: InvoiceType.TPurchaseOrderLinksWithPoDetail = invoiceCommonSvc.assignAbsoluteNetToPo(
          invoice,
          response,
        );
        if (purchaseOrderLinks) {
          setValue(`invoice_purchase_order_links_attributes`, purchaseOrderLinks);
        }

        inheritPOHeaderToInvoiceHeader(response);
        if (currentUser.company.allow_invoice_items && currentUser.company.auto_pull_po_line_item) {
          assignPoItemsToInvoice(response);
        }

        if (currentUser.company.invoice && currentUser.company.invoice.auto_pull_po_items_to_expense_lines) {
          assignPoItemsToInvoiceExpenses(response); //to inherits po items to invoice expenses
        }

        if (currentUser.company?.invoice?.auto_pull_po_expenses_line) {
          assignPoExpenseToInvoice(response); //to inherits po expense to invoice
        }
        //TODO-SANKET - AUTO SUBMIT VENDOR
        // if ($scope.invoice.auto_submit) {
        //     $scope.load_vendor_data_before_auto_submit();
        // }
        calculateInvoiceTotalAmount();
      } catch (error) {}
    }
  };

  const changePo = (po: PurchaseOrderPickerType.TPurchaseOrderOptionType) => {
    assignPoLineToInvoiceLine(po);
  };

  const addPurchaseOrderLinks = (po: PurchaseOrderPickerType.TPurchaseOrderOptionType | null) => {
    if (po) {
      inheritPoToInvoice(po);
      changePo(po);
      callPosBySubsidiary(po.subsidiary_id);
      const requestorId = getValues("requestor_id");
      if (!requestorId && po.requestor_id) {
        setValue("requestor_id", po.requestor_id);
      }
    }
  };

  const removeInvoiceDebitAccount = (obj: any, type: string) => {
    const debitEntries = getValues("debit_entries_attributes");
    if (debitEntries && _.isArray(debitEntries)) {
      let isChanged = false;
      let changedDebitEntries = debitEntries.map((debit_entry) => {
        if (type == "account" && debit_entry.account_link_id && debit_entry.account_link_id == obj.id) {
          debit_entry._destroy = 1;
          isChanged = true;
        } else if (type == "item" && debit_entry.item_line_id && debit_entry.item_line_id == obj.id) {
          debit_entry._destroy = 1;
          isChanged = true;
        }
        return debit_entry;
      });
      if (isChanged) {
        setValue("debit_entries_attributes", changedDebitEntries);
      }
    }
  };

  const removeInvoiceItem = (poItem: PurchaseOrderType.PoItemType) => {
    const invoiceItems = getValues("invoice_items_attributes");
    if (_.isArray(invoiceItems)) {
      let isChanged = false;

      let changedInvoiceItem = invoiceItems.map((invoiceItem) => {
        if (invoiceItem.inherit_po_item_id == poItem.id) {
          isChanged = true;
          invoiceItem._destroy = 1;
          removeInvoiceDebitAccount(invoiceItem, "item");
        }
        return invoiceItem;
      });
      if (isChanged) {
        setValue("invoice_items_attributes", changedInvoiceItem);
      }
    }
  };

  const removePoInheritedLine = async (po: PurchaseOrderPickerType.TPurchaseOrderOptionType) => {
    if (po) {
      try {
        const response = await PurchaseOrdersApis.getPurchaseOrder(po.id);
        const invoiceItems = getValues("invoice_items_attributes");
        if (
          _.isPlainObject(response) &&
          _.isArray(response.po_items) &&
          response.po_items.length > 0 &&
          _.isArray(invoiceItems) &&
          invoiceItems.length > 0
        ) {
          response.po_items.forEach((poItem) => {
            removeInvoiceItem(poItem);
          });
        }
        if (
          _.isPlainObject(response) &&
          _.isArray(response.invoice_debit_accounts) &&
          response.invoice_debit_accounts.length > 0
        ) {
          response.invoice_debit_accounts.forEach((invoice_debit_account) => {
            removeInvoiceDebitAccount(invoice_debit_account, "account");
          });
        }
        calculateInvoiceTotalAmount();
      } catch (error) {
        console.log(error);
      }
    }
  };

  const updatePoByVendor = () => {
    const invoicePoLinks = getValues("invoice_purchase_order_links_attributes");
    if (_.isArray(invoicePoLinks) && invoicePoLinks.length > 0) {
      let isUpdated = false;
      let updatedPoLinks = invoicePoLinks.map((val) => {
        if (val.purchase_order.vendor_id != vendorId) {
          val._destroy = 1;
          removePoInheritedLine(val.purchase_order);
          isUpdated = true;
        }
        return val;
      });
      if (isUpdated) {
        setValue(`invoice_purchase_order_links_attributes`, updatedPoLinks);
      }
    }
  };

  useEffect(() => {
    if (oldVendor.current != vendorId && vendorId) {
      oldVendor.current = vendorId;
      updatePoByVendor();
    } else {
      oldVendor.current = vendorId;
    }
  }, [vendorId]);

  return (
    <Fragment>
      <Col md="12">
        {!hideMultipleVendorExpensesCorpCardCheckbox && (
          <Checkbox
            id="is_not_single_vendor_po"
            name="is_not_single_vendor_po"
            label="Multiple Vendor/ Expense/ Corp Card"
          />
        )}
      </Col>
      <PurchaseOrderPicker
        name="invoice_purchase_order_links_attributes"
        isMulti
        label={getCustomLabel("admin.pages.invoice.purchaseOrder")}
        placeholder="search/select po"
        instanceId="invoice-po-links"
        callBack={addPurchaseOrderLinks}
        deleteCallBack={removePoInheritedLine}
        modelName="invoice"
        tooltip={
          <TooltipRenderQues
            title={"Type in the first letter of the PO you would like to link to this invoice"}
            placement={"top"}
            className={styles.poTooltip}
          />
        }
      />
      <PurchaseOrdersLinksMatching />
    </Fragment>
  );
};

export default memo(ManagePurchaseOrder);
