import { stopEventPropagation } from "components/admin/invoices/invoiceDetails/invoiceDetailsService";
import React, { MouseEvent, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  BsArrowClockwise,
  BsArrowCounterclockwise,
  BsArrowsMove,
  BsChevronLeft,
  BsChevronRight,
  BsDash,
  BsPlus,
} from "react-icons/bs";
import styles from "./pdfToolbar.module.css";

const ZERO_XY_POINT = { x: 0, y: 0 };
const INCREASE_SIZE = 0.05;
const MAX_SCALE = 3;
const MIN_SCALE = 0.4;
const INITIAL_SIZE = 1;

type PdfToolbarProps = {
  collapseIcon: JSX.Element;
  scale: number;
  setScale: React.Dispatch<React.SetStateAction<number>>;
  setPageNumber: (pageNumber: number) => void;
  setDragStart: React.Dispatch<React.SetStateAction<any>>;
  setDragOffset: React.Dispatch<React.SetStateAction<any>>;
  setRotation: React.Dispatch<React.SetStateAction<number>>;
  pageNumber: number;
  numPages: number | undefined;
  pageActions?: JSX.Element | JSX.Element[];
};

const PdfToolbar: React.FC<PdfToolbarProps> = ({
  collapseIcon,
  scale,
  setScale,
  setPageNumber,
  setDragStart,
  setDragOffset,
  setRotation,
  pageNumber,
  numPages,
  pageActions,
}) => {
  const { t } = useTranslation();
  const [scaleInput, setScaleInput] = useState(Math.round(INITIAL_SIZE * 100).toString());
  const [isScaleInputInvalid, setIsScaleInputInvalid] = useState(false);

  const reset = useCallback(() => {
    setRotation(0);
    setScale(INITIAL_SIZE);
    setDragStart(ZERO_XY_POINT);
    setDragOffset(ZERO_XY_POINT);
  }, [setDragOffset, setDragStart, setRotation, setScale]);

  const goToPreviousPage = useCallback(
    (event: any) => {
      stopEventPropagation(event);
      if (!numPages) return;
      if (pageNumber <= 1) return;
      setPageNumber(pageNumber - 1);
    },
    [numPages, pageNumber, setPageNumber],
  );

  const goToNextPage = useCallback(
    (event: any) => {
      stopEventPropagation(event);
      if (!numPages) return;
      if (pageNumber >= numPages) return;
      setPageNumber(pageNumber + 1);
    },
    [numPages, pageNumber, setPageNumber],
  );

  const zoomIn = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      stopEventPropagation(event);
      setDragStart(ZERO_XY_POINT);
      setDragOffset(ZERO_XY_POINT);
      setScale((prevScale: number) => Math.min(prevScale + INCREASE_SIZE, MAX_SCALE));
    },
    [setDragOffset, setDragStart, setScale],
  );

  const zoomOut = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      stopEventPropagation(event);
      setDragStart(ZERO_XY_POINT);
      setDragOffset(ZERO_XY_POINT);
      setScale((prevScale) => Math.max(prevScale - INCREASE_SIZE, MIN_SCALE));
    },
    [setDragOffset, setDragStart, setScale],
  );

  const rotatePDF = useCallback(
    (event: MouseEvent<HTMLButtonElement>, offset: number) => {
      stopEventPropagation(event);
      setRotation((prevRotation) => (prevRotation + offset) % 360);
    },
    [setRotation],
  );

  const rotatePDFRight = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      rotatePDF(event, 90);
    },
    [rotatePDF],
  );

  const rotatePDFLeft = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      rotatePDF(event, -90);
    },
    [rotatePDF],
  );

  const fitWindow = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      stopEventPropagation(event);
      reset();
    },
    [reset],
  );

  const onScaleInputChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.replace(/%/g, "");
    if (/^\d*$/.test(value)) {
      setScaleInput(value);
      setIsScaleInputInvalid(false);
    }
  }, []);

  const onScaleInputBlur = useCallback(() => {
    if (scaleInput === "") {
      setIsScaleInputInvalid(true);
      return;
    }
    const inputValue = parseInt(scaleInput, 10);
    if (isNaN(inputValue)) {
      setIsScaleInputInvalid(true);
    } else {
      const newScale = inputValue / 100;
      if (newScale < MIN_SCALE || newScale > MAX_SCALE) {
        setIsScaleInputInvalid(true);
      } else {
        setIsScaleInputInvalid(false);
        setScale(newScale);
      }
    }
  }, [scaleInput, setScale]);

  const onScaleInputKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter") {
        event.preventDefault();
        onScaleInputBlur();
      }
    },
    [onScaleInputBlur],
  );

  useEffect(() => {
    setScaleInput(Math.round(scale * 100).toString());
    setIsScaleInputInvalid(false);
  }, [scale]);

  return (
    <div className={styles.toolbar}>
      <div className={styles.leftGroup}>
        {collapseIcon}
        <button
          type="button"
          className={styles.toolbarButton}
          onClick={goToPreviousPage}
          aria-label={t("components.common.filePreview.toolbar.previousPage")}
          title={t("components.common.filePreview.toolbar.previousPage")}
          disabled={pageNumber <= 1}
        >
          <BsChevronLeft size={20} />
        </button>
        <span className={styles.pagesDisplay}>
          {t("components.common.filePreview.toolbar.page")} {pageNumber} / {numPages}
        </span>
        <button
          type="button"
          className={styles.toolbarButton}
          onClick={goToNextPage}
          aria-label={t("components.common.filePreview.toolbar.nextPage")}
          title={t("components.common.filePreview.toolbar.nextPage")}
          disabled={!!numPages && pageNumber >= numPages}
        >
          <BsChevronRight size={20} />
        </button>
      </div>
      <div className={styles.centerGroup}>
        <button
          type="button"
          onClick={zoomOut}
          className={styles.toolbarButton}
          aria-label={t("components.common.filePreview.toolbar.zoomOut")}
          disabled={scale <= MIN_SCALE}
          title={t("components.common.filePreview.toolbar.zoomOut")}
        >
          <BsDash size={24} />
        </button>
        <div className={styles.scaleInputContainer}>
          <input
            type="text"
            className={`${styles.scaleDisplay} ${isScaleInputInvalid ? styles.invalidInput : ""}`}
            value={`${scaleInput}%`}
            onChange={onScaleInputChange}
            onBlur={onScaleInputBlur}
            onKeyDown={onScaleInputKeyDown}
          />
        </div>
        <button
          type="button"
          onClick={zoomIn}
          className={styles.toolbarButton}
          aria-label={t("components.common.filePreview.toolbar.zoomIn")}
          disabled={scale >= MAX_SCALE}
          title={t("components.common.filePreview.toolbar.zoomIn")}
        >
          <BsPlus size={24} />
        </button>
        <button
          type="button"
          className={styles.toolbarButton}
          onClick={fitWindow}
          aria-label={t("components.common.filePreview.toolbar.fitWindow")}
          title={t("components.common.filePreview.toolbar.fitWindow")}
        >
          <BsArrowsMove size={16} />
        </button>
        <span className={styles.separator}></span>
        <button
          type="button"
          className={styles.toolbarButton}
          onClick={rotatePDFLeft}
          title={t("components.common.filePreview.toolbar.rotateLeft")}
          aria-label={t("components.common.filePreview.toolbar.rotateLeft")}
        >
          <BsArrowCounterclockwise size={20} />
        </button>
        <button
          type="button"
          className={styles.toolbarButton}
          onClick={rotatePDFRight}
          title={t("components.common.filePreview.toolbar.rotateRight")}
          aria-label={t("components.common.filePreview.toolbar.rotateRight")}
        >
          <BsArrowClockwise size={20} />
        </button>
      </div>
      <div className={styles.rightGroup}>{pageActions}</div>
    </div>
  );
};

export default React.memo(PdfToolbar);
