import _ from "lodash";
import { useCallback } from "react";
import { useFormContext } from "react-hook-form";

export const useCategoryService = () => {
  const { setValue } = useFormContext();

  const mapCategoryAccount = useCallback(
    (category: any, accountObj: string) => {
      if (_.isPlainObject(category)) {
        setValue(accountObj + ".is_category_map_to_account", category.is_category_map_to_account);
        setValue(accountObj + ".only_show_mapped_account", true);
      }
    },
    [setValue],
  );

  return { mapCategoryAccount };
};
