import _ from "lodash";
import { restApiService } from "providers/restApi";
import React, { memo, useCallback, useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useTypedSelector } from "reducers";
import { selectCurrentUser } from "reducers/userReducers";
import commonService from "services/common/commonSvc";
import { IUser } from "services/common/user/userTypes";
import { AbstractListPickerTypes } from "../abstractListPicker/abstractListPickerTypes";
import AbstractListPicker from "../abstractListPicker/index2";
import { BusinessUnitPickerTypes } from "./businessUnitPickerType";

const BusinessUnitPicker = ({
  name,
  label,
  required,
  instanceId,
  callBack,
  parentObj,
  modelDataName,
  containerClassName,
  objectPropertyKey,
  disabled,
}: BusinessUnitPickerTypes.TBusinessUnitPickerProps) => {
  const { getValues, setValue } = useFormContext();
  const currentUser: IUser = useTypedSelector(selectCurrentUser);

  const modelData = !_.isNil(modelDataName) ? (modelDataName === "" ? "" : modelDataName) : null;
  const parentData = !_.isNil(parentObj) ? (parentObj === "" ? "" : parentObj) : null;

  const productItemIdFieldName = useMemo((): string => {
    const field = "product_item_id";
    if (_.isNil(modelData)) return "";
    return `${modelData}.${field}`;
  }, [modelData]);

  const accountIdFieldName = useMemo((): string => {
    const field = "account_id";
    if (_.isNil(modelData)) return "";
    return `${modelData}.${field}`;
  }, [modelData]);

  const subsidiaryIdFieldName = useMemo((): string => {
    const field = "subsidiary_id";
    if (_.isNil(parentData)) return "";
    return `${parentData}.${field}`;
  }, [parentData]);

  const [productItemId, subsidiaryId, accountId] = useWatch({
    name: [productItemIdFieldName, subsidiaryIdFieldName, accountIdFieldName],
  });

  const mergeInactive = useCallback(
    async (currentId, businessUnits) => {
      let inactiveOption = null;
      const onlyShowMappedBusinessUnit =
        !_.isNil(modelData) && getValues(`${modelData}.only_show_mapped_business_unit`);

      if (currentId > 0) {
        const businessUnit = Array.isArray(businessUnits)
          ? businessUnits.filter((businessUnit) => businessUnit?.id === currentId)
          : [];

        if (businessUnit.length === 0 && onlyShowMappedBusinessUnit) {
          setValue(`${modelData}.only_show_mapped_business_unit`, false);
          inactiveOption = null;
        } else if (businessUnit.length === 0) {
          try {
            const response = await restApiService.get("business_units/" + currentId);
            inactiveOption = response.data;
          } catch (error) {
            commonService.handleError(error);
          }
        } else {
          inactiveOption = businessUnit[0];
        }
        return { inactiveOption };
      }

      return { inactiveOption };
    },
    [getValues, modelData, setValue],
  );

  const isProductItemLinkToBusinessUnit = useMemo((): boolean => {
    if (_.isNil(modelData) || !productItemId) {
      return false;
    }

    const modelDataObj = getValues(modelData);
    // Ensure that the return value is always a boolean
    return !!modelDataObj?.is_product_item_link_to_business_unit;
  }, [getValues, modelData, productItemId]);

  const getModelDataParams = useCallback(
    (apiParams: Record<string, any>) => {
      if (isProductItemLinkToBusinessUnit) {
        apiParams.product_item_id = productItemId;
        setValue(`${modelData}.only_show_mapped_business_unit`, true);
      }

      if (currentUser.company.has_account_structures) {
        apiParams.account_id = accountId;
      }
    },
    [
      isProductItemLinkToBusinessUnit,
      currentUser.company.has_account_structures,
      accountId,
      productItemId,
      setValue,
      modelData,
    ],
  );

  const handlePickerSelection = (
    selected: AbstractListPickerTypes.TPickerValue<BusinessUnitPickerTypes.TBusinessUnitPickerOption>,
  ) => {
    if (callBack) {
      callBack(selected);
    }
  };

  const getParentObjParams = useCallback(
    (apiParams: Record<string, any>) => {
      if (currentUser.company.po_request_data_restrict_by_subsidiary && subsidiaryId) {
        apiParams.subsidiary_id = subsidiaryId;
      }
    },
    [currentUser.company.po_request_data_restrict_by_subsidiary, subsidiaryId],
  );

  const params = useMemo(() => {
    let apiParams: Record<string, any> = { status: "ACTIVE" };

    if (!_.isNil(parentData)) {
      getParentObjParams(apiParams);
    }

    if (!_.isNil(modelData)) {
      getModelDataParams(apiParams);
    }
    return apiParams;
  }, [getModelDataParams, getParentObjParams, modelData, parentData]);

  return (
    <AbstractListPicker<BusinessUnitPickerTypes.TBusinessUnitPickerOption>
      name={name}
      label={label}
      required={required}
      mergeInactive={mergeInactive}
      params={params}
      fetchUrl="business_units.lk"
      callBack={handlePickerSelection}
      objectPropertyKey={!_.isNil(modelData) ? `${modelData}.business_unit` : ""}
      instanceId={instanceId}
      disabled={disabled}
      containerClassName={containerClassName}
      placeholder="-- Select Business Unit --"
    />
  );
};
export default memo(BusinessUnitPicker);
