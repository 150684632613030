import {
  ColumnApi,
  GridApi,
  GridReadyEvent,
  IServerSideGetRowsParams,
  IServerSideSelectionState,
  PaginationChangedEvent,
  SelectionChangedEvent,
} from "ag-grid-community";
import ExportDataButton from "components/common/dataGrid/exportDataButton";
import ImportDataButton from "components/common/dataGrid/importDataButton";
import ServerSideDataGrid from "components/common/dataGrid/serverSideDataGrid/serverSideDataGrid";
import useShowFilterState from "components/common/hooks/useShowFilterState";
import ToggleFilterButton from "components/datagrid/buttons/toggleFilterButton";
import GridFilterDropdown from "components/datagrid/gridFilterDropdown";
import TooltipRender from "components/toolTip/tooltipRender";
import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Button, ButtonGroup, Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { Link } from "react-router-dom";
import { useTypedSelector } from "reducers";
import { updateCurrentUser } from "reducers/userReducers";
import ExpenseReportApis from "services/admin/expenses/expenseReport/expenseReportApis";
import { ExpenseReportTypes } from "services/admin/expenses/expenseReport/expenseReportType";
import ExpensesApis from "services/admin/expenses/expensesApis";

import { ExpenseConstants } from "services/admin/expenses/expenseSvc";
import { Can } from "services/authorization/authorization";
import { processFilterModel, processSortModel, saveDefaultOrder } from "services/common/gridService";
import { IDType } from "services/common/types/common.type";
import { IUser } from "services/common/user/userTypes";
import gridService from "services/grid/gridSvc";
import style from "../expenseItem/expenseItem.module.css";
import NavTabs from "../nav";
import AddToReport from "./addToReport";
import ExpenseItemWarning from "./expenseItemWarning";
import { FIELD_NAME_MAP, getExpenseItemHeaders } from "./listExpenseItemHeaders";
import QuickFilter from "./quickFilter";
const paginationOptions = [25, 50, 100];
const GRID_STORAGE_NAME = "ai.grid.listExpensesItems";

type RouteFilterType = {
  [key: string]: string | undefined;
  subsidiaryId?: string;
  expenseType?: string;
  subsidiaryName?: string;
  status?: string;
};

function ListExpenseItem() {
  const [gridApi, setGridApi] = useState<GridApi>();
  const [gridColumnApi, setGridColumnApi] = useState<ColumnApi>();
  const { showFilters, updateShowFilters } = useShowFilterState("listExpensesItemsFilter", true);
  const [encodedGridFilter, setEncodedGridFilter] = useState<string>();
  const currentUser: IUser = useTypedSelector((state) => state.user);
  const checkBoxRef: any = useRef(null);
  const { t } = useTranslation();
  const [filterBtn, setFilterBtn] = useState<string>();
  const [routeParam, setRouteParam] = useState<String | null>(null);
  const location = useLocation();
  const history = useHistory();
  const ref = useRef(filterBtn);
  const routeParamsRef = useRef(routeParam);
  const routeFilterRef = useRef<RouteFilterType>({});
  const [selectedRowsLength, setSelectedRowsLength] = useState<number | undefined>();
  const [expenseWarningData, setExpenseWarningData] = useState<ExpenseReportTypes.RejectedExpenseMessageType | null>(
    null,
  );
  const dispatch = useDispatch();

  const applyButtonFilter = (gridApi: GridApi) => {
    if (gridApi) {
      if (ref.current === "UNSUBMITTED") {
        notLinkedExpenseReport(gridApi);
      } else if (ref.current == "ALL" && routeParamsRef.current === "withMerchantName") {
        setRouteParam(null);
        merchantNameFilter(gridApi);
      } else if (ref.current === "ALL" && routeParamsRef.current === "withPolicyViolation") {
        setRouteParam(null);
        hasViolationFilter(gridApi);
      } else if (ref.current === "ALL") {
        gridApi?.setFilterModel(null);
      }
    }
  };

  const gridReady = useCallback((params: GridReadyEvent) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    applyButtonFilter(params.api);
    routeParamsRef.current = null;
    params.api.setGridOption("serverSideDatasource", { getRows });
  }, []);

  const notLinkedExpenseReport = (gridApi: GridApi) => {
    gridApi?.setFilterModel(null);
    if (gridApi) {
      const filterInstance = gridApi.getFilterInstance("expense_report.number");
      if (filterInstance) {
        filterInstance.setModel({
          filterType: "text",
          type: "blank",
        });
      }

      if (routeFilterRef?.current?.subsidiaryName) {
        const subsidiaryFilter = gridApi.getFilterInstance("subsidiary.name");
        if (subsidiaryFilter) {
          subsidiaryFilter.setModel(gridService.getContainTextFilterModal(routeFilterRef?.current?.subsidiaryName));
        }
      }
      if (routeFilterRef?.current?.expenseType) {
        const subsidiaryFilter = gridApi.getFilterInstance("reimbursable");
        if (subsidiaryFilter) {
          subsidiaryFilter.setModel(
            gridService.getContainTextFilterModal(
              routeFilterRef?.current?.expenseType === "all" ? "" : routeFilterRef?.current?.expenseType,
            ),
          );
        }
      }
      gridApi.onFilterChanged();
    }
  };

  const merchantNameFilter = (gridApi: GridApi) => {
    gridApi?.setFilterModel(null);
    if (gridApi) {
      if (routeFilterRef?.current?.dateAfter && routeFilterRef?.current?.dateBefore) {
        const dateFilter = gridApi.getFilterInstance("date");
        if (dateFilter) {
          dateFilter.setModel(
            gridService.getInRangeStringFilterModel(
              routeFilterRef.current.dateAfter,
              routeFilterRef.current.dateBefore,
            ),
          );
        }
      }
      if (routeFilterRef?.current?.subsidiaryName) {
        const subsidiaryFilter = gridApi.getFilterInstance("subsidiary.name");
        if (subsidiaryFilter) {
          subsidiaryFilter.setModel(gridService.getContainTextFilterModal(routeFilterRef?.current?.subsidiaryName));
        }
      }
      if (routeFilterRef?.current?.expenseType) {
        const expenseTypeFilter = gridApi.getFilterInstance("reimbursable");
        if (expenseTypeFilter) {
          expenseTypeFilter.setModel(
            gridService.getContainTextFilterModal(
              routeFilterRef?.current?.expenseType === "all" ? "" : routeFilterRef?.current?.expenseType,
            ),
          );
        }
      }
      if (routeFilterRef?.current?.merchantName) {
        const merchantNameFilter = gridApi.getFilterInstance("vendor_name");
        if (merchantNameFilter) {
          merchantNameFilter.setModel(
            routeFilterRef?.current?.merchantName === "No Merchant"
              ? gridService.getBlankTextFilterModel()
              : gridService.getEqualsTextFilterModal(routeFilterRef.current.merchantName),
          );
        }
      }
      if (routeFilterRef?.current?.dateAfter && routeFilterRef?.current?.dateBefore) {
        const dateFilter = gridApi.getFilterInstance("date");
        if (dateFilter) {
          dateFilter.setModel(
            gridService.getInRangeStringFilterModel(
              routeFilterRef.current.dateAfter,
              routeFilterRef.current.dateBefore,
            ),
          );
        }
      }
      gridApi.onFilterChanged();
    }
  };

  const hasViolationFilter = (gridApi: GridApi) => {
    gridApi?.setFilterModel(null);
    if (gridApi) {
      const filterInstance = gridApi.getFilterInstance("has_violations");
      if (filterInstance) {
        filterInstance.setModel({
          filterType: "text",
          type: "equals",
          filter: "true",
        });
      }
      if (routeFilterRef?.current?.subsidiaryName) {
        const subsidiaryFilter = gridApi.getFilterInstance("subsidiary.name");
        if (subsidiaryFilter) {
          subsidiaryFilter.setModel(gridService.getContainTextFilterModal(routeFilterRef?.current?.subsidiaryName));
        }
      }
      if (routeFilterRef?.current?.expenseType) {
        const reimbursableFilter = gridApi.getFilterInstance("reimbursable");
        if (reimbursableFilter) {
          reimbursableFilter.setModel(
            gridService.getContainTextFilterModal(
              routeFilterRef?.current?.expenseType === "all" ? "" : routeFilterRef?.current?.expenseType,
            ),
          );
        }
      }
      gridApi.onFilterChanged();
    }
  };

  const setParamsRoute = (routeFilter: string, filterObj: RouteFilterType) => {
    const { status, subsidiaryName, expenseType }: RouteFilterType = filterObj;

    if (subsidiaryName) {
      routeFilterRef.current.subsidiaryName = subsidiaryName;
    }

    if (expenseType) {
      routeFilterRef.current.expenseType = expenseType;
    }

    if (filterObj?.merchantName) {
      routeFilterRef.current.merchantName = filterObj.merchantName;
    }

    if (filterObj?.dateBefore) {
      routeFilterRef.current.dateBefore = filterObj.dateBefore;
    }

    if (filterObj?.dateAfter) {
      routeFilterRef.current.dateAfter = filterObj.dateAfter;
    }

    if (expenseType) {
      routeFilterRef.current.expenseType = expenseType;
    }
    if (routeFilter === "withPolicyViolation") {
      setRouteParam("withPolicyViolation");
      routeParamsRef.current = "withPolicyViolation";
      setFilterBtn((state) => "ALL");
      ref.current = "ALL";
    }
    if (status === "all") {
      setFilterBtn((state) => "ALL");
      ref.current = "ALL";
    }

    if (status === "unSubmitted") {
      setFilterBtn((state) => "UNSUBMITTED");
      ref.current = "UNSUBMITTED";
    }

    switch (filterObj?.filterId) {
      case "1":
        setRouteParam("withMerchantName");
        routeParamsRef.current = "withMerchantName";
        break;
      default:
        break;
    }

    history.replace({
      search: "",
    });
  };

  useEffect(() => {
    const searchParams: URLSearchParams = new URLSearchParams(location.search);
    let filterObj: RouteFilterType = {};
    searchParams.forEach((value, key) => {
      filterObj[key] = value;
    });
    let routeFilter = filterObj?.status;
    if (gridApi) {
      applyButtonFilter(gridApi);
      if (routeFilter) {
        setParamsRoute(routeFilter, filterObj);
      }
    }
  }, [gridApi, filterBtn]);

  const getParams = (params: IServerSideGetRowsParams) => {
    // sorting fields
    let expenseItemFieldMap = {
      "purchase_order.number": "purchase_order_number",
      "purchase_order.open_balance": "purchase_order_open_balance",
      "policy.name": "policy_name",
      currency_code: "currency_iso_code",
      "expense_report.number": "expense_report_number",
      "submitter.name": "submitter_name",
      "employee.name": "employee_name",
      "subsidiary.name": "subsidiary_name",
      category_names: "category_name",
    };

    let sortObject: any = {};
    if (params?.request?.sortModel?.length > 0) {
      sortObject = processSortModel(params.request.sortModel, expenseItemFieldMap);
    } else {
      sortObject = { id: { direction: "desc", priority: 0 }, number: { direction: "desc", priority: 1 } };
    }
    let paramsObj: any = {
      sort: sortObject,
    };
    if (params?.request?.endRow !== undefined && params?.request?.startRow !== undefined) {
      paramsObj.items = params?.request?.endRow - params?.request?.startRow; //grid will pass in the first index and last index that needs to be fetched
      paramsObj.page = 1 + params?.request?.startRow / params?.api?.paginationGetPageSize();
    }

    if (params?.request?.filterModel) {
      let filters: any = processFilterModel(params.request.filterModel, FIELD_NAME_MAP);

      if (filters && filters["statuses"]) {
        filters["statuses"] = filters["statuses"].split(",");
      }
      // Filters need to send with params we are passign to expense list API
      paramsObj = { ...paramsObj, ...filters };
    }
    return paramsObj;
  };

  // Everytime the grid needs new rows (such as first load or scrolling) this function will fire
  // We make the API call and then call the success function on the object the grid passed in
  const getRows = async (params: IServerSideGetRowsParams) => {
    const apiParams = getParams(params);
    localStorage.setItem(
      ExpenseConstants.LIST_EXPENSE_ITEM_SEARCH_API_FILTERS,
      encodeURIComponent(JSON.stringify(apiParams)),
    );
    try {
      const result = await ExpensesApis.getPaginatedExpenseList({ filter: apiParams });
      params.success({
        rowData: result.data,
        rowCount: result.meta.count,
      });
    } catch (error) {
      params.fail();
    }
  };

  const gridHeaders = useMemo(() => getExpenseItemHeaders({ gridApi, currentUser }), [currentUser, gridApi]);

  const getExpenseWarning = async () => {
    try {
      const response = await ExpenseReportApis.getRejectedExpense();
      if (response && !_.isEmpty(response)) {
        setExpenseWarningData(response);
        markRejectedExpenseMessageRead(response?.message_ids || []);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const markRejectedExpenseMessageRead = async (ids: IDType[]) => {
    try {
      if (_.isArray(ids) && ids.length > 0) {
        const response = await ExpenseReportApis.markRejectedExpenseMessageRead({ ids });
        if (response) {
          dispatch(updateCurrentUser());
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const defaultColDef = useMemo(() => {
    return { resizable: true, filter: true, floatingFilter: showFilters };
  }, [showFilters]);

  const isExpensesSelected = () => {
    return selectedRowsLength !== undefined && selectedRowsLength > 0;
  };

  useEffect(() => {
    saveDefaultOrder(GRID_STORAGE_NAME, gridHeaders.defaultOrder);
    getExpenseWarning();
  }, []);

  const onSelectionChanged = useCallback((e: SelectionChangedEvent<any>) => {
    const serverSideSelectionState = e.api.getServerSideSelectionState() as IServerSideSelectionState | null;
    setSelectedRowsLength(
      serverSideSelectionState?.selectAll && e.api.getDisplayedRowCount() > 0
        ? e.api.paginationGetPageSize()
        : serverSideSelectionState?.toggledNodes.length,
    );
  }, []);

  const onPaginationChange = useCallback((e: PaginationChangedEvent) => {
    // ref: https://ag-grid.com/react-data-grid/row-selection
    const serverSideSelectionState = e.api.getServerSideSelectionState() as IServerSideSelectionState | null;
    if (
      serverSideSelectionState?.selectAll ||
      (serverSideSelectionState?.toggledNodes?.length && serverSideSelectionState?.toggledNodes?.length > 0)
    ) {
      e.api.deselectAll();
    }
  }, []);

  return (
    <>
      <Container fluid>
        {expenseWarningData && (
          <ExpenseItemWarning expenseWarningData={expenseWarningData} onToggle={() => setExpenseWarningData(null)} />
        )}
        <Row className="mt-4">
          <Col>
            <NavTabs activePageName="Expenses" />
          </Col>
        </Row>
        <Row className="ml-1">
          <div className={"d-flex justify-content-start"}>
            <GridFilterDropdown
              gridApi={gridApi}
              gridColumnApi={gridColumnApi}
              gridStorageName={GRID_STORAGE_NAME}
              options={{
                month: { dateName: "date" },
              }}
              expenseButton={style.expenseButton}
              customFilter={<QuickFilter gridApi={gridApi} />}
            />
            <span className="ml-2">
              {isExpensesSelected() ? (
                <AddToReport gridApi={gridApi} isDisabled={!isExpensesSelected()} />
              ) : (
                <TooltipRender
                  placement="bottom"
                  containerClassName="addToReportTooltip"
                  title="Select one or more expense items to add to a new or existing expense report."
                >
                  <AddToReport gridApi={gridApi} isDisabled={!isExpensesSelected()} />
                </TooltipRender>
              )}
            </span>
            {/* need to make sure that this is disabled based on the new company setting for card customers */}
            {!currentUser.company.expense_item?.hide_create_expense_button && (
              <Can I="add" a="ExpenseItems" permissions={["addExpenseItems", "submitExpenseItems"]}>
                <Link to={"/ap/expense"}>
                  <Button variant="primary" className={`ml-2 ${(style.expenseButton, style.fortyPxHeight)}`}>
                    <i className="btn-icon icon-add-btn mt-1"></i>Create Expense Item
                  </Button>
                </Link>
              </Can>
            )}
          </div>
          <Col className="d-flex justify-content-center">
            <div className={style.tab}>
              <ButtonGroup className={style.expenseItemStatusBtn}>
                <Button
                  variant="secondary"
                  onClick={() => {
                    setFilterBtn("UNSUBMITTED");
                    ref.current = "UNSUBMITTED";
                  }}
                  className={`${style.btnDefault} ${filterBtn === "UNSUBMITTED" ? style.active : ""}`}
                >
                  Unsubmitted
                </Button>
                <Button
                  variant="secondary"
                  onClick={() => {
                    setFilterBtn("ALL");
                    ref.current = "ALL";
                  }}
                  className={`${style.btnDefault} ${filterBtn === "ALL" ? style.active : ""}`}
                >
                  All Expenses
                </Button>
              </ButtonGroup>
            </div>
          </Col>
          <Col className="d-flex justify-content-end">
            {gridApi && (
              <div className="ml-auto mt-1">
                <ExportDataButton
                  title={t("admin.pages.expenseItem.exportExpenses")}
                  csvName="expense_items"
                  gridApi={gridApi}
                />
              </div>
            )}
            <div>
              <ImportDataButton title={t("admin.pages.expenseItem.importExpenseItems")} />
            </div>
            <ToggleFilterButton
              classes="float-right"
              clickCallback={() => {
                updateShowFilters(!showFilters);
              }}
            />
          </Col>
        </Row>

        <Row className="px-3 mt-3" style={{ height: "68vh" }}>
          <ServerSideDataGrid
            columnDefs={gridHeaders.columnDefs}
            defaultColDef={defaultColDef}
            onSelectionChanged={onSelectionChanged}
            onPaginationChanged={onPaginationChange}
            gridReady={gridReady}
            rowSelection="multiple"
            gridStorageName={GRID_STORAGE_NAME}
            domLayout="normal"
            paginationSize={25}
            paginationOptions={paginationOptions}
            pagination
          />
        </Row>
      </Container>
    </>
  );
}

export default ListExpenseItem;
