import { AxiosResponse } from "axios";
import PickerErrorBlock from "components/common/pickers/pickerErrorBlock";
import { Mandatory } from "components/forms/bootstrapFields";
import _, { debounce } from "lodash";
import { restApiService } from "providers/restApi";
import React, { useCallback } from "react";
import { Form } from "react-bootstrap";
import { Controller, useFormContext } from "react-hook-form";
import { GroupBase, MultiValue, OptionsOrGroups } from "react-select";
import AsyncSelect from "react-select/async";
import { useTypedSelector } from "reducers";
import invoiceCommonSvc from "services/admin/invoices/invoiceCommonSvc";
import { IUser } from "services/common/user/userTypes";
import { companyDateFormat } from "services/general/dateSvc";
import { formattedAmount } from "services/general/helpers";
import { PurchaseOrderPickerType } from "./purchaseOrderPickerTypes";

const parseForSelect = (purchaseOrders: PurchaseOrderPickerType.TPurchaseOrderListType[]) => {
  return purchaseOrders?.map((purchaseOrder) => ({
    value: purchaseOrder.id,
    label: purchaseOrder.number,
    ...purchaseOrder,
  }));
};

const parsePoLink = (po: PurchaseOrderPickerType.TPurchaseOrderSelectedType) => {
  return {
    value: po.purchase_order_id,
    label: po?.purchase_order?.number,
    ...po.purchase_order,
  };
};

const parsePoLinksForSelect = (purchaseOrderLinks: PurchaseOrderPickerType.TPurchaseOrderSelectedType[]) => {
  return purchaseOrderLinks
    ? purchaseOrderLinks
        .filter(
          (purchaseOrderLink: PurchaseOrderPickerType.TPurchaseOrderSelectedType) => purchaseOrderLink._destroy != 1,
        )
        .map((purchaseOrderLink: PurchaseOrderPickerType.TPurchaseOrderSelectedType) => parsePoLink(purchaseOrderLink))
    : [];
};

// field values should have purchase_order_id to uniquely identify po
const MultiPicker = ({
  name,
  placeholder,
  instanceId,
  deleteCallBack,
  callBack,
  modelName = "",
  label,
  required,
  tooltip,
}: PurchaseOrderPickerType.TPurchaseOrderPickerMultiPropsType) => {
  const currentUser: IUser = useTypedSelector((state) => state.user);
  const { control, getValues } = useFormContext();

  const getPurchaseOrders = async (getParams: any) => {
    const result: AxiosResponse<PurchaseOrderPickerType.TPurchaseOrderListType[]> = await restApiService.get(
      "purchase_orders.lk",
      getParams,
      null,
      true,
      null,
      true,
    );
    let parseOptions = parseForSelect(result.data);
    return parseOptions;
  };

  const loadPurchaseOrders = useCallback(async (inputValue: string) => {
    let getParams: any = { by_open: true, record_limit: 50, number: inputValue };
    const [isNotSingleVendorPo, vendorId, subsidiaryId, currencyCode] = getValues([
      "is_not_single_vendor_po",
      "vendor_id",
      "subsidiary_id",
      "currency_code",
    ]);

    getParams.vendor = isNotSingleVendorPo ? -1 : vendorId;
    if (subsidiaryId) {
      getParams.subsidiary_id = subsidiaryId;
    }
    if (currencyCode && modelName == "invoice" && !invoiceCommonSvc.allowMultiCurrencyPO(currentUser)) {
      getParams.currency_code = currencyCode;
    }

    if (getParams.number) {
      return await getPurchaseOrders(getParams);
    } else {
      return [];
    }
  }, []);

  const loadOptions = useCallback(
    debounce((inputValue: string, callback: (options: OptionsOrGroups<any, GroupBase<any>>) => void) => {
      loadPurchaseOrders(inputValue).then(callback);
    }, 300), // 300ms debounce delay
    [],
  );

  const onChangePo = (
    selected: MultiValue<PurchaseOrderPickerType.TPurchaseOrderOptionType>,
    values: PurchaseOrderPickerType.TPurchaseOrderSelectedType[],
    onChange: any,
  ) => {
    //checks whether
    // 1. po already exist in current values
    // 2. po is removed from current values
    let changed = false;
    let removedPo;

    if (!_.isArray(selected)) {
      return;
    } else if (!_.isArray(values)) {
      onChange([]);
    }

    let changedPo = values.map((value) => {
      let found = selected.some((po) => po.id == value.purchase_order_id);

      //if po already exist just remove destroy
      if (found && value._destroy == 1) {
        changed = true;
        return { ...value, _destroy: false };
      }

      // If the value does not found in selected:
      // 1. It has already been removed from the purchase order.
      // 2. If it is already removed from the PO, leave the "changed" flag unchanged
      //    to avoid triggering the "removePo" functionality below.
      if (!found && value._destroy != 1) {
        changed = true;
        removedPo = parsePoLink(value);
        return { ...value, _destroy: 1 };
      }
      return value;
    });

    if (changed) {
      onChange(changedPo);
      if (removedPo && deleteCallBack) {
        deleteCallBack(removedPo);
      }
      return;
    }

    const added = selected.filter((po) =>
      _.isArray(values) && values.length > 0
        ? !values.some((currentSelectedPo) => currentSelectedPo.purchase_order_id == po.id)
        : true,
    );

    if (_.isArray(added) && added.length > 0) {
      let obj: any = {};
      obj.purchase_order_id = added[0].id;
      if (modelName === "invoice") {
        obj.invoice_id = getValues("id");
      }
      obj.purchase_order = added[0];
      values.push(obj);
      onChange(values);
      if (callBack) {
        callBack(added[0]);
      }
    }
  };

  return (
    <Form.Group>
      {label && (
        <label className="pickerLabel">
          {label}
          <Mandatory required={required} />
          {tooltip ?? ""}
        </label>
      )}
      <Controller
        name={name}
        control={control}
        defaultValue={[]}
        rules={{
          validate: {
            required: (value) => required && _.isEmpty(value) && "This field is required",
          },
        }}
        render={({ field: { onChange, value, ref }, fieldState }) => (
          <>
            <AsyncSelect
              ref={ref}
              value={parsePoLinksForSelect(value)}
              isMulti={true}
              isClearable={false}
              loadOptions={loadOptions}
              formatOptionLabel={(purchaseOrder, context) =>
                context.context == "menu" ? (
                  <small className="selectChoices">
                    <strong>
                      {purchaseOrder.number} - {purchaseOrder.vendor_name || "(Type: " + purchaseOrder.po_type + ")"}
                    </strong>
                    <br />
                    <strong>Amount:</strong>{" "}
                    {formattedAmount(purchaseOrder.amount?.toString(), purchaseOrder.currency?.iso_code)} /{" "}
                    <strong>Balance:</strong>{" "}
                    {formattedAmount(purchaseOrder.open_balance?.toString(), purchaseOrder.currency?.iso_code)} <br />
                    <strong>Date:</strong> {companyDateFormat(purchaseOrder.date, currentUser, true)}{" "}
                    <strong>Status:</strong> {purchaseOrder.status} <br />
                    <strong>Description:</strong> {purchaseOrder.description}
                  </small>
                ) : (
                  purchaseOrder.label
                )
              }
              onChange={(selected) => onChangePo(selected, value, onChange)}
              placeholder={placeholder}
              defaultOptions
              instanceId={instanceId || "multi-po-picker"}
            />
            {fieldState?.error && <PickerErrorBlock error={fieldState.error.message || ""} />}
          </>
        )}
      />
    </Form.Group>
  );
};

export default MultiPicker;
