import CurrencySymbolRenderer from "components/admin/commonUsed/currencySymbolRenderer";
import useConfirmModal from "components/modals/confirmModal/useConfirmModalHook";
import { restApiService } from "providers/restApi";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Table } from "react-bootstrap";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { BsLink45Deg, BsPlusLg } from "react-icons/bs";
import { useTypedSelector } from "reducers";
import { CommonApis } from "services/admin/commonApis";
import { InvoiceType } from "services/admin/invoices/invoiceType";
import { useInvoiceCommonSvc } from "services/admin/invoices/useInvoiceCommonSvc";
import commonService from "services/common/commonSvc";
import { IUser } from "services/common/user/userTypes";
import { CreateNotification, NotificationType } from "services/general/notifications";
import { useInvoiceDetailsContext } from "../invoiceDetailsContext";
import { linkPoItemsAsync, unlinkPoItemsAsync } from "../invoiceDetailsService";
import styles from "../materialDataTable.module.css";
import InvoiceItemLine from "./invoiceItemLine";

type TInvoiceItemLineSectionProps = {
  saveAsyncCallback: (notify: boolean, skipWorkflowTriggerApi?: boolean) => Promise<void>;
};

const InvoiceItemLineSection = ({ saveAsyncCallback }: TInvoiceItemLineSectionProps) => {
  const { control } = useFormContext<any>();
  const currentUser: IUser = useTypedSelector((state) => state.user);
  const { invoiceDetails, refetchInvoiceDetails, poItems } = useInvoiceDetailsContext();
  const [recommendedAccounts, setRecommendedAccounts] = useState<InvoiceType.recommendedAccounts>();
  const [isShowLinkRadios, setIsShowLinkRadios] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any | null>(null);
  const { createConfirmModal } = useConfirmModal();

  const openLinkActions = useCallback((item: any) => {
    setIsShowLinkRadios(true);
    setSelectedItem(item);
  }, []);

  const closeLinkActions = useCallback((item: any) => {
    setIsShowLinkRadios(false);
    setSelectedItem(null);
  }, []);

  const { fields, append, update } = useFieldArray<InvoiceType.TInvoiceItem>({
    control,
    name: "invoice_items_attributes",
    keyName: "_id" as "id",
  });

  const invoiceItems = fields as unknown as InvoiceType.TInvoiceItem[];

  const invoiceCommonSvc = useInvoiceCommonSvc();
  const vendorId = useWatch({ name: "vendor_id" });

  const linkedIndexes = useMemo(() => {
    return invoiceItems
      .filter((item: any) => item.invoice_item_po_item_link_id)
      .map((item: any) => item.po_item.serial_number);
  }, [invoiceItems]);

  const isItemLinked = useCallback(
    (index: number) => {
      return linkedIndexes.includes(index);
    },
    [linkedIndexes],
  );

  const showPoMatching = useMemo(() => {
    return poItems && poItems.length > 0 && invoiceItems && invoiceItems.length > 0;
  }, [poItems, invoiceItems]);

  const poItemsMemo = useMemo(() => {
    const flattened: any[] = [];
    const poItemIndexMap: { [key: number]: number } = {};

    poItems?.forEach((po: any) => {
      po.po_items.forEach((poItem: any) => {
        flattened.push({
          id: poItem.id,
          poNumber: po.number,
          poDate: po.date,
          description: poItem.description,
          department: poItem.department_name,
          businessUnit: poItem.business_unit_name,
          location: poItem.location_name,
          unitPrice: poItem.unit_price,
          qty: poItem.qty,
          qtyReceived: poItem.qty_received,
          amount: poItem.amount,
          tax: poItem.tax,
          total: poItem.total,
          serialNumber: poItem.serial_number,
        });
      });
    });

    const sortedItems = flattened.sort((a, b) => b.serialNumber - a.serialNumber);

    sortedItems.forEach((item, index) => {
      poItemIndexMap[item.id] = index + 1;
    });

    return sortedItems;
  }, [poItems]);

  // Calculate the number of columns dynamically
  const dynamicColumnCount = useMemo(() => {
    let columnCount = 16; // Actions, PO Line#, PO#, Item, Item Description, Qty, Unit Price, Account, Total Price (static columns), Unit Price,	Qty,	Qty Received,	Amount,	Tax	, Receipt Number, Receipt Qty
    if (invoiceDetails?.invoice?.is_valid_3_way_matched) columnCount += 2; // Receipt Number, Receipt Qty
    if (currentUser.company.has_taxes) columnCount += 2; // Tax Code, Tax
    if (currentUser.company.has_units) columnCount += 1; // Unit
    if (currentUser.company.allow_edit_invoice_item_subtotal) columnCount += 1; // Sub Amount
    if (currentUser.company.has_departments && !currentUser.company.invoice?.items?.department?.is_hide)
      columnCount += 1; // Department
    if (currentUser.company.has_locations && !currentUser.company.invoice_item_hide_location) columnCount += 1; // Location
    if (currentUser.company.has_business_units && !currentUser.company.invoice_item_hide_business_unit)
      columnCount += 1; // Business Unit
    if (currentUser.company.has_projects) {
      columnCount += 1; // Project
    }
    return columnCount;
  }, [
    currentUser.company.allow_edit_invoice_item_subtotal,
    currentUser.company.has_business_units,
    currentUser.company.has_departments,
    currentUser.company.has_locations,
    currentUser.company.has_projects,
    currentUser.company.has_taxes,
    currentUser.company.has_units,
    currentUser.company.invoice?.items?.department?.is_hide,
    currentUser.company.invoice_item_hide_business_unit,
    currentUser.company.invoice_item_hide_location,
    invoiceDetails?.invoice?.is_valid_3_way_matched,
  ]);

  const addNewRow = () => {
    let obj = { allowEdit: true, total: 0 };
    append(obj);
  };

  const removeAmortizationSchedule = (data: InvoiceType.TInvoiceItem) => {
    delete data.amortization;
    delete data.amortization_id;
    delete data.amortization;
    delete data.amortization_schedule_name;
    delete data.amortization_start_date;
    delete data.amortization_end_date;
  };

  const getRecommendedAccounts = useCallback(async () => {
    try {
      const response = await CommonApis.getVendorRecommendedAccounts(vendorId);
      setRecommendedAccounts(response);
    } catch (error) {
      commonService.handleError(error);
    }
  }, [vendorId]);

  useEffect(() => {
    if (vendorId) {
      getRecommendedAccounts();
    }
  }, [getRecommendedAccounts, vendorId]);

  const copyRecommendedAccounts = (invoiceItem: InvoiceType.TInvoiceItem) => {
    if (invoiceItem) {
      delete invoiceItem.id;
      removeAmortizationSchedule(invoiceItem);
      const newInvoiceItem = { ...invoiceItem };
      append(newInvoiceItem);
    }
  };

  const linkPoItem = useCallback(
    async (item: any) => {
      try {
        saveAsyncCallback(false, true);
        await linkPoItemsAsync(invoiceDetails?.invoice!, selectedItem!, item?.id!).then((res) => {
          const linked = res.linked;
          const result = res.result;
          if (!linked) return;

          if (result) CreateNotification("Success", "Items linked successfully.", NotificationType.success);
          closeLinkActions(null);
          refetchInvoiceDetails();
        });
      } catch (e) {
        CreateNotification("Error", "Error linking items.", NotificationType.danger);
      }
    },
    [closeLinkActions, invoiceDetails?.invoice, selectedItem, refetchInvoiceDetails, saveAsyncCallback],
  );

  const unlinkPoItem = useCallback(
    async (linkId: number) => {
      try {
        saveAsyncCallback(false, true);
        await unlinkPoItemsAsync(invoiceDetails?.invoice.id!, linkId);
        CreateNotification("Success", "Items unlinked successfully.", NotificationType.success);
        closeLinkActions(null);
        refetchInvoiceDetails();
      } catch (e) {
        CreateNotification("Error", "Error unlinking items.", NotificationType.danger);
      }
    },
    [closeLinkActions, invoiceDetails?.invoice.id, refetchInvoiceDetails, saveAsyncCallback],
  );

  // const calculateItemsTotal = () => {
  //   const items = isItemsExist();
  //   return items.reduce((total, item) => total + (item.total || 0), 0);
  // };

  const onUnlinkClick = (id: number) => {
    createConfirmModal({
      title: "Confirm",
      body: "Are you sure, do you want to unlink?",
      callBackData: null,
      cancelCallBack: null,
      saveCallBack: () => unlinkPoItem(id),
    });
  };

  const total = invoiceCommonSvc.getItemsTotal();

  return (
    <>
      {(recommendedAccounts?.invoice_items?.length ?? 0) > 0 && (
        <>
          <span className="mt-1 mb-2">
            <h6>Recommended Line Items</h6>
          </span>
          <div className="overflow-hidden">
            <Table size="sm" className={styles.tableMaterial}>
              <thead>
                <tr>
                  <th>Actions</th>
                  <th>Source</th>
                  <th>Item</th>
                  <th>Unit Price</th>
                  <th>Account</th>
                  <th>Business Unit</th>
                  <th>Department</th>
                  <th>Location</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                {recommendedAccounts?.invoice_items?.map((invoiceItem, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <span className={styles.copyLink} onClick={() => copyRecommendedAccounts(invoiceItem)}>
                          Add
                        </span>
                      </td>
                      <td>
                        <a
                          href={restApiService.angularBaseURL() + `invoices/${invoiceItem.invoice?.id}`}
                          className={styles.copyLink}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {invoiceItem?.invoice?.number || "--"}
                        </a>
                      </td>
                      <td>{invoiceItem.product_item?.name}</td>
                      <td>
                        <CurrencySymbolRenderer name="currency_code" amount={invoiceItem.unit_price || 0} />
                      </td>
                      <td>
                        {invoiceItem?.account ? `${invoiceItem.account.number} - ${invoiceItem.account.name}` : "--"}
                      </td>
                      <td>{invoiceItem.business_unit?.name || "--"}</td>
                      <td>{invoiceItem.department?.name || "--"}</td>
                      <td>{invoiceItem.location?.name || "--"}</td>
                      <td>{invoiceItem.description || "--"}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </>
      )}
      <div className="overflow-auto custom-overflow pt-3">
        <Table size="sm" className={styles.tableMaterial}>
          <thead>
            <tr>
              <th rowSpan={2} className={styles.regularHeader}>
                Actions
              </th>
              <th rowSpan={2} className={styles.regularHeader}>
                PO Line#
              </th>
              <th rowSpan={2} className={styles.regularHeader}>
                PO#
              </th>
              <th rowSpan={2} className={styles.regularHeader}>
                Item
              </th>
              <th rowSpan={2} className={styles.regularHeader}>
                Description
              </th>
              {currentUser.company.has_taxes && (
                <th rowSpan={2} className={styles.regularHeader}>
                  Tax Code
                </th>
              )}

              {/* Invoice Information header */}
              <th colSpan={5} className={styles.invoiceHeader}></th>

              {/* Purchase Order Information header */}
              <th colSpan={7} className={styles.poHeader}>
                Purchase Order Information
              </th>

              {/* Receipts header */}
              {invoiceDetails?.invoice?.is_valid_3_way_matched && (
                <th colSpan={2} className={styles.receiptsHeader}>
                  Receipts
                </th>
              )}

              {/* Other info header */}
              <th colSpan={5} className={styles.regularHeader}></th>
            </tr>
            <tr>
              {/* Invoice Information columns */}
              {currentUser.company.has_units && <th className={styles.invoiceColumnHeader}>Unit</th>}
              <th className={styles.invoiceColumnHeader}>Qty</th>
              <th className={styles.invoiceColumnHeader}>Unit Price</th>
              {currentUser.company.has_taxes && <th className={styles.invoiceColumnHeader}>Tax</th>}
              <th className={styles.invoiceColumnHeader}>Total</th>
              {currentUser.company.allow_edit_invoice_item_subtotal && (
                <th className={styles.invoiceColumnHeader}>Sub Amount</th>
              )}
              {/* 2 way Purchase Order Information columns */}
              <th className={styles.poColumnHeader}>Item</th>
              <th className={styles.poColumnHeader}>Unit Price</th>
              <th className={styles.poColumnHeader}>Qty</th>
              <th className={styles.poColumnHeader}>Qty Received</th>
              <th className={styles.poColumnHeader}>Amount</th>
              {currentUser.company.has_taxes && <th className={styles.poColumnHeader}>Tax</th>}
              <th className={styles.poColumnHeader}>Total</th>
              {/* 3 way Receipts columns */}
              {invoiceDetails?.invoice?.is_valid_3_way_matched && (
                <>
                  <th>Receipt Number</th>
                  <th>Qty</th>
                </>
              )}
              {/* Other info*/}
              <th>Account</th>
              <th>Department</th>
              <th>Location</th>
              <th>Business Unit</th>
              <th>Project</th>
            </tr>
          </thead>
          <tbody>
            {invoiceItems.map((item, index) => (
              <InvoiceItemLine
                is3WayMatch={!!invoiceDetails?.invoice?.is_valid_3_way_matched}
                key={index}
                item={item}
                selectedItem={selectedItem}
                index={index}
                update={update}
                append={append}
                linkPoItemCallback={openLinkActions}
                unlinkPoItemCallback={onUnlinkClick}
                showLinkActions={isShowLinkRadios}
                cancelLinkPoItemCallback={closeLinkActions}
              />
            ))}
            {invoiceItems.length < 1 && (
              <tr>
                <td colSpan={dynamicColumnCount}>
                  <span className={styles.noRecords}>Records not found!</span>
                </td>
              </tr>
            )}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={dynamicColumnCount - 1}>Total</td>
              <td>
                <span>
                  <CurrencySymbolRenderer name="currency_code" amount={invoiceCommonSvc.getItemsTotal()} />
                </span>
              </td>
            </tr>
          </tfoot>
        </Table>
      </div>
      <span>
        <button className={styles.actionBtn} onClick={() => addNewRow()} type="button">
          <BsPlusLg className={styles.actionIcon} />
          Add Line Item
        </button>
      </span>
      {showPoMatching && (
        <>
          <h5 className={`${styles.cardTitle} mt-4 mb-2`}>Outstanding PO Line Items</h5>
          <div className="overflow-auto custom-overflow">
            <Table className={styles.tableMaterial}>
              <thead>
                <tr>
                  <th>#</th>
                  <th>PO #</th>
                  <th>PO Issuance Date</th>
                  <th>Item Name</th>
                  <th>Department</th>
                  <th>Business Unit</th>
                  <th>Location</th>
                  <th>Unit Price</th>
                  <th>PO Qty</th>
                  <th>Qty Received</th>
                  <th>Amount</th>
                  <th>Tax</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {poItemsMemo ? (
                  poItemsMemo?.map((item) => {
                    const isLinked = isItemLinked(item.serialNumber);
                    return !isLinked ? (
                      <tr key={"poItems_" + item.serialNumber}>
                        <td>
                          {isShowLinkRadios && (
                            <BsLink45Deg className={styles.operationIcon} size={18} onClick={() => linkPoItem(item)} />
                          )}
                          {item.serialNumber}
                        </td>
                        <td>{item.poNumber}</td>
                        <td>{item.poDate}</td>
                        <td>{item.description}</td>
                        <td>{item.department}</td>
                        <td>{item.businessUnit || "--"}</td>
                        <td>{item.location || "--"}</td>
                        <td>
                          <CurrencySymbolRenderer name="currency_code" amount={item.unitPrice} />
                        </td>
                        <td>{item.qty}</td>
                        <td>{item.qtyReceived}</td>
                        <td>
                          <CurrencySymbolRenderer name="currency_code" amount={item.amount} />
                        </td>
                        <td>
                          <CurrencySymbolRenderer name="currency_code" amount={item.tax} />
                        </td>
                        <td>
                          <CurrencySymbolRenderer name="currency_code" amount={item.total} />
                        </td>
                      </tr>
                    ) : (
                      <></>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={13} className={styles.noRecords}>
                      No records found.
                    </td>
                  </tr>
                )}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={10}>Subtotal Amount :</td>
                  <td colSpan={3}>
                    <CurrencySymbolRenderer name="currency_code" amount={total} />
                  </td>
                </tr>
                <tr>
                  <td colSpan={10}>Total Amount :</td>
                  <td colSpan={3}>
                    <CurrencySymbolRenderer name="currency_code" amount={total} />
                  </td>
                </tr>
              </tfoot>
            </Table>
          </div>
        </>
      )}
    </>
  );
};

export default InvoiceItemLineSection;
