import _ from "lodash";
import React from "react";
import { Table } from "react-bootstrap";
import { useTypedSelector } from "reducers";
import { IUser } from "services/common/user/userTypes";
import { ObjectPropertiesType } from "../../../../../services/admin/invoices/recurringInvoice/recurringInvoiceTypes";
import paymentTypeService from "../../../../../services/common/paymentMethod/paymentTypeSvc";
import Panel from "../../../../common/panel/panel";
import TooltipRender from "../../../../toolTip/tooltipRender";
import styles from "../recurringInvoice.module.css";

type LineItemsPropsType = {
  invoice?: ObjectPropertiesType;
};

const LineItems = ({ invoice }: LineItemsPropsType) => {
  const currentUser: IUser = useTypedSelector((state) => state.user);
  return (
    <Panel>
      <Table className={`mb-1 text-left`} responsive striped bordered>
        <thead>
          <tr>
            <th>Bank</th>
            <th>Type</th>
            <th>Account Type</th>
            <th>Currency</th>
            <th>Bank Address</th>
            {invoice?.payment_method?.payment_type === "cambridge" && <th>Cambridge Id</th>}
            {invoice?.payment_method?.program_id && <th>Program ID</th>}
          </tr>
        </thead>
        <tbody>
          {(!invoice || !invoice.payment_method) && (
            <tr>
              <td colSpan={5} className="text-center">
                There are no line items.
              </td>
            </tr>
          )}
          {_.isPlainObject(invoice?.payment_method) && (
            <tr>
              <td>
                {invoice?.payment_method?.bank_name ? invoice.payment_method.bank_name : "--"}
                {invoice?.payment_method?.is_payoneer && (
                  <p className="d-flex" ng-if="invoice.payment_method.is_payoneer">
                    Status: {invoice?.payment_method?.payoneer_status}
                    {invoice?.payment_method?.payoneer_status === "PENDING" && (
                      <TooltipRender
                        placement="auto"
                        title="Payoneer Account is under review and should be processed in the next 2-3 hours."
                        className={"icon-info-blue " + styles.payonnerPendingStatus}
                      ></TooltipRender>
                    )}
                  </p>
                )}
                {invoice?.payment_method?.is_payoneer &&
                  !invoice.payment_method.is_payoneer_active &&
                  invoice.payment_method.payoneer_response_desc && (
                    <p>({invoice?.payment_method?.payoneer_response_desc})</p>
                  )}
              </td>
              <td>
                {invoice?.payment_method?.payment_type &&
                  paymentTypeService.getPaymentMethodLabel(invoice.payment_method.payment_type, currentUser)}
              </td>
              <td>{invoice?.payment_method?.account_type}</td>
              <td>{invoice?.payment_method?.currency_code}</td>
              <td>{invoice?.payment_method?.bank_address}</td>
              {invoice?.payment_method?.payment_type === "cambridge" && (
                <td>{invoice?.payment_method?.cambridge?.beneficiaryId}</td>
              )}
              {invoice?.payment_method?.program_id && <td>{invoice.payment_method.program_id}</td>}
            </tr>
          )}
        </tbody>
      </Table>
    </Panel>
  );
};

export default LineItems;
