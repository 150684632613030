import CurrencySymbolRenderer from "components/admin/commonUsed/currencySymbolRenderer";
import AccountPicker from "components/admin/pickers/reactHookFormPickers/accountPicker";
import BusinessUnitPicker from "components/admin/pickers/reactHookFormPickers/businessUnitPicker";
import CategoryPicker from "components/admin/pickers/reactHookFormPickers/categoryPicker";
import DepartmentPicker from "components/admin/pickers/reactHookFormPickers/departmentPicker";
import LocationPicker from "components/admin/pickers/reactHookFormPickers/locationPicker";
import { InputField, TextAreaField } from "components/forms/hookFormFields";
import _ from "lodash";
import React, { useMemo } from "react";
import { Table } from "react-bootstrap";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useTypedSelector } from "reducers";
import { InvoiceType } from "services/admin/invoices/invoiceType";
//import { useInvoiceCommonSvc } from "services/admin/invoices/useInvoiceCommonSvc";
import { BsCheck2, BsPencil, BsXLg } from "react-icons/bs";
import { IUser } from "services/common/user/userTypes";
import styles from "../materialDataTable.module.css";

const InvoiceCreditLineSection = () => {
  const { control, getValues, setValue, trigger } = useFormContext<any>();
  const currentUser: IUser = useTypedSelector((state) => state.user);
  //const currencyCode = getValues("currency_code");

  const { fields, update } = useFieldArray<any>({
    control,
    name: "credit_entries_attributes",
    keyName: "_id" as "id",
  });

  const creditEntries = fields as unknown as InvoiceType.TInvoiceExpenseLineEntry[];
  //const invoiceCommonSvc = useInvoiceCommonSvc();

  // Calculate the number of columns dynamically
  const dynamicColumnCount = useMemo(() => {
    let columnCount = 5; // Actions, Account, Percent, Memo, Amount (static columns)
    if (currentUser.company.has_departments && !currentUser.company.invoice?.expenses?.department?.is_hide)
      columnCount += 1; // Department
    if (currentUser.company.has_locations && !currentUser.company.invoice_account_hide_location) columnCount += 1; // Location
    if (currentUser.company.has_business_units && !currentUser.company.invoice_account_hide_business_unit)
      columnCount += 1; // Business Unit
    if (
      currentUser.company.has_categories &&
      (!currentUser.company.invoice || (currentUser.company.invoice && !currentUser.company.invoice.hide_categories))
    ) {
      columnCount += 1; // Category
    }
    return columnCount;
  }, [currentUser]);

  /*
  const addNewRow = () => {
    let obj = { allowEdit: true };
    append(obj);
  };
  */

  const disableEditRow = async (entry: InvoiceType.TInvoiceExpenseLineEntry, index: number) => {
    if (!entry.allowEdit) {
      return;
    }
    const updatedItem = {
      ...getValues(`credit_entries_attributes.${index}`),
      id: entry.id,
      allowEdit: !entry.allowEdit,
    };
    setValue(`credit_entries_attributes.${index}`, updatedItem);
    update(index, updatedItem);
  };

  const allowEditRow = async (entry: InvoiceType.TInvoiceExpenseLineEntry, index: number) => {
    let isValidate = true;
    if (entry.allowEdit) {
      isValidate = await trigger(`credit_entries_attributes.${index}`);
    }
    if (isValidate) {
      const updatedItem = {
        ...getValues(`credit_entries_attributes.${index}`),
        id: entry.id,
        allowEdit: !entry.allowEdit,
      };
      setValue(`credit_entries_attributes.${index}`, updatedItem);
      update(index, updatedItem);
    }
  };

  // const destroyExpense = (entry: InvoiceType.TInvoiceExpenseLineEntries, index: number) => {
  //   let updatedEntry = { ...entry, _destroy: 1 };
  //   let updatedItem = { ...getValues(`credit_entries_attributes.${index}`), _destroy: 1 };

  //   setValue(`credit_entries_attributes.${index}`, updatedItem);
  //   update(index, updatedItem);
  // };

  return (
    <>
      <span className="mt-1 mb-2">
        <h5 className={styles.cardTitle}>Credit Entries</h5>
      </span>
      <div className="overflow-auto custom-overflow pb-3">
        <Table size="sm" className={styles.tableMaterial}>
          <thead>
            <tr>
              <th>Actions</th>
              <th>Account</th>
              <th>Amount</th>
              <th>Memo</th>

              {currentUser.company.has_departments && !currentUser.company.invoice?.expenses?.department?.is_hide && (
                <th>Department</th>
              )}
              {currentUser.company.has_locations && !currentUser.company.invoice_account_hide_location && (
                <th>Location</th>
              )}
              {currentUser.company.has_business_units && !currentUser.company.invoice_account_hide_business_unit && (
                <th>Business Unit</th>
              )}
              {currentUser.company.has_categories &&
                (!currentUser.company.invoice ||
                  (currentUser.company.invoice && !currentUser.company.invoice.hide_categories)) && <th>Category</th>}
            </tr>
          </thead>
          <tbody>
            {creditEntries.map((entry, index) => {
              const editIcon = entry.allowEdit ? (
                <BsCheck2 className={styles.operationIcon} size={16} />
              ) : (
                <BsPencil className={styles.operationIcon} size={14} />
              );
              return (
                _.isPlainObject(entry) &&
                entry._destroy !== 1 && (
                  <tr key={entry._id || index} className={styles.tableBody + " uploadQueueItemLineRow"}>
                    <td className={styles.iconsSection}>
                      <button
                        type="button"
                        className={styles.operationBtn}
                        onClick={() => {
                          allowEditRow(entry, index);
                        }}
                      >
                        {editIcon}
                      </button>
                      {entry.allowEdit && (
                        <button
                          type="button"
                          className={styles.operationBtn}
                          onClick={() => disableEditRow(entry, index)}
                        >
                          <BsXLg className={styles.operationIcon} size={16} />
                        </button>
                      )}
                      {/* <i className="icon delete" onClick={() => destroyExpense(entry, index)}></i> */}
                    </td>
                    <td className="px-pt-10">
                      {entry.allowEdit ? (
                        <AccountPicker
                          containerClassName={styles.largeInputDesc}
                          name={`credit_entries_attributes.${index}.account_id`}
                          accountGroupName="INVOICE_CREDIT_GROUPS"
                          modelData={`credit_entries_attributes.${index}`}
                          required={true}
                          parentObj={""}
                        />
                      ) : (entry.account_number ? entry.account_number : "") + " - " + entry.account_name ? (
                        entry.account_name
                      ) : (
                        ""
                      )}
                    </td>

                    <td>
                      {entry.allowEdit ? (
                        <InputField
                          containerClassName={styles.largeInput}
                          name={`credit_entries_attributes.${index}.amount`}
                          type="number"
                        />
                      ) : (
                        <CurrencySymbolRenderer name="currency_code" amount={Number(entry.amount) || 0} />
                      )}
                    </td>

                    <td>
                      {entry.allowEdit ? (
                        <TextAreaField
                          containerClassName={styles.largeInput}
                          name={`credit_entries_attributes.${index}.memo`}
                        />
                      ) : (
                        entry.memo
                      )}
                    </td>

                    {currentUser.company.has_departments &&
                      !currentUser.company.invoice?.expenses?.department?.is_hide && (
                        <td>
                          {" "}
                          {entry.allowEdit ? (
                            <DepartmentPicker
                              containerClassName={styles.largeInputDesc}
                              name={`credit_entries_attributes.${index}.department_id`}
                              modelData={`credit_entries_attributes.${index}`}
                              // parentObj={""} //header
                            />
                          ) : (
                            (entry.department?.name ?? entry.department_name)
                          )}
                        </td>
                      )}
                    {currentUser.company.has_locations && !currentUser.company.invoice_account_hide_location && (
                      <td>
                        {entry.allowEdit ? (
                          <LocationPicker
                            name={`credit_entries_attributes.${index}.location_id`}
                            modelDataName={`credit_entries_attributes.${index}`}
                            containerClassName={styles.projectMinWidth}
                          />
                        ) : (
                          (entry.location?.name ?? entry.location_name)
                        )}
                      </td>
                    )}
                    {currentUser.company.has_business_units &&
                      !currentUser.company.invoice_account_hide_business_unit && (
                        <td>
                          {entry.allowEdit ? (
                            <BusinessUnitPicker
                              name={`credit_entries_attributes.${index}.business_unit_id`}
                              modelDataName={`credit_entries_attributes.${index}`}
                              disabled={currentUser?.company?.readonly_business_unit_to_all}
                              containerClassName={styles.projectMinWidth}
                            />
                          ) : (
                            (entry.business_unit?.name ?? entry.business_unit_name)
                          )}
                        </td>
                      )}

                    {currentUser.company.has_categories &&
                      (!currentUser.company.invoice ||
                        (currentUser.company.invoice && !currentUser.company.invoice.hide_categories)) && (
                        <td>
                          {entry.allowEdit ? (
                            <CategoryPicker
                              name={`credit_entries_attributes.${index}.category_id`}
                              modelData={`credit_entries_attributes.${index}`}
                              containerClassName={styles.projectMinWidth}
                            />
                          ) : (
                            (entry.category?.name ?? entry.category_name)
                          )}
                        </td>
                      )}
                  </tr>
                )
              );
            })}
            {creditEntries.length < 1 && (
              <tr>
                <td colSpan={dynamicColumnCount}>
                  <span className={styles.noRecords}>Records not found!</span>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
      {/* Not allowing to add credit entry */}
      {/* <Row>
        <Col>
          <Button variant="light" className={styles.btnAddNewEntry} onClick={() => addNewRow()}>
            <i className="icon icon-add-black"></i>Add Credit Line
          </Button>
        </Col>
      </Row> */}
    </>
  );
};

export default InvoiceCreditLineSection;
