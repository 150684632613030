import React from "react";
import InvoiceItemLineSection from "./subSections/itemLineSection";

type TInvoiceDetailsItemLineSectionProps = {
  saveAsyncCallback: (notify: boolean, skipWorkflowTriggerApi?: boolean) => Promise<void>;
};

const InvoiceDetailsItemLineSection = ({ saveAsyncCallback }: TInvoiceDetailsItemLineSectionProps) => {
  return <InvoiceItemLineSection saveAsyncCallback={saveAsyncCallback} />;
};

export default InvoiceDetailsItemLineSection;
