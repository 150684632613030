import { restApiService } from "providers/restApi";
import React, { memo, useCallback, useMemo } from "react";
import AbstractListPicker from "../abstractListPicker/index2";
import { GrantPickerTypes } from "./grantPickerTypes";

const GrantPicker = ({
  name,
  label,
  instanceId,
  required = false,
  modelDataName,
  objectPropertyKey,
}: GrantPickerTypes.TGrantPickerProps) => {
  const mergeInactive = useCallback(async (currentId, grants) => {
    let inactiveOption = null;
    if (currentId) {
      const grant = Array.isArray(grants) ? grants.filter((grant) => grant.id === currentId) : [];
      if (grant.length === 0) {
        try {
          const response = await restApiService.get("grants/" + currentId);
          inactiveOption = response.data;
        } catch (error) {
          console.log(error);
        }
      } else {
        inactiveOption = grant[0];
      }
    }
    return { inactiveOption };
  }, []);

  const params = useMemo(() => ({ status: "ACTIVE" }), []);

  return (
    <AbstractListPicker<GrantPickerTypes.TGrantPickerOption>
      name={name}
      label={label}
      required={required}
      instanceId={instanceId}
      params={params}
      objectPropertyKey={objectPropertyKey ?? (modelDataName && `${modelDataName}.grant`)}
      mergeInactive={mergeInactive}
      fetchUrl="grants"
    />
  );
};

export default memo(GrantPicker);
