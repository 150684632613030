//Define all of our configuration variables here for each environment
const prodEnv = {
  apiURL: "https://api.accrualify.com/",
  logoutURL: "https://app.accrualify.com/logout",
  loginURL: "https://app.accrualify.com/login",
  clientId: process.env.REACT_APP_CLIENT_ID,
  agGridLicense: process.env.REACT_APP_AGGRID_KEY,
  appURL: "https://app.accrualify.com/",
  appDomainNameAccrualify: process.env.REACT_APP_DOMAIN_NAME_ACCRUALIFY,
  oktaLoginURL: "https://login.accrualify.com/?redirectUrl=https://portal.accrualify.com",
  oktaLogoutURLAccrualify: "https://login.accrualify.com/logout",
  oktaLogoutURLCorpay: "https://login.corpay.com/logout",
  oktaIssuer: process.env.REACT_APP_OKTA_ISSUER,
  oktaClientId: process.env.REACT_APP_OKTA_CLIENT_ID,
  oktaRedirectUri: "https://portal.accrualify.com/login/callback",
  oktaEnabled: process.env.REACT_APP_OKTA_ENABLED == "true" ? true : false,
  salesForceUrl:
    process.env.REACT_APP_SALES_FORCE_URL ||
    "https://comdata--qa.sandbox.lightning.force.com//servlet/servlet.WebToCase?encoding=UTF-8&orgId=00DEm0000044ez5",
  salesForceUrlRedirectUrl: process.env.REACT_APP_SALES_FORCE_REDIRECT_URL || "https://portal.accrualify.com/",
  salesForceOrgId: process.env.REACT_APP_SALES_FORCE_ORG_ID || "00DEm0000044ez5",
  googleMapAccessKey: process.env.REACT_APP_GOOGLE_MAP_ACCESS_KEY,
};
const stageEnv = {
  apiURL: "https://api-stage.accrualify.com/",
  logoutURL: "https://stage.accrualify.com/logout",
  loginURL: "https://stage.accrualify.com/login",
  clientId: process.env.REACT_APP_CLIENT_ID,
  agGridLicense: "none",
  appURL: "https://stage.accrualify.com/",
  appDomainNameAccrualify: process.env.REACT_APP_DOMAIN_NAME_ACCRUALIFY,
  oktaLoginURL: "https://login-stage.accrualify.com/?redirectUrl=https://portal-stage.accrualify.com",
  oktaLogoutURLAccrualify: "https://login-stage.accrualify.com/logout",
  oktaLogoutURLCorpay: "https://login-stage.corpay.com/logout",
  oktaIssuer: process.env.REACT_APP_OKTA_ISSUER,
  oktaClientId: process.env.REACT_APP_OKTA_CLIENT_ID,
  oktaRedirectUri: "https://portal-stage.accrualify.com/login/callback",
  oktaEnabled: process.env.REACT_APP_OKTA_ENABLED == "true" ? true : false,
  salesForceUrl:
    process.env.REACT_APP_SALES_FORCE_URL ||
    "https://comdata--qa.sandbox.lightning.force.com//servlet/servlet.WebToCase?encoding=UTF-8&orgId=00DEm0000044ez5",
  salesForceUrlRedirectUrl: process.env.REACT_APP_SALES_FORCE_REDIRECT_URL || "https://portal-stage.accrualify.com/",
  salesForceOrgId: process.env.REACT_APP_SALES_FORCE_ORG_ID || "00DEm0000044ez5",
  googleMapAccessKey: process.env.REACT_APP_GOOGLE_MAP_ACCESS_KEY,
};
const sandboxEnv = {
  apiURL: "https://api-sandbox.accrualify.com/",
  logoutURL: "https://sandbox.accrualify.com/logout",
  loginURL: "https://sandbox.accrualify.com/login",
  clientId: process.env.REACT_APP_CLIENT_ID,
  agGridLicense: "none",
  appURL: "https://sandbox.accrualify.com/",
  appDomainNameAccrualify: process.env.REACT_APP_DOMAIN_NAME_ACCRUALIFY,
  oktaLoginURL: "https://login-sandbox.accrualify.com/?redirectUrl=https://portal-sandbox.accrualify.com",
  oktaLogoutURLAccrualify: "https://login-qa.accrualify.com/logout",
  oktaLogoutURLCorpay: "https://login-qa.corpay.com/logout",
  oktaIssuer: process.env.REACT_APP_OKTA_ISSUER,
  oktaClientId: process.env.REACT_APP_OKTA_CLIENT_ID,
  oktaRedirectUri: "https://portal-sandbox.accrualify.com/login/callback",
  oktaEnabled: process.env.REACT_APP_OKTA_ENABLED == "true" ? true : false,
  salesForceUrl:
    process.env.REACT_APP_SALES_FORCE_URL ||
    "https://comdata--qa.sandbox.lightning.force.com//servlet/servlet.WebToCase?encoding=UTF-8&orgId=00DEm0000044ez5",
  salesForceUrlRedirectUrl: process.env.REACT_APP_SALES_FORCE_REDIRECT_URL || "https://portal-sandbox.accrualify.com/",
  salesForceOrgId: process.env.REACT_APP_SALES_FORCE_ORG_ID || "00DEm0000044ez5",
  googleMapAccessKey: process.env.REACT_APP_GOOGLE_MAP_ACCESS_KEY,
};
const qaEnv = {
  apiURL: "https://api-qa.accrualify.com/",
  logoutURL: "https://qa.accrualify.com/logout",
  loginURL: "https://qa.accrualify.com/login",
  clientId: process.env.REACT_APP_CLIENT_ID,
  agGridLicense: process.env.REACT_APP_AGGRID_KEY,
  appURL: "https://qa.accrualify.com/",
  appDomainNameAccrualify: process.env.REACT_APP_DOMAIN_NAME_ACCRUALIFY,
  oktaLoginURL: "https://login-qa.accrualify.com/?redirectUrl=https://portal-qa.accrualify.com",
  oktaLogoutURLAccrualify: "https://login-qa.accrualify.com/logout",
  oktaLogoutURLCorpay: "https://login-qa.corpay.com/logout",
  oktaIssuer: process.env.REACT_APP_OKTA_ISSUER,
  oktaClientId: process.env.REACT_APP_OKTA_CLIENT_ID,
  oktaRedirectUri: "https://portal-qa.accrualify.com/login/callback",
  oktaEnabled: process.env.REACT_APP_OKTA_ENABLED == "true" ? true : false,
  salesForceUrl:
    process.env.REACT_APP_SALES_FORCE_URL ||
    "https://comdata--qa.sandbox.lightning.force.com//servlet/servlet.WebToCase?encoding=UTF-8&orgId=00DEm0000044ez5",
  salesForceUrlRedirectUrl: process.env.REACT_APP_SALES_FORCE_REDIRECT_URL || "https://portal-qa.accrualify.com/",
  salesForceOrgId: process.env.REACT_APP_SALES_FORCE_ORG_ID || "00DEm0000044ez5",
  googleMapAccessKey: process.env.REACT_APP_GOOGLE_MAP_ACCESS_KEY,
};
const betaEnv = {
  apiURL: "https://api-beta.accrualify.com/",
  logoutURL: "https://beta.accrualify.com/logout",
  loginURL: "https://beta.accrualify.com/login",
  clientId: process.env.REACT_APP_CLIENT_ID,
  agGridLicense: process.env.REACT_APP_AGGRID_KEY,
  appURL: "https://beta.accrualify.com/",
  appDomainNameAccrualify: process.env.REACT_APP_DOMAIN_NAME_ACCRUALIFY,
  oktaLoginURL: "https://login-qa.accrualify.com/?redirectUrl=https://portal-beta.accrualify.com",
  oktaLogoutURLAccrualify: "https://login-qa.accrualify.com/logout",
  oktaLogoutURLCorpay: "https://login-qa.corpay.com/logout",
  oktaIssuer: process.env.REACT_APP_OKTA_ISSUER,
  oktaClientId: process.env.REACT_APP_OKTA_CLIENT_ID,
  oktaRedirectUri: "https://portal-beta.accrualify.com/login/callback",
  oktaEnabled: process.env.REACT_APP_OKTA_ENABLED == "true" ? true : false,
  salesForceUrl:
    process.env.REACT_APP_SALES_FORCE_URL ||
    "https://comdata--qa.sandbox.lightning.force.com//servlet/servlet.WebToCase?encoding=UTF-8&orgId=00DEm0000044ez5",
  salesForceUrlRedirectUrl: process.env.REACT_APP_SALES_FORCE_REDIRECT_URL || "https://portal-qa.accrualify.com/",
  salesForceOrgId: process.env.REACT_APP_SALES_FORCE_ORG_ID || "00DEm0000044ez5",
  googleMapAccessKey: process.env.REACT_APP_GOOGLE_MAP_ACCESS_KEY,
};
const localEnv = {
  apiURL: "http://localhost:3000/",
  logoutURL: "https://localhost:3000/logout",
  loginURL: "http://localhost:9001/login",
  clientId: process.env.REACT_APP_CLIENT_ID,
  agGridLicense: process.env.REACT_APP_AGGRID_KEY,
  appURL: "http://localhost:9000/",
  appDomainNameAccrualify: "localhost",
  oktaLoginURL: "https://login-qa.accrualify.com/?redirectUrl=http://localhost:9001",
  oktaLogoutURLAccrualify: "https://login-qa.accrualify.com/logout",
  oktaLogoutURLCorpay: "https://login-qa.corpay.com/logout",
  oktaIssuer: "https://dev-06039782.okta.com/oauth2/aus7ybtazj5TLVBnK5d7",
  oktaClientId: "0oa8i8xdbx8PY9BMQ5d7", //new
  oktaRedirectUri: "http://localhost:9001/login/callback",
  oktaEnabled: false,
  salesForceUrl:
    process.env.REACT_APP_SALES_FORCE_URL ||
    "https://comdata--qa.sandbox.lightning.force.com//servlet/servlet.WebToCase?encoding=UTF-8&orgId=00DEm0000044ez5",
  salesForceUrlRedirectUrl: process.env.REACT_APP_SALES_FORCE_REDIRECT_URL || "https://portal-qa.accrualify.com/",
  salesForceOrgId: process.env.REACT_APP_SALES_FORCE_ORG_ID || "00DEm0000044ez5",
  googleMapAccessKey: process.env.REACT_APP_GOOGLE_MAP_ACCESS_KEY || "AIzaSyCkPHIiJ9rJpLfM3DG4QMWUr88aKMkpRmU",
};
const indiaEnv = {
  apiURL: prodEnv.apiURL,
  logoutURL: "http://app-india.accrualify.com/#/logout",
  clientId: process.env.REACT_APP_CLIENT_ID,
  agGridLicense: prodEnv.agGridLicense,
  appURL: "http://app-india.accrualify.com/",
  oktaLoginURL: "",
  oktaIssuer: process.env.OKTA_ISSUER,
  oktaClientId: process.env.OKTA_CLIENT_ID,
  oktaRedirectUri: prodEnv.oktaRedirectUri,
  oktaEnabled: process.env.REACT_APP_OKTA_ENABLED == "true" ? true : false,
  salesForceUrl:
    process.env.REACT_APP_SALES_FORCE_URL ||
    "https://comdata--qa.sandbox.lightning.force.com//servlet/servlet.WebToCase?encoding=UTF-8&orgId=00DEm0000044ez5",
  salesForceUrlRedirectUrl: process.env.REACT_APP_SALES_FORCE_REDIRECT_URL || "https://portal-qa.accrualify.com/",
  salesForceOrgId: process.env.REACT_APP_SALES_FORCE_ORG_ID || "00DEm0000044ez5",
  googleMapAccessKey: process.env.REACT_APP_GOOGLE_MAP_ACCESS_KEY,
};
const ecsEnv = {
  apiURL: process.env.REACT_APP_API_URL,
  logoutURL: process.env.REACT_APP_LOGOUT_URL,
  loginURL: process.env.REACT_APP_LOGIN_URL,
  clientId: process.env.REACT_APP_CLIENT_ID,
  agGridLicense: process.env.REACT_APP_AGGRID_KEY,
  appURL: process.env.REACT_APP_APP_URL,
  oktaLoginURL: process.env.REACT_APP_OKTA_LOGIN_URL,
  oktaLogoutURLAccrualify: process.env.REACT_APP_OKTA_LOGOUT_URL_ACCRUALIFY,
  oktaLogoutURLCorpay: process.env.REACT_APP_OKTA_LOGOUT_URL_CORPAY,
  oktaIssuer: process.env.REACT_APP_OKTA_ISSUER,
  oktaClientId: process.env.REACT_APP_OKTA_CLIENT_ID,
  oktaRedirectUri: process.env.REACT_APP_OKTA_REDIRECT_URI,
  oktaEnabled: process.env.REACT_APP_OKTA_ENABLED == "true" ? true : false,
  salesForceUrl: process.env.REACT_APP_SALES_FORCE_URL,
  salesForceUrlRedirectUrl: process.env.REACT_APP_SALES_FORCE_REDIRECT_URL,
  salesForceOrgId: process.env.REACT_APP_SALES_FORCE_ORG_ID,
  googleMapAccessKey: process.env.REACT_APP_GOOGLE_MAP_ACCESS_KEY,
};

//Depending on the env set the exported config to production, QA, development, etc.
var chosenEnv = {};
switch (process.env.REACT_APP_ENV) {
  case "production":
    chosenEnv = prodEnv;
    break;
  case "qa":
    chosenEnv = qaEnv;
    break;
  case "beta":
    chosenEnv = betaEnv;
    break;
  case "stage":
    chosenEnv = stageEnv;
    break;
  case "sandbox":
    chosenEnv = sandboxEnv;
    break;
  case "development":
    chosenEnv = localEnv;
    break;
  case "india":
    chosenEnv = indiaEnv;
    break;
  case "ecs":
    chosenEnv = ecsEnv;
    break;
  default:
    chosenEnv = localEnv;
}

module.exports = chosenEnv;
