import CurrencyCodePicker, {
  CurrencyCodeOptionType,
} from "components/admin/pickers/reduxFormPickers/currencyCodePicker";
import DatePicker from "components/admin/pickers/reduxFormPickers/datePicker/datePicker";
import { RenderCheckBox, RenderField, RenderFieldNumber, RenderSelect } from "components/forms/bootstrapFields";
import React, { ChangeEvent, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "reducers";
import { selectCurrentUser } from "reducers/userReducers";
import { change, Field } from "redux-form";
import expenseItemCommonSvc from "services/admin/expenses/expenseItems/expenseItemCommonSvc";
import { IUser } from "services/common/user/userTypes";
import { isDefined } from "services/general/helpers";
import { required } from "services/validations/reduxFormValidation";
import style from "./expenseItem.module.css";
import { useExpenseItemFormContext } from "./expenseItemFormSectionContext";
import { formExpenseItemFormData } from "./formExpenseItem";

const MileageForm = () => {
  const dispatch = useDispatch();
  const ctx = useExpenseItemFormContext();
  const currentUser: IUser = useTypedSelector(selectCurrentUser);
  const expenseFormData = useTypedSelector(formExpenseItemFormData(ctx?.formName ?? ""));
  const expenses = expenseFormData?.expenses;
  const expenseItem = expenses && ctx && expenses[ctx.index];
  // if using map_route then disable distance and unit;
  const disableUnitAndDistance = !!expenseItem?.map_route;

  const setDefaultDistanceUnit = (currency: CurrencyCodeOptionType) => {
    if (currency?.iso_code && ctx && isDefined(ctx?.index) && expenses && expenseItem) {
      expenseItem.currency = currency;
      expenseItem.currency_code = currency.value;
      dispatch(change(ctx.formName, ctx.sectionPrefix, expenses[ctx.index]));
      const expense = expenseItemCommonSvc.setDefaultDistanceUnit({
        currencyCode: currency.iso_code,
        index: ctx.index,
        expenses,
        currentUser,
      });
      if (expense) {
        dispatch(change(ctx.formName, ctx.sectionPrefix, expense));
      }
    }
  };

  const updateDistance = (distance: number) => {
    if (expenseItem && ctx && isDefined(ctx?.index)) {
      expenseItem.distance = Number(distance);
      const expense = expenseItemCommonSvc.calculateExpenseDistance(expenseItem, ctx.index, currentUser);
      if (expense) {
        dispatch(change(ctx.formName, ctx.sectionPrefix, expense));
      }
    }
  };

  const onDistanceChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (expenseItem && ctx && isDefined(ctx?.index)) {
      const distance = Number(e.target.value);
      updateDistance(distance);
    }
  };

  const updateDistanceUnit = (unit: string) => {
    if (expenseItem && ctx && isDefined(ctx?.index)) {
      expenseItem.distance_unit = unit;
      const expense = expenseItemCommonSvc.calculateExpenseDistance(expenseItem, ctx.index, currentUser);
      if (expense) {
        dispatch(change(ctx.formName, ctx.sectionPrefix, expense));
      }
    }
  };

  const onDistanceUnitChange = (e: ChangeEvent<HTMLSelectElement>) => {
    if (expenseItem && ctx && isDefined(ctx?.index)) {
      const distanceUnit = e.target.value;
      updateDistanceUnit(distanceUnit);
    }
  };

  useEffect(() => {
    // - [mohit] TODO: improve logic to update amount when distance is updated.
    if (expenseItem?.distance && disableUnitAndDistance) {
      updateDistance(expenseItem.distance);
    }
  }, [expenseItem?.distance]);

  useEffect(() => {
    // - [mohit] TODO: improve logic to update amount when distance_unit is updated.
    if (expenseItem?.distance_unit && disableUnitAndDistance) {
      updateDistanceUnit(expenseItem.distance_unit);
    }
  }, [expenseItem?.distance_unit]);

  return (
    <>
      <Row>
        <Col lg="3">
          <Field
            label="Currency"
            name="currency_code"
            component={CurrencyCodePicker}
            callBack={setDefaultDistanceUnit}
            modelData={expenseItem}
            notClearAble
            validate={[required]}
            required
          />
        </Col>
        <Col lg="3">
          <Field id="expense_date" label="Date" name="date" component={DatePicker} validate={[required]} required />
        </Col>

        <Col lg="4">
          <Row>
            <Col lg="6">
              <Field
                id="distance"
                label="Distance"
                name="distance"
                type="number"
                onChange={onDistanceChange}
                component={RenderFieldNumber}
                disabled={disableUnitAndDistance}
                validate={[required]}
                required
              />
            </Col>
            <Col lg="6">
              <Field
                id="type"
                label="Unit"
                name="distance_unit"
                type="number"
                options={[
                  { label: "mile", value: "mi" },
                  { label: "km", value: "km" },
                ]}
                onChange={onDistanceUnitChange}
                component={RenderSelect}
                disabled={disableUnitAndDistance}
                FieldClassName={"expense-item-milage-unit"}
                validate={[required]}
                required
              />
            </Col>
          </Row>
        </Col>
        <Col lg="2">
          <Field id="total" label="Total Amount" name="amount" type="number" component={RenderFieldNumber} disabled />
        </Col>
      </Row>
      {expenseItem?.set_mileage_rate_error && (
        <h5 className="text-danger expense-item-mileage-error">
          No Mileage Rate is available for this currency code in the selected Policy: {expenseItem?.policy?.name}
        </h5>
      )}
      <Row>
        <Col lg="10">
          <Field
            id="expense_description"
            label="Description"
            name="description"
            type="text"
            component={RenderField}
            required={
              currentUser.company.expense_item?.required_description ||
              expenseItem?.policy_violations?.missing_description_by_category ||
              expenseItem?.policy_violations?.missing_description_by_policy
            }
            validate={currentUser.company?.expense_item?.required_description ? [required] : []}
          />
        </Col>
        <Col lg="2" className={style.mt40}>
          {currentUser.company?.expense_item?.show_billable && (
            <>
              <Field
                id="billable_expense"
                label="Billable"
                name="billable"
                type="checkbox"
                component={RenderCheckBox}
              />
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

export default MileageForm;
