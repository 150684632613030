import { AxiosResponse } from "axios";
import useIsMounted from "components/common/hooks/useIsMounted";
import PickerErrorBlock from "components/common/pickers/pickerErrorBlock";
import { Mandatory } from "components/forms/hookFormFields";
import { restApiService } from "providers/restApi";
import React, { useCallback, useEffect, useState } from "react";
import { Control, Controller, RegisterOptions, useFormContext } from "react-hook-form";
import Select from "react-select";
import { useTypedSelector } from "reducers";
import { IDType } from "services/common/types/common.type";
import { IUser } from "services/common/user/userTypes";
import { compare } from "services/general/helpers";

type TPostingPeriod = {
  name: string;
  control?: Control;
  rules?: RegisterOptions;
  required?: boolean;
  callBack?: (selected: TSelected) => void;
};

type TSelected = {
  value: IDType;
  label: string | number;
  id: string;
};

type TPostingPeriodProps = {
  name?: string;
  status?: string;
  external_id?: string;
  value?: number | string;
  label?: string;
};

const PostingPeriodPicker = ({ name, required, ...otherProps }: TPostingPeriod) => {
  const [options, setOptions] = useState<TPostingPeriodProps[]>([]);
  const isMounted = useIsMounted();
  const { setValue, control } = useFormContext();

  const currentUser: IUser = useTypedSelector((state) => state.user);
  const currentUserCompanyId = currentUser?.company?.id;

  const parseOptions = (options: TPostingPeriodProps[]) => {
    return options
      .map((option: TPostingPeriodProps) => {
        return {
          value: option.external_id,
          label: option.name,
          ...option,
        };
      })
      .sort(compare);
  };

  const filterActiveRecord = (dateList: TPostingPeriodProps[]) => {
    return dateList.filter((list) => list.status === "ACTIVE");
  };

  const getPostingPeriods = useCallback(async () => {
    try {
      const result: AxiosResponse = await restApiService.get(
        `companies/${currentUserCompanyId}/custom_data_list?model=PostingPeriod`,
        null,
        null,
        true,
        null,
        true,
      );
      if (result?.data?.properties?.data_list) {
        const propertiesDateList = result.data.properties.data_list;
        let parsedOptions = parseOptions(propertiesDateList);
        let postingPeriodsList = filterActiveRecord(parsedOptions);
        if (isMounted.current) {
          setOptions(postingPeriodsList);
        }
      }
    } catch {}
  }, [isMounted]);

  useEffect(() => {
    getPostingPeriods();
  }, [getPostingPeriods]);

  return (
    <>
      <label className="pickerLabel">
        Posting Period <Mandatory required={required} />
      </label>
      <Controller
        control={control}
        name={name}
        rules={{ required: required ? "This field is required" : false }}
        render={({ field: { value, onChange, ...field }, fieldState }) => (
          <>
            <Select
              {...field}
              isClearable={!required}
              value={options.find((option) => option.value === value) ?? null}
              getOptionLabel={(option) => option.label || ""}
              getOptionValue={(option) => option.value?.toString() || ""}
              className="pickerSelectedOption"
              options={required ? options : [{ label: "-- Select Posting Period --", value: "" }, ...options]}
              required={required}
              onChange={(selected) => {
                setValue(name, selected?.value);
                setValue("posting_period_name", selected?.label || "");
              }}
            />
            {fieldState.error && <PickerErrorBlock error={fieldState.error.message || ""} />}
          </>
        )}
      />
    </>
  );
};

export default PostingPeriodPicker;
