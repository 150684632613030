import { useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { PDFDocument } from "pdf-lib";
import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useInvoiceDetailsContext } from "./invoiceDetailsContext";
import { getFile, handleError } from "./invoiceDetailsService";
import { TAsset } from "./invoices.type";

type FileRenderingContextType = {
  pdfDocumentToRender: PDFDocument | undefined;
  fileToRenderMemo: File | undefined;
  loadFileAsync: (url: string) => Promise<ArrayBuffer | undefined>;
  isMainDocumentLoaded: boolean;
  setIsMainDocumentLoaded: Dispatch<SetStateAction<boolean>>;
  pageNumber: number;
  setPageNumber: Dispatch<SetStateAction<number>>;
  numPages: number | undefined;
  setNumPages: Dispatch<SetStateAction<number | undefined>>;
  resetFileContext: () => void;
  invalidateCurrentInvoiceFileCache: () => void;
};

const QUERY_KEY = "loadInvoiceFile";
const FileRenderingContext = createContext<FileRenderingContextType | undefined>(undefined);

export const FileRenderingProvider: React.FC = ({ children }) => {
  const { t } = useTranslation();
  const { selectedAsset, isSelectedAssetPdf } = useInvoiceDetailsContext();
  const [pdfDocumentToRender, setPdfDocumentToRender] = useState<PDFDocument | undefined>(undefined);
  const [fileToRender, setFileToRender] = useState<File | undefined>(undefined);
  const [isMainDocumentLoaded, setIsMainDocumentLoaded] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [numPages, setNumPages] = useState<number>();
  const queryClient = useQueryClient();
  const fileToRenderMemo = useMemo(() => fileToRender, [fileToRender]);

  const getFilePath = useCallback((asset: TAsset | undefined) => {
    if (!asset) return "";
    try {
      const urlObj = new URL(asset.asset_expiring_url!);
      return `${urlObj.origin}${urlObj.pathname}`;
    } catch (error) {}
    return "";
  }, []);

  const filePath = useMemo(() => getFilePath(selectedAsset), [getFilePath, selectedAsset]);

  const invalidateCurrentInvoiceFileCache = useCallback(() => {
    queryClient.removeQueries([QUERY_KEY, selectedAsset?.id]);
  }, [queryClient, selectedAsset?.id]);

  const resetFileContext = useCallback(() => {
    setPdfDocumentToRender(undefined);
    setFileToRender(undefined);
    setIsMainDocumentLoaded(false);
    setPageNumber(1);
    setNumPages(undefined);
  }, []);

  const loadFileAsync = useCallback(async (fileUrl: string): Promise<ArrayBuffer | undefined> => {
    if (!fileUrl) return undefined;
    const response = await axios.get(fileUrl, { responseType: "arraybuffer" });
    return response.data;
  }, []);

  const { data: fileBuffer, error } = useQuery(
    [QUERY_KEY, filePath, selectedAsset?.id],
    () => loadFileAsync(selectedAsset?.asset_expiring_url!),
    {
      enabled: !!selectedAsset?.asset_expiring_url,
      cacheTime: Infinity,
      staleTime: Infinity,
    },
  );

  useEffect(() => {
    if (error) {
      console.error("Error loading file:", error);
      handleError(error, t);
    }
  }, [error, t]);

  useEffect(() => {
    const processFileBuffer = async () => {
      if (!fileBuffer || !selectedAsset) return;
      try {
        const file = getFile(new Uint8Array(fileBuffer), selectedAsset.asset_file_file_name!);
        setFileToRender(file);
        if (!isSelectedAssetPdf) return;
        const pdfDoc = await PDFDocument.load(fileBuffer);
        if (pdfDoc) {
          setPdfDocumentToRender(pdfDoc);
        }
      } catch (e) {
        console.error("Error processing file:", e);
        handleError(e, t);
      }
    };
    processFileBuffer();
  }, [isSelectedAssetPdf, fileBuffer, selectedAsset, t]);

  useEffect(() => {
    if (!selectedAsset) {
      resetFileContext();
    }
  }, [resetFileContext, selectedAsset]);

  const contextValue = useMemo(
    () => ({
      pdfDocumentToRender,
      fileToRenderMemo,
      loadFileAsync,
      isMainDocumentLoaded,
      setIsMainDocumentLoaded,
      pageNumber,
      setPageNumber,
      numPages,
      setNumPages,
      resetFileContext,
      invalidateCurrentInvoiceFileCache,
    }),
    [
      fileToRenderMemo,
      invalidateCurrentInvoiceFileCache,
      isMainDocumentLoaded,
      loadFileAsync,
      numPages,
      pageNumber,
      pdfDocumentToRender,
      resetFileContext,
    ],
  );

  return <FileRenderingContext.Provider value={contextValue}>{children}</FileRenderingContext.Provider>;
};

export const useFileRenderingContext = (): FileRenderingContextType => {
  const context = useContext(FileRenderingContext);
  if (!context) {
    throw new Error("useFileRenderingContext must be used within a FileRenderingProvider");
  }
  return context;
};
