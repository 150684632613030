import AddVendor from "./add";
import EditVendor from "./edit";
import ListVendor from "./list";
import ListVendorTracker from "./VendorTracker/list";

const VendorRoutes = [
  {
    path: "/ap/vendors",
    exact: true,
    name: "Vendors List",
    component: ListVendor,
    authorization: {
      I: "list",
      a: "Vendors",
    },
  },
  {
    path: "/ap/vendors/add",
    exact: true,
    name: "Add Vendor",
    component: AddVendor,
    authorization: {
      I: "add",
      a: "Vendors",
    },
  },
  {
    path: "/ap/vendor/:id/edit",
    exact: true,
    name: "Edit Vendor",
    component: EditVendor,
    authorization: {
      I: "edit",
      a: "Vendors",
    },
  },
  {
    path: "/ap/vendor_tracker",
    exact: true,
    name: "List Vendor Tracker",
    component: ListVendorTracker,
    authorization: {
      I: "list",
      a: "Vendors",
    },
  },
];

export default VendorRoutes;
