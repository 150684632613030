import useGetValue from "components/admin/hooks/hookFormHooks/useGetValue";
import React, { useCallback, useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import AsyncSelect from "react-select/async";
import { useAutocompleteService, usePlacesService, useSessionToken } from "./mapServiceHooks";

/********************************************************************
 * This component provides a reusable place-search autocomplete input.
 * It integrates with Google Places API to allow users to search for locations.
 * The component uses react-hook-form for form integration and handles
 * both place searching and place detail fetching via Google Maps API.
 ********************************************************************/

type TPlaceAutocomplete = {
  name: string;
  onPlaceSelectCallback: (
    placeDetails: google.maps.places.PlaceResult | null,
    selectedOption: TPlaceOption | null,
  ) => void;
};

type TPlaceOption = { label: string; value: string };

const PlaceAutocomplete = ({ name, onPlaceSelectCallback }: TPlaceAutocomplete) => {
  const [selected, setSelected] = useState<TPlaceOption | null>(null);
  const placesService = usePlacesService();
  const autocompleteService = useAutocompleteService();
  const sessionToken = useSessionToken();
  const currentValueObject = useGetValue(name);
  const [inputValue, setInputValue] = useState("");

  const handleSuggestionClick = useCallback(
    (selectedOption: TPlaceOption | null) => {
      if (selectedOption) {
        const detailRequestOptions = {
          placeId: selectedOption.value ?? "",
          fields: ["geometry", "name", "formatted_address"],
          sessionToken,
        };

        const detailsRequestCallback = (placeDetails: google.maps.places.PlaceResult | null) => {
          setSelected({
            label: placeDetails?.formatted_address ?? "",
            value: selectedOption.value ?? "",
          });

          onPlaceSelectCallback(placeDetails, selectedOption);
        };

        placesService?.getDetails(detailRequestOptions, detailsRequestCallback);
      }
      if (!selectedOption) {
        setSelected(null);
        onPlaceSelectCallback(null, null);
      }

      if (inputValue) {
        setInputValue("");
      }
    },
    [inputValue, onPlaceSelectCallback, placesService, sessionToken],
  );

  const loadOptions: any = useCallback(
    async (inputValue: string) => {
      if (!autocompleteService || !inputValue) {
        return [];
      }
      const request = { input: inputValue, sessionToken };
      const response = await autocompleteService.getPlacePredictions(request);

      const options = response.predictions.map((prediction) => ({
        label: prediction.description,
        value: prediction.place_id,
      }));
      return options;
    },
    [autocompleteService, sessionToken],
  );

  const onInputChange = (inputValue: string) => {
    setInputValue(inputValue);
  };

  useEffect(() => {
    if (currentValueObject) {
      setSelected({
        label: currentValueObject?.address ?? "",
        value: currentValueObject.place_id ?? "",
      });
    }
  }, [currentValueObject]);

  return (
    <Controller
      name={name}
      render={({ field: { value, onChange }, fieldState: { error } }) => {
        return (
          <AsyncSelect
            components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
            value={selected}
            inputValue={inputValue}
            onInputChange={onInputChange}
            isClearable
            loadOptions={loadOptions}
            onChange={(selected) => handleSuggestionClick(selected)}
          />
        );
      }}
    />
  );
};

export default PlaceAutocomplete;
