import React from "react";
import { Col, Row } from "react-bootstrap";

import ServerSideDataGrid from "components/common/dataGrid/serverSideDataGrid/serverSideDataGrid";

import ToggleFilterButton from "components/datagrid/buttons/toggleFilterButton";
import GridFilterDropdown from "components/datagrid/gridFilterDropdown";
import { BulkUploadGridable } from "wombatifier/components/bulk_upload/bulkUploadGridable";
import { BulkUploadFilesRowExpanded } from "./bulkUploadFilesRowExpanded";

const GRID_STORAGE_NAME = "listBulkUpload";

interface BulkUploadsFilesGridPropsType {
  destinationTypes: string[];
}

export const BulkUploadsFilesGrid = ({ destinationTypes = [] }: BulkUploadsFilesGridPropsType) => {
  const { gridApi, gridColumnApi, showFilters, updateShowFilters, gridReady, gridHeaders } = BulkUploadGridable({
    destinationTypes: destinationTypes,
  });

  return (
    <>
      <Row className="px-2 pb-1">
        <Col md="12" className="px-0 d-flex justify-content-between">
          <GridFilterDropdown gridApi={gridApi} gridColumnApi={gridColumnApi} gridStorageName={GRID_STORAGE_NAME} />
          <ToggleFilterButton
            clickCallback={() => {
              updateShowFilters(!showFilters);
            }}
          />
        </Col>
      </Row>
      <Row style={{ height: "70vh" }} className="p-2">
        <ServerSideDataGrid
          columnDefs={gridHeaders.columnDefs}
          defaultColDef={{
            resizable: true,
            filter: true,
            floatingFilter: showFilters,
          }}
          gridReady={gridReady}
          gridStorageName={GRID_STORAGE_NAME}
          domLayout="normal"
          paginationSize={25}
          paginationOptions={[25, 50, 100]}
          pagination
          masterDetail={true}
          detailRowAutoHeight={true}
          detailCellRenderer={BulkUploadFilesRowExpanded}
        />
      </Row>
    </>
  );
};
