import { AxiosResponse } from "axios";
import { AssignVendorInfoPropsType } from "components/admin/invoices/adminInvoiceForm/invoiceHeaderLineSection";
import { ManageInvoiceExpensesType } from "components/admin/invoices/adminInvoiceForm/manageInvoiceExpenseLines/debitLines";
import { AccountObjType } from "components/admin/pickers/reduxFormPickers/accountPicker";
import _, { isNumber } from "lodash";
import moment from "moment";
import { restApiService } from "providers/restApi";
import { change } from "redux-form";
import { CurrenciesPropsType, IDType } from "services/common/types/common.type";
import { IUser } from "services/common/user/userTypes";
import { isDefined } from "services/general/helpers";
import { CreateNotification, NotificationType } from "services/general/notifications";
import { roundUpAmount } from "services/vp/services/roundUpAmount";
import { CommonApis } from "../commonApis";
import adminCommonSvc from "../commonSvc";
import { PurchaseOrderType } from "../purchaseOrders/purchaseOrderType";
import { removeVendor } from "../vendors/vendorSvc";
import InvoiceApis from "./invoiceApis";
import { InvoiceType } from "./invoiceType";
import i18n from "i18n";

class InvoiceCommonSvc {
  //Calculate Rebate for expense lines
  updateSubAmountWithRebate(accontLine: ManageInvoiceExpensesType) {
    if (_.isPlainObject(accontLine) && this.isRebateIncluded(accontLine) && accontLine.rebate_amount) {
      if (!accontLine.actual_sub_amount) {
        accontLine.actual_sub_amount = accontLine.sub_amount;
      }
      accontLine.sub_amount =
        accontLine.sub_amount && accontLine.rebate_amount && accontLine.sub_amount - accontLine.rebate_amount;
    }
  }

  calculateExpenseLineRebate(accountEntry: ManageInvoiceExpensesType, currentUser: IUser): any {
    if (this.allowRebateExpensesLine(currentUser)) {
      if (accountEntry.sub_amount && accountEntry.rebate) {
        accountEntry.rebate_amount = adminCommonSvc.roundUpAmount(
          (accountEntry.sub_amount / 100) * accountEntry.rebate.rate,
          null,
          currentUser,
        );
      } else if (!accountEntry.rebate_amount) {
        accountEntry.rebate = undefined;
        accountEntry.rebate_id = null;
        accountEntry.rebate_amount = null;
      }
    }
    return accountEntry;
  }

  isRebateIncluded = (lineData: InvoiceType.ManageInvoiceItemsType): boolean => {
    if (_.isPlainObject(lineData) && lineData.rebate && lineData.rebate.is_included) {
      return true;
    } else if (_.isPlainObject(lineData) && !lineData.rebate && lineData.rebate_link?.rebate?.is_included) {
      return true;
    } else {
      return false;
    }
  };
  updateUnitPriceWithRebate = (itemLine: InvoiceType.ManageInvoiceItemsType, currentUser: IUser) => {
    if (_.isPlainObject(itemLine) && this.isRebateIncluded(itemLine) && itemLine.rebate_amount) {
      if (!itemLine.actual_unit_price) {
        itemLine.actual_unit_price = itemLine.unit_price;
      }
      let amount =
        itemLine.qty &&
        itemLine.actual_unit_price &&
        itemLine.qty * itemLine.actual_unit_price - itemLine.rebate_amount;
      itemLine.unit_price =
        amount &&
        itemLine?.qty &&
        adminCommonSvc.roundUpAmount(
          amount / itemLine?.qty,
          adminCommonSvc.unitPriceDecimalLimit(currentUser),
          currentUser,
        );
      itemLine.amount = itemLine.qty && itemLine.unit_price && itemLine.qty * itemLine.unit_price;
    }
  };

  async_po_count = 50;
  async_items_count = 50;

  isSetPriorPeriodRange = (currentUser: IUser) => {
    return currentUser?.company?.invoice && currentUser?.company?.invoice?.set_prior_period_range;
  };

  setPriorPeriodRange = (modelData: InvoiceType.InvoiceDetailType, currentUser: IUser) => {
    if (!this.isSetPriorPeriodRange(currentUser)) {
      return false;
    }
    const invoiceSetting = currentUser?.company?.invoice;
    modelData.minPriorDate = invoiceSetting?.prior_period_range_start_date
      ? invoiceSetting.prior_period_range_start_date
      : null; //optional date. It will work on set range_end_date only, if not available.
    modelData.maxPriorDate = invoiceSetting?.prior_period_range_end_date
      ? invoiceSetting.prior_period_range_end_date
      : null; //optional date. It will work on set range_start_date only, if not available.
  };

  isPriorPeriodDateRangeAvailable = (currentUser: IUser) => {
    return (
      this.isSetPriorPeriodRange(currentUser) &&
      _.isPlainObject(currentUser.company.invoice) &&
      (currentUser.company.invoice.prior_period_range_start_date !== null ||
        currentUser.company.invoice.prior_period_range_end_date !== null)
    );
  };

  isPriorPeriodWithinRange = (currentUser: IUser, date: Date, startDate?: string | null, endDate?: string | null) => {
    if (this.isPriorPeriodDateRangeAvailable(currentUser)) {
      let dt = moment(date).format("YYYY-MM-DD");
      if (!endDate && startDate) {
        return dt < startDate;
      } else if (!startDate && endDate) {
        return dt > endDate;
      } else if (startDate && endDate) {
        if (dt >= startDate && dt <= endDate) {
          return false;
        } else {
          return true;
        }
      }
    }
  };

  validatePriorPeriodWithinRange = (modelData: InvoiceType.InvoiceDetailType, currentUser: IUser) => {
    if (modelData.prior_period) {
      return this.isPriorPeriodWithinRange(
        currentUser,
        modelData.prior_period,
        modelData.minPriorDate,
        modelData.maxPriorDate,
      );
    }
  };

  getCreditAccountObj = (vendor: InvoiceType.VendorDetailType) => {
    if (_.isArray(vendor.invoice_credit_accounts) && vendor.invoice_credit_accounts.length > 0) {
      return vendor.invoice_credit_accounts[0];
    } else if (_.isArray(vendor.company_invoice_credit_accounts) && vendor.company_invoice_credit_accounts.length > 0) {
      return vendor.company_invoice_credit_accounts[0];
    }
  };

  assignVendorInfo = async ({
    vendorId,
    invoice,
    currentUser,
    dispatch,
    formName,
    appCurrencyCodes,
  }: AssignVendorInfoPropsType) => {
    try {
      const response: AxiosResponse<InvoiceType.VendorDetailType> = await restApiService.get("vendors/" + vendorId);
      const responseId = response?.data?.id;
      if (responseId) {
        const vendor = response.data;
        dispatch(change(formName, "vendor_id", vendor?.id));
        if (vendor.term) {
          invoice.term = vendor.term;
          invoice.term_id = vendor.term;
          dispatch(change(formName, "term_id", vendor.term.id));
          dispatch(change(formName, "term", vendor.term));

          // updateDueDate
          this.updateDueDate(invoice, dispatch, formName);

          // updateDiscountDate
          this.updateDiscountDate(invoice, dispatch, formName, currentUser);

          // updateDiscountAmount
          this.updateDiscountAmount(invoice, dispatch, formName, currentUser);
        }

        if (vendor.vendor_id) {
          invoice.vendor.vendor_id = vendor.vendor_id;
          invoice.vendor.po_not_required = vendor.po_not_required;
          invoice.vendor.is_1099_eligible = vendor.is_1099_eligible;
          invoice.vendor.has_vendor_locations = vendor.has_vendor_locations;
          dispatch(change(formName, "no_push", vendor.no_push));
          dispatch(change(formName, "vendor", invoice.vendor));
        }

        if (vendor.subsidiary) {
          const vendorSubsidiary = _.find(vendor.subsidiaries, { id: invoice.subsidiary_id });
          if (!vendorSubsidiary) {
            invoice.subsidiary = await vendor?.subsidiary;
            dispatch(change(formName, "subsidiary", vendor.subsidiary));
            dispatch(change(formName, "subsidiary_id", vendor.subsidiary?.id));

            await adminCommonSvc.refreshLocationPicker(invoice, invoice.subsidiary, currentUser);
          }
          invoice.is_projects_linked_to_subsidiary = vendor.is_projects_linked_to_subsidiary;
          if (
            vendor?.subsidiary?.location_id &&
            vendor?.subsidiary?.location_id > 0 &&
            currentUser?.company?.has_locations
          ) {
            invoice.location_id = vendor.subsidiary.location_id;
            dispatch(change(formName, "location_id", invoice.location_id));
          }
        }

        let currency = _.find(vendor.currency_codes, { code: invoice?.currency_code });

        if (!invoice?.po_global_search) {
          // only used when global po search is used
          if (vendor.currency_code && (!currency || !invoice.id)) {
            invoice.currency_code = vendor.currency_code;
            invoice.currency = _.find(appCurrencyCodes, { value: invoice?.currency_code })?.symbol;
          } else if (currentUser?.company?.currency && !currency) {
            invoice.currency_code = currentUser?.company?.currency?.iso_code;
            invoice.currency = _.find(appCurrencyCodes, { value: invoice?.currency_code })?.symbol;
          }
          dispatch(change(formName, "currency_code", invoice.currency_code));
        }

        if (invoice.term) {
          invoice.term_id = invoice.term.id;
        }

        let creditAccount: any = this.getCreditAccountObj(vendor);
        if (creditAccount) {
          if (!_.isArray(invoice.credit_entries_attributes)) {
            invoice.credit_entries_attributes = [
              {
                hide_exp_account: true,
                account_id: creditAccount.account_id,
              },
            ];
          }
          dispatch(change(formName, "credit_entries_attributes", invoice.credit_entries_attributes));
        }
      }
    } catch (error) {
      throw error;
    }
  };

  getCurrenciesWithSymbol = (currencies: CurrenciesPropsType, companyCurrencies: CurrenciesPropsType[]) => {
    if (_.isArray(currencies) && currencies.length > 0) {
      return currencies.map(
        (currency: { code: string; value?: string; label?: string; symbol?: string; primary: boolean }) => {
          const currencyObj = _.find(companyCurrencies, { value: currency.code });
          if (currencyObj) {
            currency.symbol = currencyObj.symbol;
            currency.value = currencyObj.value;
            currency.label = `${currencyObj.label}`;
          }
          return currency;
        },
      );
    }
  };

  getVendorCurrencies = async (invoice: InvoiceType.InvoiceDetailType, companyCurrencies: CurrenciesPropsType[]) => {
    try {
      let vendorId = invoice?.vendor_id;
      const response: AxiosResponse = await restApiService.get(`vendors/${vendorId}/currency_codes`);
      const currenciesResponse = response?.data?.currency_codes;
      return this.getCurrenciesWithSymbol(currenciesResponse, companyCurrencies);
    } catch (error) {
      throw error;
    }
  };

  markDeleteHeaderTaxRates = (taxRateAmount: any) => {
    if (_.isArray(taxRateAmount) && taxRateAmount.length > 0) {
      taxRateAmount.forEach((taxRateAmount) => {
        taxRateAmount._destroy = 1;
      });
    }
  };

  getInvoiceSubTotalAmount = (invoice: InvoiceType.InvoiceDetailType, currentUser: IUser) => {
    return roundUpAmount(this.getInvoiceItemsTotal(invoice, currentUser) + this.getAccountsTotal(invoice));
  };

  checkTaxRateAtHeader = (taxRateAmounts: any, taxRate: any) => {
    if (!_.isArray(taxRateAmounts)) {
      return false;
    }

    let found = false;

    taxRateAmounts.forEach((taxRateAmount) => {
      if (taxRateAmount.tax_rate_id === taxRate.id) {
        taxRateAmount._destroy = undefined;
        taxRateAmount.tax_rate = taxRate;
        found = true;
      }
    });

    return found;
  };

  updateHeaderTaxRates = (taxRateAmounts: any, taxRates: any) => {
    taxRates.forEach((taxRate: any) => {
      if (!this.checkTaxRateAtHeader(taxRateAmounts, taxRate)) {
        taxRateAmounts.push({ tax_rate_id: taxRate.id, amount: 0, tax_rate: taxRate });
      }
    });
  };

  debitLineTaxRates = (invoice: InvoiceType.InvoiceDetailType) => {
    if (_.isArray(invoice.debit_entries_attributes) && invoice.debit_entries_attributes.length > 0) {
      invoice.debit_entries_attributes.forEach((entry) => {
        if (
          entry._destroy != 1 &&
          !entry.product_item_id &&
          !entry.is_header_level_tax &&
          entry.tax_code &&
          entry.tax_code.tax_rates
        ) {
          this.updateHeaderTaxRates(invoice.tax_rate_amounts, entry.tax_code.tax_rates);
        }
      });
    }
  };

  itemLineTaxRates = (invoice: InvoiceType.InvoiceDetailType) => {
    if (_.isArray(invoice.invoice_items_attributes) && invoice.invoice_items_attributes.length > 0) {
      invoice.invoice_items_attributes.forEach((item) => {
        if (item._destroy != 1 && item.tax_code && item.tax_code.tax_rates) {
          this.updateHeaderTaxRates(invoice.tax_rate_amounts, item.tax_code.tax_rates);
        }
      });
    }
  };

  manageHeaderLevelTaxRates = (invoice: InvoiceType.InvoiceDetailType) => {
    if (!invoice.tax_rate_amounts) {
      invoice.tax_rate_amounts = [];
    }
    this.markDeleteHeaderTaxRates(invoice.tax_rate_amounts);
    this.debitLineTaxRates(invoice);
    this.itemLineTaxRates(invoice);
    return invoice.tax_rate_amounts;
  };

  isActiveHeaderTax = (invoice: any, currentUser: IUser) => {
    const apply_tax_on_line_and_store_header_level =
      currentUser?.company?.global?.apply_tax_on_line_and_store_header_level;
    if (invoice && typeof invoice === "object") {
      return apply_tax_on_line_and_store_header_level && (!invoice.subsidiary || !invoice?.subsidiary?.allow_line_tax);
    } else {
      return apply_tax_on_line_and_store_header_level;
    }
  };

  calculateWhTax = (entry: any, currentUser: IUser) => {
    if (entry.amount && entry.wh_tax_code) {
      entry.wh_tax_amount = roundUpAmount(-(entry.amount / 100) * entry.wh_tax_code.rate);
    } else if (currentUser?.company?.global?.enable_to_enter_wh_tax_amount) {
      entry.wh_tax_code = undefined;
      entry.wh_tax_id = null;
    } else {
      entry.wh_tax_code = undefined;
      entry.wh_tax_id = null;
      entry.wh_tax_amount = null;
    }
    return entry;
  };

  isTaxRate = (taxRates: any, taxRateId: number) => taxRates.some((taxRate: any) => taxRate.id == taxRateId);

  isTaxRateAtLine = (entry: any, taxRateId: any) => {
    return entry.tax_code && _.isArray(entry.tax_code.tax_rates) && this.isTaxRate(entry.tax_code.tax_rates, taxRateId);
  };

  taxAmount = (amount: number, rate: number) => {
    return roundUpAmount((amount / 100) * rate);
  };

  //it will return the tax object from the line group by tax id
  getLinkedTaxToGroup = function (lineEntry: any, taxRateId: any) {
    let tax = {};
    if (lineEntry.tax_code && lineEntry.tax_code.tax_rates) {
      tax = _.find(lineEntry.tax_code.tax_rates, { id: taxRateId });
    }
    return tax;
  };

  lineTaxAmount = (amount: any, taxObj: any) => {
    let tax = 0;
    if (amount && taxObj?.group_link_rate) {
      tax = this.taxAmount(amount, taxObj.group_link_rate);
    }
    return tax;
  };

  //it will calculate total tax of expenses line if one tax but multiple tax rates linked according to the group tax
  calculateExpenseLineTaxAmount = (debitEntriesAttributes: any, taxRateId: any) => {
    let expensesTotalTax = 0;
    if (_.isArray(debitEntriesAttributes) && debitEntriesAttributes.length > 0) {
      debitEntriesAttributes.forEach((entry) => {
        if (
          parseFloat(entry.amount) &&
          entry._destroy != 1 &&
          !entry.product_item_id &&
          !entry.is_header_level_tax &&
          this.isTaxRateAtLine(entry, taxRateId)
        ) {
          let taxObj = this.getLinkedTaxToGroup(entry, taxRateId);
          expensesTotalTax += this.lineTaxAmount(entry.amount, taxObj);
        }
      });
    }
    return expensesTotalTax;
  };

  //it will calculate total tax of item line if one tax but multiple tax rates linked according to the group tax
  calculateItemLineTaxAmount = (invoiceItemsAttributes: any, taxRateId: any) => {
    let itemsTotalTax = 0;
    if (_.isArray(invoiceItemsAttributes) && invoiceItemsAttributes.length > 0) {
      invoiceItemsAttributes.forEach((item) => {
        if (item._destroy != 1 && this.isTaxRateAtLine(item, taxRateId)) {
          let taxObj = this.getLinkedTaxToGroup(item, taxRateId);
          itemsTotalTax += this.lineTaxAmount(item.amount, taxObj);
        }
      });
    }
    return itemsTotalTax;
  };

  calculateExpenseLineSubAmount = (debitEntriesAttributes: any, taxRateId: any) => {
    let total = 0;
    if (_.isArray(debitEntriesAttributes) && debitEntriesAttributes.length > 0) {
      debitEntriesAttributes.forEach((entry) => {
        if (
          parseFloat(entry.amount) &&
          entry._destroy != 1 &&
          !entry.product_item_id &&
          !entry.is_header_level_tax &&
          this.isTaxRateAtLine(entry, taxRateId)
        ) {
          total += parseFloat(entry.amount);
        }
      });
    }
    return total;
  };

  calculateItemLineSubAmount = (invoiceItemsAttributes: any, taxRateId: any) => {
    let total = 0;
    if (_.isArray(invoiceItemsAttributes) && invoiceItemsAttributes.length > 0) {
      invoiceItemsAttributes.forEach((item) => {
        if (item._destroy != 1 && this.isTaxRateAtLine(item, taxRateId)) {
          total += parseFloat(item.amount);
        }
      });
    }
    return total;
  };

  //calculate tax amount using sub amount
  headerLineTaxAmount = (subAmount: any, taxRateAmount: any) => {
    let tax = 0;
    if (subAmount && taxRateAmount.tax_rate) {
      tax = this.taxAmount(subAmount, taxRateAmount.tax_rate.rate);
    }
    return tax;
  };

  // Inherits the header department and location to the item line if the condition is met.
  inheritHeaderToItemLine = (invoice: InvoiceType.InvoiceDetailType, item: any, currentUser: IUser, flag?: boolean) => {
    if (flag === undefined) {
      flag = false;
    }

    if (
      currentUser?.company?.invoice?.items?.inherit_header_department &&
      (!flag || (flag && currentUser?.company?.invoice?.items?.department?.is_hide))
    ) {
      item.department_id = { ...invoice }.department_id;
    }

    if (
      currentUser?.company?.invoice?.items?.inherit_header_location &&
      (!flag || (flag && currentUser?.company?.invoice_item_hide_location))
    ) {
      item.location_id = { ...invoice }.location_id;
    }
  };

  // Inherits the header department and location to the expense line if the condition is met.
  inheritHeaderToExpenseLine = (
    invoice: InvoiceType.InvoiceDetailType,
    debitEntry: any,
    currentUser: IUser,
    flag?: boolean,
  ) => {
    if (
      currentUser?.company?.invoice?.expenses?.inherit_header_department &&
      (!flag || (flag && currentUser?.company?.invoice?.expenses?.department?.is_hide))
    ) {
      debitEntry.department_id = { ...invoice }.department_id;
    }

    if (
      currentUser?.company?.invoice?.expenses?.inherit_header_location &&
      (!flag || (flag && currentUser?.company?.invoice_account_hide_location))
    ) {
      debitEntry.location_id = { ...invoice }.location_id;
    }
  };

  calculateHeaderLevelTax = (invoice: InvoiceType.InvoiceDetailType) => {
    invoice.tax_rate_amounts = this.manageHeaderLevelTaxRates(invoice);
    if (_.isArray(invoice.tax_rate_amounts) && invoice.tax_rate_amounts.length > 0) {
      invoice.tax_rate_amounts.forEach((taxRateAmount) => {
        taxRateAmount.amount = 0;
        taxRateAmount.sub_amount = 0;

        if (taxRateAmount.tax_rate && isNumber(taxRateAmount.tax_rate.group_link_rate)) {
          taxRateAmount.amount += this.calculateExpenseLineTaxAmount(
            invoice.debit_entries_attributes,
            taxRateAmount.tax_rate_id,
          );
          taxRateAmount.amount += this.calculateItemLineTaxAmount(
            invoice.invoice_items_attributes,
            taxRateAmount.tax_rate_id,
          );
        } else {
          let subAmount = 0;
          subAmount += this.calculateExpenseLineSubAmount(invoice.debit_entries_attributes, taxRateAmount.tax_rate_id);
          subAmount += this.calculateItemLineSubAmount(invoice.invoice_items_attributes, taxRateAmount.tax_rate_id);
          taxRateAmount.amount = this.headerLineTaxAmount(subAmount, taxRateAmount);
          taxRateAmount.sub_amount = subAmount;
        }
      });
    }
  };

  removeAmortizationSchedule = (copiedObj: any, type: string) => {
    delete copiedObj.amortization;
    delete copiedObj.amortization_id;
    delete copiedObj.amortization_name;
    delete copiedObj.amortization_schedule_name;
    if (type === "account") {
      delete copiedObj.start_date;
      delete copiedObj.end_date;
    } else if (type === "item") {
      delete copiedObj.amortization_end_date;
      delete copiedObj.amortization_start_date;
    }
  };

  mapAccountRequiredFields = (
    account: AccountObjType,
    currentUser: IUser,
    accountObj: any,
    modelName: string | null = null,
  ) => {
    account = _.isPlainObject(account) ? account : {};
    modelName = modelName ? modelName?.toLowerCase() : null;
    if (modelName !== "payment" || currentUser?.company?.has_account_structures) {
      const {
        is_department_required,
        is_location_required,
        is_project_required,
        is_hide_project,
        is_grant_required,
        is_business_unit_required,
        is_inter_company_required,
        is_warehouse_required,
      } = account;

      accountObj.is_department_required = is_department_required;
      accountObj.is_location_required = is_location_required;
      accountObj.is_project_required = is_project_required;
      accountObj.is_hide_project = is_hide_project;
      accountObj.is_grant_required = is_grant_required;
      accountObj.is_business_unit_required = is_business_unit_required;
      accountObj.is_inter_company_required = is_inter_company_required;
      accountObj.is_warehouse_required = is_warehouse_required;

      if (is_hide_project) {
        accountObj.project_id = null;
      }
    }
  };

  show1099Eligible = (parentObjData: any, currentUser: IUser) => {
    return currentUser?.company?.invoice?.show_eligible_1099_on_lines && parentObjData?.vendor?.is_1099_eligible;
  };

  assign1099Eligible = (parentObjData: any, obj: any, currentUser: IUser) => {
    if (this.show1099Eligible(parentObjData, currentUser)) {
      obj.eligible_1099 = parentObjData?.vendor.is_1099_eligible;
    }
  };

  updateDiscountAmount(invoice: InvoiceType.InvoiceDetailType, dispatch: any, formName: string, currentUser: IUser) {
    if (!currentUser.company.invoice_hide_discount && invoice) {
      const discAmount = adminCommonSvc.calculateDiscountAmt(invoice, currentUser);
      invoice.amount_disc = discAmount;
      dispatch(change(formName, "amount_disc", discAmount));
    }
  }

  updateDiscountDate(invoice: InvoiceType.InvoiceDetailType, dispatch: any, formName: string, currentUser: IUser) {
    if (!currentUser.company.invoice_hide_discount && invoice) {
      const discDate = adminCommonSvc.calculateDiscDate(invoice);
      invoice.amount_disc_date = discDate;
      dispatch(change(formName, "amount_disc_date", discDate));
    }
  }

  updateDueDate(invoice: InvoiceType.InvoiceDetailType, dispatch: any, formName: string) {
    if (invoice) {
      const dueDate = adminCommonSvc.calculateDueDate(invoice);
      invoice.due_date = dueDate;
      dispatch(change(formName, "due_date", dueDate));
    }
  }

  isProjectRequired(modelData: any, currentUser: IUser, isInvoiceItem: boolean = false) {
    const companyInvoice = currentUser?.company?.invoice;

    return (
      modelData?.is_project_required ||
      (isInvoiceItem && companyInvoice?.items?.project?.is_required) ||
      (!isInvoiceItem && companyInvoice?.expenses?.project?.is_required)
    );
  }

  /**
   *
   * @param companySetting normal company setting if field is required
   * @param overrideFalse if we need to specifically make this field not required, overrides company setting
   * @returns
   */
  isFieldRequired(companySetting: boolean | undefined, overrideFalse: boolean = false) {
    if (overrideFalse) {
      return false;
    }
    return companySetting;
  }

  getDuplicateRow = (modelData: any, modelDataType: string) => {
    const propertiesToUndefined: any = {
      item: ["linked_txn_external_line_id", "linked_txn_external_id", "inherit_po_item_id"],
      account: ["is_expense_received", "is_matched"],
    };
    const duplicatedData = { ...modelData };
    const properties = propertiesToUndefined[modelDataType] || [];

    properties?.forEach((key: string) => {
      duplicatedData[key] = undefined;
    });
    delete duplicatedData.id;
    this.removeAmortizationSchedule(duplicatedData, modelDataType);
    return duplicatedData;
  };

  hideAccountTab = (accountType: string, currentUser: IUser) => {
    const company = currentUser?.company;

    if (accountType === "credit accounts") {
      return company?.invoice_hide_credit_account;
    } else if (accountType === "debit accounts") {
      return company?.invoice?.hide_debit_account;
    } else {
      return company?.invoice_hide_credit_account && company?.invoice?.hide_debit_account;
    }
  };

  allowDisableTaxByAmountsAre = (amountsAre: string) => {
    return amountsAre === "Out of scope of tax";
  };

  isItemExist = (invoiceItems: any) => {
    let itemsArr;

    if (_.isArray(invoiceItems) && invoiceItems.length > 0) {
      itemsArr = invoiceItems?.filter((item: any) => item._destroy !== 1);
    }
    return _.isUndefined(itemsArr) ? [] : itemsArr;
  };

  isDebitExist = (invoiceDebitItems: any) => {
    let debitItemsArr;

    if (_.isArray(invoiceDebitItems) && invoiceDebitItems.length > 0) {
      debitItemsArr = invoiceDebitItems?.filter((item: any) => item._destroy !== 1);
    }
    return _.isUndefined(debitItemsArr) ? [] : debitItemsArr;
  };

  isTaxEnableToExpenses = (currentUser: IUser) => {
    return currentUser?.company?.has_taxes && currentUser?.company?.is_tax_to_invoice_expenses_line;
  };

  isNexusField = (invoice: InvoiceType.InvoiceDetailType) => {
    return invoice.custom_fields && invoice.custom_fields["nexus"];
  };

  isInvoiceLineLevelTaxRequired = (invoice: InvoiceType.InvoiceDetailType, currentUser: IUser) => {
    return (
      currentUser?.company?.invoice?.is_tax_code_required_on_line_level ||
      (this.isNexusField(invoice) && invoice.currency_code !== "USD")
    );
  };

  totalTaxAmount = (invoice: InvoiceType.InvoiceDetailType) => {
    let total = 0;
    invoice?.tax_rate_amounts?.forEach((taxRateAmount) => {
      if (taxRateAmount !== 1) {
        total += parseFloat(taxRateAmount.amount);
      }
    });
    return total;
  };

  getInvoiceItemUseTaxTotal = (invoice: InvoiceType.InvoiceDetailType) => {
    let invoiceItemUseTaxTotal = 0;
    _.isArray(invoice.invoice_items_attributes) &&
      invoice.invoice_items_attributes?.forEach((invoiceItem) => {
        if (invoiceItem._destroy != 1 && invoice.is_used_tax) {
          // invoiceItemUseTaxTotal += invoiceItem.tax ? parseFloat(invoiceItem.tax) : 0;
          invoiceItemUseTaxTotal += invoiceItem.tax ? invoiceItem.tax : 0;
        }
      });
    return invoiceItemUseTaxTotal;
  };

  getAcccountUseTaxTotal = (invoice: InvoiceType.InvoiceDetailType) => {
    let invoiceAccountUseTaxTotal = 0;

    if (_.isArray(invoice?.debit_entries_attributes) && invoice?.debit_entries_attributes?.length > 0) {
      invoice?.debit_entries_attributes?.forEach((debitEntriesAttribute) => {
        if (
          parseFloat(debitEntriesAttribute?.amount) &&
          debitEntriesAttribute?._destroy != 1 &&
          !debitEntriesAttribute?.product_item_id &&
          !debitEntriesAttribute?.is_header_level_tax
        ) {
          if (invoice.is_account_used_tax) {
            invoiceAccountUseTaxTotal += debitEntriesAttribute.tax ? parseFloat(debitEntriesAttribute.tax) : 0;
          }
        }
      });
    }
    return invoiceAccountUseTaxTotal;
  };

  getItemTotal = (invoice: InvoiceType.InvoiceDetailType, invoiceItem: any, currentUser: IUser) => {
    if (invoiceItem) {
      let amt = 0;
      let amount = parseFloat(invoiceItem.amount) || 0;
      let tax = parseFloat(invoiceItem.tax) || 0;
      let rebateAmount = parseFloat(invoiceItem.rebate_amount) || 0;
      this.makeWhTaxNegative(invoiceItem, currentUser);
      let whTaxAmount = parseFloat(invoiceItem.wh_tax_amount) || 0;

      let itemsObj = {
        invoiceSubTotal: 0,
        invoiceTaxesTotal: 0,
        invoiceWHTaxesTotal: 0,
        invoiceRebatTotal: 0,
        invoiceItemUseTaxesTotal: 0,
        subTotalCharges: 0,
      };

      if (invoice.is_used_tax) {
        amt = amount;
        itemsObj.invoiceItemUseTaxesTotal += tax;
      } else {
        amt = amount + tax + whTaxAmount + rebateAmount;
        itemsObj.invoiceTaxesTotal += tax;
        itemsObj.invoiceRebatTotal += rebateAmount;
      }

      if (invoiceItem?.wh_tax_amount) {
        itemsObj.invoiceWHTaxesTotal += whTaxAmount;
      }

      itemsObj.invoiceSubTotal += amount;
      invoice.itemsObj = { ...itemsObj };

      invoiceItem.total = roundUpAmount(amt);
      return invoiceItem.total;
    }
  };

  getInvoiceItemsTotal = (invoice: InvoiceType.InvoiceDetailType, currentUser: IUser, isItemsHeaderLabel = false) => {
    let total = 0;
    if (_.isArray(invoice?.invoice_items_attributes) && invoice?.invoice_items_attributes?.length > 0) {
      invoice?.invoice_items_attributes?.forEach((item) => {
        if (item._destroy !== 1 && item.account_id) {
          total += this.getItemTotal(invoice, item, currentUser);
        }
      });
    }
    return roundUpAmount(total);
  };

  getInvoiceItemsTotals = (invoice: InvoiceType.InvoiceDetailType | undefined): InvoiceType.InvoiceItemsTotals => {
    const totals = {
      invoiceSubTotal: 0,
      invoiceTaxesTotal: 0,
      invoiceWHTaxesTotal: 0,
      invoiceRebateTotal: 0,
    };
    _.isArray(invoice?.invoice_items_attributes) &&
      invoice?.invoice_items_attributes?.forEach((invoiceItem: InvoiceType.TInvoiceItem) => {
        if (!invoiceItem._destroy) {
          if (invoiceItem.amount && _.isNumber(Number(invoiceItem.amount))) {
            totals.invoiceSubTotal += Number(invoiceItem.amount);
          }
          if (invoiceItem.tax && _.isNumber(Number(invoiceItem.tax))) {
            totals.invoiceTaxesTotal += Number(invoiceItem.tax);
          }
          if (invoiceItem.wh_tax_amount && _.isNumber(Number(invoiceItem.wh_tax_amount))) {
            totals.invoiceWHTaxesTotal += Number(invoiceItem.wh_tax_amount);
          }
          if (invoiceItem.rebate_amount && _.isNumber(Number(invoiceItem.rebate_amount))) {
            totals.invoiceRebateTotal += Number(invoiceItem.rebate_amount);
          }
        }
      });
    return totals;
  };

  isSubtotalUsedTaxExits = (invoice: InvoiceType.InvoiceDetailType) => {
    let isUsedTaxExits = false;
    if (invoice?.tax_debit_entries_attributes && invoice.tax_debit_entries_attributes.length > 0) {
      invoice.tax_debit_entries_attributes.forEach((entry: any) => {
        if (entry._destroy !== 1) {
          isUsedTaxExits = entry;
          if (invoice.itemsObj) {
            invoice.itemsObj.subTotalUsedTax = entry.amount;
          }
        }
      });
    }
    return isUsedTaxExits;
  };

  getSubtotalChargesTotal = (debit_entries_attributes: any) => {
    let totalSubtotals = 0;
    debit_entries_attributes?.forEach((entry: any) => {
      // Check if the entry is a subtotal and not marked for deletion
      if (entry._destroy !== 1 && (entry.subtotal_template_id || entry.is_subtotal_template)) {
        totalSubtotals += parseFloat(entry.amount);
      }
    });

    return totalSubtotals;
  };

  calculateSubtotalTax = (invoice: InvoiceType.InvoiceDetailType, entry: any, currentUser: IUser) => {
    let entryTotal = invoice.is_used_tax
      ? this.getInvoiceItemsTotal(invoice, currentUser)
      : this.getAccountsTotal(invoice);
    let subTotalChargesTotal = 0;

    subTotalChargesTotal = this.getSubtotalChargesTotal(invoice.debit_entries_attributes);
    if (invoice.is_subtotal_charges_in_usetax && subTotalChargesTotal) {
      entryTotal += subTotalChargesTotal;
    }

    if (entryTotal && entry.tax_code) {
      entry.amount = roundUpAmount((entryTotal / 100) * entry.tax_code.rate);
    }

    invoice.itemsObj = { ...invoice.itemsObj, subTotalTax: 0 };
  };

  updateSubtotalUsetax = (invoice: InvoiceType.InvoiceDetailType, currentUser: IUser, flag?: boolean) => {
    if (invoice.is_used_tax && (!flag || (flag && invoice.is_subtotal_charges_in_usetax))) {
      let obj = this.isSubtotalUsedTaxExits(invoice);
      if (obj) {
        this.calculateSubtotalTax(invoice, obj, currentUser);
      }
    }
  };

  getAccountsTotal = (invoice: InvoiceType.InvoiceDetailType) => {
    let total = 0;

    let itemsObj = {
      invoiceAccountUseTaxTotal: 0,
    };

    if (_.isArray(invoice?.debit_entries_attributes) && invoice?.debit_entries_attributes?.length > 0) {
      invoice?.debit_entries_attributes?.forEach((debit_entries_attribute) => {
        if (
          parseFloat(debit_entries_attribute?.amount) &&
          debit_entries_attribute?._destroy !== 1 &&
          !this.isItemDebitLine(debit_entries_attribute) &&
          !debit_entries_attribute?.is_header_level_tax
        ) {
          if (invoice.is_account_used_tax) {
            itemsObj.invoiceAccountUseTaxTotal += debit_entries_attribute.tax;
          }
          total += parseFloat(debit_entries_attribute.amount);
        }
        invoice.itemsObj = { ...invoice.itemsObj, ...itemsObj };
      });
    }
    return roundUpAmount(total);
  };

  getInvoiceTotalAmount = (invoice: InvoiceType.InvoiceDetailType, currentUser: IUser, dispatch?: any) => {
    if (!isDefined(invoice?.amount)) {
      return;
    }

    const itemsTotal = this.getInvoiceItemsTotal(invoice, currentUser);
    const accountsTotal = this.getAccountsTotal(invoice);
    const totalTaxAmount = this.totalTaxAmount(invoice);

    invoice.amount = this.isActiveHeaderTax(invoice, currentUser)
      ? roundUpAmount(itemsTotal + accountsTotal + totalTaxAmount)
      : roundUpAmount(itemsTotal + accountsTotal);
  };

  allowRebateExpensesLine = (currentUser: IUser) => {
    return currentUser.company.has_rebates && currentUser.company.global?.allow_rebate_at_expenses_line;
  };

  enabledExpenseTaxOrRebate = (currentUser: IUser) => {
    return (
      (currentUser?.company?.has_taxes && currentUser?.company?.is_tax_to_invoice_expenses_line) ||
      this.allowRebateExpensesLine(currentUser)
    );
  };

  allowRebateItemsLine = (currentUser: IUser) => {
    return currentUser?.company?.has_rebates && currentUser?.company?.global.allow_rebate_at_items_line;
  };

  resetAccounts = (invoice: InvoiceType.InvoiceDetailType) => {
    if (invoice?.debit_entries_attributes && invoice?.debit_entries_attributes?.length > 0) {
      invoice?.debit_entries_attributes?.forEach((debit_entries_attribute) => {
        debit_entries_attribute._destroy = 1;
      });
    }
  };
  calculateAmount = (entry: any) => {
    return roundUpAmount(parseFloat(entry.unit_price) * parseFloat(entry.qty));
  };

  makeWhTaxNegative = (entry: any, currentUser: IUser) => {
    const enable_to_enter_wh_tax_amount = currentUser?.company?.global?.enable_to_enter_wh_tax_amount;
    if (entry && entry.wh_tax_amount > 0 && enable_to_enter_wh_tax_amount) {
      entry.wh_tax_amount = -entry.wh_tax_amount;
    }
  };

  calculateExpenseLineAmount = (invoice: InvoiceType.InvoiceDetailType, debitEntry: any, currentUser: IUser) => {
    debitEntry.tax = roundUpAmount(parseFloat(debitEntry.tax) ? debitEntry.tax : 0);
    debitEntry.sub_amount = roundUpAmount(parseFloat(debitEntry.sub_amount) ? debitEntry.sub_amount : 0);
    this.makeWhTaxNegative(debitEntry, currentUser);
    let tax = parseFloat(debitEntry.tax) || 0;
    let subAmount = parseFloat(debitEntry.sub_amount) || 0;
    let whTaxAmount = parseFloat(debitEntry.wh_tax_amount) || 0;
    let rebateAmount = parseFloat(debitEntry.rebate_amount) || 0;

    if (invoice.is_account_used_tax) {
      debitEntry.amount = roundUpAmount(subAmount);
    } else {
      debitEntry.amount = roundUpAmount(subAmount + tax + whTaxAmount + rebateAmount);
    }
  };

  calculateExpenseLineWhTax = (accountEntry: any, currentUser: IUser) => {
    if (accountEntry.sub_amount && accountEntry.wh_tax_code) {
      accountEntry.wh_tax_amount = roundUpAmount(-(accountEntry?.sub_amount / 100) * accountEntry?.wh_tax_code?.rate);
    } else if (currentUser?.company?.global?.enable_to_enter_wh_tax_amount) {
      accountEntry.wh_tax_code = null;
      accountEntry.wh_tax_id = null;
    } else {
      accountEntry.wh_tax_code = null;
      accountEntry.wh_tax_id = null;
      accountEntry.wh_tax_amount = null;
    }
    return accountEntry;
  };

  getSubtotalUsedTaxTotal = (invoice: InvoiceType.InvoiceDetailType) => {
    let total = 0;
    if (_.isArray(invoice?.tax_debit_entries_attributes) && invoice?.tax_debit_entries_attributes?.length > 0) {
      invoice.tax_debit_entries_attributes.forEach((entry: any) => {
        if (entry._destroy !== 1) {
          total = entry.amount;
        }
      });
    }
    return roundUpAmount(total);
  };

  getUseTaxTotal = (invoice: InvoiceType.InvoiceDetailType) => {
    let invoiceItemUseTaxTotal = this.getInvoiceItemUseTaxTotal(invoice);
    let invoiceAccountUseTaxTotal = this.getAcccountUseTaxTotal(invoice);
    return roundUpAmount(invoiceItemUseTaxTotal + this.getSubtotalUsedTaxTotal(invoice) + invoiceAccountUseTaxTotal);
  };

  normalizeFieldValues = (entries: any) => {
    if (_.isArray(entries) && entries.length > 0) {
      entries.forEach((entry) => {
        if (entry.amount) {
          entry.amount = parseFloat(entry.amount);
        }

        if (entry.percent) {
          entry.percent = parseFloat(entry.percent);
        }

        if (entry.sub_amount) {
          entry.sub_amount = parseFloat(entry.sub_amount);
        }
      });
    }
  };

  validateSubtotalTemplate = (debitEntriesAttributes: any) => {
    let arr: any = [];
    let bool = false;

    if (_.isArray(debitEntriesAttributes) && debitEntriesAttributes.length > 0) {
      debitEntriesAttributes.forEach((debitEntry) => {
        if (debitEntry.subtotal_template_id && debitEntry._destroy != 1) {
          if (arr.indexOf(debitEntry.subtotal_template_id) != -1) {
            bool = true;
          }
          arr.push(debitEntry.subtotal_template_id);
        }
      });
    }
    return bool;
  };

  inheritInvoiceItemTaxSubtotal = (val: any, currentUser: IUser, invoice: InvoiceType.InvoiceDetailType) => {
    if (
      currentUser.company.invoice_use_tax_subtotal_hide_location ||
      currentUser.company.invoice_use_tax_subtotal_hide_department ||
      currentUser.company.invoice_use_tax_subtotal_hide_project
    ) {
      const invoiceItem: { [key: string]: any } = this.getFirstInvoiceItem(invoice);

      if (currentUser.company.invoice_use_tax_subtotal_hide_location) {
        val.location_id = invoiceItem.location_id;
      }

      if (currentUser.company.invoice_use_tax_subtotal_hide_department) {
        val.department_id = invoiceItem.department_id;
      }

      if (currentUser.company.invoice_use_tax_subtotal_hide_project) {
        val.project_id = invoiceItem.project_id;
      }
    }
  };

  getTaxDebit = (taxDebitEntries: any, currentUser: IUser, invoice: InvoiceType.InvoiceDetailType) => {
    let obj = {};
    if (_.isArray(taxDebitEntries) && taxDebitEntries.length > 0) {
      taxDebitEntries.forEach((taxDebitEntry) => {
        if (!taxDebitEntry._destroy) {
          this.inheritInvoiceItemTaxSubtotal(taxDebitEntry, currentUser, invoice);
          obj = { ...taxDebitEntry };
        }
      });
    }
    return obj;
  };

  removeTax = (invoice: InvoiceType.InvoiceDetailType) => {
    if (_.isArray(invoice.debit_entries_attributes)) {
      invoice.debit_entries_attributes.forEach((entries) => {
        entries.tax_id = null;
        entries.tax_code = undefined;
        entries.tax = null;
        if (entries.is_header_level_tax) {
          entries._destroy = 1;
        }
      });
    }

    if (_.isArray(invoice.invoice_items_attributes)) {
      invoice.invoice_items_attributes.forEach((item) => {
        item.tax_id = null;
        item.tax_code = undefined;
        item.tax = null;
      });
    }
    this.calculateHeaderLevelTax(invoice);
  };

  getFirstInvoiceItem = (invoice: InvoiceType.InvoiceDetailType) => {
    let item = {};
    let flag = 1;
    const checkAndAssignFirstEntry = (array: any) => {
      array.forEach((entry: any) => {
        if (entry._destroy !== 1 && flag === 1) {
          item = entry;
          flag++;
        }
      });
    };

    if (_.isArray(invoice.invoice_items_attributes) && invoice.invoice_items_attributes?.length > 0) {
      checkAndAssignFirstEntry(invoice.invoice_items_attributes);
    } else if (_.isArray(invoice.debit_entries_attributes) && invoice.debit_entries_attributes?.length > 0) {
      checkAndAssignFirstEntry(invoice.debit_entries_attributes);
    }

    return item;
  };

  inheritCompanyLevelCreditAccount = (invoice: InvoiceType.InvoiceDetailType, currentUser: IUser) => {
    if (currentUser?.company?.invoice_hide_credit_account) {
      if (!_.isArray(invoice.credit_entries_attributes)) {
        invoice.credit_entries_attributes = invoice.invoice_credit_accounts;
      } else if (
        _.isArray(invoice.credit_entries_attributes) &&
        !invoice.credit_entries_attributes[0]?.account_id &&
        invoice.invoice_credit_accounts
      ) {
        invoice.credit_entries_attributes[0].account_id = invoice.invoice_credit_accounts[0].account_id;
      }

      if (currentUser?.company?.inherit_default_credit_detail_to_invoice_credit_account) {
        if (_.isArray(invoice.credit_entries_attributes) && _.isArray(invoice.invoice_credit_accounts)) {
          invoice.credit_entries_attributes[0].location_id = invoice.invoice_credit_accounts[0].location_id;
          invoice.credit_entries_attributes[0].department_id = invoice.invoice_credit_accounts[0].department_id;
          invoice.credit_entries_attributes[0].business_unit_id = invoice.invoice_credit_accounts[0].business_unit_id;
        }
      } else if (
        currentUser.company.invoice?.inherit_credit_line_details_from_header &&
        _.isArray(invoice.credit_entries_attributes)
      ) {
        invoice.credit_entries_attributes[0].location_id = invoice.location_id;
        invoice.credit_entries_attributes[0].department_id = invoice.department_id;
        invoice.credit_entries_attributes[0].business_unit_id = invoice.business_unit_id;
      } else {
        let obj: any = this.getFirstInvoiceItem(invoice);
        if (!obj || !obj.account_id) {
          obj = this.getFirstInvoiceItem(invoice);
        }

        if (obj && _.isArray(invoice.credit_entries_attributes)) {
          invoice.credit_entries_attributes[0].location_id = obj.location_id;
          invoice.credit_entries_attributes[0].department_id = obj.department_id;
          invoice.credit_entries_attributes[0].business_unit_id = obj.business_unit_id;
        }
      }
    }
  };

  assignInvoiceAmountToCredit = (invoice: InvoiceType.InvoiceDetailType, currentUser: IUser) => {
    this.inheritCompanyLevelCreditAccount(invoice, currentUser);
    if (_.isArray(invoice.credit_entries_attributes) && invoice.credit_entries_attributes.length === 1) {
      invoice.credit_entries_attributes[0].percent = 100;
      invoice.credit_entries_attributes[0].amount = invoice.amount;
    }
  };

  mergeUsedTax = (
    invoicePayload: InvoiceType.InvoiceDetailType,
    entry: any,
    currentUser: IUser,
    activeUseTax?: boolean,
  ) => {
    if (!_.isArray(entry.tax_debit_accounts_attributes)) {
      entry.tax_debit_accounts_attributes = [];
    }

    if (!_.isArray(entry.tax_credit_accounts_attributes)) {
      entry.tax_credit_accounts_attributes = [];
    }

    let taxDebitEntry: any = {};
    let taxCreditEntry: any = {};

    if (entry.tax > 0) {
      const taxEntryCommonAttribute = {
        department_id: entry.department_id,
        location_id: entry.location_id,
        business_unit_id: entry.business_unit_id,
        project_id: entry.project_id,
        amount: entry.tax,
        tax_id: entry.tax_id,
        memo: entry.description || entry.memo,
      };

      if (activeUseTax) {
        taxDebitEntry = { account_id: entry.account_id, ...taxEntryCommonAttribute };
      }

      if (!activeUseTax || !(taxDebitEntry.amount > 0)) {
        taxDebitEntry._destroy = 1;
      }

      entry.tax_debit_accounts_attributes[0] = taxDebitEntry;

      if (activeUseTax) {
        taxCreditEntry = {
          account_id: entry?.tax_code?.credit_account_id
            ? entry?.tax_code?.credit_account_id
            : currentUser.company.used_tax_credit_account_id,
          ...taxEntryCommonAttribute,
        };
      }

      if (!activeUseTax || !(taxCreditEntry.amount > 0)) {
        taxCreditEntry._destroy = 1;
      }

      entry.tax_credit_accounts_attributes[0] = taxCreditEntry;
    } else {
      if (
        invoicePayload.id &&
        activeUseTax &&
        _.isArray(entry.tax_debit_accounts_attributes) &&
        entry.tax_debit_accounts_attributes[0]
      ) {
        taxDebitEntry.id = entry.tax_debit_accounts_attributes[0].id;
        taxDebitEntry._destroy = entry.tax_debit_accounts_attributes[0]._destroy;
      }

      if (
        invoicePayload.id &&
        activeUseTax &&
        _.isArray(entry.tax_credit_accounts_attributes) &&
        entry.tax_credit_accounts_attributes[0]
      ) {
        taxCreditEntry.id = entry.tax_credit_accounts_attributes[0].id;
        taxCreditEntry._destroy = taxDebitEntry._destroy;
      }
    }
  };

  uploadAttachments = async (assetsAttributes: any, invoiceId: IDType) => {
    try {
      const formData = new FormData();

      assetsAttributes.forEach((asset: any, key: number) => {
        if (!asset.document_type) {
          formData.append(`invoice[assets_attributes[${key}][asset_file]]`, asset);
          formData.append(`invoice[assets_attributes[${key}][file_name]]`, asset.name);
        }
      });
      await InvoiceApis.uploadDocuments(formData, invoiceId);
    } catch (error: any) {
      const { response } = error;
      if (response.status === 422) {
        CreateNotification("Document Upload Failed", "Please enter valid data", NotificationType.danger);
      }
    }
  };

  updateRebateLink = (lineData: any, currentUser: IUser) => {
    let allowRebateExpensesLine =
      currentUser?.company?.has_rebates && currentUser?.company?.global?.allow_rebate_at_expenses_line;
    let allowRebateItemsLine =
      currentUser?.company?.has_rebates && currentUser?.company?.global?.allow_rebate_at_items_line;

    if (lineData && (allowRebateItemsLine || allowRebateExpensesLine)) {
      if (lineData.rebate_id && lineData.rebate_link) {
        let rebateLink = { rebate_id: lineData.rebate_id, amount: lineData.rebate_amount };
        lineData.rebate_link = { ...lineData.rebate_link, ...rebateLink };
      } else if (lineData.rebate_id && !lineData.rebate_link) {
        lineData.rebate_link = { rebate_id: lineData.rebate_id, amount: lineData.rebate_amount };
      } else if (!lineData.rebate_id && lineData.rebate_link) {
        lineData.rebate_link._destroy = 1;
      }

      if (lineData.rebate_link) {
        lineData.rebate_link_attributes = lineData.rebate_link;
      }
    }
  };

  inheritHeaderToAllItemLine = (invoice: InvoiceType.InvoiceDetailType, currentUser: IUser) => {
    let invoiceItems = invoice.invoice_items_attributes;
    let inheritHeaderDepartmentToItemLine =
      currentUser?.company?.invoice?.items?.inherit_header_department &&
      currentUser?.company?.invoice?.items?.department?.is_hide;
    let inheritHeaderLocationToItemLine =
      currentUser?.company?.invoice?.items?.inherit_header_location && currentUser?.company?.invoice_item_hide_location;
    let allowRebateItemsLine =
      currentUser?.company?.has_rebates && currentUser?.company?.global?.allow_rebate_at_items_line;

    if (
      invoiceItems &&
      (inheritHeaderDepartmentToItemLine || inheritHeaderLocationToItemLine || allowRebateItemsLine)
    ) {
      invoiceItems.forEach((item) => {
        if (item._destroy != 1) {
          this.inheritHeaderToItemLine(invoice, item, currentUser, true);
          this.updateRebateLink(item, currentUser);
        }
      });
    }
  };

  updateFieldsBeforeSubmit = (invoice: InvoiceType.InvoiceDetailType, currentUser: IUser) => {
    this.inheritHeaderToAllItemLine(invoice, currentUser);
    this.inheritHeaderToAllExpenses(invoice, currentUser);
  };

  taxRateAmountTotal = (taxRateAmounts: any) => {
    let total = 0;
    taxRateAmounts.forEach((taxRateAmount: any) => {
      if (taxRateAmount._destroy != 1) {
        total += taxRateAmount.amount;
      }
    });
    return total;
  };

  setHeaderTaxDebitEntry = (invoice: InvoiceType.InvoiceDetailType, currentUser: IUser) => {
    if (this.isActiveHeaderTax(invoice, currentUser) && _.isArray(invoice.debit_entries_attributes)) {
      let isTaxEntryExist = false;
      let accountId = null;
      let totalTax = this.taxRateAmountTotal(invoice.tax_rate_amounts);

      invoice.debit_entries_attributes.forEach((entry: any) => {
        if (entry.is_header_level_tax) {
          entry.amount = totalTax;
          entry._destroy = undefined;
          isTaxEntryExist = true;
        } else {
          accountId = entry.account_id;
        }
      });

      if (this.isActiveHeaderTax(invoice, currentUser) && currentUser?.company?.global?.tax_expense_account_id) {
        accountId = currentUser?.company?.global?.tax_expense_account_id;
      }

      if (!isTaxEntryExist && totalTax && accountId) {
        invoice.debit_entries_attributes.push({ account_id: accountId, is_header_level_tax: true, amount: totalTax });
      }
    }
  };

  callPoBySubsidiary = (invoice: InvoiceType.InvoiceDetailType, subsidiaryId: number) => {
    invoice.subsidiary_id = subsidiaryId > 0 ? subsidiaryId : null;
  };

  resetBillDistributionSchedule = (billDistributionSchedules: any, billDistributionScheduleId?: number | null) => {
    const foundSchedule = billDistributionSchedules.find((schedule: any) => schedule.id === billDistributionScheduleId);
    return foundSchedule ? billDistributionScheduleId : null;
  };

  loadSubsidiaryDependantFields = async (
    invoice: InvoiceType.InvoiceDetailType,
    subsidiary: any,
    currentUser: IUser,
  ) => {
    invoice = await removeVendor(invoice, subsidiary, currentUser);

    await this.callPoBySubsidiary(invoice, subsidiary.id);
    //refresh_business_unit_picker()-> TODO: handle business unit field reload on subsidiary
    invoice.is_projects_linked_to_subsidiary = subsidiary.is_projects_linked_to_subsidiary;

    if (_.isArray(subsidiary.bill_distribution_schedules) && subsidiary.bill_distribution_schedules.length > 0) {
      invoice.bill_distribution_schedule_id = this.resetBillDistributionSchedule(
        subsidiary.bill_distribution_schedules,
        invoice?.bill_distribution_schedule_id,
      );
    }
    return invoice;
  };

  isPoExist = (poId: number, poList: any) => {
    let flag = false;
    let newPoList;
    if (_.isArray(poList)) {
      newPoList = poList.map((po, index) => {
        if (po.purchase_order_id == poId) {
          flag = true;
          return { ...po, _destroy: false };
        } else {
          return po;
        }
      });
    }
    return flag ? newPoList : false;
  };

  assignAbsoluteNetToPo = (modelData: InvoiceType.InvoiceDetailType, po: PurchaseOrderType.DetailType) => {
    let found = false;
    let poLinks: any = [];
    if (_.isArray(modelData.invoice_purchase_order_links_attributes)) {
      poLinks = modelData.invoice_purchase_order_links_attributes.map((poLink) => {
        if (poLink.purchase_order_id == po.id && poLink.purchase_order) {
          found = true;
          poLink.purchase_order.absolute_net = po.absolute_net;
        } else if (poLink.purchase_order_id == po.id) {
          found = true;
          poLink.purchase_order = po;
        }
        return poLink;
      });
    }
    return found ? poLinks : false;
  };

  inheritFromRequestor = async (requestor: any, invoice: InvoiceType.InvoiceDetailType, currentUser: IUser) => {
    // here obj is used to send needed data to respective function call from its detail api
    let obj: any = {};

    if (
      currentUser?.company?.has_departments &&
      !currentUser?.company?.invoice_hide_department &&
      currentUser?.company?.invoice?.inherit_department_from_requestor &&
      requestor
    ) {
      let response = await CommonApis.getContact(requestor.id);
      if (response.department) {
        invoice.department_id = response.department.id;
        obj.changed_department = response.department;
      } else {
        invoice.department_id = null;
        obj.department_removed = true;
      }
      return obj;
    }
  };

  setProductItemMapping = (selected: any, invoiceItem: any, currentUser: IUser) => {
    invoiceItem.product_item_id = selected.id;

    if (invoiceItem.is_product_item_link_to_department || selected.is_product_item_link_to_department) {
      invoiceItem.is_product_item_link_to_department = selected.is_product_item_link_to_department;
    }

    if (invoiceItem.is_product_item_link_to_business_unit || selected.is_product_item_link_to_business_unit) {
      invoiceItem.is_product_item_link_to_business_unit = selected.is_product_item_link_to_business_unit;
    }
    // removed code is not required in react
  };

  calculateItemLineRebate = <
    T extends { amount?: number; rebate_id?: IDType | null; rebate?: any; rebate_amount?: any },
  >(
    item: T,
    currentUser: IUser,
  ): any => {
    if (this.allowRebateExpensesLine(currentUser)) {
      if (item.amount && _.isPlainObject(item.rebate)) {
        item.rebate_amount = adminCommonSvc.roundUpAmount((item.amount / 100) * item.rebate.rate, null, currentUser);
      } else if (!item.rebate_amount) {
        item.rebate = undefined;
        item.rebate_id = null;
        item.rebate_amount = null;
      }
    }
    return item;
  };
  //We need to pass the api an amount for each po link. If there is an amount already
  //on the link do nothing, else set it to the invoice amount
  setPoLinksAmount = (invoice: InvoiceType.InvoiceDetailType) => {
    if (_.isArray(invoice.invoice_purchase_order_links_attributes)) {
      invoice.invoice_purchase_order_links_attributes.forEach(function (poLink) {
        if (!poLink.amount) {
          poLink.amount = poLink.purchase_order?.open_balance ? poLink.purchase_order.open_balance : invoice.amount;
          //make sure we dont link more than the invoice amount
          if (invoice?.amount != null && poLink.amount > invoice.amount) {
            poLink.amount = invoice.amount;
          }
        }
      });
    }
  };

  inheritHeaderDepartmentToExpenseLine = (currentUser: IUser) => {
    return !!currentUser?.company?.invoice?.expenses?.inherit_header_department;
  };

  isHideExpenseLineDepartment = (currentUser: IUser) => {
    return !!currentUser?.company?.invoice?.expenses?.department?.is_hide;
  };

  inheritHeaderLocationToExpenseLine = (currentUser: IUser) => {
    return !!currentUser?.company?.invoice?.expenses?.inherit_header_location;
  };

  isHideExpenseLineLocation = (currentUser: IUser) => {
    return !!currentUser?.company?.invoice?.expenses?.location?.is_hide;
  };

  allowExpenseInherit = (debitEntry: any): boolean => {
    if (
      debitEntry.product_item_id ||
      debitEntry.is_tax ||
      debitEntry.subtotal_template_id ||
      debitEntry.is_subtotal_template ||
      debitEntry.is_header_level_tax ||
      debitEntry._destroy
    ) {
      return false;
    }
    return true;
  };

  inheritHeaderToAllExpenses = (invoice: InvoiceType.InvoiceDetailType, currentUser: IUser) => {
    if (
      invoice.debit_entries_attributes &&
      ((this.inheritHeaderDepartmentToExpenseLine(currentUser) && this.isHideExpenseLineDepartment(currentUser)) ||
        (this.inheritHeaderLocationToExpenseLine(currentUser) && this.isHideExpenseLineLocation(currentUser)) ||
        this.allowRebateExpensesLine(currentUser))
    ) {
      invoice.debit_entries_attributes.forEach((debitEntry) => {
        if (this.allowExpenseInherit(debitEntry)) {
          this.inheritHeaderToExpenseLine(invoice, debitEntry, currentUser, true);
        }

        //update rebate link
        this.updateRebateLink(debitEntry, currentUser);
      });
    }
  };

  getItemLinesTotalWithSubTotalCharges = (invoice: InvoiceType.InvoiceDetailType, currentUser: IUser) => {
    let subTotalCharges = 0;
    let itemsTotal = this.getInvoiceItemsTotal(invoice, currentUser);
    if (_.isArray(invoice?.debit_entries_attributes) && invoice?.debit_entries_attributes?.length > 0) {
      invoice?.debit_entries_attributes?.forEach((debitEntry) => {
        if (
          debitEntry._destroy !== 1 &&
          (debitEntry.subtotal_template_id || debitEntry.is_subtotal_template) &&
          !isNaN(parseFloat(debitEntry.amount))
        ) {
          subTotalCharges += parseFloat(debitEntry.amount);
        }
      });
    }
    let itemLinesTotal = adminCommonSvc.roundUpAmount(itemsTotal + subTotalCharges, null, currentUser);
    return itemLinesTotal;
  };

  allowMultiCurrencyPO = (currentUser: IUser) => {
    return currentUser?.company?.invoice?.allow_multi_currency_po;
  };

  checkToRemoveSubtotalTemplates = (invoice: InvoiceType.InvoiceDetailType) => {
    if (
      _.isPlainObject(invoice) &&
      _.isArray(invoice.invoice_items_attributes) &&
      invoice.invoice_items_attributes.length > 0
    ) {
      return invoice.invoice_items_attributes.every((invoiceItem) => invoiceItem._destroy == 1);
    }
    return false;
  };

  isSubtotalTemplateDebitLine = (debitEntry: any) => {
    return _.isPlainObject(debitEntry) && (debitEntry.subtotal_template_id || debitEntry.is_subtotal_template);
  };

  isExpenseDebitLine = (accountEntry: any) => {
    return !(
      accountEntry.is_tax ||
      this.isSubtotalTemplateDebitLine(accountEntry) ||
      this.isItemDebitLine(accountEntry) ||
      accountEntry.is_header_level_tax
    );
  };

  isItemDebitLine = (debitEntry: any) => {
    return _.isPlainObject(debitEntry) && (debitEntry.item_line_id || debitEntry.product_item_id);
  };

  showDebitEntry = (accountEntry: any) => {
    return !(accountEntry._destroy == 1 || !this.isExpenseDebitLine(accountEntry));
  };

  isExpenseWithTaxDebitLineExists = (invoice: InvoiceType.InvoiceDetailType) => {
    let flag = false;
    if (_.isArray(invoice?.debit_entries_attributes)) {
      invoice?.debit_entries_attributes.forEach((val) => {
        if (val._destroy != 1 && !this.isItemDebitLine(val) && !this.isSubtotalTemplateDebitLine(val)) {
          flag = true;
        }
      });
    }
    return flag;
  };

  isAnyExpensesExists = (invoice: InvoiceType.InvoiceDetailType) => {
    let flag = false;
    if (_.isArray(invoice?.debit_entries_attributes)) {
      invoice?.debit_entries_attributes.forEach((value) => {
        if (value._destroy != 1 && !this.isItemDebitLine(value)) {
          flag = true;
        }
      });
      return flag;
    }
  };

  disabledFreetextItem = (currentUser: IUser) => {
    return currentUser.company.disabled_freetext_item == true;
  };

  parseInvoiceForUploadQueue = (invoice?: InvoiceType.InvoiceDetailType) => {
    if (invoice && _.isPlainObject(invoice)) {
      invoice.invoice_purchase_order_links_attributes = invoice.purchase_order_links;
      if (_.isPlainObject(invoice.currency) && invoice.currency) {
        invoice.currency.code = invoice.currency?.iso_code;
        invoice.original_amount = invoice.amount;
        invoice.original_currency_code = invoice.currency_code;
      }
      invoice.credit_entries_attributes = invoice.credit_entries;
      invoice.debit_entries_attributes = invoice.debit_entries;
    }

    return invoice;
  };

  triggerProactiveApprovals = async (invoice: InvoiceType.InvoiceDetailType) => {
    const result = await restApiService.patch(`invoices/${invoice.id}/trigger_proactive_approvals`, null, null);
    if (result?.data) {
      const notificationType = result.data?.removed_approvals ? NotificationType.warning : NotificationType.success;
      const notificationMessage = result.data?.removed_approvals
        ? i18n.t("admin.pages.details.approvalWorkflowTriggersUnselected")
        : i18n.t("admin.pages.details.workflowTriggered");
      CreateNotification(
        notificationMessage,
        i18n.t("admin.pages.details.approvalsUpdated", { modelDataInfo: invoice.number }),
        notificationType,
      );
    }
  };
}

const invoiceCommonSvc = new InvoiceCommonSvc();
export default invoiceCommonSvc;
