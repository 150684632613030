import React from "react";
import suspenseComponent from "services/common/suspenseHelper";
const AddSubsidiary = React.lazy(() => import("./add"));
const DetailSubsidiary = React.lazy(() => import("./details"));
const EditSubsidiary = React.lazy(() => import("./edit"));
const ListSubsidiary = React.lazy(() => import("./list"));

const EditFormOld = React.lazy(() => import("./editFormOld")); //TODO: Import for the legacy form, to be removed when the new subsidiary form gets stable.
const AddFormOld = React.lazy(() => import("./addFormOld")); //TODO: Import for the legacy form, to be removed when the new subsidiary form gets stable.

const SubsidiaryRoutes = [
  {
    path: "/ap/subsidiaries",
    exact: true,
    name: "Subsidiaries List",
    component: suspenseComponent(ListSubsidiary),
    authorization: {
      I: "list",
      a: "Subsidiaries",
    },
  },
  {
    path: "/ap/subsidiaries/add",
    exact: true,
    name: "Add Subsidiary",
    component: suspenseComponent(AddSubsidiary),
    authorization: {
      I: "add",
      a: "Subsidiaries",
    },
  },
  {
    path: "/ap/subsidiaries/:id/edit",
    exact: true,
    name: "Edit Subsidiary",
    component: suspenseComponent(EditSubsidiary),
    authorization: {
      I: "edit",
      a: "Subsidiaries",
    },
  },
  {
    path: "/ap/subsidiaries/:id(\\d+)", //it indicates that the parameter must be a numeric value (digits)
    exact: true,
    name: "Subsidiaries Details",
    component: suspenseComponent(DetailSubsidiary),
    authorization: {
      I: "read",
      a: "Subsidiaries",
    },
  },
  // Creating separate routes for add and edit form for the legacy form.
  // to be removed when the new subsidiary form gets stable.
  {
    path: "/ap/subsidiaries/add/old",
    exact: true,
    name: "Add Subsidiary",
    component: suspenseComponent(AddFormOld),
    authorization: {
      I: "add",
      a: "Subsidiaries",
    },
  },
  {
    path: "/ap/subsidiaries/:id/edit/old",
    exact: true,
    name: "Edit Subsidiary",
    component: suspenseComponent(EditFormOld),
    authorization: {
      I: "edit",
      a: "Subsidiaries",
    },
  },
];

export default SubsidiaryRoutes;
