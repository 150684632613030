import AddInvoicePdfProcess from "./draftInvoice/processPdf/add";
import EmailList from "./email";
import InvoiceDetailsProcess from "./invoiceDetails";
import AddInvoice from "./processingInvoices/add";
import EditInvoice from "./processingInvoices/edit";
import ListPrompts from "./prompts/list";
import DetailReccuringInvoice from "./recurringInvoice/detail";
import ListRecurringInvoice from "./recurringInvoice/list";

export const invoiceRoutes = [
  {
    path: ["/ap/invoices/emails", "/ap/invoices/emails/:id"],
    exact: true,
    component: EmailList,
    authorization: {
      I: "read",
      a: "InvoiceEmails",
    },
  },
  {
    path: "/ap/recurring_invoices",
    exact: true,
    component: ListRecurringInvoice,
    authorization: {
      I: "list",
      a: "Invoices",
    },
  },
  {
    path: "/ap/recurring_invoices/:id",
    exact: true,
    component: DetailReccuringInvoice,
    authorization: {
      I: "read",
      a: "Invoices",
    },
  },
  {
    path: "/ap/invoices/upload_queue/:id/edit",
    exact: true,
    component: InvoiceDetailsProcess,
    authorization: {
      I: "edit",
      a: "Invoices",
    },
  },
  {
    path: "/ap/invoices/draft-inbox/process_pdf",
    exact: true,
    name: "ProcessPdf",
    component: AddInvoicePdfProcess,
  },
  {
    path: "/ap/invoices/add",
    exact: true,
    component: AddInvoice,
    authorization: {
      I: "add",
      a: "Invoices",
    },
  },
  {
    path: "/ap/invoices/:id/edit",
    exact: true,
    component: EditInvoice,
    authorization: {
      I: "edit",
      a: "Invoices",
    },
  },
  {
    path: "/ap/invoices/prompts",
    exact: true,
    component: ListPrompts,
    authorization: {
      I: "list", // We can only see the DraftInbox section if we are a Service role.
      a: "DraftInbox", // we are using listDraftInbox because we want the 'Prompts' section to be visible only to Service role.
    },
  },
];
