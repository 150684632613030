import Panel from "components/common/panel/panel";
import InvoicesNavsTabs from "pages/admin/invoice/nav";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import gridStyles from "wombatifier/components/bulk_upload/bulkUpload.module.css";
import rowActionStyles from "wombatifier/components/bulk_upload/bulkUploadFilesRowAction.module.css";
import { BulkUploadsFilesGrid } from "./bulkUploadFilesGrid";

let BulkUploadsFilesGridInvoice = () => {
  return (
    <Container fluid={true}>
      <Row>
        <Col md="12" className="removeHPseudoClass">
          <InvoicesNavsTabs activePageName="File Uploads" />
        </Col>
      </Row>

      <hr className="mt-4 mb-4" />

      <Panel cardBodyClass={`py-2 ${rowActionStyles.allowRowOverflow}`}>
        <Row className="m-0 justify-content-between align-items-center">
          <Col className="p-0" style={{ flex: 0, minWidth: "fit-content" }}>
            <span className={`m-0 ${gridStyles.gridLabel}`}>All File Uploads</span>
          </Col>
          <Col className="p-0" style={{ flex: 0 }}>
            <Link to="/ap/bulk_uploads/add?destination_type=payment_file">
              <button type="button" className={`btn btn-primary py-1 ${gridStyles.uploadFileButton}`}>
                Upload File
              </button>
            </Link>
          </Col>
        </Row>
        <hr className="p-0 my-2 mb-3 mx-0" />
        <BulkUploadsFilesGrid destinationTypes={["invoice", "payment_file"]} />
      </Panel>
    </Container>
  );
};

export default BulkUploadsFilesGridInvoice;
