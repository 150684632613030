import { AxiosResponse } from "axios";
import { restApiService } from "../../../providers/restApi";
import { ApprovalType, approvalCountType } from "./approvalsType";
import { PoChangeOrderType } from "../changeOrders/poChangeOrderApprovalsType";
import { IDType } from "services/common/types/common.type";

class ApprovalsApis {
  static async getPoChangeOrderApprovalList() {
    try {
      const response: AxiosResponse<PoChangeOrderType.ApprovalListType[]> =
        await restApiService.get("change_requests/approvals");
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static getActionApprovalDetails = async (id: string | number) => {
    try {
      const response: AxiosResponse<ApprovalType.DetailsType> = await restApiService.get("approvals/" + id);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static claimApproval = async (id: string | number) => {
    try {
      const response: AxiosResponse<ApprovalType.DetailsType> = await restApiService.post(
        "approvals/" + id + "/claim_approval",
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static async updateApproval(id: string | number, payload: any) {
    try {
      const response: AxiosResponse<ApprovalType.UpdateResponseType> = await restApiService.patch(
        "approvals/" + id,
        null,
        payload,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async getApprovalCount() {
    try {
      const response: AxiosResponse<approvalCountType> = await restApiService.get("approvals/stats");
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  // TODO: remove payload type any to valid type
  static async requestApproval(payload: any) {
    try {
      const response: AxiosResponse<unknown> = await restApiService.post("approvals", null, payload);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async deleteApproval(approvalId: IDType, params?: any) {
    try {
      const response: AxiosResponse<unknown> = await restApiService.delete(`approvals/${approvalId}`, null, params);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async makeMessage(approvalId: IDType) {
    try {
      const response: AxiosResponse = await restApiService.post(`approvals/${approvalId}/make_message`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async sendApprovalReminder(approvalId: IDType) {
    try {
      const response: AxiosResponse = await restApiService.post(`approvals/${approvalId}/resend_message`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}

export default ApprovalsApis;
