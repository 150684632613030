import ManageInvoiceItems from "components/admin/invoices/adminInvoiceForm/manageInvoiceItems";
import ErrorBoundary from "components/common/errorBoundary/errorBoundary";
import { RenderCheckBox } from "components/forms/bootstrapFields";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "reducers";
import { Field, FieldArray, getFormValues } from "redux-form";
import adminCommonSvc from "services/admin/commonSvc";
import { CustomLabelSvc } from "services/admin/customLabels/customLabelsSvc";
import invoiceCommonSvc from "services/admin/invoices/invoiceCommonSvc";
import { InvoiceType } from "services/admin/invoices/invoiceType";
import { IUser } from "services/common/user/userTypes";
import { formattedAmount } from "services/general/helpers";
import { CreateNotification, NotificationType } from "services/general/notifications";
import { MetadataTemplateApis } from "wombatifier/services/metadata/metadataTemplateApis";
import styles from "./invoices.module.css";
import ManageSubtotalCharges from "./manageSubtotalCharges";
import ManageUseTaxOnSubTotal from "./manageUseTaxOnSubTotal";

type InvoiceItemLineSectionPropsType = {
  valid?: boolean;
  customLabels?: { [key: string]: string[] };
  allowSubmitWithGLErrors?: boolean;
};

const InvoiceItemLineSection = ({ valid, customLabels, allowSubmitWithGLErrors }: InvoiceItemLineSectionPropsType) => {
  const currentUser: IUser = useTypedSelector((state) => state.user);
  const invoice: InvoiceType.InvoiceDetailType = useTypedSelector((state) => getFormValues("InvoiceForm")(state));
  const [isUsedTaxenable, setUsedTax] = useState(invoice?.is_used_tax ? invoice?.is_used_tax : false);
  const defaultDecimal = adminCommonSvc.getDefaultDecimal(currentUser);
  const isItemExist = () => invoiceCommonSvc.isItemExist(invoice?.invoice_items_attributes);
  const dispatch = useDispatch();
  const [showAdditionalCodingSection, setShowAdditionalCodingSection] = useState(false);
  const { t } = useTranslation();

  const checkIsUsingMetadata = useCallback(async () => {
    try {
      const res = await MetadataTemplateApis.list();
      setShowAdditionalCodingSection(res.length > 0);
    } catch (err) {
      setShowAdditionalCodingSection(false);
    }
  }, []);

  useEffect(() => {
    checkIsUsingMetadata();
  }, [showAdditionalCodingSection]);

  useEffect(() => {
    if (isItemExist().length > 0) {
      invoiceCommonSvc.getInvoiceTotalAmount(invoice, currentUser);
    }
  }, [isItemExist().length]);

  //clear tax code if select/unselect use tax
  const clearItemsTaxCode = (isUsedTax: boolean) => {
    if (isUsedTax && invoice.is_account_used_tax) {
      isUsedTax = false;
      invoice.is_used_tax = false;
      setUsedTax(false);
      CreateNotification(
        "Warning",
        "Unselect use tax from expense line to use for item line.",
        NotificationType.warning,
      );
      return false;
    }

    invoice?.invoice_items_attributes?.forEach((debitEntry: any) => {
      if (debitEntry._destroy !== 1) {
        debitEntry.tax_id = "";
        debitEntry.tax_code = "";
        debitEntry.tax = 0;
      }
    });

    invoice?.tax_debit_entries_attributes?.forEach((entry: any) => {
      entry._destroy = 1;
    });

    setUsedTax(isUsedTax);
  };

  const itemLineTotalWithSubTotalCharges = useMemo(
    () => invoiceCommonSvc.getItemLinesTotalWithSubTotalCharges(invoice, currentUser),
    [currentUser, invoice],
  );

  return (
    <ErrorBoundary>
      {invoiceCommonSvc.isItemExist(invoice?.invoice_items_attributes).length > 0 && (
        <>
          {currentUser?.company?.enable_used_tax && (
            <Row className="px-mt-15">
              <Col sm={4} className={`${styles.useTaxLabel}`}>
                <Field
                  id="allowed-used-tax"
                  name="is_used_tax"
                  component={RenderCheckBox}
                  type="checkbox"
                  label={CustomLabelSvc.setCustomLabel(customLabels, t("admin.pages.invoice.invoiceItems.applyUseTax"))}
                  isChecked={isUsedTaxenable}
                  onChange={(value: any) => clearItemsTaxCode(value)}
                />
              </Col>
              {isUsedTaxenable && (
                <Col sm={8} className={`${styles.LabelFontSize14}`}>
                  <span>Use Tax Total: </span>
                  {formattedAmount(
                    (invoiceCommonSvc.getUseTaxTotal(invoice) || 0).toString(),
                    invoice.currency_code,
                    defaultDecimal,
                    true,
                  )}
                </Col>
              )}
            </Row>
          )}
          <Row className={`${styles.invoiceLinesStats} px-mt-15 mr-10`}>
            {invoiceCommonSvc.getInvoiceItemsTotal(invoice, currentUser) > 0 && (
              <Col>
                <span>Invoice Item Expense Total: </span>
                {formattedAmount(
                  (invoiceCommonSvc.getInvoiceItemsTotal(invoice, currentUser) || 0).toString(),
                  invoice.currency_code,
                  defaultDecimal,
                  true,
                )}
              </Col>
            )}
            {invoiceCommonSvc.getAccountsTotal(invoice) !== 0 && (
              <Col>
                <span>Expense Account Total: </span>
                {formattedAmount(
                  (invoiceCommonSvc.getAccountsTotal(invoice) || 0).toString(),
                  invoice.currency_code,
                  defaultDecimal,
                  true,
                )}
              </Col>
            )}
            <Col>
              <span>Total Invoice Amount: </span>
              {formattedAmount((invoice?.amount || 0).toString(), invoice?.currency_code, defaultDecimal, true)}
            </Col>
          </Row>
        </>
      )}
      <Row className="px-mt-15">
        <Col>
          <FieldArray
            key={`manageInvoiceItems`}
            name="invoice_items_attributes"
            component={ManageInvoiceItems}
            invoice={invoice}
            customLabels={customLabels}
            formName="InvoiceForm"
            showAdditionalCodingSection={showAdditionalCodingSection}
            allowSubmitWithGLErrors={allowSubmitWithGLErrors}
          />
        </Col>
      </Row>
      {currentUser?.company?.has_subtotal_templates && (
        <Row>
          <Col>
            <FieldArray
              key={`manageSubtotalCharges`}
              name="debit_entries_attributes"
              component={ManageSubtotalCharges}
              invoice={invoice}
              formName="InvoiceForm"
            />
          </Col>
        </Row>
      )}
      {invoiceCommonSvc.isItemExist(invoice?.invoice_items_attributes).length > 0 && (
        <Row>
          <Col sm={{ offset: "10", span: "2" }} className={styles.subtotalAmountsAlignment}>
            <Row>
              <Col sm={12} className="px-mb-10">
                <span>
                  Total:{" "}
                  {formattedAmount(
                    (itemLineTotalWithSubTotalCharges || 0).toString(),
                    invoice?.currency_code,
                    adminCommonSvc.getDefaultDecimal(currentUser),
                    true,
                  )}
                </span>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
      {isUsedTaxenable &&
        invoice?.itemsObj?.invoiceItemUseTaxesTotal == 0 &&
        invoiceCommonSvc.getInvoiceItemUseTaxTotal(invoice) === 0 && (
          <Row className="px-mt-25">
            <Col>
              <FieldArray
                key={`useTaxOnSubTotal`}
                name="tax_debit_entries_attributes"
                component={ManageUseTaxOnSubTotal}
                invoice={invoice}
              />
            </Col>
          </Row>
        )}
    </ErrorBoundary>
  );
};

export default InvoiceItemLineSection;
