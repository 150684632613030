import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import { Field, getFormValues, reduxForm } from "redux-form";
import { RenderSelect, RenderTextArea } from "../../../../components/forms/bootstrapFields";
import {
  INTEGRATION_FORM_NAME,
  onApplicationTypeChange,
  onSystemNameChange,
} from "../../../../reducers/admin/integrationsReducer";
import { required } from "../../../../services/validations/reduxFormValidation";
import ConnectToBlackbaud from "./components/buttons/connectToBlackBaud";
import ConnectToQb from "./components/buttons/connectToQb";
import ConnectToXero from "./components/buttons/connectToXero";
import { Sage300AI } from "./templates/Sage300/sage300Ai";
import { Sage300AI2 } from "./templates/Sage300/sage300Ai2";
import { Sage300AUTHENTICATION } from "./templates/Sage300/sage300Authentication";
import { AcumaticaAi2 } from "./templates/acumatica/acumaticaAi2";
import { AcumaticaAUTHENTICATION } from "./templates/acumatica/acumaticaAuthentication";
import { IntegrationAusEftPayment } from "./templates/ai/IntegrationAusEftPayment";
import { IntegrationNzEftPayment } from "./templates/ai/IntegrationNzEftPayment";
import { IntegrationAI } from "./templates/ai/integrationAI";
import { IntegrationAI2 } from "./templates/ai/integrationAI2";
import { IntegrationAuthentication } from "./templates/ai/integrationAuthentication";
import { IntegrationBOA_PAYMENT } from "./templates/ai/integrationBoaPayment";
import { IntegrationCambridge } from "./templates/ai/integrationCambridge";
import { IntegrationCanEftPayment } from "./templates/ai/integrationCanEftPayment";
import { IntegrationCcPayment } from "./templates/ai/integrationCcPayment";
import { IntegrationCheckPayment } from "./templates/ai/integrationCheckPayment";
import { IntegrationCheckPrint } from "./templates/ai/integrationCheckPrint";
import { IntegrationCorpay } from "./templates/ai/integrationCorpay";
import { IntegrationDataExtract } from "./templates/ai/integrationDataExtract";
import { IntegrationErpExtract } from "./templates/ai/integrationErpExtract";
import { IntegrationExpensify } from "./templates/ai/integrationExpensify";
import { IntegrationFileIntegrationIngest } from "./templates/ai/integrationFileIntegrationIngest";
import { IntegrationFileOutbound } from "./templates/ai/integrationFileOutbound";
import { IntegrationFtp } from "./templates/ai/integrationFtp";
import { IntegrationInvoiceAutomation } from "./templates/ai/integrationInvoiceAutomation";
import { IntegrationNachaPayment } from "./templates/ai/integrationNachaPayments";
import { IntegrationPayment } from "./templates/ai/integrationPayment";
import { IntegrationPayoneer } from "./templates/ai/integrationPayoneer";
import { IntegrationPayoneerBrandedPayment } from "./templates/ai/integrationPayoneerBrandedPayment";
import { IntegrationPayoneerPayment } from "./templates/ai/integrationPayoneerPayment";
import { IntegrationPunchout } from "./templates/ai/integrationPunchout";
import { IntegrationSepa } from "./templates/ai/integrationSepa";
import { IntegrationTransfewire } from "./templates/ai/integrationTransfewire";
import { IntegrationTransfewireQuote } from "./templates/ai/integrationTransfewireQuote";
import { IntegrationVirtualCard } from "./templates/ai/integrationVirtualCard";
import { IntegrationServiceAI } from "./templates/aiService/integrationServiceAI";
import { IntegrationServiceAI2 } from "./templates/aiService/integrationServiceAI2";
import { IntegrationServiceAuthentication } from "./templates/aiService/integrationServiceAuthentication";
import { IntegrationServiceBOA_PAYMENT } from "./templates/aiService/integrationServiceBoaPayment";
import { IntegrationServiceCambridge } from "./templates/aiService/integrationServiceCambridge";
import { IntegrationServiceCanEftPayment } from "./templates/aiService/integrationServiceCanEftPayment";
import { IntegrationServiceCcPayment } from "./templates/aiService/integrationServiceCcPayment";
import { IntegrationServiceCheckPayment } from "./templates/aiService/integrationServiceCheckPayment";
import { IntegrationServiceCheckPrint } from "./templates/aiService/integrationServiceCheckPrint";
import { IntegrationServiceCorpay } from "./templates/aiService/integrationServiceCorpay";
import { IntegrationServiceExpensify } from "./templates/aiService/integrationServiceExpensify";
import { IntegrationServiceFtp } from "./templates/aiService/integrationServiceFtp";
import { IntegrationServiceInvoiceAutomation } from "./templates/aiService/integrationServiceInvoiceAutomation";
import { IntegrationServiceNachaPayment } from "./templates/aiService/integrationServiceNachaPayments";
import { IntegrationServicePayment } from "./templates/aiService/integrationServicePayment";
import { IntegrationServicePayoneer } from "./templates/aiService/integrationServicePayoneer";
import { IntegrationServicePayoneerBrandedPayment } from "./templates/aiService/integrationServicePayoneerBrandedPayment";
import { IntegrationServicePayoneerPayment } from "./templates/aiService/integrationServicePayoneerPayment";
import { IntegrationServicePunchout } from "./templates/aiService/integrationServicePunchout";
import { IntegrationServiceSepa } from "./templates/aiService/integrationServiceSepa";
import { IntegrationServiceTransfewire } from "./templates/aiService/integrationServiceTransfewire";
import { IntegrationServiceTransfewireQuote } from "./templates/aiService/integrationServiceTransfewireQuote";
import { IntegrationServiceVirtualCard } from "./templates/aiService/integrationServiceVirtualCard";
import { BlackBaudAI2 } from "./templates/blackBaud/blackBaudAi2";
import { BlackBaudAUTHENTICATION } from "./templates/blackBaud/blackBaudAuthentication";
import { CambridgeCAMBRIDGE } from "./templates/cambridge/cambridge";
import { ComdataVirtualCard } from "./templates/comdata/comdataVirtualCard";
import { ComdataWebVirtualCard } from "./templates/comdataWeb/comdataWebVirtualCard";
import { CorpayCORPAY } from "./templates/corpay/corpay";
import { DeltekAUTHENTICATION } from "./templates/deltekVantagpoint/deltekAuthentication";
import { DeltekVantagpointAi2 } from "./templates/deltekVantagpoint/deltekVantagpointAi2";
import { ExpensifyEXPENSIFY_AUTHENTICATION } from "./templates/expensify/expensifyAuthentication";
import { FortePAYMENT } from "./templates/forte/forte";
import { IntacctAI } from "./templates/intacct/intacctAi";
import { IntacctAI2 } from "./templates/intacct/intacctAi2";
import { IntacctAUTHENTICATION } from "./templates/intacct/intacctAuthentication";
import { CheckCHECK_PAYMENT } from "./templates/lobCheck/lobCheck";
import { NetsuiteAI } from "./templates/netsuite/netsuiteAi";
import { NetsuiteAI2 } from "./templates/netsuite/netsuiteAi2";
import { NetsuiteAUTHENTICATION } from "./templates/netsuite/netsuiteAuthentication";
import { PostGridCHECK_PAYMENT } from "./templates/postGridCheck/postGridCheck";
import { PunchoutPUNCHOUT } from "./templates/punchout/integrationPunchout";
import { QuickbookAI } from "./templates/quickBooks/quickBookAi";
import { QuickbookAI2 } from "./templates/quickBooks/quickBookAi2";
import { QuickbookAUTHENTICATION } from "./templates/quickBooks/quickBookAuthentication";
import { QuickbooksEnterpriseAI } from "./templates/quickBooksEnterprise/quickBookEnterpriseAi";
import { QuickbooksEnterpriseAI2 } from "./templates/quickBooksEnterprise/quickBookEnterpriseAi2";
import { QuickBookEnterpriseAUTHENTICATION } from "./templates/quickBooksEnterprise/quickBookEnterpriseAuthentication";
import { SapCloudAI } from "./templates/sapCloud/sapCloudAi";
import { SapCloudAI2 } from "./templates/sapCloud/sapCloudAi2";
import { SapOnPremiseAI } from "./templates/sapOnPremise/sapOnPremiseAi";
import { SapOnPremiseAI2 } from "./templates/sapOnPremise/sapOnPremiseAi2";
import { SpectrumAI } from "./templates/spectrum/spectrumAi";
import { SpectrumAI2 } from "./templates/spectrum/spectrumAi2";
import { SpectrumAUTHENTICATION } from "./templates/spectrum/spectrumAuthentication";
import { StripeVIRTUAL_CARD } from "./templates/stripe/virtualCard";
import { SvbAchPayment } from "./templates/svb/svbAchPayment";
import { SvbMasterCardVirtualCard } from "./templates/svbMasterCard/svbMasterCardVirttualCard";
import { TransferwiseTRANSFERWISE_PAYMENT } from "./templates/transferwise/transferwisePayment";
import { TransferwiseTRANSFERWISE_QUOTE } from "./templates/transferwise/transferwiseQuote";
import { UsBankCheckPayment } from "./templates/usBank/usBankCheckPayment";
import { UsBankFtp } from "./templates/usBank/usBankFtp";
import { UsBankPayment } from "./templates/usBank/usBankPayment";
import { UsBankVirtualCard } from "./templates/usBank/usBankVirtualCard";
import { VISAVIRTUAL_CARD } from "./templates/visa/visaVirttualCard";
import { XeroAI } from "./templates/xero/xeroAi";
import { XeroAI2 } from "./templates/xero/xeroAi2";
import { XeroAUTHENTICATION } from "./templates/xero/xeroAuthentication";
const templates = {
  // Accrualify Integrations
  "Accrualify IntegrationAI": IntegrationAI,
  "Accrualify IntegrationAI2": IntegrationAI2,
  "Accrualify IntegrationBOA_PAYMENT": IntegrationBOA_PAYMENT,
  "Accrualify IntegrationCC_PAYMENT": IntegrationCcPayment,
  "Accrualify IntegrationCHECK_PRINT": IntegrationCheckPrint,
  "Accrualify IntegrationCHECK_PAYMENT": IntegrationCheckPayment,
  "Accrualify IntegrationERP_EXTRACT": IntegrationErpExtract,
  "Accrualify IntegrationFILE_INTEGRATION": IntegrationDataExtract,
  "Accrualify IntegrationCAN_EFT_PAYMENT": IntegrationCanEftPayment,
  "Accrualify IntegrationAUS_EFT_PAYMENT": IntegrationAusEftPayment,
  "Accrualify IntegrationNZ_EFT_PAYMENT": IntegrationNzEftPayment,
  "Accrualify IntegrationFTP": IntegrationFtp,
  "Accrualify IntegrationINVOICE_AUTOMATION": IntegrationInvoiceAutomation,
  "Accrualify IntegrationNACHA_PAYMENT": IntegrationNachaPayment,
  "Accrualify IntegrationVIRTUAL_CARD": IntegrationVirtualCard,
  "Accrualify IntegrationPAYONEER": IntegrationPayoneer,
  "Accrualify IntegrationPAYONEER_PAYMENT": IntegrationPayoneerPayment,
  "Accrualify IntegrationPAYONEER_BRANDED_PAYMENT": IntegrationPayoneerBrandedPayment,
  "Accrualify IntegrationAUTHENTICATION": IntegrationAuthentication,
  "Accrualify IntegrationSEPA_PAYMENT": IntegrationSepa,
  "Accrualify IntegrationCAMBRIDGE": IntegrationCambridge,
  "Accrualify IntegrationEXPENSIFY_AUTHENTICATION": IntegrationExpensify,
  "Accrualify IntegrationCORPAY": IntegrationCorpay,
  "Accrualify IntegrationPUNCHOUT": IntegrationPunchout,
  "Accrualify IntegrationTRANSFERWISE_PAYMENT": IntegrationTransfewire,
  "Accrualify IntegrationTRANSFERWISE_QUOTE": IntegrationTransfewireQuote,
  "Accrualify IntegrationPAYMENT": IntegrationPayment,
  "Accrualify IntegrationFILE_INTEGRATION_INGEST": IntegrationFileIntegrationIngest,
  "Accrualify IntegrationFILE_INTEGRATION_OUTBOUND": IntegrationFileOutbound,

  // Accrulify Internal Services
  "Accrualify Internal ServiceAI": IntegrationServiceAI,
  "Accrualify Internal ServiceAI2": IntegrationServiceAI2,
  "Accrualify Internal ServiceBOA_PAYMENT": IntegrationServiceBOA_PAYMENT,
  "Accrualify Internal ServiceCC_PAYMENT": IntegrationServiceCcPayment,
  "Accrualify Internal ServiceCHECK_PRINT": IntegrationServiceCheckPrint,
  "Accrualify Internal ServiceCHECK_PAYMENT": IntegrationServiceCheckPayment,
  "Accrualify Internal ServiceCAN_EFT_PAYMENT": IntegrationServiceCanEftPayment,
  "Accrualify Internal ServiceFTP": IntegrationServiceFtp,
  "Accrualify Internal ServiceINVOICE_AUTOMATION": IntegrationServiceInvoiceAutomation,
  "Accrualify Internal ServiceNACHA_PAYMENT": IntegrationServiceNachaPayment,
  "Accrualify Internal ServicevVIRTUAL_CARD": IntegrationServiceVirtualCard,
  "Accrualify Internal ServicePAYONEER": IntegrationServicePayoneer,
  "Accrualify Internal ServicePAYONEER_PAYMENT": IntegrationServicePayoneerPayment,
  "Accrualify Internal ServicePAYONEER_BRANDED_PAYMENT": IntegrationServicePayoneerBrandedPayment,
  "Accrualify Internal ServiceAUTHENTICATION": IntegrationServiceAuthentication,
  "Accrualify Internal ServiceSEPA_PAYMENT": IntegrationServiceSepa,
  "Accrualify Internal ServiceCAMBRIDGE": IntegrationServiceCambridge,
  "Accrualify Internal ServiceEXPENSIFY_AUTHENTICATION": IntegrationServiceExpensify,
  "Accrualify Internal ServiceCORPAY": IntegrationServiceCorpay,
  "Accrualify Internal ServicePUNCHOUT": IntegrationServicePunchout,
  "Accrualify Internal ServiceTRANSFERWISE_PAYMENT": IntegrationServiceTransfewire,
  "Accrualify Internal ServiceTRANSFERWISE_QUOTE": IntegrationServiceTransfewireQuote,
  "Accrualify Internal ServicePAYMENT": IntegrationServicePayment,
  "Accrualify Internal ServiceVIRTUAL_CARD": IntegrationServiceVirtualCard,

  // Sap Cloud
  "SAP CloudAI": SapCloudAI,
  "SAP CloudAI2": SapCloudAI2,

  // sap on primse
  "SAP On PremiseAI": SapOnPremiseAI,
  "SAP On PremiseAI2": SapOnPremiseAI2,

  // visa
  VISAVIRTUAL_CARD,

  // cambrige
  CambridgeCAMBRIDGE,

  // Comdata
  "Comdata VirtualVIRTUAL_CARD": ComdataVirtualCard,

  // ComdataWeb
  "Comdata WebVIRTUAL_CARD": ComdataWebVirtualCard,

  // Comdata Payments
  "Comdata PaymentsCC_PAYMENT": IntegrationServiceCcPayment,

  // expensify
  ExpensifyEXPENSIFY_AUTHENTICATION,

  // FortePAYMENT
  FortePAYMENT,

  // inacct
  IntacctAI,
  IntacctAI2,
  IntacctAUTHENTICATION,

  // netsuite
  NetsuiteAI,
  NetsuiteAI2,
  NetsuiteAUTHENTICATION,

  // Quickbooks Enterprise
  "Quickbooks EnterpriseAI": QuickbooksEnterpriseAI,
  "Quickbooks EnterpriseAI2": QuickbooksEnterpriseAI2,
  "Quickbooks EnterpriseAUTHENTICATION": QuickBookEnterpriseAUTHENTICATION,

  // Quickbook
  QuickbookAI,
  QuickbookAI2,
  QuickbookAUTHENTICATION,

  // corapy

  CorpayCORPAY,

  //CheckCHECK_PAYMENT
  "LOB CheckCHECK_PAYMENT": CheckCHECK_PAYMENT,
  //
  PunchoutPUNCHOUT,
  StripeVIRTUAL_CARD,
  SpectrumAI,
  SpectrumAI2,
  SpectrumAUTHENTICATION,

  // SVB ACHPAYMENT
  "SVB ACHPAYMENT": SvbAchPayment,

  // SVB Master CardVIRTUAL_CARD
  "SVB Master CardVIRTUAL_CARD": SvbMasterCardVirtualCard,

  TransferwiseTRANSFERWISE_PAYMENT,
  TransferwiseTRANSFERWISE_QUOTE,

  // us Bank
  "US BankCHECK_PAYMENT": UsBankCheckPayment,
  "US BankFTP": UsBankFtp,
  "US BankPAYMENT": UsBankPayment,
  "US BankVIRTUAL_CARD": UsBankVirtualCard,

  // xero
  XeroAI,
  XeroAI2,
  XeroAUTHENTICATION,

  //PostGrid
  "PostGrid CheckCHECK_PAYMENT": PostGridCHECK_PAYMENT,

  //Sage 300,
  Sage300CAI: Sage300AI,
  Sage300CAI2: Sage300AI2,
  Sage300CAUTHENTICATION: Sage300AUTHENTICATION,
  "Deltek VantagepointAI2": DeltekVantagpointAi2,
  "Deltek VantagepointAUTHENTICATION": DeltekAUTHENTICATION,
  AcumaticaAI2: AcumaticaAi2,
  AcumaticaAUTHENTICATION,
  BlackbaudAI2: BlackBaudAI2,
  BlackbaudAUTHENTICATION: BlackBaudAUTHENTICATION,
};

let IntegrationModalForm = (props) => {
  const { onHide, handleSubmit } = props;
  const dispatch = useDispatch();

  const system_names = useSelector((state) => state.integrationsRed.system_names);
  const status = useSelector((state) => state.integrationsRed.status);
  const applicationTypes = useSelector((state) => state.integrationsRed.applicationTypes);
  const authenticationMethods = useSelector((state) => state.integrationsRed.authenticationMethods);
  const showAuthenticationMethods = () => {
    return form?.application_type !== "ERP_EXTRACT";
  };

  const { form } = useSelector((state) => getFormValues(INTEGRATION_FORM_NAME)(state));

  const handleSystemNameChange = function (system_name) {
    dispatch(onSystemNameChange(system_name));
  };

  const handleApplicationTypeChange = function (system_name, application_type) {
    dispatch(onApplicationTypeChange(system_name, application_type));
  };

  const templateName = form.system_name + form.application_type;
  const Template = templates[templateName];

  const isQuickbook = () => {
    return (
      form.system_name === "Quickbook" &&
      form.application_type === "AUTHENTICATION" &&
      form.authentication_method === "QB_TOKEN_BASED"
    );
  };

  const isXero = () => {
    return (
      form.system_name === "Xero" &&
      form.application_type === "AUTHENTICATION" &&
      form.authentication_method === "XERO_TOKEN_BASED"
    );
  };

  const isBlackBaud = () => {
    return (
      form.system_name === "Blackbaud" &&
      form.application_type === "AUTHENTICATION" &&
      form.authentication_method === "BLACK_BAUD_TOKEN_BASED"
    );
  };

  return (
    <div>
      <Modal.Header closeButton>
        <Modal.Title>Save Credentials</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        <Form name={`${INTEGRATION_FORM_NAME}`} onSubmit={(value) => handleSubmit(value, dispatch)}>
          <Row className="m-0">
            <Col lg="6" className="">
              <Field
                id="integration_settings.system_name"
                name="form.system_name"
                label="System Name"
                autoFocus
                component={RenderSelect}
                validate={[required]}
                options={system_names}
                required={true}
                onChange={(e) => handleSystemNameChange(e.target.value)}
              />
            </Col>

            <Col lg="6" className="">
              <Field
                id="integration_settings.application_type"
                name="form.application_type"
                label="Application Type"
                component={RenderSelect}
                validate={[required]}
                options={applicationTypes}
                required={true}
                onChange={(e) => handleApplicationTypeChange(form.system_name, e.target.value)}
              />
            </Col>

            {form?.system_name != "Corpay" && showAuthenticationMethods() && (
              <Col lg="6">
                <Field
                  id="integration_settings.authentication_method"
                  label="Authentication Method"
                  name="form.authentication_method"
                  component={RenderSelect}
                  options={authenticationMethods}
                />
              </Col>
            )}
            <Col lg="6">
              <Field
                id="integration_settings.status"
                label="Status"
                name="form.status"
                validate={[required]}
                component={RenderSelect}
                options={status}
                required={true}
              />
            </Col>
            <Col lg="6">
              <Field
                id="integration_settings.description"
                label="Description"
                name="form.description"
                component={RenderTextArea}
                rows="3"
              />
            </Col>
          </Row>

          <Row className="m-0">
            {/* Our all templates will be imported dynamically according to name of system_name and application type */}
            {Template && <Template />}
          </Row>
          <Row className="m-0">
            <Col lg="12" className="d-flex justify-content-end">
              {isQuickbook() && <ConnectToQb />}
              {isXero() && <ConnectToXero />}
              {isBlackBaud() && <ConnectToBlackbaud />}
              <Button variant="secondary" className="m-2" onClick={onHide}>
                Cancel
              </Button>
              <Button className="m-2" variant="primary" type="submit">
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </div>
  );
};

// export default IntegrationModalForm;
IntegrationModalForm = reduxForm({
  form: INTEGRATION_FORM_NAME, // a unique identifier for this form
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  touchOnChange: false,
  touchOnBlur: false,
})(IntegrationModalForm);

IntegrationModalForm = connect((state, props) => ({
  initialValues: state.integrationsRed.credentialsForm,
}))(IntegrationModalForm);

export default IntegrationModalForm;
