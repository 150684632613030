import { AxiosResponse } from "axios";
import { restApiService } from "../../../providers/restApi";
import { IDType, getListOptionsType } from "../../common/types/common.type";
import { InvoiceType } from "./invoiceType";

class InvoiceApis {
  static async getNewInvoice() {
    try {
      const response: AxiosResponse<InvoiceType.InvoiceDetailType> = await restApiService.get("invoices/new");
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async getInvoiceList({ filter, cache }: getListOptionsType = {}) {
    try {
      const response: AxiosResponse<{ meta: InvoiceType.ListMetaDataType; data: InvoiceType.ListType[] }> =
        await restApiService.get("invoices", filter, null, true, null, cache);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async getInvoice(id: IDType) {
    try {
      const response: AxiosResponse<InvoiceType.InvoiceDetailType> = await restApiService.get("invoices/" + id);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async deleteInvoice(id: number) {
    try {
      const response: AxiosResponse<InvoiceType.InvoiceDetailType> = await restApiService.delete("invoices/" + id);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async addInvoice(invoicePayload: InvoiceType.AddPayloadType) {
    try {
      const response: AxiosResponse<InvoiceType.InvoiceDetailType> = await restApiService.post(
        "invoices",
        null,
        invoicePayload,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async getInvoiceItems(id: string) {
    try {
      const response: AxiosResponse<InvoiceType.InvoiceDetailType> = await restApiService.get(
        "invoices/" + id + "/invoice_items",
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async uploadDocuments(documentPayload: any, invoiceId: IDType) {
    try {
      const response: AxiosResponse<InvoiceType.InvoiceDetailType> = await restApiService.patch(
        "invoices/" + invoiceId,
        null,
        documentPayload,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async editInvoice(id: string, invoicePayload: InvoiceType.EditPayloadType) {
    try {
      const response: AxiosResponse<InvoiceType.InvoiceDetailType> = await restApiService.patch(
        "invoices/" + id,
        null,
        invoicePayload,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async getNextInvoiceIdAsync(id: string, params: string) {
    try {
      const response: AxiosResponse = await restApiService.get("invoices/" + id + "/next?" + params);
      return response.data.invoice.id;
    } catch (error) {
      throw error;
    }
  }

  static async getPrevInvoiceIdAsync(id: string, params: string) {
    try {
      const response: AxiosResponse = await restApiService.get("invoices/" + id + "/previous?" + params);
      return response.data.invoice.id;
    } catch (error) {
      throw error;
    }
  }

  static async attachFilesToInvoice(invoiceId: string, files: File[]) {
    if (files.length === 0) {
      return;
    }

    const formData = new FormData();

    files.forEach((file, index) => {
      formData.append(`invoice[assets_attributes[${index}]asset_file]`, file);
      formData.append(`invoice[assets_attributes[${index}]file_name]`, file.name);
      formData.append(`invoice[avoid_default_account_entries]`, "true");
    });

    try {
      const response: AxiosResponse<InvoiceType.InvoiceDetailType> = await restApiService.patch(
        "invoices/" + invoiceId,
        null,
        formData,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}

export default InvoiceApis;
