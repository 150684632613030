import React from "react";
import { Link } from "react-router-dom";
import { DocumentTypes } from "services/common/documents/documentTypes";

export const BulkUploadFilesRowAction = ({ data }: { data: DocumentTypes.Details }) => {
  return (
    <>
      {/*
      <Link to={`/ap/bulk_uploads/${data.id}`}>
        <button type="button" className="actionButtons icon-view mb-1" data-toggle="tooltip" title="View"></button>
      </Link>
    */}
      {(data.assets || []).map((asset) => {
        const extension = asset.asset_file_file_name.split(".").pop()?.toUpperCase();
        return (
          <Link
            target="_blank"
            to=""
            key={asset.id}
            href={asset.asset_expiring_url}
            download={asset.asset_file_file_name}
            className="actionButtons icon-download-blue mb-1"
            title={`Download ${extension || ""}`}
          />
        );
      })}
    </>
  );
};
