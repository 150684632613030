import useShowSelectMenu from "components/admin/hooks/useShowSelectMenu";
import useIsMounted from "components/common/hooks/useIsMounted";
import PickerErrorBlock from "components/common/pickers/pickerErrorBlock";
import { Mandatory } from "components/forms/bootstrapFields";
import _, { isPlainObject } from "lodash";
import { restApiService } from "providers/restApi";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { useTypedSelector } from "reducers";
import { CommonType } from "services/admin/commonTypes";
import { IUser } from "services/common/user/userTypes";
import { isDefined, parseForSelect } from "services/general/helpers";
import { CreateNotification, NotificationType } from "services/general/notifications";
import { DepartmentPickerPropsTypes, DepartmentsListOptionsType } from ".";
import Styles from "./../override.module.css";

interface SingleDepartmentPickerPropTypes extends DepartmentPickerPropsTypes {
  input: any;
}

const SingleDepartmentPicker = ({
  label,
  input,
  labelClassName,
  meta: { touched, error },
  originalValue,
  disabled,
  instanceId = "department-selector",
  placeholder,
  required,
  tooltip,
  params,
  modelData,
  callBack,
  parentObjData,
  isClearable,
  showAsteriskWithoutLabel,
  menuAutoFixed,
  parentDivId,
  parentDivVariance,
  restrictOptionsByEmployeeDepartment,
}: SingleDepartmentPickerPropTypes) => {
  const [selected, setSelected] = useState<DepartmentsListOptionsType | null>();
  const [departments, setDepartments] = useState<DepartmentsListOptionsType[]>();
  const currentUser: IUser = useTypedSelector((state) => state.user);

  const isMounted = useIsMounted();
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const selectRef = useRef<HTMLDivElement | null>(null);
  useShowSelectMenu({
    setIsMenuOpen,
    selectRef,
    parentDivId,
    variance: parentDivVariance,
  });

  const isSubsidiaryHasDepartment = () => {
    return currentUser?.company?.global?.show_departments_by_subsidiary && parentObjData?.subsidiary?.has_departments;
  };

  const getDepartments = useCallback(async () => {
    let apiParams: any = { status: "ACTIVE" };

    // Set "is_request_PO" true in parentObjData={purchaseOrder} if PO creating from Request PO section
    if (
      parentObjData?.is_request_PO &&
      currentUser?.company?.po_request_data_restrict_by_subsidiary &&
      parentObjData?.subsidiary_id > 0
    ) {
      apiParams.subsidiary_id = parentObjData?.subsidiary_id;
    }

    if (parentObjData?.is_request_PO && currentUser?.company?.po_request_auto_set_department) {
      apiParams.po_request = true;
    }

    if (modelData?.location?.is_location_map_to_department && modelData?.location_id) {
      apiParams.location_id = modelData?.location.id;
      modelData.only_show_mapped_department = true;
    }

    if (currentUser?.company?.global?.show_departments_by_subsidiary && parentObjData?.subsidiary?.has_departments) {
      apiParams.subsidiary_id = parentObjData?.subsidiary?.id;
      modelData.only_show_mapped_department = true;
    }

    if (modelData?.is_product_item_link_to_department) {
      apiParams.product_item_id = modelData.product_item_id;
      modelData.only_show_mapped_department = true;
    }

    if (modelData?.business_unit?.has_departments) {
      apiParams.business_unit_id = modelData?.business_unit_id;
      modelData.only_show_mapped_department = true;
    }

    // 'has_account_structures' becomes true if account_structures is uploaded
    // In this case, department picker will override all previous parameters
    // and send has_account_structures's parameters to departments.lk API.
    if (currentUser?.company?.has_account_structures) {
      apiParams = { status: "ACTIVE", account_structure: true };
      apiParams.account_structure = true;

      if (modelData) {
        apiParams.account_id = modelData?.account_id;
        modelData.only_show_mapped_department = true;
      }

      if (parentObjData) {
        apiParams.subsidiary_id = parentObjData?.subsidiary_id;
      }
    }

    if (params) {
      apiParams = { status: "ACTIVE", ...params };
    }

    try {
      const result = await restApiService.get("departments.lk", apiParams, null, true, null, true);
      // if employee has department_ids, restrict the department options to only those departments
      if (restrictOptionsByEmployeeDepartment && modelData?.employee?.department_ids?.length > 0) {
        const departmentIds = modelData?.employee?.department_ids;
        const filteredDepartments = result.data.filter((department: any) => departmentIds.includes(department.id));
        result.data = filteredDepartments;
      }
      let list = parseForSelect(result.data);
      if (isMounted.current) {
        if (!required) {
          list = [
            {
              label: "-- Select Department --",
              value: null,
              id: null,
              status: undefined,
            },
            ...list,
          ];
        }
        setDepartments(list);
      }
      return list;
    } catch (error) {}
  }, [parentObjData, modelData, modelData?.account_id]);

  const setValue = (value: CommonType.DepartmentLk | null) => {
    if (isPlainObject(value) && value?.id) {
      input.onChange(value.id);
      setSelected(value);
      if (callBack) {
        callBack(value);
      }
    } else {
      input.onChange(null);
      setSelected(null);
      if (callBack) {
        callBack(null);
      }
    }
  };

  const findSelectedSingle = async (input: { value: number | string }, options: DepartmentsListOptionsType[]) => {
    let selectedValues = null;
    if (input.value) {
      const department = await options.filter((option: any) => option.value === input.value);

      if (department.length === 0 && modelData?.only_show_mapped_department) {
        modelData.only_show_mapped_department = false;
        selectedValues = null;
        setValue(null);
      } else if (department.length === 0 && !isSubsidiaryHasDepartment()) {
        // Fetch inactive departments from the API and enable them to be appear in the department dropdown.
        try {
          const result = await restApiService.get("departments/" + input.value);
          const inactiveDepartment = {
            value: result.data.id,
            label: (
              <>
                {result.data.name} - <small style={{ fontSize: "10px" }}>({result.data.status})</small>
              </>
            ),
          };
          selectedValues = inactiveDepartment;
        } catch (error) {
          console.log(error);
          CreateNotification("Error", "Problem loading inactive department.", NotificationType.danger);
        }
      }
      // Do not set default value on init if employee has multiple department_ids. Let user select department.
      if (department.length === 0 && modelData?.employee?.department_ids.length > 1) {
        selectedValues = null;
      } else {
        selectedValues = department[0];
      }
      return selectedValues ? selectedValues : null;
    } else {
      return selectedValues;
    }
  };

  useEffect(() => {
    // fetching department first
    // due to logic if we don't find department in active department list then we consider that Department being Inactive, fetch inactive department.
    // so necessary to have department in active list fetched
    getDepartments().then((res) => {
      findSelectedSingle(input, res).then((response) => {
        if (isMounted.current) {
          setSelected(response as any);
        }
      });
    });
  }, [input, parentObjData?.subsidiary_id, parentObjData?.subsidiary?.id]);

  const onChangeDepartment = (input: any, value: any) => {
    setValue(value);
  };

  const checkIsClearable = useCallback(() => {
    if (required) {
      return false;
    } else if (isDefined(isClearable)) {
      return isClearable;
    } else if (_.isUndefined(isClearable)) {
      return false;
    }
  }, [isClearable, required]);

  const pickerProps = {
    ...input,
    required,
    placeholder,
    options: departments,
    isClearable: true,
    isDisabled: disabled,
    parentObjData,
    modelData,
    instanceId: instanceId,
  };

  return (
    <>
      <Form.Group className={Styles.select} ref={selectRef}>
        {label && (
          <Form.Label className={labelClassName}>
            {label ?? ""}
            <Mandatory required={required} />
            {tooltip ?? ""}
          </Form.Label>
        )}
        {!label && showAsteriskWithoutLabel && required && (
          <div className="d-flex justify-content-end">
            <Mandatory required={required} />
          </div>
        )}
        <Select
          {...pickerProps}
          {...(menuAutoFixed ? { menuPlacement: "auto", menuPosition: "fixed" } : {})}
          menuIsOpen={isMenuOpen}
          onMenuOpen={() => setIsMenuOpen(true)}
          onMenuClose={() => setIsMenuOpen(false)}
          required={false}
          menuPlacement="auto"
          menuPosition="fixed"
          value={selected}
          isClearable={checkIsClearable()}
          onChange={(value) => onChangeDepartment(input, value)}
          onBlur={null}
          getOptionLabel={(option: DepartmentsListOptionsType) => {
            return (
              <>
                <span className="formField">{option.label}</span>
                {option?.status === "INACTIVE" && <small style={{ fontSize: "10px" }}> -({option?.status})</small>}
              </>
            );
          }}
          filterOption={(option: any, searchText) =>
            option?.data?.label?.toLowerCase().includes(searchText.toLowerCase())
          }
          classNamePrefix="select"
        />
        {error && touched && <PickerErrorBlock error={error} />}
      </Form.Group>
    </>
  );
};

export default SingleDepartmentPicker;
