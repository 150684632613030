import _ from "lodash";
import React, { useState } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "../../../../reducers";
import { updateContact } from "../../../../reducers/admin/userProfileReducer";
import ContactApis from "../../../../services/common/contact/contactApis";
import { UpdateDelegatesPayloadType } from "../../../../services/common/contact/contactTypes";
import { companyDateFormat, dateStringWithoutTimezone } from "../../../../services/general/dateSvc";
import { CreateNotification, NotificationType } from "../../../../services/general/notifications";
import Panel from "../../../common/panel/panel";
import useConfirmModal from "../../../modals/confirmModal/useConfirmModalHook";
import CustomModal from "../../../modals/customModal";
import style from "./../userProfile.module.css";
import DelegationForm, { UserDelegationFormDataType } from "./delegationForm";

const UserDelegation = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [delegationFormOpen, setDelegationFormOpen] = useState(false);
  const currentContact = useTypedSelector((state) => state.userProfileRed.contact);
  const currentUser = useTypedSelector((state) => state.user);
  const delegator_links_attributes = currentContact?.delegator_links_attributes;
  const { createConfirmModal } = useConfirmModal();

  const getDelegateName = (delegateId: number) => {
    if (_.isArray(currentContact?.delegates)) {
      const delegateContact = currentContact?.delegates.find((delegate) => delegate.id === delegateId);
      return delegateContact?.first_name + " " + delegateContact?.last_name;
    }
    return delegateId;
  };

  const updateCurrentContact = () => {
    dispatch(updateContact());
  };

  const openDelegationForm = () => {
    setDelegationFormOpen(true);
  };
  const closeDelegationForm = () => {
    setDelegationFormOpen(false);
  };

  const onSubmit = async (formData: UserDelegationFormDataType) => {
    const updateDelegatesPayload: UpdateDelegatesPayloadType = { delegator_links_attributes: [] };
    if (_.isArray(formData.delegator_links_attributes) && currentContact) {
      updateDelegatesPayload.delegator_links_attributes = formData.delegator_links_attributes.map((delegateLink) => {
        delegateLink.delegator_id = currentContact?.id;

        // removing timezone character from datestring
        if (delegateLink.start_date) {
          delegateLink.start_date = dateStringWithoutTimezone(
            delegateLink.start_date,
            currentUser.company.company_date_format,
          );
        }
        if (delegateLink.end_date) {
          delegateLink.end_date = dateStringWithoutTimezone(
            delegateLink.end_date,
            currentUser.company.company_date_format,
          );
        }
        return delegateLink;
      });
    }

    try {
      if (currentContact?.id) {
        await ContactApis.updateDelegates(currentContact?.id, { contact: updateDelegatesPayload });
        updateCurrentContact();
        closeDelegationForm();
        CreateNotification(t("success"), t("admin.pages.userProfile.delegatesUpdated"), NotificationType.success);
      }
    } catch (error) {}
  };

  const onDeleteDelegate = async (delegateId: number) => {
    try {
      if (currentContact?.id) {
        await ContactApis.deleteDelegate(currentContact?.id, { delegate_link_id: delegateId });
        updateCurrentContact();
        CreateNotification(t("success"), t("admin.pages.userProfile.delegateDeleted"), NotificationType.success);
      }
    } catch (error) {}
  };

  const confirmDeleteDelegate = (delegateId: number) => {
    createConfirmModal({
      title: t("admin.pages.userProfile.deleteDelegate"),
      body: t("admin.pages.userProfile.deleteDelegateConfirm", { id: delegateId }),
      callBackData: delegateId,
      saveCallBack: onDeleteDelegate,
      cancelCallBack: null,
    });
  };

  return (
    <>
      {delegationFormOpen && (
        <CustomModal
          size="xl"
          header={
            <Row className="px-3">
              <Col>
                <div>
                  <h1>{t("admin.pages.userProfile.delegates")}</h1>
                </div>
                <div className={style.delegateSubtitle}>{t("admin.pages.userProfile.manageDelegate")}</div>
              </Col>
            </Row>
          }
          show={delegationFormOpen}
          onHide={closeDelegationForm}
          body={
            <DelegationForm
              initialValues={{ delegator_links_attributes: delegator_links_attributes }}
              onSubmit={onSubmit}
            />
          }
        />
      )}
      <Panel
        header={
          <Col>
            <Row className="justify-content-between">
              <div className="mt-2">
                <i className="icon icon-delegate-black m-0"></i> {t("admin.pages.userProfile.delegates").toUpperCase()}
              </div>
              <div className="mt-1">
                <Button variant="secondary" size="sm" className="px-5" onClick={openDelegationForm}>
                  <i className="icon icon-plus-black m-0"></i> {t("admin.pages.userProfile.delegate")}
                </Button>
              </div>
            </Row>
          </Col>
        }
      >
        {_.isArray(delegator_links_attributes) &&
          delegator_links_attributes &&
          delegator_links_attributes.length > 0 && (
            <Table responsive striped>
              <thead>
                <tr>
                  <th>{t("admin.pages.userProfile.assignedDelegate")}</th>
                  <th>{t("admin.pages.userProfile.delegatedType")}</th>
                  <th>{t("admin.pages.userProfile.startDate")}</th>
                  <th>{t("admin.pages.userProfile.endDate")}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {delegator_links_attributes.map((delegation) => {
                  return (
                    <tr key={delegation.id}>
                      <td>{delegation?.delegate_id && getDelegateName(delegation?.delegate_id)}</td>
                      <td>{delegation.delegate_type}</td>
                      <td>{delegation.start_date ? companyDateFormat(delegation.start_date, currentUser) : "--"}</td>
                      <td>{delegation.end_date ? companyDateFormat(delegation.end_date, currentUser) : "--"}</td>
                      <td>
                        <Button
                          onClick={() => {
                            if (delegation?.id) confirmDeleteDelegate(delegation?.id);
                          }}
                          className="bg-transparent border-0 m-0 mt-1"
                        >
                          <i className="icon icon-delete m-0" />
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          )}
        {_.isArray(delegator_links_attributes) &&
          delegator_links_attributes &&
          delegator_links_attributes?.length < 1 &&
          t("admin.pages.userProfile.noAssignedDelegate")}
      </Panel>
    </>
  );
};

export default UserDelegation;
