import { restApiService } from "providers/restApi";
import React, { memo, useCallback, useMemo } from "react";
import AbstractListPicker from "../abstractListPicker/index2";
import { CostCategoryPickerTypes } from "./costCategoryPickerTypes";

const CostCategoryPicker = ({
  name,
  label,
  instanceId,
  required = false,
  modelDataName,
  objectPropertyKey,
}: CostCategoryPickerTypes.CostCategoryPickerProps) => {
  const mergeInactive = useCallback(async (currentId, costCategories) => {
    let inactiveOption = null;
    if (currentId) {
      const costCategory = Array.isArray(costCategories)
        ? costCategories.filter((costcategory) => costcategory.id === currentId)
        : [];
      if (costCategory.length === 0) {
        try {
          const response = await restApiService.get(`cost_categories/${currentId}`);
          inactiveOption = response.data;
        } catch (error) {
          console.error(error);
        }
      } else {
        inactiveOption = costCategory[0];
      }
    }
    return { inactiveOption };
  }, []);

  const params = useMemo(() => ({ status: "ACTIVE" }), []);

  return (
    <AbstractListPicker<CostCategoryPickerTypes.TCostCategoryPickerOption>
      name={name}
      label={label}
      required={required}
      instanceId={instanceId}
      params={params}
      objectPropertyKey={objectPropertyKey ?? (modelDataName && `${modelDataName}.cost_category`)}
      mergeInactive={mergeInactive}
      fetchUrl="cost_categories"
    />
  );
};

export default memo(CostCategoryPicker);
