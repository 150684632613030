import { DatePickerField } from "components/forms/hookFormFields";
import React from "react";
import { useFormContext } from "react-hook-form";
import useCustomLabel from "services/admin/customLabels/useCustomLabel";
import { InvoiceType } from "services/admin/invoices/invoiceType";
import { useInvoiceCommonSvc } from "services/admin/invoices/useInvoiceCommonSvc";

const InvoiceDetailsDate = () => {
  const { setValue } = useFormContext<InvoiceType.InvoiceDetailType>();
  const { getCustomLabel } = useCustomLabel();
  const invoiceCommonHookSvc = useInvoiceCommonSvc();

  const onDateChange = (date: string | null) => {
    if (date) {
      setValue("date", date);
      invoiceCommonHookSvc.updateDueDate();
    }
  };

  return (
    <DatePickerField
      name="date"
      label={getCustomLabel("admin.pages.invoice.invoiceDate")}
      required
      onChange={onDateChange}
    />
  );
};

export default InvoiceDetailsDate;
