// This file contains common logic used in the admin portal.

import _ from "lodash";
import moment from "moment";
import { IUser } from "services/common/user/userTypes";
import { CommonApis } from "./commonApis";

import commonService from "services/common/commonSvc";
import { getDateFormat } from "services/general/dateSvc";
import { CommonType } from "./commonTypes";
import invoiceCommonSvc from "./invoices/invoiceCommonSvc";
import { InvoiceType } from "./invoices/invoiceType";

class AdminCommonSvc {
  isNvpCustomer = (currentUser: IUser): boolean => {
    return !!(currentUser?.company?.accepted_payment_methods?.corpay && currentUser?.company?.is_nvp_credit_model);
  };

  isNvpOnlyCustomer = (currentUser: IUser): boolean => {
    return !!currentUser.company.is_nvp_customer;
  };

  //customer which have nvp integration
  isNvpIntegratedCustomer = (currentUser: IUser): boolean => {
    return currentUser?.company?.accepted_payment_methods?.corpay;
  };

  isBudgetVisibleAtHeaderLevel = (currentUser: IUser): boolean => {
    return !!(currentUser?.company?.has_budgets && currentUser?.company?.budget?.show_field_at === "header_level");
  };

  isBudgetVisibleAtLineLevel = (currentUser: IUser): boolean => {
    return !!(currentUser?.company?.has_budgets && currentUser?.company?.budget?.show_field_at === "line_level");
  };

  removeIdFromBudgetItemLinks = (item: any) => {
    if (_.isPlainObject(item) && _.isArray(item.budget_item_links) && item.budget_item_links.length > 0) {
      item.budget_item_links.forEach((link: any) => delete link.id);
    }
  };

  showItemsBySubsidiary = (modelData: any, currentUser: IUser) => {
    return currentUser?.company?.global?.show_items_by_subsidiary && modelData?.subsidiary_id;
  };

  roundUp = (val: number) => {
    return Math.round(val * 100) / 100;
  };

  getDefaultDecimal(currentUser?: IUser) {
    return currentUser?.company?.has_any_integration ? 2 : 4;
  }

  roundUpNumber = ({ val, d = 2 }: { val: number; d?: number }) => {
    return Math.round(val * Math.pow(10, d)) / Math.pow(10, d);
  };

  roundUpAmount = (val: number, decimal: number | null = null, currentUser?: IUser) => {
    let d = decimal && decimal > 0 ? decimal : this.getDefaultDecimal(currentUser);
    return Math.round(val * Math.pow(10, d)) / Math.pow(10, d);
  };

  unitPriceDecimalLimit(currentUser: IUser) {
    let decimalLimit = 8;
    if (currentUser?.company?.global?.decimal_limit_to_unit_price > 0) {
      decimalLimit = currentUser.company.global.decimal_limit_to_unit_price;
    }
    return decimalLimit;
  }

  getSymbolFromIsoCode = (isoCode?: string, currencies?: any[]) => {
    if (_.isArray(currencies) && currencies.length > 0) {
      return currencies.find((currency) => currency.value === isoCode)?.symbol ?? "$";
    }
  };

  getDateObjFromDateStr = (dateString: string): Date | null => {
    /**
     * Moment construction falls back to js Date.
     * This is discouraged and will be removed in an upcoming major release.
     * This deprecation warning is thrown when no known format is found for a date passed into the string constructor.
     * To work around this issue, specify a format for the string being passed to moment().
     */
    const validDateFormats = ["MM/DD/YYYY", "DD/MM/YYYY", "YYYY-MM-DD", moment.ISO_8601, moment.RFC_2822];

    // checking if all possible date format if valid date string
    const dateValid = moment(dateString, validDateFormats, true).isValid();

    if (dateValid) {
      if (moment(dateString, ["MM/DD/YYYY", "DD/MM/YYYY"], true).isValid()) {
        return moment(dateString, ["MM/DD/YYYY", "DD/MM/YYYY"], true).toDate();
      } else if (moment(dateString, "YYYY-MM-DD", true).isValid()) {
        return moment(dateString, "YYYY-MM-DD", true).toDate();
      } else if (moment(dateString, moment.ISO_8601, true).isValid()) {
        return moment.parseZone(dateString, moment.ISO_8601, true).toDate();
      } else {
        return moment.parseZone(dateString, moment.RFC_2822, true).toDate();
      }
    } else {
      return null;
    }
  };

  getDateObjFromKnowDateFormate = (dateString: string, formate: string) => {
    const dateValid = moment(dateString, formate, true).isValid();
    if (dateValid) {
      return moment(dateString, formate, true).toDate();
    }
    // If the date string is not valid for the provided format,attempt to parse it using multiple known date formats in the getDateObjFromDateStr method.
    else {
      return this.getDateObjFromDateStr(dateString);
    }
  };

  companyFormatedDate = (dateString: string, currentUser: IUser): string => {
    if (!dateString) return "";
    /**
     * Moment construction falls back to js Date.
     * This is discouraged and will be removed in an upcoming major release.
     * This deprecation warning is thrown when no known format is found for a date passed into the string constructor.
     * To work around this issue, specify a format for the string being passed to moment().
     */

    const validDateFormats = ["MM/DD/YYYY", "DD/MM/YYYY", moment.ISO_8601, moment.RFC_2822];
    const companyDateFormat = getDateFormat(currentUser).toUpperCase();

    // checking if all possible date format if valid date string
    const dateValid = moment(dateString, validDateFormats, true).isValid();

    if (dateValid) {
      if (moment(dateString, "YYYY-MM-DD", true).isValid()) {
        return moment(dateString, "YYYY-MM-DD", true).format(companyDateFormat);
      } else if (moment(dateString, ["MM/DD/YYYY", "DD/MM/YYYY"], true).isValid()) {
        return moment(dateString, ["MM/DD/YYYY", "DD/MM/YYYY"], true).format(companyDateFormat);
      } else if (moment(dateString, "YYYY-MM-DD", true).isValid()) {
        return moment(dateString, "YYYY-MM-DD", true).format(companyDateFormat);
      } else if (moment(dateString, moment.ISO_8601, true).isValid()) {
        return moment.parseZone(dateString, moment.ISO_8601, true).format(companyDateFormat);
      } else {
        return moment.parseZone(dateString, moment.RFC_2822, true).format(companyDateFormat);
      }
    } else {
      return "";
    }
  };

  nextDueDate = (invoice: InvoiceType.InvoiceDetailType, dueDate: Date) => {
    const due_date = moment(dueDate);
    const invoice_date = moment(invoice.date);
    const days = due_date.diff(invoice_date, "days");
    if (invoice.term?.days_within_due_date && days <= invoice.term.days_within_due_date) {
      const month_days = due_date.add(1, "months").daysInMonth(); //it is used to get total days of the month
      const day = invoice?.term?.due_days && invoice.term.due_days > month_days ? month_days : invoice.term.due_days; //it is used to set valid date of the month. Ex-> invoice.term.due_days is 31 and month total days is 30 so we will consider 30th for that month
      dueDate.setMonth(dueDate.getMonth() + 1, day); //in the parent function we are using Date obj JS so continue with this to set date
    }
    return dueDate;
  };

  calculateDueDate = (invoice: InvoiceType.InvoiceDetailType) => {
    let dueDate = invoice.date && this.getDateObjFromDateStr(invoice.date);
    if (invoice?.term && dueDate) {
      // if term present calculate due date from term
      if (!invoice.term.is_due_month_date && invoice?.term?.due_days && invoice?.term?.due_days > 0) {
        dueDate.setDate(dueDate.getDate() + invoice.term.due_days);
      } else {
        let m = dueDate.getMonth();
        let y = dueDate.getFullYear();
        if (invoice?.term?.due_upto_month && m + invoice.term.due_upto_month > 11) {
          m = m + invoice.term.due_upto_month - 12;
          y = y + 1;
        } else if (invoice?.term?.due_upto_month) {
          m = m + invoice.term.due_upto_month;
        }
        const monthDays = moment([y, m, 1]).daysInMonth();
        let day = invoice?.term?.due_days && invoice.term.due_days > monthDays ? monthDays : invoice.term.due_days;
        dueDate.setFullYear(y, m, day);
        if (invoice.term.days_within_due_date > 0) {
          dueDate = this.nextDueDate(invoice, dueDate);
        }
      }
    }
    if (dueDate) {
      let dt = dueDate.getDate() < 10 ? "0" + dueDate.getDate() : dueDate.getDate();
      let m = dueDate.getMonth() + 1;
      return dueDate.getFullYear() + "-" + (m < 10 ? "0" + m : m) + "-" + dt;
    }
  };

  calculateDiscDate = (invoice: InvoiceType.InvoiceDetailType) => {
    if (invoice) {
      const discDate = invoice?.date && this.getDateObjFromDateStr(invoice.date);
      const term = invoice.term;
      if (discDate) {
        if (_.isObject(term) && !term?.is_disc_month_date && term?.disc_days && term?.disc_days > 0) {
          let discDays =
            term?.disc_grace_days && term?.disc_grace_days > 0 ? term.disc_grace_days + term.disc_days : term.disc_days;
          discDate.setDate(discDate.getDate() + discDays);
        } else if (_.isObject(term) && term.is_disc_month_date && term?.disc_days && term.disc_days > 0) {
          let m = discDate.getMonth();
          let y = discDate.getFullYear();

          if (term?.disc_upto_month && m + term?.disc_upto_month > 11) {
            m = m + term.disc_upto_month - 12;
            y = y + 1;
          } else if (term?.disc_upto_month) {
            m = m + term.disc_upto_month;
          }
          discDate.setFullYear(y, m, term.disc_days);
          if (term?.disc_grace_days && term.disc_grace_days > 0) {
            discDate.setDate(discDate.getDate() + term.disc_grace_days);
          }
        } else {
          return null;
        }
        let dt = discDate.getDate() < 10 ? "0" + discDate.getDate() : discDate.getDate();
        let m = discDate.getMonth() + 1;
        return discDate.getFullYear() + "-" + (m < 10 ? "0" + m : m) + "-" + dt;
        // YYYY-MM-DD
      }
    }
  };

  isAccDiscountable: CommonType.IsAccDiscountableMethodType = (account) => {
    if (
      _.isPlainObject(account) &&
      account.is_exclude_for_discount &&
      account.is_exclude_for_discount.toLowerCase() === "true"
    ) {
      return false;
    } else if (
      _.isPlainObject(account) &&
      !account.is_exclude_for_discount &&
      _.isPlainObject(account.properties) &&
      account.properties.is_exclude_for_discount &&
      account.properties.is_exclude_for_discount.toLowerCase() === "true"
    ) {
      return false;
    }
    return true;
  };

  isDiscountable: CommonType.IsDiscountableMethodType = (entry) => {
    if (_.isPlainObject(entry.account) && entry?.account && !this.isAccDiscountable(entry?.account)) {
      return false;
    } else if (
      entry.product_item &&
      _.isPlainObject(entry.product_item) &&
      _.isArray(entry?.product_item?.invoice_debit_accounts) &&
      _.isPlainObject(entry.product_item?.invoice_debit_accounts[0]) &&
      _.isPlainObject(entry?.product_item?.invoice_debit_accounts[0].account) &&
      !this.isAccDiscountable(entry.product_item?.invoice_debit_accounts[0].account)
    ) {
      return false;
    }
    return true;
  };

  calculateAccountTotalAmount: CommonType.CalculateAccountTotalAmountMethodType = (invoice, currentUser) => {
    let account_total_amount = 0;
    _.forEach(invoice.debit_entries_attributes, (debit_entry) => {
      if (
        debit_entry._destroy !== 1 &&
        this.isDiscountable(debit_entry) &&
        parseFloat(debit_entry.amount) &&
        !debit_entry.product_item_id &&
        !debit_entry.is_header_level_tax
      ) {
        account_total_amount += parseFloat(debit_entry.amount);
      }
    });
    return parseFloat(this.roundUpAmount(account_total_amount, null, currentUser) as unknown as string);
  };

  calculateAccountSubTotalAmount: CommonType.CalculateAccountSubTotalAmountMethodType = (invoice, currentUser) => {
    let account_subtotal_amount = 0;
    _.forEach(invoice.debit_entries_attributes, (debit_entry) => {
      if (
        debit_entry._destroy !== 1 &&
        this.isDiscountable(debit_entry) &&
        parseFloat(debit_entry.amount) &&
        !debit_entry.product_item_id &&
        !debit_entry.is_header_level_tax
      ) {
        if (
          invoiceCommonSvc.enabledExpenseTaxOrRebate(currentUser) &&
          !debit_entry.subtotal_template_id &&
          !debit_entry.is_subtotal_template
        ) {
          account_subtotal_amount += parseFloat(debit_entry.sub_amount);
        } else if (!debit_entry.subtotal_template_id && !debit_entry.is_subtotal_template) {
          account_subtotal_amount += parseFloat(debit_entry.amount);
        }
      }
    });
    return parseFloat(this.roundUpAmount(account_subtotal_amount, null, currentUser) as unknown as string);
  };

  calculateItemSubtotalAmount: CommonType.CalculateItemSubtotalAmountMethodType = (invoice, currentUser) => {
    let item_subtotal_amount = 0;
    _.forEach(invoice.invoice_items_attributes, (invoice_item) => {
      if (invoice_item._destroy !== 1 && this.isDiscountable(invoice_item)) {
        item_subtotal_amount += parseFloat(invoice_item.amount) || 0;
      }
    });
    return parseFloat(this.roundUpAmount(item_subtotal_amount, null, currentUser) as unknown as string);
  };

  calculateItemTotalAmount: CommonType.CalculateItemTotalAmounMethodType = (invoice, currentUser) => {
    let item_total_amount = 0;
    _.isArray(invoice.invoice_items_attributes) &&
      invoice.invoice_items_attributes.forEach((invoice_item) => {
        if (invoice_item._destroy !== 1 && this.isDiscountable(invoice_item)) {
          item_total_amount += invoiceCommonSvc.getItemTotal(invoice, invoice_item, currentUser);
        }
      });
    return parseFloat(this.roundUpAmount(item_total_amount, null, currentUser) as unknown as string);
  };

  getValidInvoiceLineItems = (invoice: InvoiceType.InvoiceDetailType) => {
    return Array.isArray(invoice.invoice_items_attributes)
      ? invoice?.invoice_items_attributes?.filter((item) => item._destroy !== 1)
      : [];
  };

  getValidInvoiceDebitEntries = (invoice: InvoiceType.InvoiceDetailType) => {
    return Array.isArray(invoice.debit_entries_attributes)
      ? invoice?.debit_entries_attributes?.filter((item) => item._destroy !== 1)
      : [];
  };

  getDiscountableAmount: CommonType.GetDicountAmountInvoiceMethodType = (invoice, currentUser) => {
    var term = invoice.term;
    let amount = 0;
    if (this.getValidInvoiceLineItems(invoice).length > 0 || this.getValidInvoiceDebitEntries(invoice).length > 0) {
      if (_.isPlainObject(term) && term.is_discount_on_subtotal) {
        amount =
          this.calculateItemSubtotalAmount(invoice, currentUser) +
          this.calculateAccountSubTotalAmount(invoice, currentUser);
      } else {
        amount =
          this.calculateItemTotalAmount(invoice, currentUser) + this.calculateAccountTotalAmount(invoice, currentUser);
      }
    } else {
      amount = Number(invoice.amount);
    }
    return parseFloat(this.roundUpAmount(amount, null, currentUser) as unknown as string);
  };

  calculateDiscountAmt = (invoice: InvoiceType.InvoiceDetailType, currentUser: IUser) => {
    let amt = 0;
    let term = invoice.term;
    let amount = this.getDiscountableAmount(invoice, currentUser);
    if (amount && this.calculateDiscDate(invoice) && _.isPlainObject(term)) {
      if (term?.disc_amount && term.disc_amount > 0) {
        amt = this.roundUp(Number(term.disc_amount));
      } else if (term?.disc_rate) {
        amt = this.roundUp(Number(term.disc_rate * 0.01 * amount));
      }
    }
    return amt;
  };

  removeLocationId = (locations: any, lineArray: any) => {
    if (_.isArray(lineArray) && lineArray.length > 0) {
      lineArray.forEach((lineObj) => {
        let location = _.find(locations, (location) => location.id === lineObj.location_id);
        if (!location) {
          lineObj.location_id = "";
        }
      });
      return lineArray;
    }
  };

  locationIdPromise = async (modelData: any, subsidiary: any) => {
    let params: any = { status: "ACTIVE" };

    if (subsidiary?.has_locations) {
      params.subsidiary_id = subsidiary.id;
    }

    try {
      let locations = await CommonApis.getLocations(params);

      // For header level location
      if (modelData?.location_id > 0) {
        const updatedLineArray = this.removeLocationId(locations, [{ location_id: modelData.location_id }]);
        if (_.isArray(updatedLineArray)) {
          modelData.location_id = updatedLineArray[0]?.location_id;
        }
      }

      // For line level location
      if (modelData) {
        if (_.isArray(modelData?.invoice_items_attributes) && modelData?.invoice_items_attributes?.length > 0) {
          modelData.invoice_items_attributes = this.removeLocationId(locations, modelData.invoice_items_attributes);
        }

        if (_.isArray(modelData?.debit_entries_attributes) && modelData?.debit_entries_attributes?.length > 0) {
          modelData.debit_entries_attributes = this.removeLocationId(locations, modelData.debit_entries_attributes);
        }

        if (_.isArray(modelData?.credit_entries_attributes) && modelData?.credit_entries_attributes?.length > 0) {
          modelData.credit_entries_attributes = this.removeLocationId(locations, modelData.credit_entries_attributes);
        }

        if (_.isArray(modelData?.vendor_credit_items) && modelData?.vendor_credit_items?.length > 0) {
          modelData.vendor_credit_items = this.removeLocationId(locations, modelData.vendor_credit_items);
        }

        if (_.isArray(modelData?.po_items) && modelData?.po_items?.length > 0) {
          modelData.po_items = this.removeLocationId(locations, modelData.po_items);
        }

        if (_.isArray(modelData?.invoice_debit_accounts) && modelData.invoice_debit_accounts.length > 0) {
          modelData.invoice_debit_accounts = this.removeLocationId(locations, modelData.invoice_debit_accounts);
        }
      }
    } catch (error) {
      throw error;
    }
  };

  refreshLocationPicker = async (modelData: any, subsidiary: any, currentUser: IUser) => {
    if (currentUser.company.has_locations) {
      await this.locationIdPromise(modelData, subsidiary);
      // is_subsidiary_link_with_department() ->  TODO: handle department field reload on subsidiary
    }
  };

  inheritCustomField = (modelData: any, customFields: any) => {
    let obj: any = {};
    if (modelData && !modelData.custom_fields) {
      modelData.custom_fields = {};
    }
    for (let key in customFields) {
      if (
        customFields[key] &&
        _.isPlainObject(customFields[key]) &&
        ((_.isPlainObject(customFields[key].value) && customFields[key].value.external_id) ||
          customFields[key].inherit == true ||
          (_.isPlainObject(customFields[key].value) && customFields[key].value.inherit == true))
      ) {
        modelData.custom_fields[key] = customFields[key];
      }
    }
    return modelData.custom_fields;
  };

  inheritMetaData = (targetModel: any, sourceModel: any) => {
    if (!targetModel || !sourceModel || !sourceModel.metadata) {
      return;
    }

    targetModel.metadata = sourceModel.metadata;

    return targetModel.metadata;
  };

  checkManagerExists = async (subsidiaryId: number, departmentId: number) => {
    if (subsidiaryId > 0 && departmentId > 0) {
      let response = await CommonApis.getSubsidiaries({ department_id: departmentId, subsidiary_id: subsidiaryId });
      let isManagerExist = response.length === 0 ? true : false;
      return isManagerExist;
    }
  };

  normalizeAmount = (value: number, previousValue: number, currentUser: IUser) => {
    const nfValue1 = commonService.normalizeNotToNegative(value, previousValue);
    const nfValue2 = this.roundUpAmount(nfValue1, null, currentUser);
    return nfValue2;
  };

  getColorsHex = () => {
    const colorsArray = [
      "#EFCCD2",
      "#D1E7CC",
      "#CCF0F9",
      "#00FFFF",
      "#F5F5DC",
      "#7FFFD4",
      "#FFEBCD",
      "#8A2BE2",
      "#FFE4C4",
      "#FF6347",
      "#DEB887",
      "#5F9EA0",
      "#7FFF00",
      "#D2691E",
      "#BC8F8F",
      "#6495ED",
      "#FFF8DC",
      "#DC143C",
      "#00008B",
      "#008B8B",
      "#B8860B",
      "#A9A9A9",
      "#006400",
      "#BDB76B",
      "#8B008B",
      "#556B2F",
      "#FF8C00",
      "#9932CC",
      "#8B0000",
      "#E9967A",
      "#8FBC8F",
      "#483D8B",
      "#2F4F4F",
      "#00CED1",
      "#9400D3",
      "#FF1493",
      "#00BFFF",
      "#696969",
    ];

    const colorsMap = new Map([
      ["#EFCCD2", "#EFCCD2"], // Pale Pink
      ["#D1E7CC", "#D1E7CC"], // Pale Green
      ["#CCF0F9", "#CCF0F9"], //Light Azure
      ["#00FFFF", "#00FFFF"], // Aqua
      ["#F5F5DC", "#F5F5DC"], // Beige
      ["#7FFFD4", "#7FFFD4"], // Aquamarine
      ["#FFEBCD", "#FFEBCD"], // Blanched Almond
      ["#8A2BE2", "#8A2BE2"], // Blue Violet
      ["#FFE4C4", "#FFE4C4"], // Bisque
      ["#FF6347", "#FF6347"], // Tomato (Warm)
      ["#DEB887", "#DEB887"], // Burlywood
      ["#5F9EA0", "#5F9EA0"], // Cadet Blue
      ["#7FFF00", "#7FFF00"], // Chartreuse
      ["#D2691E", "#D2691E"], // Chocolate
      ["#BC8F8F", "#BC8F8F"], // Rosy Brown
      ["#6495ED", "#6495ED"], // Cornflower Blue
      ["#FFF8DC", "#FFF8DC"], // Cornsilk
      ["#DC143C", "#DC143C"], // Crimson
      ["#00008B", "#00008B"], // Dark Blue
      ["#008B8B", "#008B8B"], // Dark Cyan
      ["#B8860B", "#B8860B"], // Dark Goldenrod
      ["#A9A9A9", "#A9A9A9"], // Dark Gray
      ["#006400", "#006400"], // Dark Green
      ["#BDB76B", "#BDB76B"], // Dark Khaki
      ["#8B008B", "#8B008B"], // Dark Magenta
      ["#556B2F", "#556B2F"], // Dark Olive Green
      ["#FF8C00", "#FF8C00"], // Dark Orange
      ["#9932CC", "#9932CC"], // Dark Orchid
      ["#8B0000", "#8B0000"], // Dark Red
      ["#E9967A", "#E9967A"], // Dark Salmon
      ["#8FBC8F", "#8FBC8F"], // Dark Sea Green
      ["#483D8B", "#483D8B"], // Dark Slate Blue
      ["#2F4F4F", "#2F4F4F"], // Dark Slate Gray
      ["#00CED1", "#00CED1"], // Dark Turquoise
      ["#9400D3", "#9400D3"], // Dark Violet
      ["#FF1493", "#FF1493"], // Deep Pink
      ["#00BFFF", "#00BFFF"], // Deep Sky Blue
      ["#696969", "#696969"], // Dim Gray
    ]);

    return { colorsMap, colorsArray };
  };
}

const adminCommonSvc = new AdminCommonSvc();
export default adminCommonSvc;
