import { AxiosResponse } from "axios";
import { IUser } from "services/common/user/userTypes";
import { restApiService } from "../../../providers/restApi";
import { default as invoiceApis } from "./invoiceApis";
import { InvoiceType } from "./invoiceType";

const invoiceFields = [
  "name",
  "unit_price",
  "tax",
  "amount",
  "total",
  "tax_id",
  "description",
  "account_id",
  "product_item_id",
  "product_item",
  "business_unit_id",
  "department_id",
  "location_id",
  "project_id",
  "project",
  "qty",
  "properties",
  "unit_id",
  "wh_tax_id",
  "wh_tax_amount",
  "warehouse_id",
  "inter_company_id",
  "for_business_unit_id",
  "secondary_category_id",
  "for_subsidiary_id",
  "customer_external_id",
  "vineyard_code_external_id",
  "for_location_id",
  "for_project_id",
  "for_project",
];

const copyInvoiceItemToCreditMemo = (invoice: InvoiceType.InvoiceDetailType) => {
  const copiedItems: any[] = [];

  if (Array.isArray(invoice.invoice_items) && invoice.invoice_items.length > 0) {
    invoice.invoice_items.forEach((item) => {
      const copiedItem: any = {};
      const itemAsAny = item as any;
      Object.keys(itemAsAny).forEach((key) => {
        if (invoiceFields.includes(key)) {
          copiedItem[key] = itemAsAny[key];
        }
      });

      copiedItems.push(copiedItem);
    });
  }

  return copiedItems;
};

const canAllowRebateExpensesLine = (user: IUser) => {
  return user.company.has_rebates && user.company.global && user.company.global.allow_rebate_at_expenses_line;
};

const isCmExpenseTaxOrRebateEnabled = (user: IUser) => {
  return (
    (user.company.has_taxes && user.company.vendor_credit_allow_tax_to_account) || canAllowRebateExpensesLine(user)
  );
};

export const isExpenseDebitEntry = (debitEntry: any) => {
  if (
    debitEntry.product_item_id ||
    debitEntry.is_tax ||
    debitEntry.subtotal_template_id ||
    debitEntry.is_subtotal_template ||
    debitEntry.is_header_level_tax
  ) {
    return false;
  }
  if (debitEntry._destroy === 1) {
    return false;
  }
  return true;
};

const copyInvoiceAssets = (invoice: InvoiceType.InvoiceDetailType, creditMemo: any) => {
  if (Array.isArray(invoice.assets) && invoice.assets.length > 0) {
    creditMemo.upload_assets_by_url = "yes";

    invoice.assets.forEach((asset, index) => {
      const fileNameKey = index === 0 ? "asset_file_name" : `asset_file_name_${index}`;
      const urlKey = index === 0 ? "asset_file_url" : `asset_file_url_${index}`;

      creditMemo[fileNameKey] = asset.asset_file_file_name;
      creditMemo[urlKey] = asset.asset_expiring_url;
    });
  }

  return creditMemo;
};

const copyInvoiceDebitAccountsToCreditMemo = (invoice: InvoiceType.InvoiceDetailType, user: IUser) => {
  const creditEntries: any[] = [];

  if (Array.isArray(invoice.debit_entries)) {
    invoice.debit_entries.forEach((entry) => {
      if (isExpenseDebitEntry(entry)) {
        const debitEntryCopy = { ...entry };
        debitEntryCopy.id = undefined;

        if (!user.company.vendor_credit_allow_tax_to_account) {
          debitEntryCopy.tax_id = undefined;
          debitEntryCopy.tax = undefined;
          debitEntryCopy.tax_code = undefined;
        }

        if (!isCmExpenseTaxOrRebateEnabled(user)) {
          debitEntryCopy.sub_amount = debitEntryCopy.amount;
        }

        creditEntries.push(debitEntryCopy);
      }
    });
  }

  return creditEntries;
};

const copyInvoiceCreditAccountsToCreditMemo = (invoice: InvoiceType.InvoiceDetailType) => {
  const debitEntries: any[] = [];
  if (Array.isArray(invoice.credit_entries)) {
    invoice.credit_entries.forEach((entry) => {
      const copiedEntry = { ...entry };
      copiedEntry.id = undefined;
      debitEntries.push(copiedEntry);
    });
  }
  return debitEntries;
};

const convertToCreditMemoAsync = async (invoice: InvoiceType.InvoiceDetailType, user: IUser) => {
  if (!invoice || !invoice.id) {
    return;
  }

  const fetchedInvoice = await invoiceApis.getInvoice(invoice.id);
  if (!fetchedInvoice || !fetchedInvoice.id) {
    return;
  }

  let creditMemo: any = {};
  creditMemo.vendor_id = invoice.vendor_id;
  creditMemo.payment_number = invoice.number;
  creditMemo.department_id = invoice.department_id;
  creditMemo.location_id = invoice.location_id || invoice.location_id;
  creditMemo.requestor = invoice.requestor;
  creditMemo.requestor_id = invoice.requestor_id;
  creditMemo.reference_number = invoice.reference_number ? invoice.reference_number : invoice.number;
  creditMemo.memo = invoice.description;
  creditMemo.status = "NEW";
  creditMemo.submit_date = invoice.submit_date;
  creditMemo.payment_date = invoice.date;
  creditMemo.subsidiary_id = invoice.subsidiary_id;
  creditMemo.subsidiary = invoice.subsidiary;
  creditMemo.currency_code = invoice.currency_code;
  creditMemo.amount = invoice.amount;
  creditMemo.term_id = typeof invoice.term === "object" ? invoice.term.id : invoice.term_id;
  creditMemo.due_date = invoice.due_date;
  creditMemo.debit_entries = copyInvoiceCreditAccountsToCreditMemo(invoice);
  creditMemo.credit_entries = copyInvoiceDebitAccountsToCreditMemo(invoice, user);
  creditMemo = copyInvoiceAssets(invoice, creditMemo);

  const fetchedItems = await invoiceApis.getInvoiceItems(invoice.id.toString());
  invoice.invoice_items = fetchedItems.invoice_items;
  creditMemo.vendor_credit_items = copyInvoiceItemToCreditMemo(invoice);

  return creditMemo;
};

export const createCreditMemoAsync = async (invoice: InvoiceType.InvoiceDetailType, currentUser: IUser) => {
  if (!invoice || !invoice.id) {
    return;
  }

  const creditMemo = await convertToCreditMemoAsync(invoice, currentUser);
  if (!creditMemo) {
    return;
  }

  creditMemo.payment_type = "CREDIT";
  creditMemo.account_transaction_attributes = {};
  creditMemo.account_transaction_attributes.date = creditMemo.payment_date;
  creditMemo.account_transaction_attributes.amount = creditMemo.amount;
  creditMemo.account_transaction_attributes.credit_entries_attributes = creditMemo.credit_entries;
  creditMemo.converted_from_invoice_id = invoice.id;

  if (!(currentUser.company.credit_memo && currentUser.company.credit_memo.hide_debit_account)) {
    creditMemo.account_transaction_attributes.debit_entries_attributes = creditMemo.debit_entries;
  }

  const response: AxiosResponse = await restApiService.post("payments/", null, {
    payment: creditMemo,
  });

  if (response.status !== 201 || !response.data || !response.data.id) {
    return;
  }

  await invoiceApis.deleteInvoice(invoice.id!);

  return response.data;
};
