import { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { shallowEqual, useSelector } from "react-redux";
import { Field } from "redux-form";
import { WombatSelect } from "wombatifier/components/pickers/wombatSelect";
import { WombatApis } from "wombatifier/services/wombatApis";
import AuthenticationMethod from "../../components/integrationComponents/authenticationMethod";
import IntegrationSftp from "../../components/integrationComponents/integrationSftp";
import { erp_names } from "lookups/integrationsLookups";
import { RenderCheckBox, RenderSelect } from "../../../../../../components/forms/bootstrapFields";
import { required } from "../../../../../../services/validations/reduxFormValidation";

const IntegrationErpExtract = () => {
  const currentUser = useSelector((state) => state.user, shallowEqual);

  useEffect(() => {}, []);

  return (
    <>
      <Col lg="12" className="p-0">
        <Row className="m-0">
          <Col>
            <Field
              name="form.erp_name"
              label="ERP"
              component={RenderSelect}
              options={erp_names}
              require={true}
              validate={[required]}
            />
          </Col>
        </Row>
        <Row className="m-0">
          <Col lg="6">
            <Field label="Include zip of invoice images" name="form.zip_invoice_images" component={RenderCheckBox} />
          </Col>
        </Row>
      </Col>
    </>
  );
};

export { IntegrationErpExtract };
