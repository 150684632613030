import { RenderTextArea } from "components/forms/bootstrapFields";
import ContactPicker from "components/pickers/reduxFormPicker/contactPicker";
import React, { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Field, InjectedFormProps, reduxForm } from "redux-form";
import ApprovalsApis from "services/admin/approvals/approvalsApis";
import { IDType } from "services/common/types/common.type";
import { CreateNotification, NotificationType } from "services/general/notifications";
import { required } from "services/validations/reduxFormValidation";

const REQUEST_APPROVAL_FORM = "REQUEST_APPROVAL_FORM";

type RequestApprovalFormDataType = {
  approval: {
    contact_approver: {
      value: IDType;
      label: string;
      id: IDType;
      name: string;
      email: string;
      external_id: IDType;
    };
    requestor_note: string;
  };
};

type RequestApprovalFormComponentCustomPropType = {
  closeRequestApproval: () => void;
  refreshApprovable: () => void;
  approvableType: string;
  approvableId: IDType;
  isInvoiceInboxEdit?: boolean;
};

type RequestApprovalFormComponentPropType = InjectedFormProps<
  RequestApprovalFormDataType,
  RequestApprovalFormComponentCustomPropType
> &
  RequestApprovalFormComponentCustomPropType;

const RequestApprovalFormComponent = ({
  handleSubmit,
  closeRequestApproval,
  approvableId,
  approvableType,
  refreshApprovable,
  isInvoiceInboxEdit,
}: RequestApprovalFormComponentPropType) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { t } = useTranslation();

  const onSubmit = async (value: RequestApprovalFormDataType) => {
    const approval = {
      approvable_id: approvableId,
      approvable_type: approvableType,
      name: value.approval.contact_approver.name,
      email: value.approval.contact_approver.email,
      contact_approver_id: value.approval.contact_approver.id,
      requestor_note: value.approval.requestor_note,
    };

    try {
      setIsSubmitting(true);
      const params = isInvoiceInboxEdit ? { approval, proactive_create: true } : { approval };
      await ApprovalsApis.requestApproval(params);
      switch (isInvoiceInboxEdit) {
        case true:
          CreateNotification(
            t("admin.pages.details.approvalAdded"),
            t("admin.pages.details.approvalAddedSuccess"),
            NotificationType.success,
          );
          break;
        default:
          CreateNotification(
            t("admin.pages.details.approvalRequest"),
            t("admin.pages.details.approvalRequestSent"),
            NotificationType.success,
          );
      }
      setIsSubmitting(false);
      closeRequestApproval();
      refreshApprovable();
    } catch (error) {
      setIsSubmitting(false);
    }
  };

  const submitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    handleSubmit(onSubmit)();
  };

  return (
    <Form noValidate name={REQUEST_APPROVAL_FORM} onSubmit={submitHandler}>
      <Row>
        <Col md="12">
          <Field
            name="approval.contact_approver"
            label="Approver"
            component={ContactPicker}
            contactType="USERS"
            validate={[required]}
            useObject
            required
          />
        </Col>
        <Col md="12">
          <Field
            label="Note to Approver"
            id="request_approval_note_to_approver"
            rows="5"
            name="approval.requestor_note"
            component={RenderTextArea}
          />
        </Col>
      </Row>
      <Row>
        <Col className="d-flex justify-content-end">
          <Button variant="secondary" className="mr-2" onClick={closeRequestApproval}>
            Cancel
          </Button>
          <Button disabled={isSubmitting} type="submit">
            {isInvoiceInboxEdit ? "Add" : "Send Approval Request"}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const RequestApprovalForm = reduxForm<RequestApprovalFormDataType, RequestApprovalFormComponentCustomPropType>({
  form: REQUEST_APPROVAL_FORM,
  keepDirtyOnReinitialize: true,
  enableReinitialize: true,
  touchOnChange: false,
  touchOnBlur: false,
})(RequestApprovalFormComponent);

export default RequestApprovalForm;
