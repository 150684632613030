import React, { useEffect, useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { useTypedSelector } from "reducers";
import invoiceCommonSvc from "services/admin/invoices/invoiceCommonSvc";
import { IUser } from "services/common/user/userTypes";
import AccountEntry from "./accountEntry";

type Props = any;

const AccountsTabEntriesPanel = ({
  accountEntryList,
  showAccountEntry,
  isPoRequest,
  modelDataName,
  paymentType,
  noVendor,
  readonly,
  disabled,
}: Props) => {
  const { label: accountType, accountEntries, accountGroupName } = accountEntryList;
  const { control, getValues, setValue, trigger, formState } = useFormContext<any>();
  const currentUser: IUser = useTypedSelector((state) => state.user);

  //TODO-SANKET : DEFINE TYpe
  const { fields, append, update, replace } = useFieldArray<any>({
    control,
    name: accountEntries,
    keyName: "_id" as "id",
  });

  const [subsidiaryId] = useWatch({ control, name: [`${modelDataName}.subsidiary_id`] });

  const showCategoryAtFirst = useMemo(
    () => isPoRequest && currentUser.company.has_categories && !currentUser?.company?.purchase_order?.hide_categories,
    [currentUser.company.has_categories, currentUser.company?.purchase_order?.hide_categories, isPoRequest],
  );

  const isTaxEnableToVendorCredit = useMemo(
    () =>
      currentUser.company.vendor_credit_allow_tax_to_account &&
      accountType == "Credit Accounts" &&
      paymentType == "CREDIT",
    [currentUser?.company?.vendor_credit_allow_tax_to_account, accountType, paymentType],
  );

  const isTaxEnableToPo = useMemo(
    () =>
      currentUser?.company?.enable_tax_to_po_expenses &&
      accountType == "Debit Accounts" &&
      modelDataName == "purchase_order",
    [currentUser?.company?.enable_tax_to_po_expenses, accountType, modelDataName],
  );

  const invoiceExpenseLine = useMemo(() => {
    if (modelDataName == "invoice" && accountType == "Debit Accounts") {
      //TODO-SET CUSTOM FIELD AND META DTAT NAME
      //  customField_model_name = "InvoiceExpenses";
      //  metadata_upsert_model_name = "AccountEntry";
      return true;
    } else {
      return false;
    }
  }, []);

  const creditMemoExpenseLine = useMemo(() => {
    if (paymentType == "CREDIT" && accountType == "Credit Accounts") {
      //TODO-SET CUSTOM FIELD AND META DATA NAME
      // scope.custom_field_model_name = "CreditMemoExpenses";
      // scope.metadata_upsert_model_name = "CreditMemoExpenses";
      return true;
    } else {
      return false;
    }
  }, [accountType, paymentType]);

  const poExpenseLine = useMemo(() => {
    if (modelDataName == "purchase_order" && accountType == "Debit Accounts") {
      //TODO-SET CUSTOM FIELD AND META DATA NAME
      //  customField_model_name = "InvoiceExpenses";
      //  metadata_upsert_model_name = "AccountEntry";
      return true;
    } else {
      return false;
    }
  }, [accountType, modelDataName]);

  const showRebate = useMemo(
    () =>
      invoiceCommonSvc.allowRebateExpensesLine(currentUser) &&
      (invoiceExpenseLine || creditMemoExpenseLine || poExpenseLine),
    [creditMemoExpenseLine, currentUser, invoiceExpenseLine, poExpenseLine],
  );

  const enableTax = useMemo(
    () => isTaxEnableToVendorCredit || isTaxEnableToPo,
    [isTaxEnableToPo, isTaxEnableToVendorCredit],
  );

  const enableTaxAndRebate = useMemo(() => {
    return enableTax || showRebate;
  }, [enableTax, showRebate]);

  const showPercentField = useMemo(() => modelDataName != "purchase_order", [modelDataName]);

  const showTaxAmount = useMemo(() => {
    const parentSubsidiary = getValues(modelDataName + ".subsidiary_id");
    return (
      !invoiceCommonSvc.isActiveHeaderTax({ subsidiary: parentSubsidiary }, currentUser) ||
      modelDataName == "purchase_order"
    );
  }, [currentUser, modelDataName, subsidiaryId]);

  const isInvoice = useMemo(() => modelDataName === "invoice", [modelDataName]);

  //This object is to avoid send multiple props
  const commonFieldsCondition: Record<string, any> = useMemo(
    () => ({
      showCategoryAtFirst,
      isTaxEnableToVendorCredit,
      isTaxEnableToPo,
      invoiceExpenseLine,
      creditMemoExpenseLine,
      poExpenseLine,
      showRebate,
      enableTax,
      enableTaxAndRebate,
      showPercentField,
      showTaxAmount,
      isInvoice,
    }),
    [
      creditMemoExpenseLine,
      enableTax,
      enableTaxAndRebate,
      invoiceExpenseLine,
      isTaxEnableToPo,
      isTaxEnableToVendorCredit,
      poExpenseLine,
      showCategoryAtFirst,
      showPercentField,
      showRebate,
      showTaxAmount,
      isInvoice,
    ],
  );

  useEffect(() => {
    if (fields.length < 1) {
      replace([{}]);
    }
  }, [formState.defaultValues, replace]);

  return (
    <Row>
      <Col md="12">
        {fields.map(
          (accountEntry: any, index) =>
            showAccountEntry(accountType, accountEntry) && (
              <AccountEntry
                key={accountEntry._id}
                index={index}
                accountType={accountType}
                accountEntries={accountEntries}
                modelDataName={modelDataName}
                isPoRequest={isPoRequest}
                commonFieldsCondition={commonFieldsCondition}
                subsidiaryId={subsidiaryId}
                noVendor={noVendor}
                accountGroupName={accountGroupName}
                paymentType={paymentType}
                readonly={readonly}
                disabled={disabled}
              />
            ),
        )}
      </Col>
    </Row>
  );
};

export default AccountsTabEntriesPanel;
