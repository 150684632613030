import React, { useState } from "react";
import { useTypedSelector } from "reducers";
import { ApprovalType } from "services/admin/approvals/approvalsType";
import { companyDateFormat } from "services/general/dateSvc";
import style from "./approval.module.css";
import { CreateNotification, NotificationType } from "services/general/notifications";
import CustomModal from "components/modals/customModal";
import ApproverDetails from "./approverDetails";
import ApprovalsApis from "services/admin/approvals/approvalsApis";
import { IDType } from "services/common/types/common.type";
import ConfirmationModal from "components/common/modals/confirmation";

type ApprovalRowPropsType = {
  endpoint: string;
  modalData: object;
  modelName: string;
  approval: ApprovalType.ApprovalType;
  allowAccess: boolean;
  refreshApprovable: () => void;
  isInvoiceInboxEdit?: boolean;
  toggleExpand?: () => void;
  isExpanded?: boolean;
  numApprovals?: number;
};

const ApprovalRow = ({
  endpoint,
  modalData,
  approval,
  allowAccess,
  modelName,
  refreshApprovable,
  isInvoiceInboxEdit,
  toggleExpand,
  isExpanded,
  numApprovals,
}: ApprovalRowPropsType) => {
  const currentUser = useTypedSelector((state) => state.user);
  const [isApproverDetailModalOpen, setIsApproverDetailModalOpen] = useState(false);
  const [deleteApproverModalOpen, setDeleteApproverModalOpen] = useState<boolean>(false);

  const isAllowResend = function (approval: ApprovalType.ApprovalType) {
    return approval.aasm_state === "sent" || approval.aasm_state === "viewed";
  };

  const deleteApprover = async () => {
    try {
      if ("id" in modalData && approval.id) {
        const params = { proactive_delete: true };
        await ApprovalsApis.deleteApproval(approval.id, params);
        refreshApprovable();
        CreateNotification("Deleted", "Approvals Deleted", NotificationType.success);
      }
    } catch (error) {
      if (typeof error === "object" && error !== null && "response" in error) {
        if (typeof error.response === "object" && error.response !== null && "status" in error.response) {
          CreateNotification(
            "Failed with error code " + error.response.status,
            "Could not start approval workflow. Please double check the data and try again.",
            "warning",
          );
        }
      }
    }
  };

  const createConfirmationModal = function (modalType: string) {
    let body: string = "";
    let saveCallback: () => void = () => {};
    let closeCallback: () => void = () => {};
    switch (modalType) {
      case "deleteApproverModal":
        body = "Are you sure you want to delete this approver?";
        saveCallback = () => {
          setDeleteApproverModalOpen(false);
          deleteApprover();
        };
        closeCallback = () => {
          setDeleteApproverModalOpen(false);
        };
        break;
      default:
    }
    return (
      <ConfirmationModal
        title="Confirm"
        body={body}
        show={true}
        saveCallback={saveCallback}
        closeCallback={closeCallback}
        confirmText="Yes"
        cancelText="No"
      />
    );
  };

  const getStatusClassName = (approval: ApprovalType.ApprovalType) => {
    if (approval.aasm_state === "viewed") {
      return style.labelWarning;
    } else if (approval.aasm_state === "approved") {
      return style.labelSuccess;
    } else if (approval.aasm_state === "rejected") {
      return style.labelDanger;
    } else {
      return style.labelDefault;
    }
  };

  const send = async (event: any) => {
    if (event) event.preventDefault();
    try {
      await ApprovalsApis.makeMessage(approval.id as IDType);
      refreshApprovable();
      CreateNotification("Send", "Approval sent", "success");
    } catch (error) {
      if (typeof error === "object" && error !== null && "response" in error) {
        if (typeof error.response === "object" && error.response !== null && "status" in error.response) {
          CreateNotification(
            "Failed with error code " + error.response.status,
            "Could not start approval workflow. Please double check the data and try again.",
            "warning",
          );
        }
      }
    }
  };

  const showApproverDetails = (event: any) => {
    if (event) event.preventDefault();
    setIsApproverDetailModalOpen(true);
  };

  const hideApproverDetails = () => {
    setIsApproverDetailModalOpen(false);
  };

  const claimApproval = async (event: any) => {
    if (event) event.preventDefault();
    try {
      if (approval?.id) {
        await ApprovalsApis.claimApproval(approval?.id);
        CreateNotification("Claim", "Approval Claim", NotificationType.success);
        refreshApprovable();
      }
    } catch (error) {}
  };

  const getApprovalStatusLabel = (aasmState: string): string => {
    const expenseModelLabels: { [key: string]: string } = {
      viewed: "Pending Approval",
      sent: "Pending Approval",
      new: "Next to Approve",
    };
    if (modelName === "expense" && aasmState && expenseModelLabels[aasmState]) {
      return expenseModelLabels[aasmState];
    } else {
      return aasmState;
    }
  };

  return (
    <>
      {isApproverDetailModalOpen && (
        <CustomModal
          show={isApproverDetailModalOpen}
          onHide={hideApproverDetails}
          header={<>Approval Details</>}
          body={
            <ApproverDetails
              approval={approval}
              getApprovalStatusLabel={getApprovalStatusLabel}
              isInvoiceInboxEdit={isInvoiceInboxEdit}
            />
          }
          size="lg"
        ></CustomModal>
      )}

      <tr>
        <td>
          {numApprovals && numApprovals > 1 && (
            <i
              className={`icon icon-arrow-${isExpanded ? "down" : "right"}`}
              style={{ cursor: "pointer" }}
              onClick={toggleExpand}
            />
          )}
        </td>
        <td>
          {approval.approval_workflow_id && approval.approval_workflow_label ? (
            <div>
              {approval.approval_workflow_label}
              {numApprovals && numApprovals > 1 && !isExpanded && (
                <span>
                  <br />
                  <small>({numApprovals} Total)</small>
                </span>
              )}
              {approval.approval_workflow_any_member &&
                approval.approval_workflow_any_member_count &&
                approval.approval_workflow_any_member_count > 0 && (
                  <span>
                    <br />
                    <small>(Any Member)</small>
                  </span>
                )}
            </div>
          ) : (
            <div>Requested Approval</div>
          )}
        </td>
        {!isInvoiceInboxEdit && (
          <>
            <td>{companyDateFormat(approval.updated_at, currentUser)}</td>
            <td>{companyDateFormat(approval.approved_at, currentUser)}</td>
            <td>{companyDateFormat(approval.rejected_at, currentUser)}</td>
          </>
        )}
        {!isInvoiceInboxEdit ? (
          <td>
            {approval.name} <br />
            {approval.email ? "<" + approval.email + ">" : ""}
            {approval.delegate_names && approval.delegate_names.length > 0 && (
              <span>
                <br />
                {approval.delegate_names.map((name: string, index: number) => {
                  return (
                    <React.Fragment key={index}>
                      {name} (Delegate) <br />
                      {approval.delegate_emails && approval.delegate_emails[index]
                        ? "<" + approval.delegate_emails[index] + ">"
                        : ""}
                      <br />
                    </React.Fragment>
                  );
                })}
              </span>
            )}
          </td>
        ) : (
          <td>
            {approval.name} <br />
            {approval.delegate_names && approval.delegate_names.length > 0 && (
              <span>
                {approval.delegate_names.map((name: string, index: number) => {
                  return (
                    <React.Fragment key={index}>
                      {name} (Delegate) <br />
                    </React.Fragment>
                  );
                })}
              </span>
            )}
          </td>
        )}

        {isInvoiceInboxEdit && (
          <td>
            {approval.email ? approval.email : ""}
            {approval.delegate_names && approval.delegate_names.length > 0 && (
              <span>
                <br />
                {approval.delegate_names.map((name: string, index: number) => {
                  return (
                    <React.Fragment key={index}>
                      {approval.delegate_emails && approval.delegate_emails[index]
                        ? approval.delegate_emails[index]
                        : ""}
                      <br />
                    </React.Fragment>
                  );
                })}
              </span>
            )}
          </td>
        )}

        {!isInvoiceInboxEdit && (
          <td>
            <span className={style.approvalState + " " + getStatusClassName(approval)}>
              {approval.aasm_state && getApprovalStatusLabel(approval.aasm_state)}
            </span>
          </td>
        )}

        <td>
          {isAllowResend(approval) && !approval.is_action && !isInvoiceInboxEdit && (
            <button
              className="btn"
              style={{ marginTop: "7px" }}
              onClick={send}
              id="start_approval_resend"
              type="button"
              data-toggle="tooltip"
              title="Send Request"
            >
              <i className="icon icon-envelope-primary"></i>
            </button>
          )}

          <button
            className="btn"
            type="button"
            onClick={showApproverDetails}
            id="request_approval_info"
            data-toggle="tooltip"
            title="View"
          >
            <i className="icon icon-view"></i>
          </button>

          {approval.email === currentUser.email &&
            currentUser?.company?.allow_claim_po &&
            isAllowResend(approval) &&
            !approval.is_claimed &&
            !isInvoiceInboxEdit && (
              <button
                className="btn"
                onClick={claimApproval}
                id="claim_approval"
                data-toggle="tooltip"
                title="Claim Approval"
                type="button"
              >
                <i className="icon icon-approval-claimed"></i>
              </button>
            )}

          {approval.is_claimed && !isInvoiceInboxEdit && (
            <button
              className="btn"
              id="claimed_approval"
              type="button"
              data-toggle="tooltip"
              disabled={approval.is_claimed}
              title="Approval Claimed"
            >
              <i className="icon icon-approval-claimed"></i>
            </button>
          )}

          {allowAccess && (
            <button
              className="btn"
              type="button"
              onClick={() => setDeleteApproverModalOpen(true)}
              id="request_approval_delete"
              data-toggle="tooltip"
              title="Delete"
            >
              <i className="icon icon-delete"></i>
            </button>
          )}
          {deleteApproverModalOpen && createConfirmationModal("deleteApproverModal")}
        </td>
      </tr>
    </>
  );
};

export default ApprovalRow;
