import md5 from "md5";
import moment from "moment";
import { IDType } from "../types/common.type";
import DocumentApis from "./documentApis";
import { DocumentTypes } from "./documentTypes";

class DocumentService {
  submitDocument = async (documentData: {
    pageData?: string;
    signReference?: string;
    signatureValue?: string;
    documentType?: string;
    name?: string;
    formData?: Record<string, any>;
    documentableId: IDType;
    documentableType: string;
  }) => {
    const fd = {
      document: {
        date: new Date(),
        document_reference: md5(documentData?.pageData ?? ""),
        document_type: documentData.documentType,
        documentable_id: documentData.documentableId,
        documentable_type: documentData.documentableType,
        name: documentData.name,
        page_data: documentData.pageData,
        sign_reference: documentData.signReference,
        signature_value: documentData.signatureValue,
        form_data: documentData.formData,
      },
    };
    try {
      const result = await DocumentApis.create(fd);
      return result;
    } catch (error) {
      throw error;
    }
  };

  uploadFile = async ({
    file,
    documentableId,
    documentableType,
  }: {
    file: File;
    documentableId: IDType;
    documentableType: string;
  }) => {
    try {
      const result = await this.submitDocument({ name: file.name, documentableId, documentableType });
      if (result?.id) {
        const formData = new FormData();
        formData.append("document[assets_attributes[0]asset_file]", file);
        formData.append("document[assets_attributes[0]file_name]", file.name);
        try {
          const patchResult = await DocumentApis.editFormData(result.id, formData);
          return patchResult;
        } catch (error) {
          // also delete the document create as not able attch the file
          try {
            await DocumentApis.delete(result.id);
          } catch (error) {
            throw error;
          }
          throw error;
        }
      }
    } catch (error) {
      throw error;
    }
  };

  uploadDiversityTracking = async ({
    formData,
    documentableId,
    documentableType,
  }: {
    formData: Record<string, any>;
    documentableId: IDType;
    documentableType: string;
  }) => {
    try {
      const result = await this.submitDocument({
        documentType: "DIVERSITY_TRACKING",
        name: "diversity-tracking-" + moment(new Date()).format("YYYY-MM-DD, h:mm:ss"),
        formData: formData,
        documentableId,
        documentableType,
      });
      return result;
    } catch (error) {
      throw error;
    }
  };

  uploadW8 = async ({
    signReference,
    signValue,
    pageData,
    documentableId,
    documentableType,
  }: {
    signReference: string;
    signValue: string;
    pageData: string;
    documentableId: IDType;
    documentableType: string;
  }) => {
    try {
      const result = await this.submitDocument({
        name: "w8-form-" + moment().format("YYYY-MM-DD, h:mm:ss"),
        documentType: "W8-FORM",
        signReference: signReference,
        signatureValue: signValue,
        pageData,
        documentableId,
        documentableType,
      });
      return result;
    } catch (error) {
      throw error;
    }
  };

  uploadW8BenE = async ({
    signReference,
    signValue,
    pageData,
    documentableId,
    documentableType,
  }: {
    signReference: string;
    signValue: string;
    pageData: string;
    documentableId: IDType;
    documentableType: string;
  }) => {
    try {
      const result = await this.submitDocument({
        name: "w8-ben-e-form-" + moment().format("YYYY-MM-DD, h:mm:ss"),
        documentType: "W8-BEN-E-FORM",
        signReference: signReference,
        signatureValue: signValue,
        pageData,
        documentableId,
        documentableType,
      });
      return result;
    } catch (error) {
      throw error;
    }
  };

  uploadW9 = async ({
    signReference,
    pageData,
    documentableId,
    documentableType,
  }: {
    signReference: string;
    pageData: File;
    documentableId: IDType;
    documentableType: string;
  }) => {
    try {
      let name = "w9-form-" + moment().format("YYYY-MM-DD, h:mm:ss");
      const result = await this.submitDocument({
        name: name,
        documentType: "W9-FORM",
        signReference: signReference,
        documentableId,
        documentableType,
      });
      if (result?.id) {
        const formData = new FormData();
        formData.append("document[assets_attributes[0]asset_file]", pageData);
        formData.append("document[assets_attributes[0]file_name]", pageData.name);
        try {
          const patchResult = await DocumentApis.editFormData(result.id, formData);
          return patchResult;
        } catch (error) {
          // also delete the document create as not able attch the file
          try {
            await DocumentApis.delete(result.id);
          } catch (error) {
            throw error;
          }
          throw error;
        }
      }
      return result;
    } catch (error) {
      throw error;
    }
  };

  onSubmitDocumentUploadModal = async ({
    diversityFormData,
    documentableId,
    documentableType,
    file,
    signReference,
    signatureValue,
    w8BenPageData,
    w8PageData,
    w9PageData,
  }: {
    file?: File | undefined;
    w8PageData?: string | undefined;
    w8BenPageData?: string | undefined;
    w9PageData?: File | undefined;
    diversityFormData?: Record<string, any> | undefined;
    signReference?: string;
    signatureValue?: string;
    documentableId: IDType;
    documentableType: string;
  }) => {
    try {
      let document: DocumentTypes.AddResponse | undefined = undefined;

      if (file) {
        const result = await this.uploadFile({ file, documentableId, documentableType });
        if (result) {
          document = result;
        }
      } else if (w8PageData && signReference && signatureValue) {
        const result = await this.uploadW8({
          pageData: w8PageData,
          signReference: signReference,
          signValue: signatureValue,
          documentableId,
          documentableType,
        });
        if (result) {
          document = result;
        }
      } else if (w8BenPageData && signReference && signatureValue) {
        const result = await this.uploadW8BenE({
          pageData: w8BenPageData,
          signReference: signReference,
          signValue: signatureValue,
          documentableId,
          documentableType,
        });
        if (result) {
          document = result;
        }
      } else if (w9PageData && signReference) {
        const result = await this.uploadW9({
          pageData: w9PageData,
          signReference: signReference,
          documentableId,
          documentableType,
        });
        if (result) {
          document = result;
        }
      } else if (diversityFormData) {
        const result = await this.uploadDiversityTracking({
          formData: diversityFormData,
          documentableId,
          documentableType,
        });
        if (result) {
          document = result;
        }
      }
      return document;
    } catch (error) {
      throw error;
    }
  };
}

const documentService = new DocumentService();
export default documentService;
