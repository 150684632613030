import { FileRenderingProvider } from "components/admin/invoices/invoiceDetails/fileRenderingContext";
import { InvoiceDetailsProvider } from "components/admin/invoices/invoiceDetails/invoiceDetailsContext";
import InvoiceDetailsManager from "components/admin/invoices/invoiceDetails/invoiceDetailsManager";
import { UiStateProvider } from "components/admin/invoices/invoiceDetails/uiStateContext";
import React, { useMemo } from "react";
import { useLocation } from "react-router";

const InvoiceDetailsProcess = ({ match }: any) => {
  const { id } = match.params;
  const invoiceId = Number(id);
  const location = useLocation();

  const params = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    const goTo = searchParams.get("goto");
    const portal = searchParams.get("portal") === "true";
    const gridParamsEncoded = searchParams.get("grid_params");

    let gridParams = {};
    if (gridParamsEncoded) {
      try {
        gridParams = decodeURIComponent(gridParamsEncoded);
      } catch (error) {
        console.error("Failed to parse grid_params:", error);
      }
    }
    return { goTo, portal, gridParams };
  }, [location.search]);

  return (
    <InvoiceDetailsProvider
      invoiceId={invoiceId}
      goTo={params.goTo!}
      portal={params.portal}
      gridParams={params.gridParams}
    >
      <FileRenderingProvider>
        <UiStateProvider>
          <InvoiceDetailsManager invoiceId={invoiceId} portal={params.portal} />
        </UiStateProvider>
      </FileRenderingProvider>
    </InvoiceDetailsProvider>
  );
};

export default InvoiceDetailsProcess;
