import { getFileExtension, getMimeType } from "components/common/unifiedViewer/mimeUtils";
import { isArray, isEmpty, isNil, isObject, isString, mapValues, omitBy } from "lodash";
import { PDFDocument } from "pdf-lib";
import { restApiService } from "providers/restApi";
import InvoiceApis from "services/admin/invoices/invoiceApis";
import { InvoiceType } from "services/admin/invoices/invoiceType";
import { CreateNotification, NotificationType } from "services/general/notifications";
import { TAsset, TInvoiceDetails } from "./invoices.type";

const createInvoiceWithDetails = (invoice: InvoiceType.InvoiceDetailType): TInvoiceDetails => {
  return {
    invoice,
    label: invoice.number?.toString() ?? "",
  };
};

const extractDataForAssetAsync = async (id: string, invNumber: string, filename: string, t: any): Promise<void> => {
  try {
    const extractResponse = await restApiService.post(
      `invoices/${encodeURIComponent(id)}/extract_data_for_asset`,
      {},
      {},
    );

    if ([200, 201, 204].includes(extractResponse?.status)) {
      CreateNotification(
        t("success"),
        t("admin.pages.details.uploadSuccess", { invNumber, filename }),
        NotificationType.success,
      );
    } else {
      CreateNotification(
        t("error"),
        t("admin.pages.details.uploadError", { invNumber, filename }),
        NotificationType.danger,
      );
    }
  } catch (extractError) {
    handleError(extractError, t);
  }
};

const attachFileToFormData = (invoice: InvoiceType.InvoiceDetailType, file: File) => {
  if (invoice == null || file == null) return null;

  const formData = new FormData();

  let index = 0;
  invoice.assets?.forEach((asset) => {
    if (asset._destroy) {
      formData.append(`invoice[assets_attributes[${index}]id]`, asset.id);
      formData.append(`invoice[assets_attributes[${index}]_destroy]`, asset._destroy.toString());
      index++;
    }
  });

  formData.append(`invoice[assets_attributes[${index}]asset_file]`, file);
  formData.append(`invoice[assets_attributes[${index}]file_name]`, file.name);

  return formData;
};

const printFileFromUrl = (url: string, t: any) => {
  try {
    const printWindow = window.open(url);

    if (printWindow === null) {
      return;
    }

    printWindow.onload = () => {
      printWindow.print();
      URL.revokeObjectURL(url);
    };
  } catch (err) {
    console.error(err);
    CreateNotification(t("error"), "Error printing file", NotificationType.danger);
  }
};

export const generateRandomId = () => {
  return -Math.floor(Math.random() * 10000000);
};

export const stopEventPropagation = (event: any) => {
  if (!event) return;
  event.preventDefault();
  event.stopPropagation();
};

export const deleteAssetAsync = async (invoiceId: string, asset: TAsset, t: any): Promise<void> => {
  if (!invoiceId || !asset) {
    handleError(new Error("Invoice or asset data is missing"), t);
    return;
  }

  try {
    const invoice = {
      assets_attributes: { id: asset.id, _destroy: 1 },
      avoid_default_account_entries: true,
    };

    await InvoiceApis.editInvoice(invoiceId, { invoice: invoice });

    CreateNotification(
      t("success"),
      t("admin.pages.details.deleteFileSuccess", { asset: asset.asset_file_file_name }),
      NotificationType.success,
    );
  } catch (error) {
    handleError(error, t);
  }
};

export const handleError = (error: any, t: any, message: string | undefined = undefined) => {
  console.error(error);
  const errorMsg = message || t("errors.genericSupport");
  const errorString = error.response?.data
    ? Object.entries(error.response.data)
        .map(([key, value]) => `${key}: ${value}`)
        .join("\n")
    : errorMsg;
  CreateNotification(t("error"), errorString, NotificationType.danger);
};

export const getFile = (bytes: Uint8Array, filename: string): File => {
  if (!(bytes instanceof Uint8Array)) {
    throw new Error("Invalid file data provided.");
  }

  const extension = getFileExtension(filename) || "unknown";
  const safeFilename = filename && filename.trim() !== "" ? filename : `invoice.${extension}`;
  const mimeType = getMimeType(extension);

  return new File([bytes], safeFilename, { type: mimeType });
};

export const createInvoiceFromFileAsync = async (
  localInvoiceFromFile: TInvoiceDetails,
  file: File,
  t: any,
): Promise<TInvoiceDetails | undefined> => {
  if (!localInvoiceFromFile || !file) {
    handleError(new Error("Invoice data or file is missing"), t);
    return undefined;
  }

  try {
    const newInvoiceData = await InvoiceApis.getNewInvoice();
    if (!newInvoiceData) {
      throw new Error("Failed to create new invoice");
    }

    const requestedInvoice: InvoiceType.InvoiceDetailType = {
      process_pdf: true,
      number: newInvoiceData.number,
      submit_method: "USER_SUBMITTED",
      status: "NEW",
    };

    const data = await InvoiceApis.addInvoice({ invoice: requestedInvoice });
    const formData = attachFileToFormData(localInvoiceFromFile, file);
    const patchResponse = await restApiService.formWithImage(`invoices/${data.id}`, formData, "patch");

    if (patchResponse?.status !== 200 || !patchResponse.data) {
      throw new Error("Failed to upload invoice file");
    }

    await extractDataForAssetAsync(patchResponse.data.id, patchResponse.data.number, file.name, t);
    const finalInvoiceData = await InvoiceApis.getInvoice(patchResponse.data.id);

    return {
      invoice: finalInvoiceData,
      label: `${finalInvoiceData.number}`,
    };
  } catch (error) {
    handleError(error, t);
  }
};

export const fetchInvoiceDetails = async (invoiceId: number | undefined): Promise<TInvoiceDetails | undefined> => {
  if (!invoiceId) return undefined;
  const invoice = await InvoiceApis.getInvoice(invoiceId);
  const response = await InvoiceApis.getInvoiceItems(invoiceId.toString());
  invoice.invoice_items_attributes = response.invoice_items || [];
  invoice.debit_entries_attributes = invoice.debit_entries;
  invoice.credit_entries_attributes = invoice.credit_entries;

  if (invoice.credit_entries_attributes?.length) {
    invoice.credit_entries_attributes.forEach((creditEntry) => {
      if (invoice.amount) {
        creditEntry.percent = Math.round((creditEntry.amount / invoice.amount) * 100);
      }
    });
  }

  return createInvoiceWithDetails(invoice);
};

export const deleteAndAttachFileToFormDataAsync = async (
  invoice: InvoiceType.InvoiceDetailType | null,
  bytes: Uint8Array,
  currentAsset: TAsset,
  t: any,
  ocr: boolean = true,
): Promise<void> => {
  if (!invoice || !invoice.id || !currentAsset) {
    throw new Error("Invalid invoice data.");
  }

  const filename = currentAsset.asset_file_file_name;

  const file = getFile(bytes, filename);
  if (!file) {
    throw new Error("Invalid file data.");
  }

  invoice.assets?.forEach((asset: TAsset) => {
    if (asset.id === currentAsset.id) {
      (asset as any)._destroy = 1;
    }
  });

  const formData = attachFileToFormData(invoice, file);

  try {
    const patchResponse = await restApiService.formWithImage(
      `invoices/${encodeURIComponent(invoice.id)}`,
      formData,
      "patch",
    );

    if (patchResponse?.status !== 200 || !patchResponse.data) {
      throw new Error("Failed to upload invoice file.");
    }

    if (ocr) {
      await extractDataForAssetAsync(patchResponse.data.id, patchResponse.data.number, file.name, t);
    }
  } catch (error) {
    handleError(error, t);
  }
};

export const createExtractedFileAsync = async (bytes: Uint8Array, filename: string, t: any): Promise<void> => {
  const file = getFile(bytes, filename);
  if (!file) {
    throw new Error("Invalid file data.");
  }

  try {
    const invoice: TInvoiceDetails = {
      invoice: { id: generateRandomId() },
      label: file.name,
    };

    await createInvoiceFromFileAsync(invoice, file, t);
  } catch (error) {
    handleError(error, t);
  }
};

export async function getNewPdfFromSelectedPages(mappedCheckboxes: number[], pdfDocumentToRender: PDFDocument) {
  const pages = mappedCheckboxes.sort((a, b) => a - b);
  const extractedPdf = await PDFDocument.create();

  for (const pageNumber of pages) {
    const [extractedPage] = await extractedPdf.copyPages(pdfDocumentToRender, [pageNumber - 1]);
    extractedPdf.addPage(extractedPage);
  }

  const newPdfFromSelectedPages = await extractedPdf.save();
  return { newPdfFromSelectedPages, pages };
}

export const getQueryParams = (params: string): string => {
  try {
    if (!isString(params)) {
      return "";
    }

    const paramsAsObj = JSON.parse(params);

    if (isEmpty(paramsAsObj)) {
      return "";
    }

    const cleanedParams = omitBy(paramsAsObj, (value) => {
      return isNil(value) || value === "" || (isObject(value) && !isArray(value) && isEmpty(value));
    });

    if (isEmpty(cleanedParams)) {
      return "";
    }

    const stringifiedParams = mapValues(cleanedParams, (value) => {
      return isObject(value) && !isArray(value) ? JSON.stringify(value) : value;
    });

    return new URLSearchParams(stringifiedParams).toString();
  } catch (error) {
    console.error("Invalid JSON string provided to getQueryParams:", params);
    return "";
  }
};

export const downloadFile = (file: File | undefined, t: any) => {
  if (!file) {
    return;
  }

  let link: HTMLAnchorElement | null = null;
  let url: string | null = null;

  try {
    url = URL.createObjectURL(file);

    link = document.createElement("a");
    link.href = url;
    link.download = file.name;

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);

    URL.revokeObjectURL(url);
  } catch (error) {
    CreateNotification(t("error"), "Error downloading file", NotificationType.danger);
  } finally {
    if (link) {
      link.remove();
    }
    if (url) {
      URL.revokeObjectURL(url);
    }
  }
};

export const printFileFromBlob = (file: Blob | File | undefined, t: any) => {
  if (!file) {
    return;
  }
  const fileURL = URL.createObjectURL(file);
  printFileFromUrl(fileURL, t);
};

export const getPoItemsAsync = async (invoiceId: number, signal: AbortSignal | undefined): Promise<any[]> => {
  const res = await restApiService.get(
    `purchase_orders/with_items?active_for_invoice=true&invoice_id=${invoiceId}`,
    null,
    null,
    true,
    null,
    false,
    signal,
  );

  return res.data;
};

const setMatchingVariables = (invoice: any, invoiceItemId: number) => {
  invoice.invoice_items.forEach((item: any) => {
    if (item.id === invoiceItemId) {
      item.is_twoway_qty_matched = item.po_item && item.qty >= item.po_item.qty;
      item.is_twoway_item_total_matched = item.po_item && item.total >= item.po_item.total;
      item.is_twoway_unitprice_matched = item.po_item && item.unit_price >= item.po_item.unit_price;
      item.is_valid_received = !!item.is_valid_received;
      item.is_matched =
        item.is_twoway_qty_matched &&
        item.is_twoway_item_total_matched &&
        item.is_twoway_unitprice_matched &&
        item.is_valid_received;
    }
  });
};

export const linkPoItemsAsync = async (
  invoice: InvoiceType.InvoiceDetailType,
  invoiceItem: InvoiceType.TInvoiceItem,
  poItemId: number,
): Promise<any> => {
  const invoiceInvoiceItem: any = invoice.invoice_items_attributes?.find((item) => item.id === invoiceItem.id);
  if (!invoice || !poItemId || !invoiceItem || !invoiceInvoiceItem) {
    return { linked: null, result: false };
  }

  const linkObj = {
    po_item_id: poItemId,
    invoice_item_id: invoiceItem.id,
    match_type: "AUTO",
  };

  const params = {
    invoice_item_po_item_link: linkObj,
    invoice_id: invoice.id,
  };

  let result = false;
  const linked = await restApiService.post(`invoice_item_po_item_links`, null, params).then(async (res) => {
    const linkData = res.data;
    const poItem = linkData.po_item;
    invoiceInvoiceItem.po_item = poItem;
    invoiceInvoiceItem.invoice_item_po_item_link_id = linkData.id;
    const updatedInvoice: any = { ...invoice };
    updatedInvoice.invoice_items = invoice.invoice_items_attributes ? [...invoice.invoice_items_attributes] : [];
    updatedInvoice.credit_entries_attributes = invoice.credit_entries_attributes
      ? [...invoice.credit_entries_attributes]
      : [];
    updatedInvoice.debit_entries_attributes = invoice.debit_entries_attributes
      ? [...invoice.debit_entries_attributes]
      : [];
    setMatchingVariables(updatedInvoice, invoiceItem.id!);

    delete updatedInvoice.credit_entries_attributes;
    delete updatedInvoice.debit_entries_attributes;
    delete updatedInvoice.invoice_items_attributes;

    invoiceItem.product_item_id = res.product_item_id;
    invoiceItem.department_id = poItem.department_id;
    invoiceItem.location_id = poItem.location_id;
    invoiceItem.business_unit_id = poItem.business_unit_id;

    const params = { invoice: updatedInvoice };

    try {
      await restApiService.patch(`/invoices/${invoice.id}`, null, params);
      result = true;
    } catch (error) {
      console.error("Failed to update invoice with linked PO item data:", error);
    }
    return linkData;
  });
  return { linked, result };
};

export const unlinkPoItemsAsync = async (invoiceId: number, linkId: number): Promise<any> => {
  if (!invoiceId || !linkId) {
    console.log("Invalid invoice or link id provided.", invoiceId, linkId);
    return null;
  }
  const params = { id: linkId };
  await restApiService.delete(
    `invoice_item_po_item_links/${linkId}?id=${linkId}&invoice_id=${invoiceId}`,
    null,
    params,
  );
};
