import Panel from "components/common/panel/panel";
import { DataTable } from "components/common/wrapperComponents";
import React, { useMemo, useState } from "react";
import ApprovalRow from "./approvalRow";
import style from "./approval.module.css";
import { restApiService } from "providers/restApi";
import { CreateNotification, NotificationType } from "services/general/notifications";
import { useTypedSelector } from "reducers";
import _ from "lodash";
import CustomModal from "components/modals/customModal";
import RequestApprovalForm from "./requestApprovalForm";
import { IDType } from "services/common/types/common.type";
import PreviewApprovalSteps from "./previewApprovalSteps";
import ConfirmationModal from "components/common/modals/confirmation";
import { ApprovalType } from "services/admin/approvals/approvalsType";

type ApprovalPropsType = {
  allowAccess: boolean;
  endpoint: string;
  modalData: object;
  modelName: string;
  refreshApprovable: () => void;
  approvableType: string;
  isInvoiceInboxEdit?: boolean;
};

const Approval = ({
  allowAccess,
  endpoint,
  refreshApprovable,
  modalData,
  modelName,
  approvableType,
  isInvoiceInboxEdit,
}: ApprovalPropsType) => {
  const isApprovalsPresent = useMemo(() => {
    return !!("approvals" in modalData && _.isArray(modalData.approvals) && modalData.approvals.length);
  }, [modalData]);
  const currentUser = useTypedSelector((state) => state.user);
  const [requestApprovalModalOpen, setRequestApprovalModalOpen] = useState<boolean>(false);
  const [previewApprovalModalOpen, setPreviewApprovalModalOpen] = useState(false);
  const [resetApprovalModalOpen, setResetApprovalModalOpen] = useState<boolean>(false);
  const [deleteApprovalModalOpen, setDeleteApprovalModalOpen] = useState<boolean>(false);
  const [startApprovalModalOpen, setStartApprovalModalOpen] = useState<boolean>(false);
  const [expandedGroups, setExpandedGroups] = useState<{ [key: string]: boolean }>({});

  const resetApproval = async () => {
    try {
      // here we not using Api service
      // enpoint is dynamic and can change as per module
      if ("id" in modalData) {
        await restApiService.post(`${endpoint}/${modalData.id}/reset_approvals`);
        setResetApprovalModalOpen(false);
        refreshApprovable();
      }
    } catch (error) {
      if (typeof error === "object" && error !== null && "response" in error) {
        const { response } = error;
        if (typeof response === "object" && response !== null && "status" in response) {
          CreateNotification(
            "Failed with error code " + response.status,
            "Could not start approval workflow. Please double check the data and try again.",
            NotificationType.danger,
          );
        }
      }
    }
  };

  const startApproval = async () => {
    try {
      // here we not using Api service
      // enpoint is dynamic and can change as per module
      if ("id" in modalData) {
        await restApiService.post(`${endpoint}/${modalData.id}/reset_approvals`);
        refreshApprovable();
        CreateNotification("Start", "Approval Start", NotificationType.success);
      }
    } catch (error) {
      if (typeof error === "object" && error !== null && "response" in error) {
        const { response } = error;
        if (typeof response === "object" && response !== null && "status" in response) {
          CreateNotification(
            "Failed with error code " + response.status,
            "Could not start approval workflow. Please double check the data and try again.",
            NotificationType.danger,
          );
        }
      }
    }
  };

  const checkOnlyCorpayNvpWorkflowResettable = function () {
    if (
      currentUser.company.accepted_payment_methods.corpay &&
      currentUser.company.multiple_accepted_payment_methods &&
      "status" in modalData
    ) {
      return modalData.status !== "NEW" && modalData.status !== "SUBMITTED";
    } else {
      return true;
    }
  };

  const deleteApprovalWorkflow = async () => {
    try {
      if ("id" in modalData) {
        await restApiService.post(`${endpoint}/${modalData.id}/delete_approvals`);
        setDeleteApprovalModalOpen(false);
        refreshApprovable();
        CreateNotification("Deleted", "Approvals Deleted", "success");
      }
    } catch (error) {
      if (typeof error === "object" && error !== null && "response" in error) {
        if (typeof error.response === "object" && error.response !== null && "status" in error.response) {
          CreateNotification(
            "Failed with error code " + error.response.status,
            "Could not start approval workflow. Please double check the data and try again.",
            "warning",
          );
        }
      }
    }
  };

  const createConfirmationModal = function (modalType: string) {
    let body: string = "";
    let saveCallback: () => void = () => {};
    let closeCallback: () => void = () => {};
    switch (modalType) {
      case "resetApprovalModal":
        body = "Are you sure you want to delete and restart the approval workflow?";
        saveCallback = () => {
          setResetApprovalModalOpen(false);
          resetApproval();
        };
        closeCallback = () => {
          setResetApprovalModalOpen(false);
        };
        break;
      case "deleteApprovalModal":
        body = "Are you sure you want to delete the approval workflow?";
        saveCallback = () => {
          setDeleteApprovalModalOpen(false);
          deleteApprovalWorkflow();
        };
        closeCallback = () => {
          setDeleteApprovalModalOpen(false);
        };
        break;
      case "startApprovalModal":
        body = "Are you sure you want to start the approval workflow?";
        saveCallback = () => {
          setStartApprovalModalOpen(false);
          startApproval();
        };
        closeCallback = () => {
          setStartApprovalModalOpen(false);
        };
        break;
      default:
    }
    return (
      <ConfirmationModal
        title="Confirm"
        body={body}
        show={true}
        saveCallback={saveCallback}
        closeCallback={closeCallback}
        confirmText="Yes"
        cancelText="No"
      />
    );
  };

  // for expanding workflow group rows
  const toggleExpand = (workflowId: string) => {
    setExpandedGroups((prevState) => ({
      ...prevState,
      [workflowId]: !prevState[workflowId],
    }));
  };

  // group the ordered approvals by approval_workflow_id and sort the groups by the priority of their contained approvals
  const groupedApprovals = _.orderBy(
    _.toPairs(_.groupBy((modalData as { approvals: ApprovalType.ApprovalType[] }).approvals, "approval_workflow_id")),
    ([, approvals]) => approvals[0].priority,
    ["asc"],
  );

  return (
    <>
      {requestApprovalModalOpen && (
        <CustomModal
          show={requestApprovalModalOpen}
          size="lg"
          onHide={() => setRequestApprovalModalOpen(false)}
          header={!isInvoiceInboxEdit ? <h3>Request Approval</h3> : <h3>Add Approval</h3>}
          body={
            <>
              {"id" in modalData && (
                <RequestApprovalForm
                  approvableType={approvableType}
                  approvableId={modalData.id as IDType}
                  closeRequestApproval={() => setRequestApprovalModalOpen(false)}
                  refreshApprovable={refreshApprovable}
                  isInvoiceInboxEdit={isInvoiceInboxEdit}
                />
              )}
            </>
          }
        />
      )}

      {previewApprovalModalOpen && (
        <CustomModal
          show={previewApprovalModalOpen}
          size="lg"
          onHide={() => setPreviewApprovalModalOpen(false)}
          header={<h3>Workflow Preview</h3>}
          body={
            <>
              {"id" in modalData && (
                <PreviewApprovalSteps
                  endpoint={endpoint}
                  approvableId={modalData.id as IDType}
                  isInvoiceInboxEdit={isInvoiceInboxEdit}
                />
              )}
            </>
          }
        />
      )}

      <Panel
        header={
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <i className="icon icon-approval-workflow mr-1"></i>
              APPROVAL WORKFLOW
            </div>
            <div className="d-flex">
              {!(!isApprovalsPresent || !allowAccess) && !isInvoiceInboxEdit && (
                <button
                  className={"btn " + style.approvalActionBtn}
                  onClick={() => setResetApprovalModalOpen(true)}
                  id="start_approval_workflow_restart"
                  type="button"
                >
                  <i className="icon icon-sync-primary mt-1"></i>
                  Restart
                </button>
              )}
              {resetApprovalModalOpen && createConfirmationModal("resetApprovalModal")}

              {!(!checkOnlyCorpayNvpWorkflowResettable() || isApprovalsPresent || !allowAccess) &&
                !isInvoiceInboxEdit && (
                  <button
                    className={"btn " + style.approvalActionBtn}
                    onClick={() => setStartApprovalModalOpen(true)}
                    id="start_approval_workflow_button"
                    type="button"
                  >
                    <i className="icon icon-play-primary"></i>
                    Start Approval
                  </button>
                )}
              {startApprovalModalOpen && createConfirmationModal("startApprovalModal")}

              {checkOnlyCorpayNvpWorkflowResettable() && allowAccess && (
                <button
                  id="request_approval_button"
                  type="button"
                  className={"btn " + style.approvalActionBtn}
                  onClick={() => {
                    setRequestApprovalModalOpen(true);
                  }}
                >
                  <i className="icon icon-person-add-primary mt-1 mr-1" />
                  {isInvoiceInboxEdit ? "Add Approval" : "Request Approval"}
                </button>
              )}

              {allowAccess && !isInvoiceInboxEdit && isApprovalsPresent && (
                <button
                  className={"btn " + style.approvalActionBtn}
                  id="preview_approval_workflow_button"
                  type="button"
                  onClick={() => {
                    setPreviewApprovalModalOpen(true);
                  }}
                >
                  <i className="icon icon-search-primary mt-1"></i>Preview Workflow
                </button>
              )}

              {!(!isApprovalsPresent || !allowAccess) && (
                <button
                  className={"btn " + style.approvalActionBtn}
                  onClick={() => setDeleteApprovalModalOpen(true)}
                  id="start_approval_workflow_delete"
                  type="button"
                >
                  <i className="icon icon-delete mr-1"></i>
                  Delete
                </button>
              )}
              {deleteApprovalModalOpen && createConfirmationModal("deleteApprovalModal")}
            </div>
          </div>
        }
      >
        {isApprovalsPresent && (
          <DataTable striped={false} hover={false} className={"text-center " + style.approvalTable}>
            <thead>
              <tr>
                <th style={{ width: "1px" }}></th>
                <th>Approval Steps</th>
                {!isInvoiceInboxEdit && (
                  <>
                    <th>Last Updated Date</th>
                    <th>Approved Date</th>
                    <th>Rejected Date</th>
                  </>
                )}
                <th>Approvers</th>
                {isInvoiceInboxEdit && <th>Emails</th>}
                {!isInvoiceInboxEdit && <th>Status</th>}
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {groupedApprovals.map(([workflowId, approvals]) => {
                const isExpanded = expandedGroups[workflowId];
                return (
                  <React.Fragment key={workflowId}>
                    {workflowId === "null" || workflowId === "undefined" ? (
                      // if approvals not part of a workflow, render approval details without expandable functionality
                      approvals.map((approval) => (
                        <ApprovalRow
                          key={approval.id}
                          modalData={modalData}
                          endpoint={endpoint}
                          approval={approval}
                          allowAccess={allowAccess}
                          refreshApprovable={refreshApprovable}
                          modelName={modelName}
                          isInvoiceInboxEdit={isInvoiceInboxEdit}
                        />
                      ))
                    ) : (
                      <>
                        {/* if workflow group not expanded, only render first approval details */}
                        {approvals.length > 1 && (
                          <ApprovalRow
                            key={approvals[0].id}
                            modalData={modalData}
                            endpoint={endpoint}
                            approval={approvals[0]}
                            allowAccess={allowAccess}
                            refreshApprovable={refreshApprovable}
                            modelName={modelName}
                            isInvoiceInboxEdit={isInvoiceInboxEdit}
                            toggleExpand={() => toggleExpand(workflowId)}
                            isExpanded={isExpanded}
                            numApprovals={approvals.length}
                          />
                        )}
                        {/* if workflow group expanded, render rest of approval details */}
                        {approvals.length > 1 &&
                          isExpanded &&
                          approvals
                            .slice(1)
                            .map((approval) => (
                              <ApprovalRow
                                key={approval.id}
                                modalData={modalData}
                                endpoint={endpoint}
                                approval={approval}
                                allowAccess={allowAccess}
                                refreshApprovable={refreshApprovable}
                                modelName={modelName}
                                isInvoiceInboxEdit={isInvoiceInboxEdit}
                              />
                            ))}
                        {/* if workflow group consists of one approval, render without expandable features */}
                        {approvals.length === 1 && (
                          <ApprovalRow
                            key={approvals[0].id}
                            modalData={modalData}
                            endpoint={endpoint}
                            approval={approvals[0]}
                            allowAccess={allowAccess}
                            refreshApprovable={refreshApprovable}
                            modelName={modelName}
                            isInvoiceInboxEdit={isInvoiceInboxEdit}
                          />
                        )}
                      </>
                    )}
                  </React.Fragment>
                );
              })}
            </tbody>
          </DataTable>
        )}
      </Panel>
    </>
  );
};

export default Approval;
