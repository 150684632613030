import React from "react";
import { ReportTemplateOptionType } from "../../../../../services/admin/reports/templates/reportTemplateType";
import CheckboxFilter from "../reportFilters/checkbox";
import ContactsOptionsFilter from "../reportFilters/contactsDropdown";
import DateFilter from "../reportFilters/date";
import DropdownFilter from "../reportFilters/dropdown";
import InputFilter from "../reportFilters/input";
import ScheduledDateRange from "../reportFilters/scheduledDateRange";

type FilterType = "dropdown" | "input" | "date" | "checkbox" | "contact";

interface CustomFilterProps {
  name: string;
  code: string;
  type: FilterType;
  options: Array<{ value: string; label: string }>;
  isRequired?: boolean;
  isMulti?: boolean;
}

interface ReportTemplateFilterProps {
  name: string;
  code: string;
  type: FilterType;
  options: Array<{ value: string; label: string }>;
  required?: boolean;
  isMulti?: boolean;
}

interface ComponentProps {
  reportTemplateChosen: ReportTemplateOptionType;
  scheduled?: boolean;
}

type FilterComponentsType = {
  [key in FilterType | "scheduled"]: React.ComponentType<CustomFilterProps>;
};

const CustomFilters: React.FC<ComponentProps> = ({ reportTemplateChosen, scheduled = false }) => {
  const customFilterComponents: FilterComponentsType = {
    dropdown: DropdownFilter,
    input: InputFilter,
    date: DateFilter,
    checkbox: CheckboxFilter,
    contact: ContactsOptionsFilter,
    scheduled: ScheduledDateRange,
  };

  if (!reportTemplateChosen.filters) {
    return null;
  }

  const startDateFilter = reportTemplateChosen.filters.find(
    (filter: ReportTemplateFilterProps): filter is ReportTemplateFilterProps => filter?.code === "start_date",
  );
  const endDateFilter = reportTemplateChosen.filters.find(
    (filter: ReportTemplateFilterProps): filter is ReportTemplateFilterProps => filter?.code === "end_date",
  );

  const hasDateRange = startDateFilter && endDateFilter && scheduled;
  const filtersToRender = hasDateRange
    ? reportTemplateChosen.filters.filter(
        (filter: CustomFilterProps) => filter?.code !== "start_date" && filter?.code !== "end_date",
      )
    : reportTemplateChosen.filters;

  return (
    <>
      {hasDateRange && startDateFilter && endDateFilter && (
        <ScheduledDateRange
          name="Scheduled Date Range"
          code="schedule_date_range"
          options={[]}
          isRequired={startDateFilter.required || endDateFilter.required}
        />
      )}
      {filtersToRender.map((filter: ReportTemplateFilterProps) => {
        if (!filter) return null;

        const TagName = customFilterComponents[filter.type];
        return (
          <TagName
            key={filter.code}
            name={filter.name}
            code={filter.code}
            type={filter.type}
            options={filter.options}
            isRequired={filter.required}
            isMulti={filter.isMulti}
          />
        );
      })}
    </>
  );
};

export default CustomFilters;
