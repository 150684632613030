import React from "react";
import { TabNavigation } from "wombatifier/components/navigation/tabNavigator";

//NOTE: add only those route which we want to show on tabNavigations
const invoiceModuleRoutes = () => [
  {
    path: "",
    pageName: "Processing Invoices",
    href: "invoices",
    authorization: {
      I: "invoice",
      a: "_processing",
    },
  },
  {
    path: "",
    pageName: "All Invoices",
    href: "invoices_all",
    authorization: {
      I: "list",
      a: "Invoices",
    },
  },
  {
    path: "/ap/recurring_invoices",
    pageName: "Recurring Invoices",
    authorization: {
      I: "list",
      a: "Invoices",
    },
  },
  {
    path: "",
    pageName: "Inbox",
    href: "invoices_new_inbox",
    authorization: {
      I: "list",
      a: "NewInbox",
    },
  },
  {
    path: "",
    pageName: "Draft",
    href: "invoices_draft_inbox",
    authorization: {
      I: "list",
      a: "DraftInbox",
    },
  },
  {
    path: "/ap/invoices/emails",
    pageName: "Emails",
    authorization: {
      I: "emails",
      a: "Tab",
    },
  },
  {
    path: "",
    pageName: "Pay Invoices",
    href: "invoices_pay_bills",
    authorization: {
      I: "invoice",
      a: "PayBills",
    },
  },
  {
    path: "/ap/bulk_uploads/invoices",
    pageName: "File Uploads",
    authorization: {
      I: "import",
      a: "BulkOperations2",
    },
  },
  {
    path: "/ap/invoices/prompts",
    pageName: "Prompts",
    authorization: {
      I: "list", // We can only see the DraftInbox section if we are a Service role.
      a: "DraftInbox", // we are using listDraftInbox because we want the 'Prompts' section to be visible only to Service role.
    },
  },
];

export type invoicesNavsProps = {
  activePageName: string;
};

const InvoicesNavsTabs = ({ activePageName }: invoicesNavsProps) => {
  return <TabNavigation activePageName={activePageName} routes={invoiceModuleRoutes()} />;
};

export default InvoicesNavsTabs;
