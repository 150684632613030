import { useQuery } from "@tanstack/react-query";
import useGetValue from "components/admin/hooks/hookFormHooks/useGetValue";
import PickerErrorBlock from "components/common/pickers/pickerErrorBlock";
import { Mandatory } from "components/forms/hookFormFields";
import { restApiService } from "providers/restApi";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { Form } from "react-bootstrap";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ReactSelect from "react-select";
import commonService from "services/common/commonSvc";
import { AddressPickerTypes } from "./addressPickerType";

const SinglePicker = ({
  instanceId,
  name,
  label,
  required,
  modelData,
  isBillingAddress,
  disabled,
  labelClassName,
  placeholder,
  objectPropertyKey,
  validate,
  menuPosition,
  menuPlacement,
  callBack,
  modelName,
  addressableType,
}: AddressPickerTypes.TSingleAddressPickerProps) => {
  const { t } = useTranslation();
  const { getValues, setValue } = useFormContext();
  const [selected, setSelected] = useState(null);
  const subsidiaryId = useGetValue(`${modelData}.subsidiary_id`);

  // If modelData is available, retrieve vendor_id from modelData; otherwise, use the standalone vendor_id field (applicable when modelData is not used, such as in the Upload Queue).
  const vendorId = useGetValue(modelData ? `${modelData}.vendor_id` : "vendor_id");

  const getOptionValue = useCallback((option) => option?.id, []); //TODO: check here
  const getOptionLabel = useCallback((option) => option?.label, []); //TODO: check here

  const isBillingAddressPicker = !!isBillingAddress && !!subsidiaryId;
  const isVendorAddressPicker = !isBillingAddress && !!vendorId;

  const shouldFetchAddresses = isBillingAddressPicker || isVendorAddressPicker;

  const setPickerValue = useCallback(
    (selected) => {
      setSelected(selected);
      if (isVendorAddressPicker) {
        if (selected) {
          selected.id = null;
        }
      }
      setValue(name, selected ? selected : null);
      if (objectPropertyKey) setValue(objectPropertyKey, selected);
      if (callBack) {
        callBack(selected);
      }
    },
    [callBack, isVendorAddressPicker, name, objectPropertyKey, setValue],
  );

  const handleSelectChange = useCallback(
    (selected) => {
      setPickerValue(selected);
    },
    [setPickerValue],
  );

  const addressParams = useMemo(() => {
    let apiParams: Record<string, any> = {};
    apiParams.status = "ACTIVE";

    if (isBillingAddress) {
      apiParams.address_type = "Billing";
      if (subsidiaryId) apiParams.subsidiary_id = subsidiaryId;
    } else if (modelName === "invoice" || modelName === "creditMemo") {
      apiParams.vendor = vendorId;
    } else {
      apiParams.addressable_type = addressableType;
    }
    return apiParams;
  }, [addressableType, isBillingAddress, modelName, subsidiaryId, vendorId]);

  const formatOptions = useCallback((address: AddressPickerTypes.TAddressPickerOption, context: any) => {
    return (
      <div>
        {context?.context !== "menu" ? (
          <span>
            {address.label}
            {address.city ? ` - ${address.city}` : ""}
          </span>
        ) : (
          <small>
            <span>
              {address.label}
              {address.id && (
                <>
                  {address.city && (
                    <>
                      <br />
                      <strong> City: </strong>
                      {address.city}
                    </>
                  )}
                  {address.zipcode && (
                    <>
                      <strong> Zipcode: </strong>
                      {address.zipcode}
                      <br />
                    </>
                  )}
                  {address.country && (
                    <>
                      <strong> Country: </strong>
                      {address.country}
                    </>
                  )}
                </>
              )}
            </span>
          </small>
        )}
      </div>
    );
  }, []);

  const {
    data: addresses,
    isError: isErrorInactive,
    isSuccess: isActiveAddressesFetched,
  } = useQuery({
    queryKey: ["addresses", addressParams],
    queryFn: async ({ signal }) => {
      const response = await restApiService.get(`addresses`, addressParams, null, true, null, false, signal);
      return response.data;
    },
    enabled: shouldFetchAddresses,
  });

  const setDefaultValue = useCallback(async () => {
    const selectedAddress = getValues(name);
    const address = Array.isArray(addresses)
      ? addresses.filter((address) => address?.label === selectedAddress.label)
      : [];

    if (address.length === 0) {
      setSelected(selectedAddress ? selectedAddress : null);
    } else {
      setSelected(address ? address[0] : null);
    }
  }, [addresses, getValues, name]);

  useEffect(() => {
    const selectedAddress = getValues(name);

    if (selectedAddress && isActiveAddressesFetched) {
      setDefaultValue();
    }
  }, [getValues, isActiveAddressesFetched, name, setDefaultValue]);

  return (
    <Form.Group className="pickerLabel">
      {label && (
        <Form.Label className={"pickerLabel"}>
          {label} <Mandatory required={required} />
        </Form.Label>
      )}
      <Controller
        name={name}
        rules={{ required: required ? t("validations.required") : undefined, validate }}
        render={({ field: { value, onChange }, fieldState: { error } }) => {
          return (
            <>
              <ReactSelect
                id={instanceId}
                instanceId={instanceId}
                value={selected}
                options={addresses}
                isClearable
                getOptionLabel={getOptionLabel}
                getOptionValue={getOptionValue}
                onChange={(selected) => handleSelectChange(selected)}
                placeholder="-- Select Address --"
                formatOptionLabel={formatOptions}
                menuPosition={menuPosition}
                menuPlacement={menuPlacement}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    ...commonService.getInvalidStyle(Boolean(error?.message)),
                  }),
                }}
              />
              {error?.message && <PickerErrorBlock error={error.message} />}
              {isErrorInactive && <PickerErrorBlock error={t("components.common.pickers.loadingOptionsError")} />}
            </>
          );
        }}
      />
    </Form.Group>
  );
};
export default memo(SinglePicker);
