import _ from "lodash";
import { restApiService } from "providers/restApi";
import React, { memo, useCallback, useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useTypedSelector } from "reducers";
import { selectCurrentUser } from "reducers/userReducers";
import commonService from "services/common/commonSvc";
import { IUser } from "services/common/user/userTypes";
import { AbstractListPickerTypes } from "../abstractListPicker/abstractListPickerTypes";
import AbstractListPicker from "../abstractListPicker/index2";
import { LocationPickerTypes } from "./LocationPickerType";

const LocationPicker = ({
  name,
  label,
  required,
  instanceId,
  callBack,
  parentObj,
  modelDataName,
  containerClassName,
  labelClassName,
  objectPropertyKey,
  disabled,
}: LocationPickerTypes.TLocationPickerProps) => {
  const { getValues, setValue } = useFormContext();
  const currentUser: IUser = useTypedSelector(selectCurrentUser);

  const modelData = !_.isNil(modelDataName) ? (modelDataName === "" ? "" : modelDataName) : null;
  const parentData = !_.isNil(parentObj) ? (parentObj === "" ? "" : parentObj) : null;

  const subsidiaryIdFieldName = useMemo((): string => {
    const field = "subsidiary_id";
    if (_.isNil(parentData)) return "";
    return `${parentData}.${field}`;
  }, [parentData]);

  const [subsidiaryId] = useWatch({
    name: [subsidiaryIdFieldName],
  });

  const mergeInactive = useCallback(
    async (currentId, businessUnits) => {
      let inactiveOption = null;
      const onlyShowMappedBusinessUnit =
        !_.isNil(modelData) && getValues(`${modelData}.only_show_mapped_business_unit`);

      if (currentId > 0) {
        const businessUnit = Array.isArray(businessUnits)
          ? businessUnits.filter((businessUnit) => businessUnit?.id === currentId)
          : [];

        if (businessUnit.length === 0 && onlyShowMappedBusinessUnit) {
          setValue(`${modelData}.only_show_mapped_business_unit`, false);
          inactiveOption = null;
        } else if (businessUnit.length === 0) {
          try {
            const response = await restApiService.get("business_units/" + currentId);
            inactiveOption = response.data;
          } catch (error) {
            commonService.handleError(error);
          }
        } else {
          inactiveOption = businessUnit[0];
        }
        return { inactiveOption };
      }

      return { inactiveOption };
    },
    [getValues, modelData, setValue],
  );

  const handlePickerSelection = (
    selected: AbstractListPickerTypes.TPickerValue<LocationPickerTypes.TLocationPickerOption>,
  ) => {
    if (callBack) {
      callBack(selected);
    }
  };

  const getParentObjParams = useCallback(
    (apiParams: Record<string, any>) => {
      let isRequestPo = getValues(`${modelData}.is_request_PO`);
      let dataRestrictBySubsidiary = currentUser?.company?.po_request_data_restrict_by_subsidiary;
      let isSubsidiaryHasLocations = currentUser?.company?.global?.show_locations_by_subsidiary;
      let hasSubsidiaryLocation = getValues(`${modelData}.subsidiary.has_locations`);

      if ((dataRestrictBySubsidiary && isRequestPo) || (isSubsidiaryHasLocations && hasSubsidiaryLocation)) {
        apiParams.subsidiary_id = subsidiaryId;
      }
    },
    [
      currentUser.company.global.show_locations_by_subsidiary,
      currentUser.company.po_request_data_restrict_by_subsidiary,
      getValues,
      modelData,
      subsidiaryId,
    ],
  );

  const getModelDataParams = useCallback(
    (apiParams: Record<string, any>) => {
      let hasBusinessUnitLocation = getValues(`${modelData}.business_unit.has_locations`);

      if (hasBusinessUnitLocation) {
        apiParams.business_unit_id = getValues(`${modelData}.business_unit_id`);
        setValue(modelData + "only_show_mapped_location", true);
      }
    },
    [getValues, modelData, setValue],
  );

  const params = useMemo(() => {
    let apiParams: Record<string, any> = { status: "ACTIVE" };

    if (!_.isNil(parentData)) {
      getParentObjParams(apiParams);
    }

    if (!_.isNil(modelData)) {
      getModelDataParams(apiParams);
    }

    return apiParams;
  }, [getModelDataParams, getParentObjParams, modelData, parentData]);

  return (
    <AbstractListPicker<LocationPickerTypes.TLocationPickerOption>
      name={name}
      label={label}
      required={required}
      mergeInactive={mergeInactive}
      params={params}
      fetchUrl="locations.lk"
      callBack={handlePickerSelection}
      objectPropertyKey={!_.isNil(modelData) ? `${modelData}.location` : ""}
      instanceId={instanceId}
      disabled={disabled}
      containerClassName={containerClassName ? containerClassName : "pickerLabel"}
      placeholder="-- Select Location  --"
    />
  );
};
export default memo(LocationPicker);
