import { useMap, useMapsLibrary } from "@vis.gl/react-google-maps";
import { useEffect, useState } from "react";

export const usePlacesService = () => {
  const map = useMap();
  const placesLibrary = useMapsLibrary("places");
  const [placesService, setPlacesService] = useState<google.maps.places.PlacesService | null>(null);

  useEffect(() => {
    if (!placesLibrary || !map) return;
    setPlacesService(new placesLibrary.PlacesService(map));
  }, [placesLibrary, map]);

  return placesService;
};

export const useAutocompleteService = () => {
  const map = useMap();
  const placesLibrary = useMapsLibrary("places");
  const [autocompleteService, setAutocompleteService] = useState<google.maps.places.AutocompleteService | null>(null);

  useEffect(() => {
    if (!placesLibrary || !map) return;
    setAutocompleteService(new placesLibrary.AutocompleteService());
  }, [placesLibrary, map]);

  return autocompleteService;
};

export const useSessionToken = () => {
  const map = useMap();
  const placesLibrary = useMapsLibrary("places");
  const [sessionToken, setSessionToken] = useState<google.maps.places.AutocompleteSessionToken>();
  useEffect(() => {
    if (!placesLibrary || !map) return;

    setSessionToken(new placesLibrary.AutocompleteSessionToken());
  }, [map, placesLibrary]);
  return sessionToken;
};

export const useMapDirectionServices = () => {
  const map = useMap();
  const routesLibrary = useMapsLibrary("routes");
  const [directionsService, setDirectionsService] = useState<google.maps.DirectionsService>();
  const [directionsRenderer, setDirectionsRenderer] = useState<google.maps.DirectionsRenderer>();

  useEffect(() => {
    if (!routesLibrary || !map) return;
    setDirectionsService(new routesLibrary.DirectionsService());
    setDirectionsRenderer(new routesLibrary.DirectionsRenderer({ map }));
  }, [routesLibrary, map]);

  return {
    directionsService,
    directionsRenderer,
  };
};
