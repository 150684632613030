import { useMap, useMapsLibrary } from "@vis.gl/react-google-maps";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import adminCommonSvc from "services/admin/commonSvc";
import { ExpensesTypes } from "services/admin/expenses/expensesType";
import { CreateNotification, NotificationType } from "services/general/notifications";

// On Google map
// Directions Component that handles calculating and rendering direction routes
// for a map with multiple waypoints, optimizing for shortest available route and
// calculating total distance based on selected unit type (miles/kilometers)

const Directions = memo(() => {
  const map = useMap();
  const { control, setValue, getValues } = useFormContext<ExpensesTypes.TMapRouteFormData>();
  const routesLibrary = useMapsLibrary("routes");
  const [directionsService, setDirectionsService] = useState<google.maps.DirectionsService>();
  const [directionsRenderer, setDirectionsRenderer] = useState<google.maps.DirectionsRenderer>();

  const wayPoints = useWatch({ name: "map_route.map_route_waypoints", control }) ?? [];
  const waypointsWithCoords = wayPoints.filter((waypoint) => waypoint?.lat && waypoint?.lng);
  const origin = waypointsWithCoords[0];

  const originLatLng: google.maps.LatLngLiteral = useMemo(() => {
    return {
      lat: origin?.lat ?? 0,
      lng: origin?.lng ?? 0,
    };
  }, [origin?.lat, origin?.lng]);

  const destination = waypointsWithCoords[waypointsWithCoords?.length - 1];
  const destinationLatLng: google.maps.LatLngLiteral = useMemo(() => {
    return {
      lat: destination?.lat ?? 0,
      lng: destination?.lng ?? 0,
    };
  }, [destination?.lat, destination?.lng]);

  const betweenWaypoints = useMemo(
    () => waypointsWithCoords.slice(1, waypointsWithCoords?.length - 1),
    [waypointsWithCoords],
  );

  const betweenWaypointsLatLng: google.maps.DirectionsWaypoint[] = useMemo(() => {
    return betweenWaypoints.map((waypoint) => {
      return {
        location: {
          lat: waypoint.lat ?? 0,
          lng: waypoint.lng ?? 0,
        },
      };
    });
  }, [betweenWaypoints]);

  const getIndexOfShortestRoute = useCallback((routes: google.maps.DirectionsRoute[]) => {
    if (routes.length === 1) return 0;

    let index = routes.reduce((a, c, currentIndex) => {
      // calculate sum of
      const lastSmallestDistance = routes[a].legs?.reduce((a, c) => {
        return a + Number(c.distance?.value);
      }, 0);

      const currentRouteDistance = c.legs?.reduce((a, c) => {
        return a + Number(c.distance?.value);
      }, 0);

      if (lastSmallestDistance && currentRouteDistance && lastSmallestDistance <= currentRouteDistance) {
        return a;
      } else {
        return currentIndex;
      }
    }, 0);

    return index;
  }, []);

  const getDistanceInCurrentUnit = useCallback(
    (distance: number) => {
      const unit = getValues("map_route.units");
      if (unit === ExpensesTypes.MILAGE_UNITS.MILE) {
        return distance / 1609.34;
      } else {
        return distance / 1000;
      }
    },
    [getValues],
  );

  const setShortestRoute = useCallback(
    (response: google.maps.DirectionsResult) => {
      if (!directionsRenderer) return;
      const routes = response.routes;

      const indexOfShortestRoute = getIndexOfShortestRoute(routes);
      directionsRenderer.setRouteIndex(indexOfShortestRoute);

      // set value of distance in form as well
      const shortestDistanceInMeter = routes[indexOfShortestRoute]?.legs?.reduce((a, c) => {
        return a + Number(c.distance?.value);
      }, 0);

      let distanceInCurrentUnit = getDistanceInCurrentUnit(shortestDistanceInMeter);
      const roundTrip = getValues("map_route.round_trip");
      if (roundTrip) {
        distanceInCurrentUnit = 2 * distanceInCurrentUnit;
      }

      const roundedDistance = adminCommonSvc.roundUpNumber({ val: distanceInCurrentUnit });
      setValue("map_route.distance", roundedDistance);
    },
    [directionsRenderer, getDistanceInCurrentUnit, getIndexOfShortestRoute, getValues, setValue],
  );

  // Initialize directions service and renderer
  useEffect(() => {
    if (!routesLibrary || !map) return;
    setDirectionsService(new routesLibrary.DirectionsService());
    setDirectionsRenderer(new routesLibrary.DirectionsRenderer({ map, suppressMarkers: true }));
  }, [routesLibrary, map]);

  // Use directions service
  useEffect(() => {
    if (!directionsService || !directionsRenderer) return;
    if (!originLatLng?.lat || !originLatLng.lng || !destinationLatLng?.lat || !destinationLatLng?.lng) {
      directionsRenderer.set("directions", null);
      return;
    }

    const routeOptions: google.maps.DirectionsRequest = {
      origin: originLatLng,
      destination: destinationLatLng,
      travelMode: google.maps.TravelMode.DRIVING,
    };

    if (betweenWaypointsLatLng.length > 0) {
      routeOptions.waypoints = betweenWaypointsLatLng;
    }

    directionsService
      .route(routeOptions)
      .then((response) => {
        directionsRenderer.setDirections(response);
        setShortestRoute(response);
      })
      .catch((error) => {
        CreateNotification("Error", error.message, NotificationType.danger);
      });
  }, [
    directionsService,
    directionsRenderer,
    originLatLng,
    destinationLatLng,
    betweenWaypointsLatLng,
    setShortestRoute,
  ]);

  return null;
});
export default Directions;
