import { restApiService } from "providers/restApi";
import React, { memo, useCallback, useMemo } from "react";
import commonService from "services/common/commonSvc";
import AbstractListPicker from "../abstractListPicker/index2";
import { EventCodePickerType } from "./eventCodeTypes";

const EventCodePicker = ({
  name,
  label,
  instanceId,
  required = false,
  modelData: modelDataStr,
}: EventCodePickerType.TEventCodePickerProps) => {
  const { modelData } = commonService.modifyParentModalDataRefStr<{ modelData?: string }>({
    modelData: modelDataStr,
  });

  const mergeInactive = useCallback(async (currentId, eventCodes) => {
    let inactiveOption = null;
    if (currentId) {
      const eventCode = Array.isArray(eventCodes) ? eventCodes.filter((eventCode) => eventCode.id === currentId) : [];
      if (eventCode.length === 0) {
        try {
          const response = await restApiService.get("event_codes", currentId);
          inactiveOption = response.data;
        } catch (error) {
          console.log(error);
        }
      } else {
        inactiveOption = eventCode[0];
      }
    }
    return { inactiveOption };
  }, []);

  const params = useMemo(() => ({ status: "ACTIVE" }), []);
  return (
    <AbstractListPicker<EventCodePickerType.TEventCodeTypeOption>
      name={name}
      label={label}
      required={required}
      instanceId={instanceId}
      params={params}
      objectPropertyKey={modelData && modelData + "event_code"}
      mergeInactive={mergeInactive}
      fetchUrl="event_codes"
    />
  );
};

export default memo(EventCodePicker);
