import { restApiService } from "providers/restApi";
import React, { memo, useCallback, useMemo } from "react";
import AbstractListPicker from "../abstractListPicker/index2";
import { SecondaryCategoryPickerTypes } from "./secondaryCategoryPickerTypes";

const SecondaryCategoryPicker = ({
  name,
  label,
  instanceId,
  required = false,
  modelDataName,
  objectPropertyKey,
}: SecondaryCategoryPickerTypes.TSecondaryCategoryPickerProps) => {
  const mergeInactive = useCallback(async (currentId, secondaryCategories) => {
    let inactiveOption = null;
    if (currentId) {
      const secondaryCategory = Array.isArray(secondaryCategories)
        ? secondaryCategories.filter((category) => category.id === currentId)
        : [];

      if (secondaryCategory.length === 0) {
        try {
          const response = await restApiService.get(`secondary_categories/${currentId}`);
          inactiveOption = response.data;
        } catch (error) {
          console.error(error);
        }
      } else {
        inactiveOption = secondaryCategory[0];
      }
    }
    return { inactiveOption };
  }, []);

  const params = useMemo(() => ({ status: "ACTIVE" }), []);

  return (
    <AbstractListPicker<SecondaryCategoryPickerTypes.TSecondaryCategoryPickerOption>
      name={name}
      label={label}
      required={required}
      instanceId={instanceId}
      params={params}
      objectPropertyKey={objectPropertyKey ?? (modelDataName && `${modelDataName}.secondary_category`)}
      mergeInactive={mergeInactive}
      fetchUrl="secondary_categories"
    />
  );
};

export default memo(SecondaryCategoryPicker);
