import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { CategoriesObjType } from "components/admin/pickers/reduxFormPickers/categoryPicker";
import { restApiService } from "providers/restApi";
import commonService from "services/common/commonSvc";
import { getListOptionsType, getPaginatedListOptionsType, IDType } from "services/common/types/common.type";
import { ExpensesTypes } from "./expensesType";

class ExpensesApis {
  static async getExpensesList({ filter, cache }: getListOptionsType = {}) {
    try {
      const response: AxiosResponse<ExpensesTypes.List> = await restApiService.get(
        "expense_items",
        filter,
        null,
        true,
        null,
        cache,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async getPaginatedExpenseList({ filter, cache }: getPaginatedListOptionsType) {
    try {
      const response: AxiosResponse<ExpensesTypes.PaginatedList> = await restApiService.get(
        "expense_items",
        filter,
        null,
        true,
        null,
        cache,
      );

      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static getNewExpenseItem = async (): Promise<ExpensesTypes.NewExpenseItem> => {
    try {
      const response: AxiosResponse<ExpensesTypes.NewExpenseItem> = await restApiService.get("expense_items/new");
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static async getExpense(id: IDType, signal?: AbortSignal) {
    try {
      const response: AxiosResponse<ExpensesTypes.Details> = await restApiService.get(
        "expense_items/" + id,
        null,
        null,
        true,
        null,
        false,
        signal,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static createExpenseItem = async (expenseItemPayload: ExpensesTypes.ExpenseItemPayload) => {
    try {
      const response: AxiosResponse<ExpensesTypes.Details> = await restApiService.post(
        "expense_items",
        null,
        expenseItemPayload,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static updateExpenseItem = async ({
    id,
    payload,
  }: {
    id: string;
    payload: { expense_item: ExpensesTypes.ExpenseItemFormDataType };
  }) => {
    try {
      const response: AxiosResponse<ExpensesTypes.Details> = await restApiService.patch(
        "expense_items/" + id,
        null,
        payload,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static addExpenseItemDocuments = async ({ id, document_ids }: { id: string; document_ids: IDType[] }) => {
    try {
      const response: AxiosResponse<ExpensesTypes.Details> = await restApiService.patch(
        "expense_items/" + id + "/link_documents",
        null,
        { document_ids: document_ids },
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static async deleteExpense(id?: string | number) {
    try {
      const response: AxiosResponse<ExpensesTypes.Details> = await restApiService.delete("expense_items/" + id);
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  static async patchAttachaments({
    id,
    formData,
    throwDetailedError = false,
  }: {
    id: IDType;
    formData: FormData;
    throwDetailedError?: boolean;
  }) {
    try {
      const response: AxiosResponse<ExpensesTypes.Details> = await restApiService.formWithImage(
        "expense_items/" + id,
        formData,
        "PATCH",
        throwDetailedError,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async deleteAttachment({ id, asset_id }: { id: IDType; asset_id: IDType }) {
    try {
      const response: AxiosResponse<ExpensesTypes.Details> = await restApiService.patch("expense_items/" + id, null, {
        expense_item: {
          assets_attributes: {
            id: asset_id,
            _destroy: 1,
          },
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async getExpensesActionRequired({ filter, cache }: getListOptionsType = {}) {
    try {
      const response: AxiosResponse<ExpensesTypes.actionRequired> = await restApiService.get(
        "expense_reports/action_required_stats",
        filter,
        null,
        true,
        null,
        cache,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  static async getExpensesReportSummary({ filter, cache }: getListOptionsType = {}) {
    try {
      const response: AxiosResponse<ExpensesTypes.reportSummary> = await restApiService.get(
        "expense_reports/summary",
        filter,
        null,
        true,
        null,
        cache,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  static async getAccountCashBalance(companyId: number) {
    try {
      const response: AxiosResponse<ExpensesTypes.accountBalance[]> = await restApiService.get(
        `companies/${companyId}/get_program_balance`,
        null,
        null,
        true,
        null,
        undefined,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  static async getCategorizeSpends({ filter, cache }: getListOptionsType = {}) {
    try {
      const response: AxiosResponse<ExpensesTypes.ExpenseCategoryType[]> = await restApiService.get(
        "expense_reports/spend_by_category_summary",
        filter,
        null,
        true,
        null,
        cache,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  static async getMonthlySpends({ filter, cache }: getListOptionsType = {}) {
    try {
      const response: AxiosResponse<ExpensesTypes.MonthlySpendType[]> = await restApiService.get(
        "expense_reports/monthly_spend_summary",
        filter,
        null,
        true,
        null,
        cache,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  static async getTopTenSpendByMerchant({ filter, cache }: getListOptionsType = {}) {
    try {
      const response: AxiosResponse<ExpensesTypes.TopTenMerchantSpendListType> = await restApiService.get(
        "expense_reports/spend_by_merchant_summary",
        filter,
        null,
        true,
        null,
        cache,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async getCategorizeViolations({ filter, cache }: getListOptionsType = {}) {
    try {
      const response: AxiosResponse<ExpensesTypes.ViolationsCategoryReturnType> = await restApiService.get(
        "expense_reports/policy_violation_summary",
        filter,
        null,
        true,
        null,
        cache,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async getCategories(signal?: AbortSignal) {
    try {
      const result: AxiosResponse<CategoriesObjType[]> = await restApiService.get(
        "categories.lk",
        null,
        null,
        true,
        null,
        false,
        signal,
      );
      return result.data;
    } catch (error) {
      commonService.handleError(error);
    }
  }
}

export function useGetCategories() {
  return useQuery(["categories"], ({ signal }) => ExpensesApis.getCategories(signal), {
    staleTime: 1000 * 60 * 5,
  });
}

export default ExpensesApis;
